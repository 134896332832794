import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Button, Select } from "antd";
import { useMediaQuery } from "react-responsive";
import BuyerSideNav from "./BuyerSideNav";
import formatter from "../../utils/formatter";
import { getSize } from "../../utils/getSizes";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { standardize_color } from "../../utils/getColorHex";

const BuyerOrderDetails = () => {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // if (!isTabletOrMobile) navigate("/account");

  const dispatch = useDispatch();
  // const { deleteAddress } = bindActionCreators(userActionCreators, dispatch);

  const [requestChangeValue, setRequestChangeValue] = useState("");

  const orderId = window.location.pathname.split("/")[2];

  const { orderItem } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.currency);

  const { rate, code, indicator } = data
    ? data
    : { rate: "", code: "", indicator: "" };

  const { fetchOrderItem } = bindActionCreators(userActionCreators, dispatch);

  const {
    delivery_status,
    delivery_address,
    order_information,
    order_receipt,
    payment_info,
    return_types,
  } = orderItem;

  const address = delivery_address?.address.split(",");
  const { number, alphabet } = getSize(delivery_status.product_size);

  useEffect(() => {
    if (isEmptyObject(orderItem)) {
      fetchOrderItem(orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderItem]);

  return (
    <div>
      <div className="account-order-details-page-container">
        <div className="account-order-details-page-header">
          {/* <img
              src="/images/back-arrow.png"
              alt=""
              className="back-icon"
              onClick={() => navigate(-1)}
            /> */}
          <div className="account-order-details-page-title">My Orders</div>
          <div className="account-order-details-page-subtitle">
            View your order history, return or exchange an item and order items
            again here{" "}
          </div>
          <div className="account-order-details-page-header-divider"></div>
        </div>

        <div className="account-order-details-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="1" />}

          <div className="account-order-details-content-main">
            <div className="account-order-details-content-main-section order-info-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Your Order Information
                </div>
                <div className="account-order-details-content-main-section-subtitle">
                  Thank you for shopping with Losode. See information about your
                  order below
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <table className="order-info-section-table">
                  <tbody>
                    <tr>
                      <td className="order-info-section-table-label">
                        Number of Items:
                      </td>
                      <td className="order-info-section-table-data">1</td>
                    </tr>
                    <tr>
                      <td className="order-info-section-table-label">
                        Order Number:
                      </td>
                      <td className="order-info-section-table-data">
                        {order_information?.order_no}
                      </td>
                    </tr>
                    <tr>
                      <td className="order-info-section-table-label">
                        Date Sent:
                      </td>
                      <td className="order-info-section-table-data">
                        {order_information?.date_sent}
                      </td>
                    </tr>
                    {order_information?.delivery_date ? (
                      <tr>
                        <td className="order-info-section-table-label">
                          Delivery date:
                        </td>
                        <td className="order-info-section-table-data">
                          {order_information?.delivery_date}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="order-info-section-table-label">
                          Scheduled Delivery date:
                        </td>
                        <td className="order-info-section-table-data">
                          {order_information?.scheduled_delivery_date}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="account-order-details-content-main-section delivery-address-info-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Delivery Address
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                {address && (
                  <div className="delivery-address-info-section-content-container">
                    <div>
                      {address[0]}, {address[1]}
                    </div>
                    <div>{address[2]}</div>
                    <div>{address[3]}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="account-order-details-content-main-section delivery-status-info-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Delivery Status
                </div>
                <div className="account-order-details-content-main-section-subtitle">
                  {delivery_status?.delivery_status.toUpperCase()}
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <div className="delivery-status-info-section-content-container">
                  <div className="delivery-status-info-subsection">
                    <div className="delivery-status-info-subsection-image-section">
                      <div
                        className="delivery-status-info-subsection-image"
                        style={{
                          backgroundImage: `url(${delivery_status?.product_img})`,
                        }}
                      />
                    </div>
                    <div className="delivery-status-info-subsection-text-section">
                      <div>
                        <h4
                          className="delivery-status-info-subsection-text-section-title"
                          style={{ fontWeight: "bold", margin: "0" }}
                        >
                          {delivery_status?.brand_name}
                        </h4>
                        <div className="delivery-status-info-subsection-image-section-id">
                          Item ID: {delivery_status?.product_id}
                        </div>
                      </div>
                      <div
                        className="delivery-status-info-subsection-text-section-subtitle"
                        style={{ fontWeight: "bold" }}
                      >
                        {delivery_status?.product_name}
                      </div>
                      {delivery_status?.product_color && (
                        <div className="delivery-status-info-subsection-text-section-color-div">
                          <div
                            className="delivery-status-info-subsection-text-section-color"
                            style={{
                              background: standardize_color(
                                delivery_status?.product_color
                              ),
                            }}
                          ></div>
                          <div className="delivery-status-info-subsection-text-section-subtitle">
                            {delivery_status?.product_color}
                          </div>
                        </div>
                      )}
                      {number && alphabet && (
                        <div className="delivery-status-info-subsection-text-section-subtitle sizes-div">
                          <div>{number}</div>
                          <div>{alphabet}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delivery-status-info-divider"></div>
                  <div className="delivery-status-info-details">
                    <div className="delivery-status-info-details-table-container">
                      <table className="delivery-status-info-details-table">
                        <tbody>
                          <tr>
                            <td className="delivery-status-info-details-table-info-label">
                              Delivery Option:
                            </td>
                            <td className="delivery-status-info-details-table-info-data">
                              {delivery_status?.delivery_option}
                            </td>
                          </tr>
                          <tr>
                            <td className="delivery-status-info-details-table-info-label">
                              Shipped Date:
                            </td>
                            <td className="delivery-status-info-details-table-info-data">
                              {order_information?.date_sent}
                            </td>
                          </tr>
                          <tr>
                            <td className="delivery-status-info-details-table-info-label">
                              Order Sent{" "}
                            </td>
                            <td className="delivery-status-info-details-table-info-data">
                              {delivery_status?.order_sent_by.toUpperCase()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Button
                      className="delivery-status-info-details-view-btn"
                      disabled
                    >
                      Track Parcel
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="account-order-details-content-main-section payment-info-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Payment Information
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <div className="payment-info-section-content-container">
                  <table className="delivery-status-info-details-table">
                    <tbody>
                      <tr>
                        <td className="delivery-status-info-details-table-info-label">
                          Paid With:
                        </td>
                        <td className="delivery-status-info-details-table-info-data">
                          {payment_info?.gateway}
                          {/* <img
                            style={{ height: "21px" }}
                            src="/images/losodepay.png"
                            alt="losodepay"
                          /> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="account-order-details-content-main-section order-reciept-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Order Reciept
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <div className="order-reciept-section-content-container">
                  <div className="order-reciept-section-content-item">
                    <div className="order-reciept-section-content-item-text-section">
                      <div
                        className="delivery-status-info-subsection-text-section-title"
                        style={{ fontWeight: "bold" }}
                      >
                        {delivery_status?.product_name}
                      </div>
                      <div
                        className="delivery-status-info-subsection-text-section-subtitle"
                        style={{
                          color: "#707070",
                          fontSize: "12px",
                        }}
                      >
                        (Item ID: {delivery_status?.product_id})
                      </div>
                      <div className="delivery-status-info-subsection-text-section-color-div">
                        <div className="delivery-status-info-subsection-text-section-color"></div>
                        <div className="delivery-status-info-subsection-text-section-subtitle">
                          {delivery_status?.product_color}
                        </div>
                      </div>
                      <div className="delivery-status-info-subsection-text-section-subtitle">
                        {delivery_status?.product_size}
                      </div>
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {formatter(
                        order_receipt?.cost_of_items,
                        rate,
                        code,
                        indicator
                      )}
                    </div>
                  </div>
                </div>

                <div className="order-reciept-addition-section">
                  <div className="order-reciept-addition-section-row">
                    <div>Cost Of Items</div>
                    <div style={{ fontWeight: "bold" }}>
                      {formatter(
                        order_receipt?.cost_of_items,
                        rate,
                        code,
                        indicator
                      )}
                    </div>
                  </div>
                  <div className="order-reciept-addition-section-row">
                    {order_receipt?.vat !== "0.00" && (
                      <>
                        <div>V.A.T.</div>
                        <div style={{ fontWeight: "bold" }}>
                          {formatter(order_receipt?.vat, rate, code, indicator)}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="order-reciept-addition-section-row">
                    <div>Delivery Fees</div>
                    <div style={{ fontWeight: "bold" }}>
                      {formatter(
                        order_receipt?.delivery_fees,
                        rate,
                        code,
                        indicator
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="order-reciept-addition-section-row"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>Total</div>
                  <div style={{ fontWeight: "bold" }}>
                    {formatter(
                      order_receipt?.total_cost,
                      rate,
                      code,
                      indicator
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="account-order-details-content-main-section returns-exchange-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Returns, Exchanges and Amendments
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <div className="returns-exchange-section-content-container">
                  <div style={{ marginBottom: "8px" }}>
                    It is our hope that you are satisfied with your order,
                    however we understand that this may not always be the case.
                  </div>
                  <div>
                    Your 'Returns' options are dictated by your designer. Click on
                    the button below to request a Return, Amendments or Exchange
                    on items purchased
                  </div>
                  <Select
                    style={{
                      width: "300px",
                      margin: !isTabletOrMobile
                        ? "40px auto 24px"
                        : "24px auto",
                      display: "block",
                    }}
                    placeholder="Select An Option"
                    value={requestChangeValue || undefined}
                    onSelect={(value) => setRequestChangeValue(value)}
                  >
                    {return_types?.includes("Amendments") && (
                      <Select.Option
                        value={`/${order_information.order_number}/request-amendment`}
                      >
                        Request An Amendment
                      </Select.Option>
                    )}
                    {return_types?.includes("Exchange") && (
                      <Select.Option
                        value={`/${order_information.order_number}/request-exchange`}
                      >
                        Request An Exchange
                      </Select.Option>
                    )}
                    {return_types?.includes("Returns") && (
                      <Select.Option
                        value={`/${order_information.order_number}/request-return`}
                      >
                        Request A Return
                      </Select.Option>
                    )}
                  </Select>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      className="delivery-status-info-details-view-btn"
                      style={{ width: "300px", marginBottom: "40px" }}
                      onClick={() => {
                        if (requestChangeValue) navigate(requestChangeValue);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="account-order-details-content-main-section get-help-section">
              <div className="account-order-details-content-main-section-header">
                <div className="account-order-details-content-main-section-title">
                  Get Help
                </div>
              </div>
              <div className="account-order-details-content-main-section-body">
                <div
                  className="get-help-section-content-container"
                  style={{ marginBottom: "24px" }}
                >
                  <div
                    onClick={() => navigate("/faqs")}
                    style={{ cursor: "pointer" }}
                  >
                    Frequently Asked Questions
                  </div>
                  <div className="delivery-status-info-divider"></div>
                  <div>
                    Need more help? Contact our customer experience team, send
                    an email to{" "}
                    <a
                      href="mailto:hello@losode.com"
                      style={{ color: "#800000" }}
                    >
                      hello@losode.com
                    </a>{" "}
                    {/* or by phone on xxx.xxx.xxx */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          .account-order-details-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-order-details-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-order-details-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-order-details-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-order-details-page-title{
            font-size:24px
          }
          .account-order-details-page-subtitle{
            font-size:16px
          }
          .account-order-details-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #e4e4e4;
            margin: 16px auto 24px auto;
          }

          .account-order-details-content-main-section{
            background:white;
            padding:24px;
            max-width: 800px;
            margin-bottom:40px;
            padding-left: 50px;
            padding-right: 100px;
          }
          .account-order-details-content-main-section-header{
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 16px;
          }
          .account-order-details-content-main-section-title{
            font-size:18px;
            font-weight:bold;
          }
          .account-order-details-content-main-section-subtitle{
            font-size:14px;
            margin-top:8px;
          }

          .order-info-section-table {
            border-collapse: unset;
          }
          .order-info-section-table td {
            padding: 0 100px 16px 0;
            min-width: 230px;
          }
          .order-info-section-table-label {
            color: #707070;
          }
          .order-info-section-table-data{
            font-weight:bold
          }

          .delivery-address-info-section-content-container{
            display:flex;
            flex-direction:column;
            gap:8px
          }

          .delivery-status-info-subsection{
            display: flex;
            gap:105px;
            margin-bottom: 16px;
            font-size: 14px;
          }


          .delivery-status-info-details{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
          }
          .delivery-status-info-subsection-image-section{
            display: flex;
            gap: 8px;
            flex-direction: column;
          }
          .delivery-status-info-subsection-image{
            height: 130px;
            width: 130px;
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
          }
          .delivery-status-info-subsection-track-btn, .delivery-status-info-details-view-btn{
            background: black;
            width: 200px;
            color: white;
            font-size: 14px;
            height: 48px;
          }
          .delivery-status-info-details-table {
            border-collapse: unset;
          }
          .delivery-status-info-details-table td {
            padding: 0 100px 16px 0;
            min-width: 230px;
          }
          .delivery-status-info-details-table-info-label {
            color: #707070;
          }
          .delivery-status-info-details-table-info-data{
            font-weight:bold;
            text-transform: capitalize;
          }

          .delivery-status-info-divider{
            border-bottom: 1px solid #e4e4e4;
            margin: 16px 0;
          }

          .delivery-status-info-subsection-text-section{
            display:flex;
            gap:6px;
            flex-direction:column;
          }

          .delivery-status-info-subsection-text-section-color-div{
            display:flex;
            gap: 8px;
            align-items: center;
          }

          .delivery-status-info-subsection-text-section-color{
            width: 14px;
            height: 14px;
            background: black;
            border-radius: 50%;
          }
          .order-reciept-section-content-item{
            display:flex;
            align-items:center;
            padding-bottom:24px;
            margin-bottom:24px;
            border-bottom: 1px solid #e4e4e4;
            justify-content:space-between
          }

          .order-reciept-section-content-item-text-section{
            display:flex;
            flex-direction:column;
            gap:2px
          }

          .order-reciept-addition-section{
            padding-bottom:24px;
            margin-bottom:24px;
            border-bottom: 1px solid #e4e4e4;
            display:flex;
            flex-direction:column;
            gap:8px
          }
          .order-reciept-addition-section-row{
            display:flex;
            justify-content:space-between;
          }

          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }

          .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            line-height:3.5
          }

          .sizes-div {
            display: flex;
            border: 1px solid;
            justify-content: space-evenly;
            align-items: center;
            width: 80px;
            height: 30px
          }
          .sizes-div div {
            background-color: #6F6F6F;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 20px;
            border: 1px solid;
            color: #fff;
            font-size: 11px;
          }

          @media(max-width:640px){
            .account-order-details-content{
              margin-top:0
            }
            .account-order-details-content-main{
              margin-left:0;
              padding:0;
            }
            .account-order-details-page-title {
              font-size: 18px;
            }
            .account-order-details-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-order-details-content-main-section{
              padding: 16px;
              margin-bottom: 16px;
            }
            .order-info-section-table td,
            .delivery-status-info-details-table td{
              padding:0px 0px 20px 0;
              min-width:auto
            }
            .order-info-section-table,
            .delivery-status-info-details-table{
              width:100%
            }
            .delivery-status-info-subsection{
              gap: 24px;
            }
            .delivery-status-info-details{
              flex-direction: column;
              gap: 8px;
              margin-bottom:0
            }
            .account-order-details-content-main-section-title{
              font-size: 16px;
            }
            .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
              line-height: 2;
              font-size: 14px;
            }
            .ant-select-single .ant-select-selector .ant-select-selection-item{
              font-size: 14px;
            }
          }
          
        `}
      </style>
    </div>
  );
};

export default BuyerOrderDetails;
