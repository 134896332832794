import colors from "../assets/colors";

const standardizeColor = (str) => {
  let color = colors.find((color) => color.name === str);
  const ctx = document.createElement("canvas").getContext("2d");
  ctx.fillStyle = color?.hex;
  return ctx.fillStyle;
};

export default standardizeColor;
