import React from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Button, Tag, Collapse } from "antd";
import { useMediaQuery } from "react-responsive";
// import { DownOutlined } from "@ant-design/icons";
import BuyerSideNav from "./BuyerSideNav";
import { getSize } from "../../utils/getSizes";
import { standardize_color } from "../../utils/getColorHex";

const { Panel } = Collapse;

const MultipleOrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // if (!isTabletOrMobile) navigate("/account");

  const { fetchOrderItem } = bindActionCreators(userActionCreators, dispatch);

  const { sortedOrder } = useSelector((state) => state.user);

  const viewItem = async (id) => {
    const res = await fetchOrderItem(id);
    if (res.status === "ok") {
      navigate(`/order/${id}/details`);
    }
  };

  const Status = ({ order }) => {
    const status = order?.delivery_status;
    let color = "success";

    if (status?.toLowerCase() === "paid") color = "#8ed6cb";
    if (status?.toLowerCase() === "sent") {
      color = "orange";
    }
    if (
      status?.toLowerCase() === "cancelled" ||
      status?.toLowerCase() === "rejected"
    )
      color = "#D00202";
    return (
      <Tag color={color} className="account-multiorder-list-item-status">
        {status}
      </Tag>
    );
  };

  return (
    <div>
      <div className="account-multiorder-page-container">
        <div className="account-multiorder-page-header">
          {/* <img
              src="/images/back-arrow.png"
              alt=""
              className="back-icon"
              onClick={() => navigate(-1)}
            /> */}
          <div className="account-multiorder-page-title">My Orders</div>
          <div className="account-multiorder-page-subtitle">
            View your order history, return or exchange an item and order items
            again here{" "}
          </div>
          <div className="account-multiorder-page-header-divider"></div>
        </div>

        <div className="account-multiorder-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="1" />}

          <div className="account-multiorder-content-main">
            {sortedOrder.map((order, idx) => (
              <Collapse
                style={{ maxWidth: 800, border: 0 }}
                defaultActiveKey={["1", "2"]}
                key={idx}
              >
                <Panel header={`Designer: ${order.designer}`} key="1">
                  <div className="account-multiorder-list-item-divider"></div>
                  <div style={{ textAlign: "right" }}>
                    {order.item_quantity}{" "}
                    {order.item_quantity > 1 ? "Items" : "Item"}
                  </div>
                  <div className="account-multiorder-list-item-divider"></div>
                  <div className="account-multiorder-list-item-all">
                    {order?.items?.map((item, idx) => {
                      const { number, alphabet } = getSize(item.product_size);
                      return (
                        <div
                          className="account-multiorder-list-item-overall-container"
                          key={idx}
                        >
                          <Status order={order} />
                          <div className="account-multiorder-list-item-body-container">
                            <div className="account-multiorder-list-item-section-content-container">
                              <div className="account-multiorder-list-item-subsection">
                                <div className="account-multiorder-list-item-subsection-image-section">
                                  <div
                                    className="account-multiorder-list-item-subsection-image"
                                    style={{
                                      backgroundImage: `url(${item.product_img})`,
                                    }}
                                  />
                                </div>
                                <div className="account-multiorder-list-item-subsection-text-section">
                                  <div>
                                    <h4
                                      className="account-multiorder-list-item-subsection-text-section-subtitle"
                                      style={{
                                        fontWeight: "bold",
                                        margin: "0",
                                      }}
                                    >
                                      {item.product_name}
                                    </h4>
                                    <div className="account-multiorder-list-item-subsection-image-section-id">
                                      Item ID: {item.product_id}
                                    </div>
                                  </div>
                                  {item.product_color && (
                                    <div className="account-multiorder-list-item-subsection-text-section-color-div">
                                      <div
                                        className="account-multiorder-list-item-subsection-text-section-color"
                                        style={{
                                          backgroundColor: standardize_color(
                                            item.product_color
                                          ),
                                        }}
                                      ></div>
                                      <div className="account-multiorder-list-item-subsection-text-section-subtitle">
                                        {item.product_color}
                                      </div>
                                    </div>
                                  )}
                                  {number && alphabet && (
                                    <div className="account-multiorder-list-item-subsection-text-section-subtitle sizes-div">
                                      <div>{number}</div>
                                      <div>{alphabet}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="account-multiorder-list-item-button-container">
                              <Button
                                className="account-multiorder-list-item-subsection-buy-btn"
                                onClick={() =>
                                  navigate(`/product/${item.product_slug}`)
                                }
                              >
                                Buy Item Again
                              </Button>
                              <Button
                                className="account-multiorder-list-item-details-view-btn"
                                onClick={() => viewItem(item.order_no)}
                              >
                                View Item
                              </Button>
                            </div>
                          </div>
                          <div className="account-multiorder-list-item-divider"></div>
                        </div>
                      );
                    })}
                  </div>

                  <table className="order-info-section-table">
                    <tbody>
                      <tr>
                        <td className="order-info-section-table-label">
                          Number of Items:
                        </td>
                        <td className="order-info-section-table-data">
                          {order.item_quantity}
                        </td>
                      </tr>
                      <tr>
                        <td className="order-info-section-table-label">
                          Order Number:
                        </td>
                        <td className="order-info-section-table-data">
                          {order.sale_no}
                        </td>
                      </tr>
                      <tr>
                        <td className="order-info-section-table-label">
                          Date Sent:
                        </td>
                        <td className="order-info-section-table-data">
                          {order.shipped_date}
                        </td>
                      </tr>
                      {order?.delivery_date ? (
                        <tr>
                          <td className="order-info-section-table-label">
                            Delivery date:
                          </td>
                          <td className="order-info-section-table-data">
                            {order.delivery_date}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="order-info-section-table-label">
                            Scheduled Delivery date:
                          </td>
                          <td className="order-info-section-table-data">
                            {order.scheduled_delivery_date}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Panel>
              </Collapse>
            ))}
          </div>
        </div>
      </div>

      <style>
        {`
          .account-multiorder-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-multiorder-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-multiorder-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-multiorder-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-multiorder-page-title{
            font-size:24px
          }
          .account-multiorder-page-subtitle{
            font-size:16px
          }
          .account-multiorder-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #e4e4e4;
            margin: 16px auto 24px auto;
          }

          .account-multiorder-content-main-section{
            background:white;
            padding:24px;
            max-width: 800px;
            margin-bottom:40px;
            padding-left: 50px;
            padding-right: 100px;
          }
          .account-multiorder-content-main-section-header{
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 16px;
            display:flex;
            justify-content:space-between
          }
          .account-multiorder-content-main-section-subtitle{
            font-size:14px;
            margin-top:8px;
          }

          .order-info-section-table {
            border-collapse: unset;
          }
          .order-info-section-table td {
            padding: 0 100px 10px 0;
            min-width: 230px;
          }
          .order-info-section-table-label {
            color: #707070;
          }
          .order-info-section-table-data{
            font-weight:bold
          }

          
          .account-multiorder-list-item-subsection{
            display: flex;
            gap:16px;
            font-size: 14px;
          }


          .account-multiorder-list-item-details{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
          }
          .account-multiorder-list-item-subsection-image-section{
            display: flex;
            gap: 8px;
            flex-direction: column;
          }
          .account-multiorder-list-item-subsection-image{
            background: url("/images/category_footwear.png");
            height: 130px;
            width: 130px;
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
          }
          .account-multiorder-list-item-subsection-buy-btn, .account-multiorder-list-item-details-view-btn{
            background: black;
            width: 200px;
            color: white;
            font-size: 14px;
            height: 48px;
          }
          

          .account-multiorder-list-item-divider{
            border-bottom: 1px solid #e4e4e4;
            margin: 16px 0;
          }

          .account-multiorder-list-item-subsection-text-section{
            display:flex;
            gap:6px;
            justify-content: space-between;
            flex-direction:column;
            max-width:245px
          }

          .account-multiorder-list-item-subsection-text-section-color-div{
            display:flex;
            gap: 8px;
            align-items: center;
          }

          .account-multiorder-list-item-subsection-text-section-color{
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }

          .account-multiorder-list-item-status{
            font-size: 12px;
            font-weight:bold;
            padding: 4px;
            margin-bottom: 16px;
            width:100px;
            text-align:center;
          }
          .account-multiorder-list-item-body-container{
              display:flex;
              justify-content:space-between
          }

          .account-multiorder-list-item-button-container{
            display:flex;
            flex-direction:column;
            justify-content:space-between
        }

        .account-multiorder-list-item-all{
            display:flex;
            flex-direction:column;
            gap:16px
        }

        .ant-collapse-content > .ant-collapse-content-box {
            padding: 2px 100px 40px 50px;
        }
        .ant-collapse-content{
            border: 0;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header{
            font-size:18px;
            font-weight:bold;
            padding: 16px;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 24px;
            padding-left: 50px;
            background:white
        }
        .ant-collapse > .ant-collapse-item{
            margin-bottom:40px;
            border:1px solid #f4f4f4
        }

        .sizes-div {
          display: flex;
          border: 1px solid;
          justify-content: space-evenly;
          align-items: center;
          width: 80px;
          height: 30px;
          margin-top: 5px;
        }
        .sizes-div div {
          background-color: #6F6F6F;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 20px;
          border: 1px solid;
          color: #fff;
          font-size: 11px;
        }

        @media(max-width:640px){
          .account-multiorder-content{
            margin-top:0
          }
          .account-multiorder-content-main{
            margin-left:0;
            padding:0;
          }
          .account-multiorder-page-title {
            font-size: 18px;
          }
          .account-multiorder-page-subtitle{
            font-size:12px;
            padding: 0 16px;
          }
          .ant-collapse > .ant-collapse-item > .ant-collapse-header{
            padding: 16px;
            color: black !important;
            font-size:16px;
          }
          .ant-collapse-content > .ant-collapse-content-box{
            padding: 0 16px;
          }
          .account-multiorder-list-item-body-container{
            flex-direction: column;
            gap: 16px;
          }
          .account-multiorder-list-item-subsection-buy-btn, .account-multiorder-list-item-details-view-btn{
            width: 100%;
            margin-top:16px
          }
          .account-multiorder-list-item-button-container{
            flex-direction: column-reverse
          }
          .account-multiorder-list-item-subsection-buy-btn{
            background:white;
            color:black
          }
          .order-info-section-table td{
            padding:0px 0px 20px 0;
            min-width:auto
          }
          .order-info-section-table{
            width:100%
          }
        }
        
          
        `}
      </style>
    </div>
  );
};

export default MultipleOrderDetails;
