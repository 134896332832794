export default class Brands {
  constructor(client) {
    this.client = client;
  }

  fetchBrands() {
    return this.client.get(`brands`);
  }
  fetchBrand(slug) {
    return this.client.get(`brand/${slug}`);
  }
  fetchMenuBrands() {
    return this.client.get(`featured/brands`);
  }
}