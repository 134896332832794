import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Footer } from "../../components/layout";

const NewAccount = () => {
  const navigate = useNavigate();

  const { info } = useSelector((state) => state.user);

  const links = [
    {
      id: 1,
      title: "My Orders",
      desc: "View your order history, return or exchange an item and order again here ",
      link: "/orders",
    },
    {
      id: 2,
      title: "Saved Items",
      desc: "Add items you love here to track their availability and purchase them when you are ready ",
      link: "/saved-items",
    },
    {
      id: 3,
      title: "Account Details",
      desc: "View or edit your account information here",
      link: "/settings",
    },
    {
      id: 4,
      title: "My Addresses",
      desc: "Manage your delivery addresses here",
      link: "/address",
    },
    // {
    //   id: 5,
    //   title: "Losode Credits ",
    //   desc: "Check your available balance here",
    //   link: "",
    // },
    {
      id: 6,
      title: "Email Preferences",
      desc: "View or edit your email preferencees",
      link: "/email-preferences",
    },
  ];

  return (
    <div>
      <Layout />
      <div className="user-account-container">
        <div className="title-div">
          <h3 className="user-welcome-txt">Hello {info.fname}</h3>
          <span className="span-welcome-txt">Welcome to your Account</span>
        </div>
        <div className="account-items-container">
          {links.map((option) => (
            <div
              className="account-item-box"
              key={option.id}
              onClick={() => navigate(option.link)}
            >
              <h3 className="txt-item-title">{option.title}</h3>
              <hr />
              <p className="txt-item-desc">{option.desc}</p>
            </div>
          ))}
        </div>
        <div className="user-account-ads-container">
          <img alt="ads" src="/images/buyer-ads.png" />
        </div>
      </div>

      <Footer />

      <style jsx="true">{`
        .user-account-container {
        }
        .title-div {
          background: url("/images/acct-bg.jpeg");
          background-size: cover;
          padding: 119px 108px;
          height: 232px;
        }
        .user-welcome-txt {
          font-family: "DomaineSansText-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 38.2439px;
          line-height: 49px;
          margin: 0px;
        }
        .span-welcome-txt {
          font-family: "DomaineSansText-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
        .account-items-container {
          background: #f9f9f9;
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          justify-content: center;
          padding: 54px 120px 300px 120px;
        }
        .account-item-box {
          background: #000000;
          cursor: pointer;
          width: 350px;
          height: 236px;
          padding: 42px 37px;
        }
        .txt-item-title {
          font-family: "DomaineSansText-Regular";
          font-weight: 400;
          font-size: 32px;
          line-height: 41px;
          margin-bottom: 31px;
          color: #ffffff;
          text-align: center;
        }
        .txt-item-desc {
          font-family: "DomaineSansText-Regular";
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
          margin-top: 26px;
        }
        .user-account-ads-container {
          text-align: center;
          padding: 50px;
        }

        @media (max-width: 640px) {
          .user-account-container {
            margin: 0 !important;
            width: 100% !important;
            padding: 16px;
          }
          .title-div {
            padding: 8px;
            margin-top: 0 !important;
            height: auto !important;
            width: 100% !important;
          }
          .user-welcome-txt {
            font-size: 24px;
            line-height: normal;
          }
          .span-welcome-txt {
            font-size: 18px;
            line-height: normal;
          }
          .account-items-container {
            background: white;
            padding: 0;
            margin: 40px auto;
            justify-content: center;
          }
          .txt-item-title {
            font-size: 24px;
            margin-bottom: 16px;
            line-height: normal;
          }
          .account-item-box {
            width: 100%;
            padding: 30px;
            height: 205px;
          }
          .user-account-ads-container {
            padding: 0 0 40px;
          }
          .user-account-ads-container img {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default NewAccount;
