export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_HEADER_CATEGORIES = "FETCH_HEADER_CATEGORIES";
export const FETCH_FOOTER_CATEGORIES = "FETCH_FOOTER_CATEGORIES";
export const FETCH_PRODUCTS_BY_CATEGORY = "FETCH_PRODUCTS_BY_CATEGORY";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const UPDATE_FILTER_PRODUCTS = "UPDATE_FILTER_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const FETCH_NEW_IN_MENU = "FETCH_NEW_IN_MENU";
