import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DesignerMenu = ({ showDesigners, hideDesigners, visible }) => {
  const navigate = useNavigate();

  const { menuBrands: data } = useSelector((state) => state.brands);

  // const { brands, weekly_brands } = data;
  const brands = data?.brands;
  const weekly_brands = data?.weekly_brands;

  const shopBrands = (slug) => {
    navigate(`/designers/${slug}`);
    hideDesigners();
  };

  return (
    <div className="designer-menu-container">
      <div className="menu-overlay" />
      <div
        className="menu-content"
        onMouseEnter={() => showDesigners()}
        onMouseLeave={() => hideDesigners()}
      >
        <div className="subs-designers-cat-container">
          <div className="designers-menu-cat">
            <h3 className="designer-menu-title" style={{ position: "absolute" }}>
              FEATURED DESIGNERS
            </h3>
            <div className="designers-menu" style={{ paddingTop: "18px" }}>
              <h3
                className="designer-item"
                onClick={() => {navigate("/designers"); hideDesigners();}}
                style={{ color: "#800000" }}
              >
                Designers A to Z
              </h3>
              {brands?.slice(0, 7).map((brand, idx) => (
                <h3
                  className="designer-item"
                  onClick={() => shopBrands(brand.slug)}
                  key={idx}
                >
                  {brand.store_name}
                </h3>
              ))}
            </div>
          </div>
          <div className="designers-menu-cat" style={{ paddingTop: "18px" }}>
            {brands?.slice(7, 15).map((brand, idx) => (
              <h3
                className="designer-item"
                onClick={() => shopBrands(brand.slug)}
                key={idx}
              >
                {brand.store_name}
              </h3>
            ))}
          </div>
        </div>
        {/* <div className="shop-by-cat">
          <h3 className="cat-title">Shop By brand</h3>
          <p className="sub-item">New designers</p>
          <p className="sub-item">Star designers</p>
          <p className="sub-item">Best female wears</p>
          <p className="sub-item">Best male wears</p>
          <p className="sub-item">Exclusives</p>
        </div> */}

        {/* <span className="vertical-line"></span> */}
        {weekly_brands && (
          <div className="featured-brand-container">
            <div className="menu-divider"></div>
            <div>
              <img
                className="cat-img"
                src="/images/designer_banner.jpeg"
                alt="brand"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/sell")}
              />
              <p
                className="txt-featured-brand"
                style={{ marginBottom: "5px", fontSize: "18px", color: "#000" }}
              >
                Grow your brand global
              </p>
              <p
                className="txt-shop-featured-brand"
                onClick={() => navigate("/sell")}
              >
                Create your store now 
              </p>
            </div>
          </div>
        )}
      </div>
      <style>{`
      
      .menu-overlay {
        position: fixed;
        left: 0px;
        top: 214px;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 998;
      }
      .designer-menu-container {
        display: ${visible};
        width: 100%;
      }

      .subs-designers-cat-container {
        display: flex;
        gap: 0 90px;
        flex-basis: 60%;
        border-right: 1px solid #a5a5a5;
      }

      .designer-menu-title{
        color:#000;
        font: normal bold 14px/18px DomaineSansText-Black;
        margin-bottom:0px;
      }
      .designer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 60vh;
        background: #ffffff;
        z-index: 999;
        padding: 40px 86px;
        margin-left: 3%;
        position: absolute;
        width:100%
      }
      .brands-left-div{
        flex-basis:60%;
      }
      .div-brands-list{
        align-items: flex-start;
        display: flex;   
        flex-direction: column;
        flex-wrap: wrap;
        height: 51%;
        gap: 0 43px;
       
      }
      .designer-item {
        cursor: pointer;
        font: normal normal 13px/16px DomaineSansText-Light;
        margin-top: 16px;
        height: 25px;
        margin-bottom: 0;
        text-transform: capitalize;
        color:#000;
        width: 150px;
      }
      .designer-item:hover {
        color: #800000;
      }
      .vertical-line {
        border: 0.5px solid #6F6F6F;
        height: 45vh;
        margin:24px;
        width: 0px;
      }
      .featured-brand-container{
        flex-basis: 45%;
        display: flex;
        justify-content: space-between;

      }
      .txt-featured-brand{
        font: normal normal 18px/23px DomaineSansText-Regular;
        margin-top:20px;
        width:282px;
      }
      .txt-shop-featured-brand{
        font: normal normal 14px/16px DomaineSansText-Light;
        color: #6F6F6F;
        cursor: pointer;
      }
      `}</style>
    </div>
  );
};

export default DesignerMenu;
