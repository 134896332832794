const buildfilterQuery = (
  type,
  id,
  page,
  code,
  filters,
  firstSelectedProperty
) => {
  // Initialize an empty filter query
  let query =
    type === "new-in"
      ? `${id}?page=${page}&`
      : type.includes("brand")
      ? `${type}?page=${page}&`
      : `${type}/${id}?page=${page}&`;

  // If a first selected property exists, add it to the query
  if (firstSelectedProperty) {
    const firstSelectedValues = filters[firstSelectedProperty];
    query += `${firstSelectedProperty}=${firstSelectedValues.join(",")}&`;

    // Remove the first selected property from the filters object
    delete filters[firstSelectedProperty];
  }

  // Loop through the remaining filters and concatenate the query
  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const values = filters[key];

      // Check if values is an array and it's not empty
      if (Array.isArray(values) && values.length > 0) {
        // Join the array values and append them to the query
        query += `${key}=${values.join(",")}&`;
      }
    }
  }

  // Remove the trailing "&" if it exists
  if (query.endsWith("&")) {
    query += `currency=${code}`;
    // query = query.slice(0, -1);
  }

  return query;
};

export default buildfilterQuery;
