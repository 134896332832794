import { FETCH_BEST_SELLERS_SUCCESS, FETCH_BEST_SELLERS_FAIL } from "./types";

const initialState = {
  items: [],
  error: "",
};

const bestSellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BEST_SELLERS_SUCCESS: {
      if (state.items.length === 0)
        // for initial load
        return { ...state, items: action.payload };
      return state;
    }
    case FETCH_BEST_SELLERS_FAIL: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default bestSellerReducer;
