import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Button, Radio, Input } from "antd";
import { useMediaQuery } from "react-responsive";
import BuyerSideNav from "./BuyerSideNav";
import { isEmptyObject } from "../../utils/isEmptyObject";

const RequestExchange = () => {
  //   const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // if (!isTabletOrMobile) navigate("/account");
  const orderId = window.location.pathname.split("/")[1];

  const { orderItem } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { fetchOrderItem } = bindActionCreators(userActionCreators, dispatch);

  const [requestReason, setRequestReason] = useState("");
  const [requestStatus, setRequestStatus] = useState("select");

  useEffect(() => {
    if (isEmptyObject(orderItem)) {
      fetchOrderItem( orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderItem]);

  const { delivery_status } = orderItem;

  return (
    <div>
      <div className="account-request-return-page-container">
        <div className="account-request-return-page-header">
          {/* <img
              src="/images/back-arrow.png"
              alt=""
              className="back-icon"
              onClick={() => navigate(-1)}
            /> */}
          <div className="account-request-return-page-title">My Orders</div>
          <div className="account-request-return-page-subtitle">
            View your order history, return or exchange an item and order items
            again here{" "}
          </div>
          <div className="account-request-return-page-header-divider"></div>
        </div>

        <div className="account-request-return-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="1" />}

          <div className="account-request-return-content-main">
            <div className="account-request-return-content-main-section ">
              <div className="account-request-return-content-main-section-header">
                <div className="account-request-return-content-main-section-title">
                  Request an exchange
                </div>
                <div className="account-request-return-content-main-section-subtitle">
                  Request an exchange by selecting the item you want to return
                  and confirm your selection. Our Customer Experience team are
                  available to support you. Contact them at{" "}
                  <a
                    href="mailto:customers@losode.com"
                    style={{ color: "#800000" }}
                  >
                    customers@losode.com
                  </a>
                </div>
              </div>
            </div>

            {requestStatus === "select" && (
              <div className="account-request-return-content-main-section item-request-section">
                <div className="account-request-return-content-main-section-header">
                  <div className="account-request-return-content-main-section-title">
                    Exchange An Item
                  </div>
                  <div className="account-request-return-content-main-section-subtitle">
                    Select item(s) below to progress your exchange(s). Adjust
                    size and/or colour as needed
                  </div>
                </div>
                <div className="account-request-return-content-main-section-body">
                  <div className="item-request-section-content-all">
                    <div className="item-request-section-content-container">
                      <div className="item-request-subsection">
                        <div className="item-request-subsection-content">
                          <div className="item-request-subsection-image-section">
                            <div className="item-request-subsection-image"></div>
                          </div>
                          <div className="item-request-subsection-text-section">
                            <div className="item-request-subsection-text-section-title">
                              {delivery_status?.brand_name}
                            </div>
                            <div
                              className="item-request-subsection-text-section-subtitle"
                              style={{ fontWeight: "bold" }}
                            >
                              {delivery_status?.product_name}
                            </div>
                            <div className="item-request-subsection-image-section-id">
                              Item ID: {delivery_status?.product_id}
                            </div>
                            <div className="item-request-subsection-text-section-color-div">
                              <div className="item-request-subsection-text-section-color"></div>
                              <div className="item-request-subsection-text-section-subtitle">
                                {delivery_status?.product_color}
                              </div>
                            </div>
                            <div className="item-request-subsection-text-section-subtitle">
                              {delivery_status?.product_size}
                            </div>
                          </div>
                        </div>
                        <Button
                          className="item-request-details-view-btn"
                          onClick={() => setRequestStatus("reason")}
                        >
                          Return Item
                        </Button>
                      </div>
                      <div className="item-request-divider"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {requestStatus === "reason" && (
              <div className="account-request-return-content-main-section ">
                <div className="account-request-return-content-main-section-header">
                  <div className="account-request-return-content-main-section-title">
                    Why are you requesting an exchange for this item?
                  </div>
                  <div className="account-request-return-content-main-section-subtitle">
                    Please tell us why you are requesting an exchange for this
                    item. We will use this information to improve your
                    experience on Losode
                  </div>
                </div>
                <div className="account-request-return-content-main-section-body">
                  <div
                    className="get-help-section-content-container"
                    style={{ marginBottom: "24px" }}
                  >
                    <Radio.Group
                      onChange={(e) => setRequestReason(e.target.value)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "16px",
                        margin: "16px auto",
                      }}
                    >
                      <Radio value="size">
                        I want the item in a different size
                      </Radio>
                      <Radio value="color">
                        I want the item in a different color
                      </Radio>
                      <Radio value="different">
                        I want a different item from the same designer
                      </Radio>
                      <Radio value="reason not here">
                        My reason is not here
                      </Radio>
                    </Radio.Group>
                    {requestReason === "reason not here" && (
                      <Input.TextArea
                        style={{ minHeight: "100px" }}
                        className="no-reason-textbox"
                        placeholder="Please enter your reason for rejecting this order"
                      />
                    )}

                    <div className="request-response-button-container">
                      <Button
                        className="item-request-details-view-btn"
                        style={{ color: "black", background: "white" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="item-request-details-view-btn"
                        onClick={() => setRequestStatus("processing")}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {requestStatus === "processing" && (
              <>
                <div className="account-request-return-content-main-section ">
                  <div className="account-request-return-content-main-section-header">
                    <div className="account-request-return-content-main-section-title">
                      We Are Processing Your Exchange
                    </div>
                    <div className="account-request-return-content-main-section-subtitle">
                      Your exchange request has been confirmed and the designer
                      has been notified.
                    </div>
                  </div>
                  <div className="account-request-return-content-main-section-body">
                    <div
                      className="get-help-section-content-container"
                      style={{ marginBottom: "24px" }}
                    >
                      <div>
                        <div style={{ marginBottom: "8px" }}>
                          <strong>Your New Item</strong>
                        </div>
                        <div className="item-request-section-content-container">
                          <div className="item-request-subsection">
                            <div className="item-request-subsection-content">
                              <div className="item-request-subsection-image-section">
                                <div className="item-request-subsection-image"></div>
                              </div>
                              <div className="item-request-subsection-text-section">
                                <div className="item-request-subsection-text-section-title">
                                  Kaliki By Ete{" "}
                                </div>
                                <div
                                  className="item-request-subsection-text-section-subtitle"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Cotton Poplin Oversized Shirtdress
                                </div>
                                <div className="item-request-subsection-image-section-id">
                                  Item ID: D472349781234
                                </div>
                                <div className="item-request-subsection-text-section-color-div">
                                  <div className="item-request-subsection-text-section-color"></div>
                                  <div className="item-request-subsection-text-section-subtitle">
                                    Black
                                  </div>
                                </div>
                                <div className="item-request-subsection-text-section-subtitle">
                                  UK-10
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-request-divider"></div>
                      <div>
                        <div>
                          <strong>Additional Costs:</strong>
                        </div>
                        <div>
                          There are no additional costs on this exchange
                        </div>
                      </div>

                      <div className="item-request-divider"></div>
                      <div>
                        <div>
                          <strong>Next Steps:</strong>
                        </div>
                        <div>
                          Place item(s) in secure packaging. A member of our
                          Customer Experience team will reach out to you if
                          needed.
                        </div>
                      </div>

                      <div className="item-request-divider"></div>

                      <div>
                        <div>
                          <strong>Send the item to:</strong>
                        </div>
                        <div>
                          <div>John Doe</div>
                          <div>32, Cavemen Close</div>
                          <div>Cadbury</div>
                          <div>United Kingdom</div>
                        </div>
                      </div>

                      <div className="item-request-divider"></div>

                      <div>
                        Please give 7 - 10 days after the item has been received
                        by the Designer for the funds to appear in your bank
                        account. Our customer experience team will reach out
                        with further details
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "40px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "800px",
                  }}
                >
                  <Button className="item-request-details-view-btn">
                    Schedule A Collection
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <style>
        {`
          .account-request-return-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-request-return-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-request-return-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-request-return-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-request-return-page-title{
            font-size:24px
          }
          .account-request-return-page-subtitle{
            font-size:16px
          }
          .account-request-return-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #e4e4e4;
            margin: 16px auto 24px auto;
          }

          .account-request-return-content-main-section{
            background:white;
            padding:24px;
            max-width: 800px;
            margin-bottom:40px;
            padding-left: 50px;
            padding-right: 100px;
          }
          .account-request-return-content-main-section-header{
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 16px;
          }
          .account-request-return-content-main-section-title{
            font-size:18px;
            font-weight:bold;
          }
          .account-request-return-content-main-section-subtitle{
            font-size:14px;
            margin-top:8px;
          }

          .order-info-section-table {
            border-collapse: unset;
          }
          .order-info-section-table td {
            padding: 0 100px 16px 0;
            min-width: 230px;
          }
          .order-info-section-table-label {
            color: #707070;
          }
          .order-info-section-table-data{
            font-weight:bold
          }

          .delivery-address-info-section-content-container{
            display:flex;
            flex-direction:column;
            gap:8px
          }

          .item-request-subsection{
            display: flex;
            justify-content:space-between;
            margin-bottom: 16px;
            align-items: flex-end;
          }

          .item-request-subsection-content{
            display: flex;
            gap:16px;
            font-size: 14px;
          }


          .item-request-details{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
          }
          .item-request-subsection-image-section{
            display: flex;
            gap: 8px;
            flex-direction: column;
          }
          .item-request-subsection-image{
            background: url(${delivery_status?.product_img});
            height: 130px;
            width: 130px;
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
          }
          .item-request-subsection-track-btn, .item-request-details-view-btn{
            background: black;
            width: 200px;
            color: white;
            font-size: 14px;
            height: 48px;
          }
          .item-request-details-table {
            border-collapse: unset;
          }
          .item-request-details-table td {
            padding: 0 100px 16px 0;
            min-width: 230px;
          }
          .item-request-details-table-info-label {
            color: #707070;
          }
          .item-request-details-table-info-data{
            font-weight:bold
          }

          .item-request-divider{
            border-bottom: 1px solid #e4e4e4;
            margin: 16px 0;
          }

          .item-request-subsection-text-section{
            display:flex;
            gap:6px;
            flex-direction:column;
          }

          .item-request-subsection-text-section-color-div{
            display:flex;
            gap: 8px;
            align-items: center;
          }

          .item-request-subsection-text-section-color{
            width: 14px;
            height: 14px;
            background: black;
            border-radius: 50%;
          }
          

          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }

          .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            line-height:3.5
          }

          .request-response-button-container{
            display: flex;
            gap: 48px;
            margin-top: 40px;
          }

          @media(max-width:640px){
            .account-request-return-content{
              margin-top:0
            }
            .account-request-return-content-main{
              margin-left:0;
              padding:0;
            }
            .account-request-return-page-title {
              font-size: 18px;
            }
            .account-request-return-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-request-return-content-main-section{
              padding: 16px;
              margin-bottom: 16px;
            }
            .item-request-subsection{
              flex-direction: column;
              gap: 16px;
              align-items: flex-start;
            }
            .item-request-subsection-track-btn, .item-request-details-view-btn{
              width: 100%;
            }
            .request-response-button-container{
              flex-direction: column-reverse;
              gap: 16px;
            }
          }
          
        `}
      </style>
    </div>
  );
};

export default RequestExchange;
