import {
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REGISTER_SELLER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  GET_USER_ADDRESS,
  GET_USER_DETAILS,
  GET_USER_ITEMS,
  LOGOUT_USER,
  UPDATE_FIRST_TIME,
  SET_USER_TYPE,
  RESET_PASSWORD_SELLER_INITIATE,
  RESET_PASSWORD_SELLER_FAIL,
  RESET_PASSWORD_SELLER_RESET,
  RESET_PASSWORD_SELLER_CONFIRM,
  RESET_PASSWORD_SELLER_CONFIRM_FAIL,
  GET_EMAIL_PREF,
  GET_ORDERS,
  GET_ORDERS_DETAILS,
  GET_ORDER_ITEM,
} from "./type";

const initialState = {
  contact: [],
  error: false,
  emailPref: 0,
  errorMessage: "",
  isAuthenticated: false,
  isFirstTime: true,
  info: {},
  orders: [],
  orderItem: {},
  resetSuccess: null,
  resetSuccessConfirm: null,
  savedItems: [],
  settings: {},
  sortedOrder: [],
  type: "Women",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_SUCCESS: {
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        error: action.error,
        info: action.payload,
        isAuthenticated: true,
      };
    }
    case REGISTER_SELLER_SUCCESS: {
      return { ...state, info: action.payload, isAuthenticated: true };
    }
    case RESET_PASSWORD_SELLER_INITIATE: {
      return { ...state, resetSuccess: true };
    }
    case RESET_PASSWORD_SELLER_FAIL: {
      return { ...state, resetSuccess: false };
    }
    case RESET_PASSWORD_SELLER_RESET: {
      return { ...state, resetSuccess: null };
    }
    case RESET_PASSWORD_SELLER_CONFIRM: {
      return { ...state, resetSuccessConfirm: true };
    }
    case RESET_PASSWORD_SELLER_CONFIRM_FAIL: {
      return { ...state, resetSuccessConfirm: false };
    }
    case REGISTER_USER_FAIL: {
      return {
        ...state,
        error: action.error,
        errorMessage: action.payload,
      };
    }
    case LOGIN_USER_SUCCESS: {
      localStorage.setItem("token", action.payload.token);

      return {
        ...state,
        error: action.error,
        info: action.payload,
        isAuthenticated: true,
      };
    }
    case LOGOUT_USER: {
      localStorage.clear();
      return (state = initialState);
    }
    case LOGIN_USER_FAIL: {
      return { ...state, error: action.error, errorMessage: action.payload };
    }
    case GET_USER_ADDRESS: {
      return { ...state, contact: action.payload };
    }
    case GET_ORDERS: {
      return { ...state, orders: action.payload };
    }
    case GET_ORDERS_DETAILS: {
      return { ...state, sortedOrder: action.payload };
    }
    case GET_ORDER_ITEM: {
      return { ...state, orderItem: action.payload };
    }
    case GET_USER_DETAILS: {
      return { ...state, settings: action.payload };
    }
    case GET_USER_ITEMS: {
      return { ...state, savedItems: action.payload };
    }
    case UPDATE_FIRST_TIME: {
      return { ...state, isFirstTime: action.payload };
    }
    case SET_USER_TYPE: {
      return { ...state, type: action.payload };
    }
    case GET_EMAIL_PREF: {
      return { ...state, emailPref: action.payload };
    }

    default:
      return state;
  }
};

export default userReducer;
