import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import AuthModal from "../components/AuthModal";
import triggerNotification from "../hooks/triggerNotification";

const SellerResetPasswordConfirm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { slug } = useParams();

  const path = location.pathname;

  const { isAuthenticated, info, resetSuccess } = useSelector(
    (state) => state.user
  );

  const { resetPasswordSellerConfirm } = bindActionCreators(
    userActionCreators,
    dispatch
  );

  const onFinish = async (values) => {
    const res = await resetPasswordSellerConfirm({ ...values, token: slug });
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
    if (res.status === "ok")
      navigate(`/${path.split("/")[1]}/reset-password-successful`);
  };

  useEffect(() => {
    if (isAuthenticated && info.role === "2") navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  // useEffect(()=>{
  //     if (!token) navigate("/")
  // },[token, history])

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);

  return (
    <div>
      <div className="div-reset-confirm-password-seller">
        <div className="reset-confirm-register-container">
          <div className="form-container">
            <h2 className="reset-confirm-txt-register">Reset password</h2>
            {!resetSuccess && (
              <p className="reset-confirm-txt-setup">
                To reset your password, please enter you email address
              </p>
            )}
            <Form
              form={form}
              name="login"
              onFinish={onFinish}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
            >
              <div className="reset-confirm-inputs-container">
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please use an acceptable email format",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="reset-confirm-input" />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  label="New password"
                  extra="Password must be longer than 8 characters and must contain at least 1 capital letter, 1 small letter and 1 number "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      pattern:
                        /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                      message:
                        "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                    },
                  ]}
                >
                  <Input.Password
                    // placeholder="Password"
                    type="password"
                    className="reset-confirm-input"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  label="Confirm password"
                  dependencies={["newPassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="reset-confirm-input"
                    // placeholder="Confirm Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="reset-confirm-btn-register"
                    type="primary"
                    htmlType="submit"
                  >
                    Reset
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>

        <AuthModal
          authModalVisible={sellerAuthModalVisible}
          setAuthModalVisible={setSellerAuthModalVisible}
          activePage="1"
          pageUserType="seller"
        />
      </div>

      <style jsx>
        {`
          .div-reset-confirm-password-seller {
            background: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 0;
          }
          .reset-confirm-txt-setup {
            font-family: "DomaineSansText-Regular";
            margin: 16px 3vw;
          }
          .ant-form label,
          .ant-form-item-explain.ant-form-item-explain-error {
            font-size: 14px;
            font-family: "DomaineSansText-Regular";
          }

          .reset-confirm-register-container {
            text-align: center;
            justify-content: center;
            background: white;
            padding: 24px;
            width: 45%;
            margin: auto;
          }
          .reset-confirm-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
          }

          .reset-confirm-inputs-container {
            margin: 0 24px;
            padding-bottom: 24px;
          }

          .ant-input-affix-wrapper > input.ant-input {
            color: #000;
          }

          .reset-confirm-input {
            border: 2px solid #ccc;
            color: #000;
            font-size: 16px !important;
            height: 60px;
            font-family: "DomaineSansText-Light";
          }
          .ant-form-item-explain.ant-form-item-explain-error,
          .ant-form-item-extra {
            text-align: left;
            margin-top: 4px;
          }

          .reset-confirm-input:focus,
          .reset-confirm-input:hover {
            border: 2px solid #000;
          }
          .reset-confirm-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 60px;
            margin-top: 8px;
          }

          @media (max-width: 1100px) and (min-width: 600px) {
            .reset-confirm-register-container {
              width: 65%;
            }
          }

          @media screen and (max-width: 600px) {
            .reset-confirm-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .div-reset-confirm-password-seller {
              background: white;
              padding: 20px 0;
            }
            .reset-confirm-txt-setup {
              font-size: 14px;
              margin: 8px 0;
            }
            .reset-confirm-txt-signin {
              display: block;
              margin-top: 16px;
            }
            .ant-input:placeholder-shown,
            .ant-picker-input > input:placeholder-shown {
              font: normal normal 14px/14px DomaineSansText-Regular !important;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
            .reset-confirm-register-container {
              width: auto;
            }
            a {
              text-decoration: underline;
              font: normal normal normal 14px DomaineSansText-Regular;
              letter-spacing: 0px;
              color: #000000;
            }
            .reset-confirm-btn-register {
              margin-top: 16px;
            }
            .ant-form-item {
              margin-bottom: 20px !important;
            }
            .ant-form-item-extra {
              margin-bottom: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SellerResetPasswordConfirm;
