import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  Col,
  Select,
  Row,
  Space,
  Tooltip,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { countries } from "../../assets/constants";
import { fetchStates, fetchCities } from "../../utils/getStates";
import PhoneDial from "../PhoneDial";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const Address = ({ address, visible, setVisible, onSubmit, type }) => {
  const [addressForm] = Form.useForm();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [countryValue, setCountryValue] = useState("Nigeria");
  const [alternativePhone, setAlternativePhone] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState("");

  const onSelectCountry = async () => {
    if (countryValue && states.length === 0) {
      const res = await fetchStates(countryValue);
      setStates(res);
    }
  };

  const getCities = async () => {
    setCities(await fetchCities("nigeria"));
  };

  useEffect(() => {
    onSelectCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  useEffect(() => {
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    type === "edit"
      ? addressForm.setFieldsValue(address)
      : addressForm.resetFields();

    if (type === "edit") {
      setPhone(address?.phone);
    }
  }, [address, addressForm, type]);

  return (
    <Modal
      centered
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      width={!isTabletOrMobile ? "60%" : "85%"}
      style={{ fontFamily: "DomaineSansText-Light" }}
      bodyStyle={{ padding: `${!isTabletOrMobile ? 0 : 10}` }}
      maskClosable={false}
      className="account-address-modal"
    >
      <div className="modal-title">
        {type === "edit" ? "Edit Current Address" : "Add New Address"}
      </div>
      <div className="address-modal">
        <Form
          form={addressForm}
          name="addressForm"
          onFinish={onSubmit}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          initialValues={{ country: "Nigeria" }}
        >
          <Col span={!isTabletOrMobile ? 12 : 24} style={{ margin: "auto" }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "This is a required field",
                },
              ]}
            >
              <Select
                className="select"
                placeholder="Select Country"
                optionFilterProp="children"
                defaultValue={countryValue}
                value={countryValue}
                onChange={(value) => setCountryValue(value)}
                disabled
              >
                {countries.map(({ country, icon }) => (
                  <Option key={country} value={country}>
                    <img className="icon-flag" src={icon} alt={country} />
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row
            className="form-row"
            gutter={[30, 0]}
            style={{
              background: "white",
            }}
          >
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    pattern: /^[a-zA-Z]+$/g,
                    message: "Only letters are allowed",
                  },
                ]}
              >
                <Input className="input" placeholder="Enter your first name" />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    pattern: /^[a-zA-Z]+$/g,
                    message: "Only letters are allowed",
                  },
                ]}
              >
                <Input className="input" placeholder="Enter your last name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    max: 60,
                    message:
                      "Please limit your address to 60 characters or less",
                  },
                ]}
              >
                <Input className="input" placeholder="19 East Drive, Agodi" />
              </Form.Item>
            </Col>

            {(countryValue === "Nigeria" ||
              countryValue === "United States of America") && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select your state"
                    showSearch
                  >
                    {states?.map(({ state_name: state }) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {addressForm.getFieldsValue("country") !== "United Kingdom" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select your city"
                    showSearch
                  >
                    {cities?.map((city, index) => (
                      <Option key={index} value={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {countryValue === "Nigeria" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="area"
                  label="Area"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter your area" />
                </Form.Item>
              </Col>
            )}

            {countryValue === "United Kingdom" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="area"
                  label="County"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter your area" />
                </Form.Item>
              </Col>
            )}

            {countryValue === "Nigeria" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="postalCode"
                  label="Postal code (Optional)"
                  rules={[]}
                >
                  <Input className="input" placeholder="Enter postal code" />
                </Form.Item>
              </Col>
            )}
            {countryValue === "United States" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="postalCode"
                  label="Zip code"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter Zip code" />
                </Form.Item>
              </Col>
            )}
            {countryValue === "United Kingdom" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="postalCode"
                  label="Post code"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter post code" />
                </Form.Item>
              </Col>
            )}

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="phone"
                label={
                  <>
                    Phone Number
                    <Tooltip title="To ensure that there is no issue with your delivery. Please make sure that this is a valid  phone number and there is someone available to respond to our delivery providers when they call.">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "14px",
                        }}
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input your phone Number!",
                  },
                ]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <PhoneDial value={countries[0].dial} country={countries[0]} />

                  <Input
                    className="input"
                    type="number"
                    placeholder="Enter phone number"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </Space.Compact>
              </Form.Item>
            </Col>
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="alternative_phone"
                label="Alternative Phone Number"
              >
                <Space.Compact style={{ width: "100%" }}>
                  <PhoneDial value={countries[0].dial} country={countries[0]} />

                  <Input
                    className="input"
                    type="number"
                    placeholder=""
                    onChange={(e) => setAlternativePhone(e.target.value)}
                    value={alternativePhone}
                  />
                </Space.Compact>
              </Form.Item>
            </Col>
            <Col />

            <Col span={!isTabletOrMobile ? 12 : 24} style={{ margin: "auto" }}>
              <Button
                className="new-address-modal-save-button"
                htmlType="submit"
              >
                {type === "new" ? "Submit Address" : "Update Address"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <style>
        {`

        .address-modal{
            padding:10px 90px;
        }
        .icon-flag {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
          .address-modal .ant-form-item{
            margin-bottom:24px;
          }

          .ant-form-vertical .ant-form-item-label > label {
            font-size: 14px;
            font-family: DomaineSansText-Light;
            display: flex;
            justify-content: space-between;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: 8px;
          }

          .input {
            height: 48px;
            border: 1px solid #6F6F6F;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }

          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #6F6F6F;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }
          .ant-form-item-with-help .ant-form-item-explain{
            margin-top:4px
          }
          .modal-title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 24px;
            border-bottom: 1px solid #ccc;
            padding:24px 32px;
          }

          .new-address-modal-save-button{
            width: 100%;
            padding: 0 40px;
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            margin: 24px 0;
          }
 

          .address-modal
          .select-city.ant-select-show-search.ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector
          input {
          height: 48px;
          padding: 0px;
        }

       .address-modal
          .select-city.ant-select-single
          .ant-select-selector
          .ant-select-selection-item,
        .ant-select-single
          .ant-select-selector
          .ant-select-selection-placeholder {
          padding: 10px;
        }
         
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }

          @media(max-width:640px){

            .account-address-modal .ant-select-single .ant-select-selector .ant-select-selection-item{
              font-size:14px !important
            }
            
   .address-modal{
            padding:10px;
        }

          }


        `}
      </style>
    </Modal>
  );
};

export default Address;
