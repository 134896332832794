import { Modal, Button, Form, Collapse } from "antd";
// import { useMediaQuery } from "react-responsive";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import formatter from "../../utils/formatter";
import { useMediaQuery } from "react-responsive";
import standardizeColor from "../../utils/standardizeColor";

const PreviewLisitingModal = ({ modalVisible, setModalVisible, product }) => {
  const [currentBigImageKey, setCurrentBigImageKey] = useState(1);

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // const isTabletOrMobile = false

  const sliderRef = useRef();

  const changeDivBg = (index) => {
    try {
      sliderRef?.current.slickGoTo(index);
      setCurrentBigImageKey(index + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const images = product?.images || [
    product?.image1,
    product?.image2,
    product?.image3,
    product?.image4,
  ];

  const { Panel } = Collapse;

  const settingsBigScreen = {
    dots: false,
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    beforeChange: (current, next) => setCurrentBigImageKey(next + 1),
    nextArrow: (
      <div>
        <div className="next-slick-arrow" style={{color:"black"}}>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="black"/>
        <path d="M13.0408 8L19.1277 14.2222L13.0408 20.4444" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow" style={{color:"black"}}>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1.90735e-06C6.71573 1.90735e-06 1.90735e-06 6.71573 1.90735e-06 15C1.90735e-06 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 1.90735e-06 15 1.90735e-06Z" fill="black"/>
        <path d="M16.6523 20.5332L11.377 15.1406L16.6523 9.74802" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        </div>
      </div>
    ),
  };
  const [form] = Form.useForm();

  // const standardize_color = (str) => {
  //   let color = colors.find((color) => color.name === str);
  //   const ctx = document.createElement("canvas").getContext("2d");
  //   ctx.fillStyle = color?.hex;
  //   return ctx.fillStyle;
  // };

  return (
    <>
      <Modal
        centered
        className="preview-listing-modal"
        visible={modalVisible}
        footer={null}
        width={!isTabletOrMobile ? "80%" : "95%"}
        onCancel={() => setModalVisible(false)}
      >
        <div className="product-container">
          <div className="product-image-more-text-contain">
            <div className="product-images-supercontainer">
              <div className="other-img-container">
                {images?.map((image, index) => image ? (
                  <div
                    key={index}
                    alt="product"
                    className="img"
                    onClick={() => changeDivBg(index)}
                    style={{
                      backgroundImage: `url(${image})`,
                      cursor: "pointer",
                      border: `${
                        index + 1 === currentBigImageKey
                          ? "3px solid #4d4d4d"
                          : ""
                      }`,
                    }}
                  />
                ):'')}
              </div>

              <div
                style={{
                  gridColumn: "2 / 6",
                  marginLeft: !isTabletOrMobile ? "40px" : 0,
                }}
              >
                <Slider {...settingsBigScreen} ref={sliderRef}>
                  {images?.map((image) => image ? (
                    <div>
                      <div
                        key={image?.imageURL}
                        style={{
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          // backgroundPosition: "center",
                          backgroundSize: "cover",
                          // height: !isTabletOrMobile
                          //   ? "clamp(200px, 48vw, 1000px)"
                          //   : "65vh",
                          height: !isTabletOrMobile ? "490px": "324px",
                        }}
                      ></div>
                      
                      <div className="mobile-item-code-product">
                        Item Code: 38063312418681103
                      </div>
                  
                    </div>
                  ):'')}
                </Slider>
                {/* <div className="mobile-item-code-product">
                        Item Code: 38063312418681103
                </div> */}
              </div>
            </div>
            {/* {!isTabletOrMobile && (
              <div className="product-more-text-contain">
                <div className="product-more-text-contain-title">
                  OTHER CATEGORIES FROM{" "}
                  <span style={{ textDecoration: "underline" }}>
                    {product?.store_info?.store_name}
                  </span>
                </div>
                <div className="product-more-text-contain-details">
                  <div>Dresses</div>
                  <div>Accessories</div>
                  <div>Shoes</div>
                </div>
              </div>
            )} */}
          </div>

          <div className="details-container">
            <div style={{ width: !isTabletOrMobile ? "70%" : "100%" }}>
              {/* <div className="low-stock-text">LOW STOCK</div> */}
              <div className="product-store-name" style={{textDecoration: "underline"}}>{product?.brand_name}</div>
              <h2 className="product-title">{product?.name}</h2>
              <div className="price-container">
                {
                product?.has_discount && (product?.discount_percent || product?.discounts_percent) &&
                  // Date.now() >= Date(product?.discount_start_date) &&
                  // Date.now() < Date(product?.discount_end_date) && 
                  (
                  <div className="product-old-price">
                    <span
                      style={{
                        textDecoration: "line-through",
                        marginRight: "8px",
                        color:"#800000"
                      }}
                    >
                      {formatter(((product?.price * (31.6 / 100)) + product?.price), rate, code, indicator)}
                      
                    </span>
                    <span style={{color: "#000", marginLeft: "5px"}}>{product.discount_percent}% OFF</span>
                  </div>
                )}
                {(product?.has_discount && (product?.discount_percent || product?.discounts_percent)) &&
                <div className="product-price">
                  {formatter(
                    Math.round(((product?.price * (31.6 / 100)) + product?.price - (product?.price * ((product?.discount_percent || product?.discounts_percent) / 100))) * 100) / 100,
                    rate,
                    code,
                    indicator
                  )}
                  
                </div>}

                {!(product?.has_discount && (product?.discount_percent || product?.discounts_percent)) &&
                <div className="product-price">
                 {formatter(((product?.price * (31.6 / 100)) + product?.price), rate, code, indicator)}
                  
                </div>}
              </div>

              <Form
                className="add-to-cart-form"
                form={form}
                name="add-to-cart"
                // onFinish={addProductToCart}
                scrollToFirstError
              >
                {product?.colors && product?.colors?.length>0 &&
                <div>
                  <div style={{marginBottom:'8px'}}>
                    Colour:
                    {/* {product?.color_objects?.map((color, idx) => {
                      const parsedColor = JSON.parse(color);
                      return <span key={idx} style={{ marginRight: 10 }}>
                        {" "}
                        {parsedColor?.name}
                      </span>
                    })} */}
                  </div>
                </div>}
                {product?.colors && product?.colors?.length>0 &&
                <div>
                  <div style={{display:'flex', gap:'16px', justifyContent:"flex-start", width:'fit-content'}}>
                    {product?.colors?.map((color) => {
                      
                     return (
                      <div style={{display:'flex', alignItems:'center', gap:'4px'}}>
                      <div
                        key={color?.id}
                        className="color-box"
                        style={{
                          background: standardizeColor(color?.name),
                        }}
                      />
                      <div>{color?.name}</div>
                      </div>)
                    })}
                  </div>
                </div>}

                {product?.sizes && product?.sizes?.length>0 &&
                <div style={{ marginTop: 20 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Size:</div>
                    <div style={{ textDecoration: "underline" }}>
                      view size guide
                    </div>
                  </div>
                  <div>
                    <div
                      className="sizes-radiogroup"
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        marginTop: "4px",
                      }}
                    >
                      {product?.sizes?.map((size) => (
                        <div
                          key={size}
                          style={{
                            border: "1px solid #E6E6E6",
                            display: "grid",
                            placeContent: "center",
                            height: 40,
                            width: "31%",
                          }}
                        >
                          <div className="size-box">{size}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>}

                <div style={{ marginTop: 40 }}>
                  <Button
                    className="btn-product bag-button"
                    style={{
                      color: "white",
                      background: "black",
                      width: "100%",
                      height: 48,
                      marginBottom: "8px",
                    }}
                    icon={
                      <svg
                        style={{
                          position: "relative",
                          top: "4px",
                          marginRight: "14px",
                        }}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.4785 19.9999H0.521526L0 19.5057L2.0231 5.42339L2.54463 5.03516H11.4491V5.91751H3.01015L1.11306 19.1175H18.8939L17.2804 5.91751H16.6083V5.03516H17.7529L18.2779 5.43221L20 19.5146L19.4785 19.9999Z"
                          fill="white"
                        />
                        <path
                          d="M14.8863 6.87059H13.1362V2.36176L12.0756 1.47059H7.92441L6.86386 2.36176V4.25294H5.11377V1.75294L7.19637 0H12.8002L14.8863 1.75294V6.87059Z"
                          fill="white"
                        />
                        <path
                          d="M14.5432 8.55613H13.4756L12.6006 7.82084V6.92084L13.4756 6.18555H14.5432L15.4182 6.92084V7.82084L14.5432 8.55613Z"
                          fill="white"
                        />
                      </svg>
                    }
                    htmlType="submit"
                  >
                    Add to Bag
                  </Button>
                  <Button
                    className="btn-product"
                    style={{
                      color: "black",
                      background: "white",
                      width: "100%",
                      height: 48,
                    }}
                    icon={
                      <img
                        alt="save"
                        src="/images/icon-wishlist.svg"
                        style={{
                          marginRight: "14px",
                        }}
                      />
                    }
                    // onClick={() => saveItem(product.product_id)}
                  >
                    Save Item
                  </Button>
                </div>
                {/* <ShareAltOutlined className="btn-share" onClick={shareURL} /> */}
              </Form>
              <div className="product-details-collapsible">
                    <Collapse
                      accordion
                      expandIconPosition="right"
                      defaultActiveKey={["0"]}
                      ghost
                    >
                      <Panel
                        header="Item Description"
                        className="panel-heading"
                      >
                        <p
                          className="txt-product-desc"
                          style={{ fontWeight: "bold", marginBottom: "8px" }}
                        >
                          {product?.name}
                        </p>
                        <p
                          className="txt-product-desc"
                          style={{ textAlign: "justify" }}
                        >{`${product?.description}`}</p>
                      </Panel>
                      {product?.size_and_fit && (
                        <Panel header="Size And Fit" className="panel-heading">
                          <ul>
                            <li className="txt-product-desc">{`${product?.size_and_fit}`}</li>
                          </ul>
                        </Panel>
                      )}
                      <Panel header="Occasion" className="panel-heading">
                        <ul>
                          {product?.occassion_type?.map((occassion, index) => (
                            <li
                              key={index}
                              className="txt-product-desc"
                              style={{ marginRight: 10 }}
                            >
                              {occassion}
                            </li>
                          ))}
                        </ul>
                      </Panel>
                      <Panel
                        header="Material And Care Info"
                        className="panel-heading"
                      >
                        <p
                          className="txt-product-desc"
                          style={{ whiteSpace: "normal" }}
                        >
                          {product?.material_info}
                        </p>
                      </Panel>
                      <Panel header="Returns Policy" className="panel-heading">
                        <ul>
                          {product?.product_return_type?.map((txt, index) => (
                            <li
                              key={index}
                              className="txt-product-desc"
                              style={{ marginRight: 10 }}
                            >
                              {txt}
                            </li>
                          ))}
                        </ul>
                      </Panel>
                    </Collapse>
                  </div>
            </div>
          </div>

         
        </div>
      </Modal>

      <style jsx="true">
        {`
          .product-container {
            margin: 40px 20px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .product-images-supercontainer {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
          }
          .other-img-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 40px;
          }
          .img {
            background-size: cover;
            background-repeat: no-repeat;
            width: 75px;
            height: 100px;
          }
          // .img-container {
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   flex: 3;
          //   height: 800px;
          // }
          .img-big-screen {
            object-fit: contain;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          .details-container {
            flex: 2;
            display: flex;
            justify-content: center;
            font-family: "DomaineSansText-Light";
          }
          .product-store-name {
            text-transform: uppercase;
            font-size: 28px;
            line-height: 40px;
            font-family: "DomaineSansText-Regular";
            margin-bottom: 8px;
          }
          .product-title {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .price-container {
            margin-bottom: 24px;
          }
          .single-price {
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .old-price {
            text-decoration: line-through;
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .discount-price {
            font: normal normal normal 16px DomaineSansText-Regular;
            color: #800000;
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
          }

          .colors-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
            border-radius: 50%;
          }
          .sizes-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
          }
          .color-box {
            border: 1px solid #cccc;
            height: 26px;
            margin: 6px auto;
            width: 26px;
            border-radius: 50%;
          }
          .size-box {
            color: black;
          }
          .btn-product {
            width: 130px;
            height: 40px;
            margin-right: 15px;
            // transition: 0.5s;
            // box-shadow: inset 0px 1px 0 rgb(0 0 0 / 5%)
          }
          // .btn-product.bag-button:hover{
          //   box-shadow: inset 20em 0 0 0 black;
          //   color: white;
          //   border: 6px solid black
          //   // transition:1s
          // }
          .btn-share {
            font-size: 30px;
            color: #b7b7b7;
            cursor: pointer;
            margin-top: 20px;
          }
          .btn-share:hover {
            color: #000;
          }
          .list-item {
            font: italic normal normal 12px DomaineSansText-Regular;
          }
          .brand-details-container {
          }
          .txt-brand-title {
            font: normal bold 16px/18px DomaineSansText-Regular;
          }
          .txt-brand-desc,
          .txt-material {
            font: normal normal 16px/18px DomaineSansText-Light;
          }
          .panel-heading {
            font: normal 600 16px/18px DomaineSansText-Regular;
          }
          .txt-product-desc {
            font: normal normal 14px DomaineSansText-Light;
            line-height: 22px;
            white-space: pre-line;
          }
          .txt-title {
            font: normal normal bold 30px DomaineSansText-Regular;
            color: #000000;
            text-transform: uppercase;
            padding-bottom: 1em;
          }
          .style-container {
            background: #eeeeee;
            padding-top: 4em;
            padding-left: 5em;
            padding-right: 5em;
            padding-bottom: 5em;
          }
          .reviews {
            margin-top: 3em;
          }
          .returns-status {
            font: normal normal bold 20px DomaineSansText-Regular;
          }
          .returns-title,
          .returns-terms {
            font: normal normal 11px DomaineSansText-Regular;
            color: #b7b7b7;
          }
          .returns-terms {
            font-size: 10px;
          }
          .arrow-image-change {
            background: #fff;
            float: right;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-top: 70%;
            margin-right: 30px;
            text-align: center;
          }
          .arrow-image-change:hover {
            background: maroon;
            // border-radius: 50%;
            // padding: 10px;
            color: white;
            transition: 0.5s;
          }
          .slick-slider div {
            height: 100%;
          }
          .slick-arrow {
            height: auto !important;
          }
          .low-stock-text {
            color: #d18f8f;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 4px;
          }
          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            background-color: transparent;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            padding: 12px 0 !important;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding: 0px !important;
          }
          .product-more-text-contain {
            font-family: "DomaineSansText-Light";
            font-size: 16px;
            margin-top: 40px;
          }
          .product-more-text-contain-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
          }
          .product-more-text-contain-details {
            display: flex;
            gap: 16px;
            margin-top: 8px;
          }
          .other-products-container {
            margin: 80px 60px 120px;
            max-width: 92%;
            margin-left: auto;
            margin-right: auto;
          }
          .other-products-header-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
            font-size: 16px;
            margin-bottom: 16px;
          }
          .slick-next:before,
          .slick-prev:before {
            font-size: 2.5vw;
            display: none;
          }
          .slick-arrow {
            z-index: 2;
            width: auto;
            text-align: center;
          }
          .slick-next {
            right: 3%;
          }
          .slick-prev {
            left: 3%;
          }
          .other-products-container .slick-arrow {
            top: -34px;
          }
          .other-products-container svg {
            color: black;
          }
          .other-products-container .slick-prev {
            right: -80%;
          }
          .other-products-container .slick-next {
            right: 20px;
          }
          .other-products-card {
            width: 230px;
          }
          .other-products-image {
            object-fit: cover;
            object-position: top;
            width: 230px;
            height: 345px;
          }
          .other-products-item-container {
            padding-right: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
            font-family: "DomaineSansText-Light";
          }
          .other-products-store-name {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            font-family: "DomaineSansText-Regular";
          }
          .other-products-header-title {
            font-size: 14px;
          }
          .mobile-item-code-product {
            color: #000;
            margin-top: 4px;
            font-family: "DomaineSansText-Light";
            font-size: 12px;
          }
          .preview-modal-close-icon{
            color: rgb(0, 0, 0);
            font-size: 30px;
            padding:15px 10px 0 0
          }

          .product-details-collapsible {
            margin-top: 40px;
          }
          .product-details-collapsible .ant-collapse-header {
            padding-left: 0px !important;
          }
          .product-details-collapsible ul {
            padding-inline-start: 17px !important;
          }
          .product-details-collapsible
            .ant-collapse-icon-position-end
            > .ant-collapse-item
            > .ant-collapse-header
            .ant-collapse-arrow {
            right: 0px !important;
          }
          .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box

          @media (max-width: 1026px) and (min-width: 640px){
            .preview-listing-modal{
              width:90% !important
            }
            .product-container{
              margin: 24px 8px;
            }
          }

          @media (max-width: 640px) {
            .preview-listing-modal .ant-modal-content{
              overflow: scroll;
              height: 96vh;
            }
            .preview-listing-modal .ant-modal-close-x{
              width: 32px;
              height: 30px;
              line-height: 22px;
              padding: 6px;
              font-size: 20px;
              color: black;
            }
            .product-container {
              margin: 32px 0 16px;
              display: block;
            }
            .mobile-item-code-product{
              color: #00000066;
              font-size: 10px;
            }
            .color-box {
              height: 13px;
              width: 13px;
            }
            .price-container {
              margin-bottom: 16px;
            }
            .other-img-container {
              display: none;
            }
            .product-images-supercontainer {
              display: block;
            }

            .slick-slider div {
              width: 100%;
            }
            .slick-arrow {
              width: auto !important;
            }
            .details-container {
              justify-content: flex-start;
              margin-top: 30px;
            }
            .product-store-name {
              font-size: 18px;
              text-decoration: underline;
              line-height: normal;
            }
            .product-title {
              font-size: 14px;
            }
            .old-price,
            .single-price,
            .discount-price {
              font-size: 14px;
            }
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-item,
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-placeholder {
              line-height: 48px;
            }
            .product-page-mobile-size-select
              .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
              background-color: #f5f5f5;
            }
            .panel-heading {
              font-size: 14px;
            }
            .txt-brand-desc,
            .txt-material {
              font-size: 14px;
            }
            .product-more-text-contain {
              font-size: 12px;
            }
            .other-products-image {
              height: 248px;
              width: 100%;
              margin: auto;
              padding-right: 8px;
            }
            .other-products-container {
              padding: 0 16px;
              margin-top: 40px;
            }
            .other-products-container .slick-arrow {
              display: none !important;
            }
            .preview-listing-modal .ant-modal-body{
              padding:16px
            }
            .preview-modal-close-icon{
              font-size: 24px;
              position: relative;
              top: 6px;
              right: -12px;
              padding:0
            }
            .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
              padding: 0px !important;
          }
          }
        `}
      </style>
    </>
  );
};

export default PreviewLisitingModal;
