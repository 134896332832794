import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { bindActionCreators } from "redux";
// import { userActionCreators } from "../../redux/user";

// import { useMediaQuery } from "react-responsive";
import Banner from "./sections/BannerKids";
// import MobileSearchBar from "../components/layout/MobileSearchBar";
import Brands from "./sections/BrandsKids";
import Ads from "./sections/Ads";
import ShopGender from "./sections/ShopGenderKids";
import StylistChoice from "./sections/StylistChoiceKids";
import RandomCategory from "./sections/RandomCategoryKids";
import Occasions from "./sections/OccasionsKids";
import Verdict from "./sections/Verdict";
import Quality from "./sections/Quality";
import KidsClosedModal from "../../components/KidsClosedModal";

const KidsPage = () => {
  // const dispatch = useDispatch();

  // const { setUserType } = bindActionCreators(userActionCreators, dispatch);
  // const [modalVisible, setModalVisible] = useState(false);
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  useEffect(() => {
    // setUserType("Men");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [kidsClosedModalVisible, setKidsClosedModalVisible] = useState(true);

  return (
    <div
    // style={{overflow:"hidden", position:"fixed"}}
    >
      {/* {isTabletOrMobile ? <MobileSearchBar /> : null} */}
      <Banner />
      <Brands />
      <ShopGender />
      <StylistChoice />
      <RandomCategory />
      <Occasions />
      <Verdict />
      <Ads />
      <Quality />
      <KidsClosedModal
        kidsClosedModalVisible={kidsClosedModalVisible}
        setKidsClosedModalVisible={setKidsClosedModalVisible}
      />
    </div>
  );
};

export default KidsPage;
