import { Col, Skeleton } from "antd";

const { Image, Input } = Skeleton;

const Product = () => {
  return (
    <Col
      style={{
        marginBottom: "10px",
      }}
      xs={12}
      sm={12}
      md={8}
      lg={6}
      xl={6}
    >
      <div className="skeleton-container">
        <Image active />
        <Input active size="large" />
        <Input active size="small" />

        <style jsx={true}>{`
          .skeleton-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .ant-skeleton-element .ant-skeleton-image {
            width: 100%;
            height: 350px;
          }
          .ant-skeleton-element .ant-skeleton-image-svg {
            display: none;
          }
          .ant-skeleton-element .ant-skeleton-input-lg {
            width: 208px;
            height: 18px;
            min-width: auto;
            margin-top: 5px;
          }
          .ant-skeleton-element .ant-skeleton-input-sm {
            width: 92px;
            height: 18px;
            min-width: auto;
            margin-top: 5px;
          }
          @media (max-width: 1024px) {
            .ant-skeleton-element .ant-skeleton-image {
              width: 175px;
              height: 249px;
            }
            .ant-skeleton-element .ant-skeleton-input-lg {
              width: 148px;
            }
            .ant-skeleton-element .ant-skeleton-input-sm {
              width: 59px;
            }
          }
         
        `}</style>
      </div>
    </Col>
  );
};

export default Product;
