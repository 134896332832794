import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import triggerNotification from '../../hooks/triggerNotification';

const BlackFridayModal = ({showBfModal, setShowBfModal}) => {

    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate()

    const onFinish = async (values) => {
        setSubmitting(true);
        console.log('Form submitted:', values);
        const result = await axios.post(
          `https://losode.dev/api/v2/black/friday/access/code`,
          {
            email: values.email,
          }
        );
        if (result?.status === 200) {
          setEmailSubmitted(true);
          window.localStorage.setItem("bfPromoSet", "true");
          // triggerNotification({
          //   type: "success",
          //   message: result?.message,
          // });

        }
        if (result?.status !== 200) {
          triggerNotification({
            type: "error",
            message: result?.message,
          });
        }
        setSubmitting(false);
    };

    const closebfModal = () => {
      setShowBfModal(false);
      // window.localStorage.setItem("newUsers", "false");
    }

    useEffect(() => {
      if(!window.localStorage.getItem("boughtItem")){
      //  setShowbfModal(true);
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
      if(!window.localStorage.getItem("bfPromoSet") || window.localStorage.getItem("bfPromoSet") === "false"){
        setShowBfModal(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (<>
    {showBfModal && (
        
        <div className="bf-overlay">
        {!emailSubmitted ? 
        <div className="bf-container">
            <img src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1731692782/website-pictures-optimized/sale-icon-bf_jcflfw.png" className="bf-img" alt="bf-modal" />
            <div className="bf-content">
                <h1 className='bf-title'>Get Ready for Our <br/> Black Friday <span style={{color:"#CF9E4E", fontWeight:'bold'}}>SALE</span></h1>
                <p className='bf-subtitle'>It is our first ever Black Friday <span style={{color:"#CF9E4E", fontFamily:'DomaineSansText-Regular', fontWeight:'bold' }}>SALE</span> and we are <br className='bf-break-subtitle'/> giving away an additional <span style={{color:"#CF9E4E", fontFamily:'DomaineSansText-Regular', fontWeight:'bold'}}>10% OFF</span> on your shopping.</p>
                <p className='bf-info'>Enter your email address below to access your unique code.</p>
            </div>
            <div className="bf-form-container">
            <Form onFinish={onFinish} className="bf-form">
                <Form.Item 
                className='bf-btn-contain'
                name="email"
                rules={[
                    {
                        type: "email",
                        message: "The input is not valid E-mail!",
                    },
                    {
                        required: true,
                        message: "Please enter your E-mail!",
                    },
                    ]}
                >
                    <Input className="input" placeholder="Enter Email Address" />
                </Form.Item>

                <Form.Item className='bf-btn-contain'>
                    <Button className="bf-btn" type="primary" htmlType="submit" disabled={submitting}>
                    Get Access Code
                    </Button>
                </Form.Item>
            </Form>
            </div>
            <Button
            className="bf-modal-cancel"
            shape="circle"
            onClick={closebfModal}
            icon={
              <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="27" cy="27" r="27" fill="white"/>
              <path d="M34.7137 19.2812L19.2852 34.7098" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.2852 19.2812L34.7137 34.7098" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                         
            }
            />
        </div>:
         <div className="bf-container">
         <img src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1731692782/website-pictures-optimized/sale-icon-bf_jcflfw.png" className="bf-img" alt="bf-modal" />
         <div className="bf-content">
             <h1 className='bf-title'>Congratulations! <br/> You Qualify for <span style={{color:"#CF9E4E"}}>10% off</span> Now </h1>
             <p className='bf-subtitle'>Your access code is</p>
             <p className='bf-subtitle'><span className='bf-code' >BlackFriday10</span></p>
         </div>
         <div style={{background:"url('https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1731696663/website-pictures-optimized/bf-text-bg_pnpqcq.png')", padding:"10px 0"}}>
          <div className="bf-content" style={{padding:"0px", color:"white"}}>
          <p className='bf-subtitle-bg-black'>Shop <span style={{color:"#CF9E4E"}}>NOW</span> for an extra <span style={{color:"#CF9E4E"}}>10% OFF</span>. </p>
          <p className='bf-subtitle-bg-black'> Use your access code for repeated discounts when our <span style={{color:"#CF9E4E"}}>Black Friday SALE</span> starts on <span style={{color:"#CF9E4E"}}>25.11.</span></p>
          </div>
         </div>
        
         <div className="bf-content" style={{padding:"0px"}}>
             <Button className="bf-shop-link" type='link' onClick={() => navigate('/new-in/women/cid=2&name=new-in-women-products')}>Shop Now</Button>
             <p className='bf-info' style={{paddingBottom:'30px'}}>Your access code has been sent to your email address. Remember to check your junk mail. </p>
         </div>
         <Button
         className="bf-modal-cancel"
         shape="circle"
         onClick={closebfModal}
         icon={
         <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="29" cy="29" r="28" fill="white" stroke="black" stroke-width="2"/>
             <path d="M36.7137 21.2812L21.2852 36.7098" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
             <path d="M21.2852 21.2812L36.7137 36.7098" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>                
         }
         />
     </div>
        }
      <style jsx="true">{`
          .bf-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 1500;
            }
          .bf-container {
            width: 60%;
            // height: 600px;
            background: #F9F9F9;
            position: relative;
            font-family:DomaineSansText-Light;
          }
          .bf-img {
            width: 100%;
            // height: 55%;
            height: 22vw;
            object-fit:cover;
          }
          .bf-content {
            text-align: center;
            color: #000000;
            padding: 24px 0 4px;
            width: 84%;
            margin: 0 auto;
          }
          .bf-content .bf-title {
            font: normal 500 40px DomaineSansDisplay-SemiBold;
            text-align: center;
            // letter-spacing: 0.03em;
            line-height: 45px;
            margin: 0 auto;
            padding: 0;
            margin-bottom: 15px;
          }
          .bf-content .bf-subtitle {
            font: normal 400 20px DomaineSansText-Light;
            text-align: center;
            padding: 0 0 4px;
            margin: 0;
          }
          .bf-content .bf-subtitle-bg-black {
            font: normal 400 28px DomaineSansText-Light;
            text-align: center;
            padding: 0 0 4px;
            margin: 0;
          }
        .bf-content .bf-info {
            font: normal 400 20px DomaineSansText-Light;
            text-align: center;
            padding: 24px 0 4px;
            margin: 0;
          }
          .bf-form {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .input {
            width: 380px;
            height: 56px;
            background: #FFFFFF;
            border: 1px solid #000000;
            margin-right: 5px;
            font-size: 20px;
            border-radius: 2px;
            font-family: DomaineSansText-Regular;
          }
          .input:placeholder {
            font: normal 400 20px DomaineSansText-Regular;
            color: #92959E;
          }
          .bf-btn {
            width: auto;
            height: 56px;
            font: normal 400 20px DomaineSansText-Regular;
            margin-left: 5px;
            border-radius: 2px;
          }
          .bf-modal-cancel {
            position: absolute;
            right: 9px;
            top: 6%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
          }
          .bf-modal-cancel svg {
            width: 40px;
            height: 40px;
          }

          .bf-shop-link{
            font: normal 400 20px DomaineSansText-Regular;
            color: #000000;
            margin-top: 10px;
          }

          .bf-shop-link span{
            text-decoration: underline !important;
          }

           .bf-shop-link:hover, .bf-shop-link:active, .bf-shop-link:visited, .bf-shop-link:focus{
           color: black !important;
           }

          .bf-container .ant-form-item-explain-error{
            margin-top:4px          
          }
          
          .bf-code{
            color: rgb(207, 158, 78);
            font-family: DomaineSansText-Black;
            font-size: 30px;
          }

          @media(min-width:640px) and (max-width: 1024px) {
            .bf-container {
              width: 95%;
            }
            .bf-img {
              height: 50%;
            }
            .bf-content h1 {
              font: normal 500 42px DomaineSansDisplay-Bold;
            }
            .bf-content p {
              font: normal 400 20px DomaineSansText-Regular;
            }
            .input {
              width: 380px;
              height: 56px;
              background: #FFFFFF;
              border: 1px solid #000000;
              margin-right: 5px;
            }
            .bf-btn {
              width: auto;
              margin-left: 5px;
            }
          }
          @media(max-width:640px){
            .bf-container {
              width: 95%;
            }
            .bf-content .bf-title {
            font: normal 500 30px DomaineSansDisplay-SemiBold;
            line-height: 35px;
          }
          .bf-content .bf-subtitle {
            font: normal 400 20px DomaineSansText-Light;
          }
            .bf-img {
              height: 35%;
            }
            .bf-content h1 {
              font: normal 500 30px DomaineSansDisplay-Bold;
            }
            .bf-content p {
              font: normal 400 16px DomaineSansText-Regular;
            }
            .bf-form {
              flex-direction: column;
              align-items: center;
              padding-top: 10px;
            }
            .input {
              width: 100%;
              height: 45px;
              margin-right: 0px;
              font-size: 18px;
            }
            .bf-btn {
              width: 100%;
              margin-left: 0px;
              height: 45px;
              font-size: 18px;
            }
            .bf-content .bf-subtitle-bg-black, .bf-content .bf-info {
                font-size: 18px;
              }
              .bf-content .bf-info{
                margin-bottom: -16px;
              }
            .bf-btn-contain{
              width: 100%;
              padding: 10px 20px 0px;
              margin: 0;
            }
            .bf-modal-cancel {
              position: absolute;
              right: -8px;
              top: 4%;
            }
            .bf-modal-cancel svg {
              width: 30px;
              height: 30px;
            }
              .bf-shop-link span{
                margin-top: 10px;
              }
                .bf-break-subtitle{
                  display: none;
                }
          }

          @media(min-width:2000px){
            .bf-content .bf-title {
            font: normal 500 54px DomaineSansDisplay-SemiBold;
            text-align: center;
            // letter-spacing: 0.03em;
            line-height: 60px;
            margin: 0 auto;
            padding: 0;
            margin-bottom: 15px;
          }
          .bf-content .bf-subtitle, .bf-content .bf-info {
            font: normal 400 30px DomaineSansText-Light;
          }
          .input, .bf-btn {
            height: 70px;
            font-size: 24px;
            }
            .bf-btn{
              width: 280px;
            }
            .input{
              width: 600px;
            }
          .ant-form-item-explain-error{
            font-size: 20px;
          }
            .bf-shop-link span{
            font-size: 30px;
            }
            .bf-code{
              font-size: 40px;
            }
            .bf-modal-cancel svg {
              width: 54px;
              height: 54px;
            }
          }
      `}</style>
  </div>
    )}
  </>
  )
}

export default BlackFridayModal