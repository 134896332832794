export const ADD_TO_CART = "ADD_TO_CART";
export const GET_USER_CART = "GET_USER_CART";
export const CHANGE_ITEMS_CURRENCY = "CHANGE_ITEMS_CURRENCY";
export const RESET = "RESET";
export const APPLY_COUPON = "APPLY_COUPON";
export const ON_CHECK_OUT_REQUEST = "ON_CHECK_OUT_REQUEST";
export const CHECK_OUT = "CHECK_OUT";
export const PAYMENT = "PAYMENT";
export const ORDER_RECEIPT = "ORDER_RECEIPT";
export const CHECK_OUT_FAILED = "CHECK_OUT_FAILED";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const ORDER_RECEIPT_FAILED = "ORDER_RECEIPT_FAILED";
export const APPLY_COUPON_FAILED = "APPLY_COUPON_FAILED";
export const UPDATE_PRODUCT_ALREADY_IN_CART = "UPDATE_PRODUCT_ALREADY_IN_CART";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_PRODUCT_IN_CART = "DELETE_PRODUCT_IN_CART";
export const SET_TRANSACTION_RESULT = "SET_TRANSACTION_RESULT";
export const DELETE_TRANSACTION_RESULT = "DELETE_TRANSACTION_RESULT";
export const DELETE_STORE_ITEMS = "DELETE_STORE_ITEMS";
