import {  Skeleton } from "antd";
import { useMediaQuery } from "react-responsive";

const { Button, Input, Image, Avatar } = Skeleton;

const ProductDetails = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const emptyArr = Array.from({ length: 6 });
  return (
    <div>
      <div className="product-container">
        <div className="product-image-more-text-contain">
          <div className="product-images-supercontainer">
            <div className="other-img-container">
              {emptyArr.slice(0, 3).map(() => (
                <Image active className="other-img" />
              ))}
            </div>

            <div
              style={{
                gridColumn: "2 / 6",
                marginLeft: !isTabletOrMobile ? "40px" : 0,
              }}
            >
              <div>
                <Image active className="main-img" />
                {isTabletOrMobile && (
                  <div className="mobile-item-code-product">
                    <Input active style={{ width: "300px", height: "22px" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isTabletOrMobile && (
            <div className="product-more-text-contain-container">
              <div className="product-more-text-contain">
                <div className="product-more-text-contain-title">
                  <Input active style={{ width: "300px", height: "22px" }} />
                </div>
                <div className="product-more-text-contain-details">
                  {emptyArr.slice(0, 3).map(() => (
                    <Input
                      active
                      size="small"
                      style={{ width: "60px", height: "22px" }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="details-container">
          <div style={{ width: !isTabletOrMobile ? "70%" : "100%" }}>
            {/* <div className="low-stock-text">LOW STOCK</div> */}
            <div className="product-store-name">
              <Input
                active
                size="small"
                style={{ width: "200px", height: "30px" }}
              />
            </div>
            <h2 className="product-title">
              <Input
                active
                size="small"
                style={{ width: " 297px", height: "23px" }}
              />
            </h2>
            <Input
              active
              size="small"
              style={{ width: "126px", height: "23px" }}
            />
            <div
              className="price-container"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 22,
              }}
            >
              <Input active size="small" style={{ height: "23px" }} />
              {!isTabletOrMobile && (
                <div style={{ display: "flex", marginTop: 5 }}>
                  <Avatar style={{ marginRight: 20 }} /> <Avatar />
                </div>
              )}
            </div>

            {!isTabletOrMobile && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Input active size="small" />
                  <Input active size="small" />
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    gap: "13px",
                    flexWrap: "wrap",
                  }}
                >
                  {emptyArr.map(() => (
                    <div className="size-box">
                      <Button style={{ width: "145px", height: "38px" }} />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="skeleton-buttons-container">
              <Button
                className="btn-product bag-button"
                style={{
                  width: 360,
                  height: 42,
                }}
              />
              <Button
                className="btn-product bag-button"
                style={{
                  width: 360,
                  height: 42,
                  marginTop: 10,
                }}
              />
            </div>
            {/* <ShareAltOutlined className="btn-share" onClick={shareURL} /> */}

            <div className="product-details-collapsible">
              <Skeleton
                paragraph={{ rows: 6, width: [150, 150, 150, 150, 150, 150] }}
                title={false}
              />
            </div>
          </div>
        </div>

        {isTabletOrMobile && (
          <div className="product-more-text-contain">
            <div className="product-more-text-contain-title">
              <Input active size="small" />
            </div>
            <div className="product-more-text-contain-details">
              {emptyArr.slice(0, 3).map(() => (
                <Input active size="small" />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="other-products-container">
        <div className="other-products-header">
          <div className="other-products-header-title">
            <Input active style={{ width: "300px", height: "22px" }} />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {emptyArr.slice(0, 5).map(() => (
            <div className="other-products-card">
              <Image
                active
                className="other-products-image"
                style={{ width: "228px", height: "332px" }}
              />

              <div className="other-products-item-container">
                <div className="other-products-store-name">
                  <Input
                    active
                    size="small"
                    style={{ width: "60px", height: "22px" }}
                  />
                </div>
                <div className="other-products-product-name">
                  <Input
                    size="small"
                    style={{ width: "200px", height: "22px" }}
                  />
                </div>
                <div className="other-products-product-price">
                  <Input
                    size="small"
                    style={{ width: "60px", height: "22px" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .ant-skeleton-image-svg {
          display: none;
        }
        .other-img {
          height: 130px !important;
        }
        .main-img {
          width: 494px !important;
          height: 65vh !important;
        }

        .product-details-collapsible ul{
            padding-inline-start:0px;
        }
         .skeleton-buttons-container {
          margin-top: 40px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
        }

        @media (max-width: 640px) {
          .main-img {
            width: 380px !important;
          }

          .other-products-image {
            width: 175px !important;
            height: 249px !important;
          }

          .skeleton-buttons-container {
            margin-left: 0px;
          }

          .bag-button {
            width: 388px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default ProductDetails;
