import { useEffect } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { bindActionCreators } from "redux";
// import { userActionCreators } from "../redux/user";
import axios from "axios";

const SellerVerifyEmail = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const location= useLocation();
  //   const email= location.state.email

  const { isAuthenticated, info } = useSelector((state) => state.user);

  // const { logout } = bindActionCreators(userActionCreators, dispatch);

  const resendMail = async () => {
    try {
      const res = await axios.post(`/api/v2/email/link/resend/${info.email}`);
      if (res.data.status === "ok") message.success(res.data.message);
    } catch (error) {
      // console.log(error.response.data.message)
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated && info.role === "2" && info.email_verified === true)
      navigate("/account");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    const sendEmailToOldUsers = async () => {
      try {
        const res = await axios.post(`/api/v2/email/link/resend/${info.email}`);

        if (res.status === "ok") message.success(res.message);
        if (res.status === "not ok") message.error(res.message);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    if (info?.user_age === "old") {
      sendEmailToOldUsers();
    }
  }, [info.email, info?.user_age]);

  return (
    <div>
      <div className="div-seller-verify-password-seller">
        <div className="seller-verify-register-container">
          <div className="form-container">
            <h2 className="seller-verify-txt-register">
              Verify your email address
            </h2>
            {info?.user_age === "new" && (
              <p className="seller-verify-txt-setup">
                A link has been sent to your email address. Please check your
                email and click on the link. You will need to sign in again
              </p>
            )}
            {info?.user_age !== "new" && (
              <p className="seller-verify-txt-setup">
                To enhance security on the Losode platform, we need you to
                verify your email address. We have sent a verification link to
                your email address. Please check your email and click on the
                link. You will need to sign in again
              </p>
            )}

            <Button
              className="seller-verify-btn-register"
              type="primary"
              onClick={resendMail}
              // htmlType="submit"
            >
              Resend link
            </Button>
            {/* <Button
                        className="seller-verify-btn-register"
                        type="primary"
                        onClick={()=>logout()}
                        // htmlType="submit"
                        >
                        Sign in as a different user
                    </Button> */}
          </div>
        </div>
      </div>

      <style jsx="true">
        {`
          .div-seller-verify-password-seller {
            height: calc(100vh - 214px);
            background: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .seller-verify-txt-setup {
            font-family: "DomaineSansText-Regular";
            margin: 16px 3vw;
          }
          .ant-form label,
          .ant-form-item-explain.ant-form-item-explain-error {
            font-size: 14px;
            font-family: "DomaineSansText-Regular";
          }

          .seller-verify-register-container {
            text-align: center;
            justify-content: center;
            background: white;
            padding: 50px;
            width: 45%;
            margin: auto;
            margin: auto;
            max-width: 600px;
          }
          .seller-verify-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
          }

          .seller-verify-inputs-container {
            margin: 0 24px;
            padding-bottom: 24px;
          }
          .seller-verify-input {
            border: 2px solid #ccc;
            color: #000;
            font-size: 16px;
            height: 60px;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            text-align: left;
            margin-top: 4px;
          }

          .seller-verify-input:focus,
          .seller-verify-input:hover {
            border: 2px solid #000;
          }
          .seller-verify-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 60px;
            margin-top: 8px;
          }

          @media (max-width: 1100px) and (min-width: 600px){
            .seller-verify-register-container{
              width:65%
            }
          }

          @media screen and (max-width: 600px) {
            .seller-verify-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .div-seller-verify-password-seller {
              background: white;
            }
            .seller-verify-txt-setup {
              font-size: 14px;
              margin: 8px 0;
            }
            .seller-verify-txt-signin {
              display: block;
              margin-top: 16px;
            }
            .ant-input:placeholder-shown,
            .ant-picker-input > input:placeholder-shown {
              font: normal normal 14px/14px DomaineSansText-Regular !important;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
            .seller-verify-register-container {
              width: auto;
            }
            a {
              text-decoration: underline;
              font: normal normal normal 14px DomaineSansText-Regular;
              letter-spacing: 0px;
              color: #000000;
            }
            .seller-verify-btn-register {
              margin-top: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SellerVerifyEmail;
