import { useState } from "react";
import { Button } from "antd";
import AuthModal from "../components/AuthModal";
import { useLocation } from "react-router-dom";

const SellerResetPasswordSuccessful = () => {
  const location = useLocation();
  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);

  const path = location.pathname;

  return (
    <div>
      <div className="div-seller-verified-password-seller">
        <div className="seller-verified-register-container">
          <div className="form-container">
            <h2 className="seller-verified-txt-register">
              Password reset successful
            </h2>
            <p className="seller-verified-txt-setup">
              Your password has been successfully reset. You will need to sign
              in again
            </p>

            <Button
              className="seller-verified-btn-register"
              type="primary"
              onClick={() => {
                setSellerAuthModalVisible(true);
              }}
              // htmlType="submit"
            >
              Sign in
            </Button>
          </div>
        </div>
      </div>
      <AuthModal
        authModalVisible={sellerAuthModalVisible}
        setAuthModalVisible={setSellerAuthModalVisible}
        activePage="1"
        pageUserType={path.split("/")[1]}
      />

      <style jsx>
        {`
          .div-seller-verified-password-seller {
            height: calc(100vh - 214px);
            background: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .seller-verified-txt-setup {
            font-family: "DomaineSansText-Regular";
            margin: 16px 3vw;
          }
          .ant-form label,
          .ant-form-item-explain.ant-form-item-explain-error {
            font-size: 14px;
            font-family: "DomaineSansText-Regular";
          }

          .seller-verified-register-container {
            text-align: center;
            justify-content: center;
            background: white;
            padding: 50px;
            width: 45%;
            margin: auto;
            margin: auto;
            max-width: 600px;
          }
          .seller-verified-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
          }

          .seller-verified-inputs-container {
            margin: 0 24px;
            padding-bottom: 24px;
          }
          .seller-verified-input {
            border: 2px solid #ccc;
            color: #000;
            font-size: 16px;
            height: 60px;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            text-align: left;
            margin-top: 4px;
          }

          .seller-verified-input:focus,
          .seller-verified-input:hover {
            border: 2px solid #000;
          }
          .seller-verified-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 60px;
            margin-top: 8px;
          }

          @media (max-width: 1100px) and (min-width: 600px) {
            .seller-verified-register-container {
              width: 65%;
            }
          }

          @media screen and (max-width: 600px) {
            .seller-verified-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .div-seller-verified-password-seller {
              background: white;
            }
            .seller-verified-txt-setup {
              font-size: 14px;
              margin: 8px 0;
            }
            .seller-verified-txt-signin {
              display: block;
              margin-top: 16px;
            }
            .ant-input:placeholder-shown,
            .ant-picker-input > input:placeholder-shown {
              font: normal normal 14px/14px DomaineSansText-Regular !important;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
            .seller-verified-register-container {
              width: auto;
            }
            a {
              text-decoration: underline;
              font: normal normal normal 14px DomaineSansText-Regular;
              letter-spacing: 0px;
              color: #000000;
            }
            .seller-verified-btn-register {
              margin-top: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SellerResetPasswordSuccessful;
