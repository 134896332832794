import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../redux/user";
import { Avatar, Dropdown, Menu, Drawer } from "antd";
import { DownOutlined, UserOutlined, MenuOutlined } from "@ant-design/icons";
import PhoneVerificationModal from "./PhoneVerificationModal";
import { useMediaQuery } from "react-responsive";
import SellerSideNav from "./SellerSideNav";
import { storeActionCreators } from "../../redux/stores";

const SellerTopBar = ({ searchBar = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [mobileSideMenuVisible, setMobileSideMenuVisible] = useState(false);

  const { details } = useSelector((state) => state.store);
  const { isAuthenticated } = useSelector((state) => state.user);
  const { clearStore } = bindActionCreators(storeActionCreators, dispatch);
  const { logout } = bindActionCreators(userActionCreators, dispatch);

  const onLogOut = () => {
    logout();
    clearStore();
    setTimeout(() => {
      navigate("/sell");
    }, 30);
  };

  useEffect(() => {
    if (!isAuthenticated) navigate("/sell");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const accountMenu = (
    <Menu className="seller-account-menu">
      <div className="seller-header-dropdown">
        {details?.logoUrl ? (
          <Avatar size={45} src={details?.logoUrl} />
        ) : (
          <Avatar size={45} icon={<UserOutlined />} />
        )}
        <div className="seller-brand-info">
          <h3 className="txt-brand-name">{details?.brandName}</h3>
          <h3 className="txt-brand-id">{details?.regNumber}</h3>
        </div>
      </div>
      <Link className="btn-dropdown" to="/store-settings">
        Store Settings
      </Link>
      <Link to="" className="btn-dropdown" onClick={() => onLogOut()}>
        Sign Out
      </Link>
    </Menu>
  );

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="manage-listings-topbar">
      {!isTabletOrMobile && (
        <div className="manage-listings-topbar-submenu-logo-desktop">
          <img
            style={{ width: "140px", zIndex: "200", cursor: "pointer" }}
            alt="losode"
            src="/images/losode-no-tagline.png"
            // onClick={() => navigate("/")}
          />
        </div>
      )}
      {!isTabletOrMobile && (
        <div className="manage-listings-topbar-menu">
          <div className="manage-listings-topbar-menu-options">
            <Dropdown overlay={accountMenu} placement="bottomLeft">
              <div>
                Hello {details?.firstName}
                <DownOutlined style={{ marginLeft: 24, fontSize: 10 }} />
              </div>
            </Dropdown>
          </div>

          <div className="manage-listings-topbar-menu-options">
            {/* <div>Seller FAQs</div> */}

            <div className="manage-listings-topbar-menu-icons-right">
              {/* <img
                className="icon-bag"
                alt="cart"
                src="/images/losode-icon-bell.svg"
              /> */}
              {/* <img
                className="icon-bag"
                alt="cart"
                src="/images/losode-icon-bag.svg"
              /> */}
            </div>
          </div>
        </div>
      )}

      {isTabletOrMobile && (
        <div className="manage-listings-topbar-menu">
          <MenuOutlined
            style={{ fontSize: "24px" }}
            onClick={() => setMobileSideMenuVisible(true)}
          />

          <div className="manage-listings-topbar-submenu-logo">
            <img
              style={{ width: "95px" }}
              alt="losode"
              src="/images/losode-no-tagline.png"
              onClick={() => navigate("/")}
            />
          </div>

          <div className="manage-listings-topbar-menu-options">
            <Dropdown overlay={accountMenu} placement="bottomLeft">
              <div>
                {details?.logoUrl ? (
                  <Avatar size={32} src={details?.logoUrl} />
                ) : (
                  <Avatar size={32} icon={<UserOutlined />} />
                )}
              </div>
            </Dropdown>
          </div>
          {/* <div className="dashboard-content">
          {!details.phone_verify_status && (
            <div className="first-time-notif">
              <p className="notif-text">
                Your phone number ({`+234${details.phoneName}`}) has not been
                verified and your store has not been activated{" "}
                <span
                  className="btn-verify_phone"
                  onClick={() => setShowModal(true)}
                >
                  Verify Now
                </span>
              </p>
            </div>
          )}
        </div> */}
        </div>
      )}
      {isTabletOrMobile && (
        <div className="dashboard-content">
          {!details.phone_verify_status && (
            <div className="first-time-notif">
              <p className="notif-text">
                {/* Your phone number ({`+234${details.phoneName}`}) has not been
                verified and your store has not been activated{" "} */}
                <span
                  className="btn-verify_phone"
                  onClick={() => setShowModal(true)}
                >
                  Verify Phone Number Now
                </span>
              </p>
            </div>
          )}
          {/* {searchBar &&
          <div className="manage-listings-topbar-submenu-search-container">
            <Input
              className="submenu-search-container-input"
              prefix={<SearchOutlined style={{ color: "#800000" }} />}
              // addonAfter={
              //   <div className="categories-in-search-container">
              //     <div>All categories</div>
              //     <ArrowDownOutlined />
              //   </div>
              // }
              placeholder="Want to shop fashion? Search here"
            />

            <Button className="submenu-search-container-button">Search</Button>
          </div>
          } */}
        </div>
      )}
      <div className="manage-listings-topbar-submenu">
        {/* {!isTabletOrMobile && (
          <div className="manage-listings-topbar-submenu-logo">
            <img
              style={{ width: "150px" }}
              alt="losode"
              src="/images/losode-no-tagline.png"
            />
          </div>
        )} */}
        {!isTabletOrMobile && (
          <div className="dashboard-content">
            {!details?.phone_verify_status ? (
              <div className="first-time-notif">
                <p className="notif-text">
                  Your phone number ({`+${details?.phoneNumber}`}) has not been
                  verified and your store has not been activated{" "}
                  <span
                    className="btn-verify_phone"
                    onClick={() => setShowModal(true)}
                  >
                    Verify Now
                  </span>
                </p>
              </div>
            ) : (
              <>
                {/* {searchBar &&
                <div className="manage-listings-topbar-submenu-search-container">
                <Input
                  className="submenu-search-container-input"
                  prefix={<SearchOutlined style={{ color: "#800000" }} />}
                  placeholder="Want to shop fashion? Search here"
                />

                <Button className="submenu-search-container-button">
                  Search
                </Button>
              </div>
              } */}
              </>
            )}
          </div>
        )}
        {/* <div className="manage-listings-topbar-submenu-search-container">
          <Input
            className="submenu-search-container-input"
            prefix={<SearchOutlined style={{ color: "#800000" }} />}
            addonAfter={
              <div className="categories-in-search-container">
                <div>All categories</div>
                <ArrowDownOutlined />
              </div>
            }
            placeholder="Want to shop fashion? Search here"
          />

          {!isTabletOrMobile && (
            <Button className="submenu-search-container-button">Search</Button>
          )}
        </div> */}

        <PhoneVerificationModal
          modalVisible={showModal}
          setModalVisible={setShowModal}
          number={details?.phoneNumber}
        />
      </div>

      <Drawer
        className="mobile-side-menu"
        placement="left"
        width={225}
        onClose={() => setMobileSideMenuVisible(false)}
        open={mobileSideMenuVisible}
      >
        <SellerSideNav />
      </Drawer>

      <style jsx="true">
        {`
          .manage-listings-topbar {
            position: fixed;
            top: 0;
            width: 100%;
            background: white;
            font-family: DomaineSansText-Light;
            z-index: 20;
          }
          .manage-listings-topbar-menu {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            border-bottom: 1px solid #d4d4d4;
            padding: 24px 32px;
            padding-bottom: 20px;
            max-height: 70px;
            position: relative;
          }
          .manage-listings-topbar-submenu-logo-desktop {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: 13px;
          }
          .seller-account-menu {
            font-family: DomaineSansText-Light;
            height: 150px;
            padding: 13px;
            width: 220px;
          }
          .seller-header-dropdown {
            display: flex;
            justify-content: space-around;
            padding: 13px;
          }
          .btn-dropdown {
            color: grey;
            display: block;
            margin-top: 10px;
            text-decoration: underline;
            padding-left: 13px;
          }
          .txt-brand-name {
            font-size: 14px;
            font-weight: 600;
          }
          .txt-brand-id {
            font-size: 12px;
          }
          .manage-listings-topbar-menu-options {
            display: flex;
            justify-content: space-between;
            z-index: 201;
          }
          .manage-listings-topbar-menu-options *:hover {
            color: #800000;
            cursor: pointer;
          }
          .first-time-notif {
            box-sizing: border-box;
            border-radius: 5px;
            padding: 0 0 8px;
          }
          .notif-text {
            font: normal normal 16px/23px DomaineSansText-Light;
            font-size: 14px;
            line-height: 31px;
            color: #303030;
            text-align: center;
            margin: 0;
          }
          .btn-verify_phone {
            cursor: pointer;
            text-decoration: underline;
            font-style: normal;
            font-weight: 700;
            line-height: 31px;
          }

          .manage-listings-topbar-menu-icons-right {
            display: flex;
            gap: 40px;
            font-size: 20px;
            width: 160px;
            justify-content: flex-end;
          }
          .icon-bag {
            height: 21px;
          }
          .manage-listings-topbar-submenu {
            padding: 0 0 23px;
            width: 80%;
            margin: auto;
          }
          .manage-listings-topbar-submenu-search-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center;
            margin: auto;
          }
          .submenu-search-container-button {
            height: 47px;
            padding: 0 28px;
            background: black;
            color: white;
            width: 155px;
            font-size: 16px;
          }
          .submenu-search-container-input {
            height: 47px;
          }
          .submenu-search-container-input
            .ant-input-affix-wrapper
            > input.ant-input {
            padding: 0 10px;
          }
          .submenu-search-container-input .ant-input-affix-wrapper {
            height: 47px;
          }
          .submenu-search-container-input .ant-input-group-addon {
            background: white;
          }
          .categories-in-search-container {
            display: flex;
            align-items: center;
            gap: 11px;
            cursor: pointer;
          }

          .mobile-side-menu .ant-drawer-body {
            padding-left: 0px;
          }
          .dashboard-content {
            margin-left: 200px;
          }

          @media (max-width: 1026px) and (min-width: 640px) {
            .notif-text {
              font-size: 12px;
            }
          }
          @media (max-width: 830px) and (min-width: 640px) {
            .dashboard-content {
              margin-left: 0;
            }
            .manage-listings-topbar-submenu-search-container {
              margin-left: 200px;
            }
          }

          @media (max-width: 640px) {
            .manage-listings-topbar-menu {
              padding: 16px;
              box-shadow: 0 2px 10px 0 #05050526;
              margin-bottom: 0;
            }
            .manage-listings-topbar-submenu {
              display: flex;
              gap: 8px;
              margin: auto;
              width: auto;
              padding: 0;
            }
            .first-time-notif {
              margin-top: 20px;
            }
            .submenu-search-container-button {
              padding: 0 16px;
              font-size: 14px;
              height: 40px;
              width: auto;
            }
            .manage-listings-topbar-submenu-search-container {
              gap: 8px;
              width: 100%;
              padding: 0 16px;
            }
            .submenu-search-container-input .ant-input-affix-wrapper {
              height: 40px;
            }
            .categories-in-search-container {
              gap: 6px;
              font-size: 12px;
            }
            .txt-brand-name {
              margin-top: 0px !important;
            }
            .notif-text {
              text-align: center;
              margin-top: -16px;
              font-size: 14px;
            }
            .dashboard-content {
              margin-left: 0;
            }
            .submenu-search-container-input {
              height: 40px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SellerTopBar;
