export default class User {
  constructor(client) {
    this.client = client;
  }

  loginUser(user) {
    return this.client.post("buyer/login", user);
  }

  addMailList(option) {
    return this.client.post("campaign/monitor/add/any", option);
  }

  joinNewsLetter(values) {
    return this.client.post("newsletter/store", values);
  }

  registerUser(user) {
    return this.client.post("buyer/register", user);
  }
  registerGuest(values) {
    return this.client.post("guest/register", values);
  }

  completeUserAccount(values) {
    return this.client.post("buyer/complete/registration", values);
  }

  registerSeller(user) {
    return this.client.post("seller/register", user);
  }

  loginSeller(user) {
    return this.client.post("seller/login", user);
  }
  logOutUser() {
    return this.client.post("user/logout", {});
  }

  getAddress() {
    return this.client.get("buyer/address");
  }

  postAddress(values) {
    return this.client.post("buyer/address", values);
  }

  makePrimaryAddress(id) {
    return this.client.post(`buyer/add/default/address/${id}`, {});
  }

  updateAddress(id, values) {
    return this.client.post(`buyer/update/address/${id}`, values);
  }

  deleteAddress(id) {
    return this.client.delete(`buyer/address/${id}`);
  }

  getUserDetails() {
    return this.client.get("buyer/details");
  }

  updateUserInfo(values) {
    return this.client.post("buyer/update/about", values);
  }

  updateUserEmail(values) {
    return this.client.post("buyer/update/email", values);
  }

  updateUserPassword(values) {
    return this.client.post("buyer/update/password", values);
  }

  getUserItems() {
    return this.client.get("saved/items");
  }

  addItem(id) {
    return this.client.post("add/item", { id });
  }

  deleteSavedItem(id) {
    return this.client.delete(`delete/item/${id} `);
  }
  getCart() {
    return this.client.get(`cart`);
  }
  addToCart(id, payload) {
    return this.client.post(`addcart/${id}`, payload);
  }
  removeFromCart(id) {
    return this.client.delete(`destroycart/${id}`);
  }

  resetPasswordSellerInitiate(email) {
    return this.client.post("forgotpassword", email);
  }

  resetPasswordSellerConfirm(values) {
    return this.client.post("resetpassword", values);
  }

  getEmailPref() {
    return this.client.get("buyer/email/status");
  }

  activateEmailList(email) {
    return this.client.post("buyer/email/activate", { email });
  }

  deactivateEmailList(email) {
    return this.client.post("buyer/email/deactivate", { email });
  }

  getOrders() {
    return this.client.get("/buyer/orders");
  }

  getOrder(id) {
    return this.client.get(`buyer/order/sortby/seller/${id}`);
  }

  getOrderItem(id) {
    return this.client.get(`buyer/order/details/${id}`);
  }
}
