import { useNavigate } from "react-router-dom";

const ShopGender = () => {
  const navigate = useNavigate();

  const shopMen = () => {
    navigate(`/men/cat/cid=${1}&name=men-products`);
  };
  const shopWomen = () => {
    navigate(`/women/cat/cid=${2}&name=women-products`);
  };

  const brands = [
    {
      id: 1,
      imageUrl:
        "./images/kids_gender_male.jpg",
      name: "Quintessential Everyday Trends",
      description:
        "Step in style. The right dress for every moment. Let the magic speak itself",
      action: "Shop Boys",
    },
    {
      id: 2,
      imageUrl:
        "./images/kids_gender_female.jpg",
      name: "An Affair with Fashion",
      description:
        "Flirty dresses and divine accessories guaranteed to leave you smitten",
      action: "Shop Girls",
    },
  ];

  return (
    <div>
      <div className="shop-gender-container">
        {brands.map((brand) => (
          <div key={brand.id} className="gender-container">
            <img className="img-gender" alt={brand.name} src={brand.imageUrl} loading="lazy" />
            <h3 className="gender-title">{brand.name}</h3>
            <h5 className="gender-desc">{brand.description}</h5>
            <p
              className="gender-cta"
              onClick={() => (brand.id === 1 ? shopMen() : shopWomen())}
            >
              {brand.action}
            </p>
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .shop-gender-container {
          display: flex;
          justify-content: space-between;
          padding: 0 24px;
          gap: 24px;
          padding-bottom: 0;
          margin: 60px 0;
        }
        .img-gender {
          width: 100%;
          height: 474px;
          object-fit: cover;
          object-position: top;
        }
        .gender-title {
          font: normal 400 24px DomaineSansText-Regular;
          line-height: 34px;
          margin-top: 15px;
          margin-bottom: 0px;
          text-transform: capitalize;
        }
        .gender-desc {
          font: normal normal 16px DomaineSansText-Light;
          line-height: 22px;
          margin-top: 6px;
          margin-bottom: 0px;
        }
        .gender-cta {
          cursor: pointer;
          font: normal 300 14px DomaineSansText-Light;
          margin-top: 15px;
          text-decoration-line: underline;
          color: #555555;
        }
        .gender-cta:hover {
          text-decoration: none;
        }
        .gender-container {
          flex-basis: 50%;
        }

        @media(max-width:640px){
          .shop-gender-container{
            flex-direction: column;
            padding: 0 16px;
            margin: 24px 0;
          }
          .gender-title{
            font-size: 16px;
            margin-top: 8px;
          }
          .gender-desc{
            font-size: 14px;
            margin: 0;
          }
          .gender-cta{
            margin-top: 12px;
            font-size: 12px;
          }
          .img-gender{
            height: 248px;
          }
        }

      `}</style>
    </div>
  );
};

export default ShopGender;
