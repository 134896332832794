// import { useEffect } from "react";
// import { useMediaQuery } from "react-responsive";
import StaticPageTemplate from "./StaticPageTemplate";

const Returns = () => {
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <StaticPageTemplate title={"Return Policy"}>
        <div className="static-content-container">
          <div>
            <h3 style={{ fontFamily: "DomaineSansText-Black" }}>
              1. Introduction
            </h3>
            <p>
              At Losode we are committed to ensuring you have a positive
              experience and are delighted with your purchase from designers on
              our platform. However, we know that sometimes, you may want to
              return an item. This returns policy sets out our conditions for
              accepting returns and initiating a refund or exchange. It also
              sets out the circumstances under which we will not accept returns
              or permit refunds or exchanges.
            </p>
            <p>
              If you are not satisfied with your purchase for any reason,
              contact Losode at{" "}
              <a
                href="mailto:hello@losode.com"
                style={{
                  fontWeight: "bold",
                  fontFamily: "DomaineSansText-Black",
                  textDecoration: "underline",
                }}
              >
                hello@losode.com
              </a>{" "}
              or via your account dashboard.
            </p>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              2. Overview of our returns process
            </h3>
            <p>There are five (5) steps in our returns process as follows:</p>
            <ol>
              <li>
                <strong>Initiate a request</strong> - Sign into your account, go
                to your account dashboard, select the relevant order from your
                order history, complete the form and provide the reason for your
                request, submit your request.
              </li>
              <li>
                <strong>Post the Item</strong> – We will send you an email with
                instructions on how to post the item. Package and post the item
                to the address provided within 5 working days of your request.
              </li>
              <li>
                <strong>Item is inspected</strong>– Once the item is inspected,
                your eligibility for a refund will be within 2 working days.
              </li>
              <li>
                <strong>Losode reviews the assessment</strong> – We will review
                the decision to ensure it is right and fair. We will facilitate
                any issues with the designer who supplied the item.
              </li>
              <li>
                <strong>Return is approved</strong> – If there are no issues, we
                will initiate a refund or exchange as appropriate and you should
                receive your new item or refund within 10 working days.
              </li>
            </ol>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              3. Return Policy Basics and Conditions
            </h3>
            <p>
              Losode will actively work with you and the designer to achieve a
              satisfactory outcome.
            </p>
            <ul>
              <li>
                We offer a 7-day return policy; however, your request must be
                made as soon as you receive the product and no later than 48
                hours of receiving the product. Losode will not be able to
                process any requests after 48 hours.
              </li>
              <li>
                Returns are only accepted for items purchased directly from
                Losode.com.
              </li>
              <li>
                It is important that the items(s) are returned in their original
                package and that they are in their original, unworn condition,
                with all labels and tags intact.
              </li>
              <li>
                All requests need to be consistent with the designer’s return
                policy at the time of purchase. Losode is guided by the
                designer’s returns policy which is clearly displayed on the item
                listing prior to your purchase.
              </li>
              <li>
                Returns are only permitted where an item is defective, faulty,
                damaged, or not consistent with the description. For hygiene
                reasons, some items cannot be returned.
              </li>
              <li>
                You are responsible for packaging and returning the items to
                Losode at your cost. We may on occasion, refund you for the cost
                of posting the item back.
              </li>
              <li>
                For hygiene reasons, some items cannot be returned; these
                include personal items such as jewelry, underwear, swimwear, and
                skincare products.
              </li>
              <li>
                Items will typically be returned directly to the designer;
                however, in some exceptional circumstances Losode may sometimes
                ask that you return the items directly to Losode and will advise
                you accordingly where this is the case.
              </li>
            </ul>
            <p>
              If you feel you have received a faulty product(s) please contact
              Losode immediately at{" "}
              <a
                href="mailto:hello@losode.com"
                style={{
                  fontWeight: "bold",
                  fontFamily: "DomaineSansText-Black",
                  textDecoration: "underline",
                }}
              >
                hello@losode.com
              </a>
              .
            </p>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              4. What can you return?
            </h3>
            <p>
              The items can be returned in line with the table below and only if
              they are in their original, unworn and unused condition and with
              their labels intact.
            </p>
            <div className="returns-table-container">
              <table className="returns-table">
                <tr>
                  <th></th>
                  <th>Lingerie, Underwear, Nightwear & Vests</th>
                  <th>Swimwear & Beachwear</th>
                  <th>Socks & Tights</th>
                  <th>All other Clothing</th>
                  <th>Footwear</th>
                  <th>All Skin and Beauty or Grooming</th>
                  <th>All Accessories</th>
                  <th>All Bags</th>
                  <th>All Fabrics</th>
                </tr>
                <tr>
                  <td>I changed my mind, I no longer want the product</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>I received the correct size, but it does not fit</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>I received the wrong size</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>I received the wrong item</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>It was damaged/broken or faulty</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>It is fake</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
              </table>
            </div>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              5. How to request a refund or exchange
            </h3>
            <ul>
              <li>
                Sign into your account, navigate to your account dashboard and
                select the relevant product(s) from your order history. If the
                item is eligible for a refund, exchange, or amendment, this will
                be visible against the order.
              </li>
              <li>
                Complete the form and provide the reason for your request.
              </li>
              <li>
                If the links are shaded out, this means that your proposed
                request is not in line with the designer’s policy as stated at the
                time of your purchase.
              </li>
              <li>
                Upon completion of the form, you will receive an email
                confirmation of the status of your request.
              </li>
            </ul>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              6. Assessing your request
            </h3>
            <p>
              Losode will automatically review your request to assess its
              eligibility based on the designer's returns policy and the timing of
              your request.
            </p>
            <p>
              If your request is eligible, Losode will send you further
              instructions by email on how to return the product along with a
              shipping label.
            </p>
            <p>
              The email will include access to download and print the relevant
              return labels along with instructions on how to package your
              products for return.
            </p>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              7. Preparing to return your order
            </h3>
            <ul>
              <li>
                You need to print the labels and attach them to the packaging.
                You will need to pay for postage.
              </li>
              <li>
                You need to post the item within 5 working days of receipt of
                the return’s confirmation email.
              </li>
              <li>
                You will be responsible for the cost of returning any item(s).
                Please use a postal service that insures you for the value of
                the items you are returning.
              </li>
              <li>
                Losode would advise that you send all items by recorded delivery
                to ensure that they reach the designer and that you retain
                postage evidence.
              </li>
              <li>
                It is important that the items(s) are returned in their original
                package and that they are in their original, unworn condition,
                with all labels and tags intact.
              </li>
              <li>
                If you are unable to return the item for any reason, then Losode
                will not be able to process your request, except at our
                discretion.
              </li>
              <li>
                The items are your responsibility until they reach the designer
                from whom you purchased them. Please ensure they are properly
                packed and cannot get damaged on their journey back to the
                designer.
              </li>
            </ul>

            <h3
              style={{ fontFamily: "DomaineSansText-Black", marginTop: "40px" }}
            >
              8. Processing your refund or exchange
            </h3>
            <p>
              Losode can only process the relevant refund or exchange for items
              that have been returned to the designer and confirmed by the
              designer as received. Once the designer has received the returned
              package, they will examine it to check it meets the relevant
              criteria after which they will notify Losode.
            </p>
            <ul>
              <li>
                If your item is eligible for a refund, Losode will refund the
                full cost of your purchase including delivery fees, back to your
                original payment method. Refunds will be issued within 10
                working days of receipt by the designer.
              </li>
              <li>
                In the event of an exchange; as soon as the designer has
                confirmed that your item(s) meet their exchange criteria, you
                will receive an exchange for your item(s) and will be notified
                via email when the exchange is processed and on the way to you.
                A new item will be dispatched to you within 10 working days of
                receipt of the original item by the designer.
              </li>
              <li>
                In the unlikely event that an item is returned to the designer
                in an unsuitable condition, the designer may refuse to accept
                the item.
              </li>
              <li>
                Where the designer refuses to accept the item, Losode will review
                evidence provided by the designer and yourself as the customer
                and make a decision that is fair to both parties. If the
                decision is not in your favor, Losode may have to send it back
                to you.
              </li>
            </ul>
            <p>
              If you have any issues with your returns, please contact The
              Losode Team at{" "}
              <a
                href="mailto:hello@losode.com"
                style={{
                  fontWeight: "bold",
                  fontFamily: "DomaineSansText-Black",
                  textDecoration: "underline",
                }}
              >
                hello@losode.com
              </a>
              .
            </p>
          </div>
        </div>
      </StaticPageTemplate>
      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        ol > li::marker {
          font-weight: bold;
        }
        .returns-table {
          text-align: center;
          font-size: 14px;
          line-height: 1.5;
        }
        .returns-table td {
          height: 80px;
          min-width: 80px;
          padding: 8px;
          border: 1px solid black;
        }
        .returns-table th {
          font-weight: normal;
          padding: 8px;
          border: 1px solid black;
        }
        .returns-table-container {
          width: 100%;
          overflow-x: auto;
        }

        @media (max-width: 640px) {
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};

export default Returns;
