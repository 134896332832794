const Banner = () => {
  return (
    <div className="banner-container">
      <style jsx="true">{`
        .banner-container {
          background: url("./images/kids_banner.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          height:  calc(100vh - 330px);
        }
        @media(max-width:640px){
          .banner-container{
            height: 248px;
          }
        }
      `}</style>
    </div>
  );
};

export default Banner;
