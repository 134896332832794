import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const RandomCategory = () => {


  const navigate = useNavigate();

  return (
    <div className="randomcategory-container">
      <img src="./images/random-category-img-women.png" alt="random-category" className="randomcategory-image" />
      <div className="randomcategory-content-container">
        <div className="txt-style-main" style={{color:'fff'}}>Bold visions, fierce colour</div>
        <div className="subtext">Explore contemporary fabrics and daring colorways that will transform  your year-end wardrobe</div>
        <Button
            className="btn-shop-randomcategory"
            style={{marginTop:"20px"}}
            onClick={() => navigate('/women/subcategory/beauty/cid=3?page=1')}
          >
            Shop Now
        </Button>
      </div>
      <style jsx="true">{`
        .randomcategory-container {
          position:relative;
          background-position: top;
          margin: 0 100px 64px;
        }
        .randomcategory-image{
          width: 100%;
          height: 100%;
        }
        .randomcategory-container .btn-shop-randomcategory {
          width: 426px;
          height: 48px;
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 17px DomaineSansText-Light;
        }
        .randomcategory-content-container{
          position:absolute;
          left: 8%;
          bottom: 22%;
          color: #fff;
        }
        .randomcategory-content-container .subtext{
          font-size: 18px;
          font-family: DomaineSansText-Light;
          max-width:470px;
        }
        .text-style-premain{
            font-size: 24px;
            font-family: DomaineSansText-Light;
            color: #fff;
            text-transform: uppercase;
        }


        @media(max-width:640px){
          .randomcategory-container{
            height: 248px;
            margin: 0px 16px;
          }
          .randomcategory-content-container{
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .randomcategory-container .btn-shop-randomcategory {
            width: 208px;
            height: 40px;
            font-size: 14px;
          }
          .randomcategory-content-container .subtext{
            font-size: 14px;
          }
          .text-style-premain{
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default RandomCategory;
