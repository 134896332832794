import React from "react";
import { useMediaQuery } from "react-responsive";

const Verdict = () => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="verdict-container">
      {/* <div className="overlay" /> */}
      {/* <div className="verdict-text-box">
        <h4 className="verdict-title">Watch Out For</h4>
        <h1 className="verdict-main">The Verdict</h1>
        <h4 className="verdict-desc">A fashion and style editorial</h4>
      </div> */}
      <img src={!isTabletOrMobile ? "/images/fashion-banner-women.png" : "/images/fashion-banner-mobile-women.png"} alt="fashion-banner" className="verdict-box" />
      <style jsx="true">{`
        .verdict-container {
          position: relative;
          margin:10px 10px -40px;
        }
        .verdict-text-box {
          display: flex;
          flex-direction: column;
          position: absolute;
          padding-left: 3.4%;
          bottom: 5rem;
          z-index: 2;
        }
        .verdict-title {
          font: normal 400 30px DomaineSansText-Regular;
          line-height: 45px;
          color: #ffffff;
          margin-bottom: 0px;
        }
        .verdict-main {
          font: normal 700 115px DomaineSansDisplay-Bold;
          // line-height: 187px;
          color: #ffffff;
          margin-bottom: 0px;
        }
        .verdict-desc {
          font: normal 300 30px DomaineSansText-Regular;
          line-height: 45px;
          color: #ffffff;
          text-transform: capitalize;
          margin-bottom: 0px;
          text-align: right;
        }
        .verdict-box {
          background-image: url("");
          height: auto;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
        }

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.25);
          z-index: 1;
        }

        .left-verdict-box {
          display: flex;
          width: 60vw;
        }
        .verdict-left-img-1 {
          object-fit: cover;
          // object-position: left;
          width: 40%;
          height: 600px;
        }
        .verdict-left-img-2 {
          object-fit: cover;
          object-position: center;
          width: 60%;
          height: 600px;
        }
        .right-verdict-box {
          width: 40vw;
        }
        .verdict-right-img {
          height: 600px;
          object-fit: cover;
          object-position: left;
        }

        @media (max-width: 640px) {
          .verdict-container {
            margin: 24px 16px;
          }

          .verdict-left-img-1,
          .verdict-left-img-2,
          .verdict-right-img {
            height: 250px;
          }
          .verdict-text-box {
            bottom: 50px;
          }

          .verdict-box {
            // height: 250px;
            // background-image: url("https://res.cloudinary.com/losode-ng/image/upload/v1698410403/website-pictures-optimized/MicrosoftTeams-image_cp7obj.jpg");
          }

          .verdict-title {
            font-size: 18px;
            line-height: 20px;
          }
          .verdict-main {
            line-height: normal;
            font-size: 30px;
            width: 168px;
          }
          .verdict-desc {
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
          }
        }
      `}</style>
    </div>
  );
};

export default Verdict;
