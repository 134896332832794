/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { useEffect, useState } from "react";
import formatter from "../../utils/formatter";
import { Tabs } from "antd";

const ManagePhotoshoots = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState("0");
  const [newshoots, setNewPhotoshoots] = useState([]);
  const [bookedshoots, setBookedPhotoshoots] = useState([]);

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };
  const { details } = useSelector((state) => state.store);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const getAllPhotoshoots = async () => {
    const { data } = await api.store.getPhotoshoots();
    if (data?.status === "ok") {
      const allShoots = data?.data;
      setNewPhotoshoots(
        allShoots?.filter((shoot) => shoot?.photoshoot_date === null)
      );
      setBookedPhotoshoots(
        allShoots?.filter((shoot) => shoot?.photoshoot_date !== null)
      );
    }
  };

  const shoots = tab === "0" ? newshoots : bookedshoots;

  useEffect(() => {
    getAllPhotoshoots();
  }, []);

  return (
    <div className="manage-payment-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="7" />}
        <div className="manage-payment-coverall-container">
          <div style={{ background: "white", paddingBottom: "16px" }}>
            {/* <div className="manage-payment-adspace"></div> */}
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div className="manage-payment-body-content-header-title">
                  Photoshoots
                </div>
                {/* <div className="manage-payment-body-content-header-subtitle">
                View your payment summary, trade summary and payment balance
                here
              </div> */}
              </div>
            </div>
            <div
              className="manage-payment-body-container"
              style={{ minHeight: 300 }}
            >
              <div className="manage-payment-body-content">
                <div>
                  <Tabs
                    defaultActiveKey={"0"}
                    className="scheduled-photoshoot-title"
                    tabBarGutter={!isTabletOrMobile ? 50 : 15}
                    onChange={(key) => setTab(key)}
                  >
                    <Tabs.TabPane
                      className="tab"
                      tab="My photoshoots"
                      key="0"
                    ></Tabs.TabPane>
                    <Tabs.TabPane
                      className="tab"
                      tab="Scheduled photoshoots"
                      key="1"
                    ></Tabs.TabPane>
                  </Tabs>

                  {shoots && shoots?.length > 0 ? (
                    shoots?.map((item, index) => (
                      <div
                        key={index}
                        className="scheduled-photoshoot-container"
                      >
                        <div className="scheduled-photoshoot-item-container">
                          <div className="scheduled-photoshoot-item-title">
                            Package {isTabletOrMobile && ":"}
                          </div>
                          <div className="scheduled-photoshoot-name">
                            {item?.photoshoot_plan} <br />{" "}
                            <span style={{ fontSize: 12 }}>
                              ({item?.photoshoot_classification})
                            </span>{" "}
                          </div>
                        </div>
                        <div className="scheduled-photoshoot-item-container">
                          <div className="scheduled-photoshoot-item-title">
                            Price {isTabletOrMobile && ":"}
                          </div>
                          <div className="scheduled-photoshoot-name">
                            {formatter(
                              Number(item?.photoshoot_price),
                              rate,
                              code,
                              indicator
                            )}
                          </div>
                        </div>
                        {tab === "1" && (
                          <div className="scheduled-photoshoot-item-container">
                            <div className="scheduled-photoshoot-item-title">
                              Scheduled Date {isTabletOrMobile && ":"}
                            </div>
                            <div className="scheduled-photoshoot-name">
                              {item?.photoshoot_date}
                            </div>
                          </div>
                        )}
                        {tab === "0" && (
                          <div className="scheduled-photoshoot-item-container">
                            <div className="scheduled-photoshoot-item-title">
                              Date {isTabletOrMobile && ":"}
                            </div>
                            <div className="scheduled-photoshoot-name">
                              {new Date(item?.created_at)?.toLocaleDateString()}
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no-order-div">
                      <svg
                        width={!isTabletOrMobile ? "84" : "48"}
                        height={!isTabletOrMobile ? "84" : "48"}
                        viewBox="0 0 84 84"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="42.1016"
                          cy="42.1036"
                          r="41.1742"
                          fill="black"
                          stroke="black"
                          stroke-width="1.44471"
                        />
                        <path
                          d="M40.5488 18.8281L40.5488 52.9661"
                          stroke="white"
                          stroke-width="1.86034"
                        />
                        <path
                          d="M40.6074 65.3789L40.5496 65.3789"
                          stroke="white"
                          stroke-width="6.2069"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {tab === "0" && (
                        <div className="txt-no-order ">
                          <div>You have no photoshoots booked please</div>
                          <div>
                            <span
                              style={{
                                fontFamily: "DomaineSansText-Black",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/book-photoshoot")}
                            >
                              Book a photoshoot
                            </span>{" "}
                            now
                          </div>
                        </div>
                      )}
                      {tab === "1" && (
                        <div className="txt-no-order ">
                          <div>
                            You have not been scheduled for any photoshoot yet.
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .manage-payment-container {
          font-family: DomaineSansText-Light;
          color: black;
        }
        .manage-payment-coverall-container {
          margin-left: 200px;
          background: rgb(249, 249, 249);
          // height: calc(100vh - 154px);
          padding: 16px;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }

        .manage-payment-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .manage-payment-body-container {
          margin: 0 40px 40px;
          display: flex;
          justify-content: space-between;
        }
        .manage-payment-body-content-header-container {
          // margin-bottom: 24px;
        }
        .manage-payment-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-payment-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .manage-payment-body-content-header-subtitle {
          font-size: 16px;
          color: #707070;
        }
        .manage-payment-body-sidebar-add-listing-button {
          height: 47px;
          background: white;
          color: black;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .manage-payment-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
        }

        .manage-payment-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
          align-items: center;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
        }
        .manage-payment-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
          border-bottom: 16px solid #f9f9f9;
        }
        .manage-payment-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .manage-payment-body-content-header-subtitle {
          font-size: 16px;
          color: #707070;
        }

        .manage-payment-body-content {
          width: 100%;
        }
        .manage-payment-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
          // gap: 21px;
        }

        .manage-payment-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
        }

        .no-listings-to-show {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 500px;
          font-weight: bold;
          font-size: 18px;
        }
        .no-listings-to-show-link {
          text-decoration: underline;
        }
        .no-listings-to-show-link:hover {
          cursor: pointer;
          color: #800000;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }

        .manage-payment-payment-summary-section {
          display: flex;
          justify-content: space-between;
          width: 88%;
        }
        .payment-summary-grey-box {
          color: #fff;
          background: #000;
          padding: 24px;
        }
        .payment-summary-pink-box {
          background: rgba(128, 0, 0, 0.21);
          padding: 24px;
          height: 150px;
          width: 400px;
          position: relative;
        }
        .payment-summary-big-number {
          font-size: 24px;
          font-weight: bold;
          font-family: "DomaineSansText-Black";
          margin-top: 8px;
        }
        .payment-summary-pink-box-button {
          background: black;
          color: white;
          height: 40px;
          font-size: 16px;
          position: absolute;
          right: 25px;
          bottom: -20px;
        }
        .payment-summary-underline-link {
          margin-top: 10px;
          padding: 16px 0;
          border-top: 1px solid #d4d4d4;
          font-size: 12px;
          color: #6f6f6f;
          text-decoration: underline;
        }
        .manage-payment-body-content-section-title {
          font-size: 18px;
          margin-bottom: 16px;
          font-weight: bold;
        }

        .manage-payment-body-content-trade-section-title {
          font-size: 18px;
          font-weight: bold;
        }

        .trade-summary-big-number {
          font-weight: bold;
          font-family: "DomaineSansText-Black";
        }
        .manage-payment-body-content-section-subtitle {
          font-size: 16px;
        }
        .trade-summary-date-select > .ant-select-selector {
          background-color: black !important;
          color: white;
          height: 47px !important;
          display: flex !important;
          align-items: center !important;
          width: 200px !important;
        }
        .trade-summary-date-select > .ant-select-arrow {
          color: white;
        }
        .manage-payment-body-content-section {
          margin-top: 40px;
        }
        .recent-transaction-image {
          background-repeat: no-repeat;
          width: 80px;
          height: 80px;
          background-size: cover;
        }
        .scheduled-photoshoot-title {
          padding: 16px 0;
          font-size: 16px;
          margin-bottom: 16px;
          font-family: DomaineSansText-Regular;
        }
        .scheduled-photoshoot-container {
          display: flex;
          padding: 30px 16px;
          background: #f9f9f9;
          border-bottom: 1px solid #ccc;
          gap: 10rem;
          font-size: 16px;
          padding-left: 60px;
        }
        .scheduled-photoshoot-item-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 16px;
        }
        .scheduled-photoshoot-item-title {
          width: 200px;
          font-weight: bold;
        }

        .no-order-div {
          display: grid;
          margin-top: 55px;
          place-items: center;
        }
        .txt-no-order {
          font: normal normal 30px/146.16% DomaineSansText-Light;
          text-align: center;
          margin-top: 30px;
          width: 70%;
        }
        .txt-no-order-desc {
          font: normal normal 18px/146.16% DomaineSansText-Light;
          margin-top: 24px;
          text-align: center;
          width: 50%;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .manage-payment-body-content {
            margin: auto;
            padding: 0 24px;
          }
          .manage-payment-body-container {
            margin: 0;
          }

          .manage-payment-body-content-header {
            padding: 24px;
          }

          .manage-payment-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
          .manage-payment-payment-summary-section {
            gap: 24px;
            width: auto;
          }
        }

        @media (max-width: 830px) and (min-width: 640px) {
          .payment-summary-pink-box {
            width: 280px;
          }
        }

        @media (max-width: 640px) {
          .manage-payment-coverall-container {
            margin-left: 0;
          }
          .manage-listing-all-content {
            margin-top: ${!details.phone_verify_status ? "154px" : "90px"};
          }
          .manage-payment-adspace {
            background-image: url(images/seller-stats-banner.png);
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          // .manage-listing-all-content {
          //   margin-top: 174px;
          // }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .manage-payment-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .manage-payment-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .manage-payment-body-content-header-title {
            // font-size: 18px;
          }
          .manage-payment-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 180px;
          }

          .manage-payment-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .manage-payment-body-content-header-container {
            display: block;
            align-items: center;
            // margin-bottom: 16px;
          }
          .manage-payment-body-content-header {
            padding: 16px;
            flex-direction: column;
          }
          .manage-payment-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .manage-payment-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
            margin: 0;
          }
          .manage-payment-body-sidebar {
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            display: flex;
            justify-content: flex-end;
          }
          .manage-payment-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .manage-payment-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .manage-payment-body-sidebar-content-select {
            width: auto;
          }
          .manage-payment-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .manage-payment-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-payment-body-content {
            width: 100%;
            padding: 0 16px;
          }
          .manage-payment-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }
          .manage-payment-payment-summary-section {
            width: 100%;
            flex-direction: column;
            gap: 16px;
          }
          .payment-summary-grey-box {
            padding: 16px;
            font-size: 12px;
          }
          .payment-summary-pink-box {
            padding: 16px;
            width: auto;
            font-size: 12px;
          }
          .payment-summary-big-number {
            font-size: 14px;
          }
          .payment-summary-pink-box-button {
            font-size: 12px;
            padding: 16px;
            line-height: 1;
          }
          .manage-payment-body-content-section {
            margin-top: 24px;
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 14px;
          }
          .payment-summary-underline-link {
            padding-bottom: 0px;
          }
          .manage-payment-transaction-table .ant-table {
            font-size: 12px;
          }
          .expanded-row-item-container {
            display: flex;
            gap: 8px;
            margin-bottom: 8px;
          }
          .expanded-row-item-container-title {
            min-width: 75px;
          }
          .expanded-row-item-container-data {
            font-weight: bold;
          }

          .scheduled-photoshoot-container {
            flex-direction: column;
            background: white;
            gap: 20px;
            padding: 0 10px 10px;
            margin-bottom: 2rem;
          }
          .scheduled-photoshoot-item-container {
            flex-direction: row;
            gap: 16px;
          }
          .scheduled-photoshoot-item-title {
            width: 130px;
            font-family: DomaineSansText-Regular;
            font-size: 14px;
          }
          .scheduled-photoshoot-title {
            text-align: center;
            margin-bottom: 0;
          }
          .scheduled-photoshoot-name {
            font-size: 14px;
            text-align: right;
          }
          .txt-no-order {
            font-size: 16px;
            width: 100%;
            margin-top: 16px;
          }
          .no-order-div {
            background: transparent !important;
          }
        }
      `}</style>
    </div>
  );
};

export default ManagePhotoshoots;
