import { useState, useEffect } from "react";
import { Layout, Menu, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { productsActionCreators } from "../../redux/products";
import { brandsActionCreators } from "../../redux/brands";
import HoverMenu from "../HoverMenu";
import DesignerMenu from "../DesignerMenu";
import NewInMenu from "../NewInMenu";
import SellMenu from "../SellMenu";

const { Header } = Layout;
const { Search } = Input;

const SubHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState("none");
  const [showBrands, setDisplay] = useState("none");
  const [showSell, setSellMenu] = useState("none");
  const [category, setCategory] = useState("Clothing");
  const [searchValue, setSearchValue] = useState("");

  const [newInVisible, setNewInVisible] = useState("none");

  const { type } = useSelector((state) => state.user);

  const { data } = useSelector((state) => state.currency);
  const { code } = data || "";

  const { fetchAllCategories, searchProduct, fetchNewInMenu } =
    bindActionCreators(productsActionCreators, dispatch);

  const { fetchMenuBrands } = bindActionCreators(
    brandsActionCreators,
    dispatch
  );

  const showCatMenu = (cat) => {
    setVisible("flex");
    setCategory(cat);
  };
  const hideCatMenu = () => setVisible("none");

  const showDesigners = () => setDisplay("flex");
  const hideDesigners = () => setDisplay("none");

  const showSellMenu = () => setSellMenu("flex");
  const hideSellMenu = () => setSellMenu("none");

  const showNewInMenu = () => setNewInVisible("flex");
  const hideNewInMenu = () => setNewInVisible("none");

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    setNewInVisible("none");

    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
    setNewInVisible("none");
  };

  const shopCategory = (cat, id) => {
    navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}?page=1`);
  };

  const newInMenu = () => {
    if (type === "Men") fetchNewInMenu(1);
    if (type === "Women") fetchNewInMenu(2);
  };

  const onSearch = async (value) => {
    const res = await searchProduct(value, code);
    if (res.status === "ok") {
      navigate(
        {
          pathname: "/products/search",
          search: `?query=${value}`,
        },
        { state: value }
      );
      setSearchValue("");
    }
  };

  useEffect(() => {
    fetchAllCategories();
    fetchMenuBrands();
    newInMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Header style={{ backgroundColor: "#000" }}>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{
            backgroundColor: "#000",
            position: "relative",
          }}
        >
          {/* <Menu.Item className="sale" key="1">
            Sale
          </Menu.Item> */}

          <Menu.Item
            className="item"
            key="2"
            onClick={shopNewIn}
            onMouseEnter={() => showNewInMenu()}
            onMouseLeave={() => hideNewInMenu()}
          >
            New In
          </Menu.Item>
          <Menu.Item
            className="item"
            key="3"
            onClick={() => {
              navigate("/designers");
              hideDesigners();
            }}
            onMouseEnter={() => showDesigners()}
            onMouseLeave={() => hideDesigners()}
          >
            Designers
          </Menu.Item>

          <Menu.Item
            className="item"
            key="4"
            onClick={() => shopCategory("clothing", type === "Women" ? 6 : 5)}
            onMouseEnter={() => showCatMenu("Clothing")}
            onMouseLeave={() => hideCatMenu()}
          >
            Clothing
          </Menu.Item>
          <Menu.Item
            className="item"
            key="5"
            onClick={() =>
              shopCategory("accessories", type === "Women" ? 10 : 9)
            }
            onMouseEnter={() => showCatMenu("Accessories")}
            onMouseLeave={() => hideCatMenu()}
          >
            Accessories
          </Menu.Item>
          <Menu.Item
            className="item"
            key="7"
            onClick={() => shopCategory("shoes", type === "Women" ? 8 : 7)}
            onMouseEnter={() => showCatMenu("Footwear")}
            onMouseLeave={() => hideCatMenu()}
          >
            Footwear
          </Menu.Item>

          {type === "Women" && (
            <Menu.Item
              className="item"
              key="6"
              onClick={() => shopCategory("beauty", 3)}
              onMouseEnter={() => showCatMenu("Skin and Beauty")}
              onMouseLeave={() => hideCatMenu()}
            >
              Beauty
            </Menu.Item>
          )}

          {/* <Menu.Item
            className="item"
            key="6"
            onClick={() => shopCategory("beauty", 3)}
            onMouseEnter={() =>
              showCatMenu(type === "Men" ? "Grooming" : "Skin and Beauty")
            }
            onMouseLeave={() => hideCatMenu()}
          >
            {type === "Men" ? "Grooming" : "Beauty"}
          </Menu.Item> */}

          <Menu.Item
            className="item"
            key="17"
            onClick={() => navigate("/sell")}
            onMouseEnter={() => showSellMenu()}
            onMouseLeave={() => hideSellMenu()}
          >
            Sell On Losode
          </Menu.Item>

          <Menu.Item
            className="item-search"
            style={{ position: "absolute", right: 0, padding: "0 24px" }}
          >
            <div className="search-container">
              <Search
                className="search"
                allowClear
                placeholder="Search for items and brands"
                onSearch={onSearch}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </Menu.Item>
        </Menu>
        <HoverMenu
          category={category}
          hideCatMenu={hideCatMenu}
          showCatMenu={showCatMenu}
          visible={visible}
        />
        <DesignerMenu
          showDesigners={showDesigners}
          hideDesigners={hideDesigners}
          visible={showBrands}
        />
        <NewInMenu
          showNewInMenu={showNewInMenu}
          hideNewInMenu={hideNewInMenu}
          visible={newInVisible}
        />
        <SellMenu
          showSellMenu={showSellMenu}
          hideSellMenu={hideSellMenu}
          visible={showSell}
        />
      </Header>

      <style jsx="true">{`
        .ant-layout-header {
          padding: 0px;
        }
        .sale {
          background: #ff0000 !important;
          color: #fff !important;
          font-size: 18px;
          font-family: DomaineSansText-Light;
          text-align: center;
          text-decoration: none;
          width: 130px;
        }
        .item {
          background: #000 !important;
          color: #fff !important;
          font-size: 16px;
          font-family: DomaineSansText-Light;
          text-decoration: none;
          padding: 0 16px !important;
        }
        .item:hover {
          background: #fff !important;
          color: #000 !important;
        }
        .active {
          background: #fff !important;
          color: #000 !important;
          font-size: 18px;
          font-family: DomaineSansText-Light;
          text-decoration: none;
        }

        .search-container .ant-input-group {
          margin-top: 12px;
        }

        .item-search {
          background: transparent !important;
          font-size: 16px;
          font-family: DomaineSansText-Light;
          // margin-left: 10rem;
          text-decoration: none;
          width: 30%;
        }
        .item-search:hover {
          background: transparent !important;
          color: #000;
        }
        .search-container
          .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          height: 40px;
          background: #2f2f2f;
          border: 0px;
        }
        .search-container
          .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child):hover {
          background: ##2f2f2f;
          border: 0px;
        }
        .search-container
          .ant-input-search
          > .ant-input-group
          > .ant-input-group-addon:last-child
          .ant-input-search-button:not(.ant-btn-primary) {
          background-color: #2f2f2f;
          border: 0px;
          color: #fff;
          height: 40px;
          width: 60px;
        }
        .search-container .txt-search {
          box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);
          color: #fff;
          cursor: pointer;
          font: normal normal 14px/146.16% DomaineSansText-Regular;
          margin: 0px;
          margin-bottom: 10px;
          padding: 5px;
          position: absolute;
          transition: box-shadow 0.5s;
        }
        .search-container .txt-search:hover {
          box-shadow: 0px 3px 10px 5px rgb(0 0 0 / 0.2);
        }
        .ant-input-affix-wrapper > input.ant-input {
          background: rgb(249, 249, 249, 0.01);
          color: #fff;
        }
        .search-container .ant-input::placeholder {
          color: #ccc;
        }
      `}</style>
    </>
  );
};

export default SubHeader;
