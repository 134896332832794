import axios from "axios";

export const fetchStates = async (country) => {
  const result = await axios.get(`utility/countries`);

  if (result.data.state === "ok") {
    const countryData = result.data.data.filter(
      (data) => data.name === country
    );
    return countryData[0]?.states;
  }
};

export const fetchCities = async (country) => {
  const res = await axios.get(`https://www.losode.dev/api/v2/cities`);
  if (res.status === 200) {
    return res.data[country];
  }
};
