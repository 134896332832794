import { useNavigate } from "react-router-dom";
import StaticPageTemplate from "./StaticPageTemplate";


const Terms = () => {

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const navigate = useNavigate()

  return (
    <div>
      <StaticPageTemplate title={"Terms and Conditions"}>
      <div className="static-content-container">
      <p className="about-content">
            These terms and conditions apply to the use of the Losode website, run by Losode Inc. 
            <br/><p style={{marginTop:'10px'}}>We would love to hear from you. If you have any questions, comments, or concerns in regards to these terms and conditions, please contact us immediately by sending an email to <a href="mailto:hello@losode.com"  style={{fontWeight:'bold', textDecoration:'underline'}}>hello@losode.com</a> or <span onClick={() => navigate("/contact-us")} style={{fontWeight:'bold', cursor:"pointer", textDecoration:'underline'}}>click here</span> for more contact options </p>
            <p style={{marginBottom:'8px', fontWeight:'bold'}}>Terms and Conditions</p>
            <p>The website Losode.com is operated by Losode Inc. Losode is referred as “we”, “us”, and “our” throughout this terms and conditions page. Losode.com offers quality clothing, shoes, accessories and skincare at affordable prices for multiple markets.  </p>
            The following terms and conditions apply to all web visitors of this website, including those who are browsing, customers who place an order, vendors, suppliers, and contributors. Please visit this web page often for any changes to our terms and conditions. 
            <br/><br/><strong>When you place an order with Losode you are agreeing to the following terms and conditions: </strong>
            <ul>
              <li>You agree that you are at least of majority of age in the city, state, province, and country of your residence or that you have given a minor the consent to make a purchase on this site </li>
              <li>The use of our products for illegal or unauthorized purposes is strictly prohibited and entails a violation to these terms and conditions </li>
              <li>A breach of any of the terms and conditions stated here will result in the immediate termination of your services with Losode </li>
              <li>Losode.com. Losode Nigeria Ltd. and Losode UK Ltd. reserve the right to deny services to any person or entity, for any reason, at any time </li>
              <li>You agree that any content you provide to us, with the exception of your credit card information may be transferred to the logical party (i.e. shipping partners) unencrypted. Your credit card information and bank account details are always safe with us or our payment partners and encrypted during transfer. </li>
              <li>You agree that the reproduction, duplication, or copying of any portion of this website without written permission is prohibited</li>
              <li>The prices for products found at Losode are subject to change at any time and without notice. Be sure to check often </li>
              <li>Losode reserves the right, at any time, to discontinue service without notice.</li>
              <li>Our products are described and shown as accurately as possible. However, due to changes in computer screen resolutions and such, colors, and product images may be slightly different than what you see online </li>
              <li>We reserve the right to limit quantities of products for any reason, at any time. We also reserve the right to discontinue any product found on our website </li>
              <li> Product and availability of products is subject to change at any time and for any reason </li>
              <li>We reserve the right to refuse to fulfill any orders on a case-by-case basis. When we cancel an order, we may notify you via email, phone, or billing address .</li>
            </ul>
            
            </p>
           </div>
      </StaticPageTemplate>
      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        @media (max-width: 640px) {
          
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};

export default Terms;
