import { Button, Carousel } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const Banner = () => {

  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="banner-container">
      {
        !isTabletOrMobile ? (
        <div className="bannercontainer-image-container">
          <img src="./images/home-main-image-1.png" alt="banner" className="bannercontainer-image" />
          <img src="./images/home-main-image-2.png" alt="banner" className="bannercontainer-image" />
          <img src="./images/home-main-image-3.png" alt="banner" className="bannercontainer-image" />
        </div>
        ) : (
          <Carousel effect="fade" autoplay>
           <img src="./images/home-main-image-1.png" alt="banner" className="bannercontainer-image" />
            <img src="./images/home-main-image-2.png" alt="banner" className="bannercontainer-image" />
            <img src="./images/home-main-image-3.png" alt="banner" className="bannercontainer-image" />
        </Carousel>
        )
      }
      
      <div className="banner-content-container">
        <div className="txt-style-main" style={{color:'fff', marginBottom:'8px'}}>Timeless design, Unique pieces</div>
        <div className="subtext">Your gateway to style and fashion excellence</div>
        <Button type="link" onClick={() => shopNewIn()} style={{color: "#000", fontFamily:'DomaineSansText-Light', padding:'0px', height:'auto'}}>
          <span style={{textDecoration:'underline'}}>Shop now</span>
        </Button>
        {/* <Button
            className="btn-shop-banner"
            style={{marginTop:"20px"}}
            onClick={shopNewIn}
          >
            Shop Now
        </Button> */}
      </div>
      <style jsx="true">{`
        .banner-container {
          position:relative;
          background-position: top;
        }
        .bannercontainer-image-container{
          display: flex;
          justify-content: space-between;
          width:100%;
          gap:0px;
        }
        .bannercontainer-image{
          flex: 1;
          height: auto;
          width: 33%;

        }
        .banner-container .btn-shop-banner {
          width: 426px;
          height: 48px;
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 17px DomaineSansText-Light;
        }
        .banner-content-container{
          margin: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 16px;
        }
        .banner-content-container .subtext{
          font-size: 20px;
          font-family: DomaineSansText-Light;
        }
        @media(max-width:640px){
          .banner-container{
            // height: 248px;
          }
          .banner-content-container{
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .bannercontainer-image{
            flex: 1;
            height: 525px;
            width: 100%;
            object-fit: cover;
          }
          .banner-container .btn-shop-banner {
            width: 100%;
            height: 40px;
            font-size: 14px;
          }
          .banner-content-container .subtext{
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default Banner;
