const PhoneDial = ({value,country}) => {
  return (
    <div
      defaultValue={"+234"}
      style={{
        width: "40%",
        border: "1px solid #6F6F6F",
        borderRight: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      value={value}
    >
      <img className="icon-flag" src={country.icon} alt={country.country} />
      {country.dial}
    </div>
  );
}

export default PhoneDial