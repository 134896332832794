import {
  fetchAllProducts as fetchProductsApi,
  fetchHeaderCategories as menuApi,
  fetchFooterCategories as footerApi,
  fetchGenderProducts as genderProductsApi,
  fetchCategoryProducts as mainCatProductsApi,
  fetchNewIn as newInApi,
  fetchProductsByCategory as categoriesApi,
  fetchProductsByBrands as brandsApi,
  fetchNextProducts as nextProductsApi,
  fetchLastProducts as lastProductsApi,
  searchProducts as searchApi,
  fetchNewInMenu as newInMenuApi,
  filterCategory,
  filterByOptions,
  filterStore,
  filterNewIn,
} from "../../api/productsApi";

import {
  FETCH_ALL_CATEGORIES,
  FETCH_FOOTER_CATEGORIES,
  FETCH_HEADER_CATEGORIES,
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS,
  FETCH_NEW_IN_MENU,
} from "./types";

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_PRODUCTS_START,
      });
      const res = await fetchProductsApi();
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchNextProducts = (url) => {
  return async (dispatch) => {
    try {
      const products = await nextProductsApi(url);
      dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: products,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchLastProducts = (url) => {
  return async (dispatch) => {
    try {
      const products = await lastProductsApi(url);
      dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: products,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchGenderProducts = (id, query, code) => {
  return async (dispatch) => {
       dispatch({
         type: FETCH_PRODUCTS_START,
       });
    try {
      dispatch({
        type: FETCH_PRODUCTS_START,
      });
      const res = await genderProductsApi(id, query, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchMainCatProducts = (id, code) => {
  return async (dispatch) => {
       dispatch({
         type: FETCH_PRODUCTS_START,
       });
    try {
      dispatch({
        type: FETCH_PRODUCTS_START,
      });
      const res = await mainCatProductsApi(id, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchNewProducts = (id, query, code) => {
  return async (dispatch) => {
       dispatch({
         type: FETCH_PRODUCTS_START,
       });
    try {
      const res = await newInApi(id, query, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchCategoryProducts = (id, cat, query, code) => {
  return async (dispatch) => {
       dispatch({
         type: FETCH_PRODUCTS_START,
       });
    try {
      const res = await categoriesApi(id, cat, query, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchBrandProducts = (slug, query, code) => {
  return async (dispatch) => {
       dispatch({
         type: FETCH_PRODUCTS_START,
       });
    try {
      const res = await brandsApi(slug, query, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchHeaderCategories = (category, type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_HEADER_CATEGORIES,
        payload: { category, type },
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchFooterCategories = () => {
  return async (dispatch) => {
    try {
      const res = await footerApi();
      if (res.status === "ok") {
        dispatch({
          type: FETCH_FOOTER_CATEGORIES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchAllCategories = () => {
  return async (dispatch) => {
    try {
      const res = await menuApi();
      console.log({res})
      if (res.status === "ok") {
        dispatch({
          type: FETCH_ALL_CATEGORIES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const sortByPrice = (type) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_PRODUCTS,
      payload: type,
    });
  };
};

export const searchProduct = (value, code) => {
  return async (dispatch) => {
    try {
      const res = await searchApi(value, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }

      return res;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchNewInMenu = (id) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_PRODUCTS_START,
    });
    try {
      const res = await newInMenuApi(id);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_NEW_IN_MENU,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const filterProductsByCat = (id, catIds, type, page, code) => {
  return async (dispatch) => {
    try {
      const res = await filterCategory(id, catIds, type, page, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
        // dispatch({
        //   type: UPDATE_FILTER_PRODUCTS,
        //   payload: res.data,
        // });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const filterProductsByOptions = (query) => {
  return async (dispatch) => {
    try {
      const res = await filterByOptions(query);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const filterNewIns = (query) => {
  return async (dispatch) => {
    try {
      const res = await filterNewIn(query);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const filterBrandProducts = (slug, catIds, page, code) => {
  return async (dispatch) => {
    try {
      const res = await filterStore(slug, catIds, page, code);
      if (res.status === "ok") {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};