import { Skeleton } from "antd";

const { Input } = Skeleton;

const Filters = () => {
  return (
    <div className="filters-skeleton-container">
      {Array.from({ length: 5 }).map(() => (
        <>
          <span className="line" />
          <Input active size="small" />
        </>
      ))}
      <span className="line" />

      <style jsx>{`
        .filters-skeleton-container {
          display: flex;
          flex-direction: column;
        }
        .line {
          border-top: 0.1px solid #d4d4d44d;
          width: 100%;
          margin: 5px;
        }
        .filters-skeleton-container
          .ant-skeleton-element
          .ant-skeleton-input-sm {
          width: 100%;
          height: 40px;
          min-width: auto;
          margin-top: 5px;
        }
      `}</style>
    </div>
  );
};

export default Filters;
