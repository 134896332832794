import { useEffect, useState, 
  // createRef 
} from "react";
import { Button, Tooltip, 
  // Carousel 
} from "antd";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { DownOutlined, PlusOutlined, InfoCircleOutlined
  // LeftOutlined, RightOutlined 
} from "@ant-design/icons";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import OrderView from "./OrderView";
import HelpFeedback from "./HelpFeedback";
import { storeActionCreators } from "../../redux/stores";
import formatter from "../../utils/formatter";
import PhoneVerificationModal from "./PhoneVerificationModal";
import { useMediaQuery } from "react-responsive";

const Dashboard = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  // const [activeSlide, setActiveSlide] = useState(0);

  // const carouselSettings = {
  //   arrows: false,
  //   slidesToShow: !isTabletOrMobile ? 3 : 1,
  //   slidesToScroll: 1,
  //   dots: false,
  //   infinite: false,
  //   afterChange: (current) => setActiveSlide(current),
  // };

  // const carouselRef = createRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { details, orders, sales, totalProducts, settlementInfo } = useSelector(
    (state) => state.store
  );

  const { data } = useSelector((state) => state.currency);

  const { code, indicator, rate } = data
    ? data
    : { indicator: "", code: "", rate: "" };

  // const { updateFirstTime } = bindActionCreators(userActionCreators, dispatch);

  //   const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const {
    fetchOrders,
    fetchStore,
    fetchTotalProducts,
    fetchStoreProducts,
    fetchStoreDrafts,
    fetchStoreSales,
    fetchStoreValue,
    fetchSizeGuide,
    fetchSettlementInfo,
  } = bindActionCreators(storeActionCreators, dispatch);

  const fetchData = async () => {
    fetchStore();
    fetchStoreValue();
    fetchStoreProducts();
    fetchStoreDrafts();
    fetchTotalProducts();
    fetchStoreSales();
    fetchOrders();
    fetchSizeGuide();
    fetchSettlementInfo();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [orderCount, setOrderCount] = useState(4)

  return (
    <div className="dashboard-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="1" />}
        <div className="dashboard-coverall-container">
          <div className="dashboard-adspace"></div>

          <div className="dashboard-body-main">
            <div className="dashboard-body-content-header">
              <div className="dashboard-body-content-header-container">
                <div className="dashboard-body-content-header-title">
                  My Dashboard
                </div>
                {!isTabletOrMobile && (
                  <div className="dashboard-body-content-header-subtitle">
                    This is your Seller Dashboard, here you can see a snapshot
                    of activities happening across your Store.
                  </div>
                )}
              </div>
              <Button
                icon={
                  <PlusOutlined
                    style={{ fontSize: "17px", verticalAlign: "middle" }}
                  />
                }
                className="dashboard-body-sidebar-add-listing-button"
                onClick={() => navigate("/list-item")}
              >
                List A New Item
              </Button>
            </div>
            <div className="dashboard-body-container">
              <div className="dashboard-body-content">
                <div className="dashboard-body-bold-numbers-container">
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {formatter(
                        settlementInfo?.last_90_days,
                        rate,
                        code,
                        indicator
                      )}
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      AMOUNT EARNED ON LOSODE (90 DAYS)
                    </div>
                  </div>
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {totalProducts}
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      ITEMS LISTED
                    </div>
                  </div>
                  <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                    <div className="dashboard-body-bold-numbers-item-number">
                      {sales}
                    </div>
                    <div className="dashboard-body-bold-numbers-item-text">
                      ITEMS SOLD
                    </div>
                  </div>
                </div>
                {!settlementInfo?.account_number && <div className="link-to-payment-container" style={{marginBottom:"12px"}}>
                  <div>Go to {' '} 
                  <span
                      onClick={() => navigate("/manage-payment")}
                      style={{fontWeight:'bold', color:'white', textDecoration:'none', cursor:'pointer'}}
                    >
                      My Payments
                    </span> to add your bank account details now </div>
                    <Tooltip className="payment-tooltip" title="To ensure you are paid on time, please make sure your bank account details have been added to your profile.">
                      <InfoCircleOutlined
                        style={{
                          color: "white",
                          fontSize: "12px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                </div>
}
                {/* <div className="link-to-payment-container">
                  <div>You have been scheduled for a photoshoot, click {' '} 
                    <span
                      onClick={() => navigate("/manage-photoshoots")}
                      style={{fontWeight:'bold', color:'white', textDecoration:'none', cursor:'pointer'}}
                    >
                      here
                    </span> to view</div>
                    
                </div> */}
                <div className="dashboard-body-photoshoot-container">
                  <div className="dashboard-body-photoshoot-title">
                    Book A Photoshoot
                  </div>
                  <div className="dashboard-body-photoshoot-subtitle">
                    Clear images can help boost sales to your Store
                    considerably. Book an affordable professional photoshoot
                    with us today{" "}
                    <a
                      className="dashboard-body-link"
                      href="/book-photoshoot"
                    >
                      here
                    </a>
                  </div>
                </div>
                <div className="dashboard-body-orders-section">
                  <div className="dashboard-body-orders-title">My Orders</div>
                  <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div>

                  <div className="dashboard-body-content-listings-container">
                    {orders?.length > 0 ? (
                    <>
                      {orders
                        .slice(0, orderCount)
                        .map((order) => (
                          <OrderView
                            key={order.id}
                            product={order}
                            type="Dashboard"
                          />
                        ))}
                     
                    </>
                    ) : (
                      <div className="no-listings-to-show">
                        <div>
                          <div>You currently have 0 new orders</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="dashboard-body-community-container">
                  <div className="dashboard-body-community-title">
                    Join Our Community
                  </div>
                  <div className="dashboard-body-community-subtitle">
                    To get exclusive offers from our partner network. Join the
                    Losode Seller Community now.{" "}
                    <a
                      className="dashboard-body-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://t.me/+EOIEDBbkFMgwNzE8"
                    >
                      Click here
                    </a>
                  </div>
                </div> */}
                {/* <div className="dashboard-body-events-section">
                  <div className="dashboard-body-events-title">My Events</div>
                  <div className="dashboard-body-events-subtitle">
                    Check here often for great events and networking
                    opportunities that will enable the growth of your business{" "}
                  </div>
                  {!isTabletOrMobile && (
                    <div
                      className="events-container"
                      style={{ display: "flex" }}
                    >
                      <img
                        alt="placeholder"
                        src="./images/dashboard-event-image-temp.png"
                        className="dashboard-events-image"
                      ></img>
                      <img
                        alt="placeholder"
                        src="./images/category_footwear.png"
                        className="dashboard-events-image"
                      ></img>
                      <img
                        alt="placeholder"
                        src="./images/category_footwear.png"
                        className="dashboard-events-image"
                      ></img>
                      <img
                        alt="placeholder"
                        src="./images/category_footwear.png"
                        className="dashboard-events-image"
                      ></img>
                    </div>
                  )}

                  {isTabletOrMobile && (
                    <>
                      <Carousel {...carouselSettings} ref={carouselRef}>
                        <img
                          alt="placeholder"
                          src="./images/dashboard-event-image-temp.png"
                          className="dashboard-events-image"
                        ></img>
                        <img
                          alt="placeholder"
                          src="./images/category_footwear.png"
                          className="dashboard-events-image"
                        ></img>
                        <img
                          alt="placeholder"
                          src="./images/category_footwear.png"
                          className="dashboard-events-image"
                        ></img>
                        <img
                          alt="placeholder"
                          src="./images/category_footwear.png"
                          className="dashboard-events-image"
                        ></img>
                        <img
                          alt="placeholder"
                          src="./images/category_footwear.png"
                          className="dashboard-events-image"
                        ></img>
                      </Carousel>

                      <div className="process-icon-div-mobile">
                        <div
                          className="process-icon-container"
                          onClick={() => {
                            if (activeSlide > 0) carouselRef.current.prev();
                          }}
                        >
                          <LeftOutlined className="process-icon" />
                        </div>
                        <div
                          className="process-icon-container"
                          onClick={() => {
                            if (activeSlide !== 3) carouselRef.current.next();
                          }}
                        >
                          <RightOutlined className="process-icon" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="dashboard-body-partners-section">
                  <div className="dashboard-body-events-title">
                    From Our Partners
                  </div>
                  <div className="dashboard-body-events-subtitle">
                    We have collaborated with a number of established partners
                    to give you and your business the best opportunity for
                    growth Visit this section of your dashboard regularly for
                    updates
                  </div>
                  <div className="dashboard-body-partners-images-container">
                    <img
                      alt="placeholder"
                      src="./images/category_footwear.png"
                      className="dashboard-partners-image"
                    ></img>
                    <img
                      alt="placeholder"
                      src="./images/category_footwear.png"
                      className="dashboard-partners-image"
                    ></img>
                  </div>
                </div> */}

                {isTabletOrMobile && (orders?.length>=orderCount) && orders?.length > 0 &&
                  <div className="view-more-mobile"
                  onClick={()=>setOrderCount(prev=>prev+1)}
                  >
                  <div>View More</div>
                  <DownOutlined/>
                </div>
                }

                <div className="dashboard-store-link-section">
                  <div>
                    <div className="dashboard-store-link-title">
                      Share Your Store Link
                    </div>
                    <div className="dashboard-store-link-subtitle">
                      Share your unique Losode Store link with members of your
                      community, simply copy it and share on social media or in
                      an email to increase sales to your store
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }} className="dashboard-store-link-link">
                        Your unique Store link:{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={details?.storeLink}
                          style={{
                            textDecoration: "underline",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {details?.storeLink}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      />

      <style jsx="true">{`
        .dashboard-container {
          font-family: DomaineSansText-Light;
        }

        .manage-listing-all-content {
          position: relative;
          margin-top: 154px ;
        }
        .dashboard-coverall-container {
          margin-left: 200px;
        }
        .dashboard-body-main {
          padding: 0 40px;
          padding-bottom: 40px;
          margin-right: 30px;
        }
        .dashboard-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }
        .dashboard-body-content-header-container {
        }
        .dashboard-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 0;
        }
        .dashboard-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .dashboard-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }
        .dashboard-body-bold-numbers-container {
          display: flex;
          gap: 30px;
          width: 100%;
          margin-bottom: 50px;
          margin-top: 24px;
        }
        .dashboard-body-bold-numbers-item {
          width: 32%;
          background: black;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px 10px;
        }
        .dashboard-body-bold-numbers-item-number {
          font-family: "DomaineSansText-Black";
          font-size: 24px;
        }
        .dashboard-body-bold-numbers-item-text {
          font-size: 12px;
        }
        .dashboard-body-content-listings-container {
          display: flex;
        }
        .dashboard-body-photoshoot-container {
          margin: 24px 0;
          background: #ffeaea;
          padding: 25px 10px;
          width: 100%;
          margin-bottom: 50px;
        }
        .dashboard-body-photoshoot-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .dashboard-body-orders-section {
          margin-bottom: 50px;
        }
        .dashboard-body-orders-title {
          font-size: 18px;
          font-weight: bold;
        }
        .dashboard-body-community-container {
          margin-bottom: 24px;
          background: #ffeaea;
          padding: 25px 10px;
          width: 100%;
          margin-bottom: 50px;
        }
        .dashboard-body-community-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .dashboard-body-events-section {
          margin-bottom: 50px;
        }
        .dashboard-body-events-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .dashboard-body-events-subtitle {
          margin-bottom: 24px;
          width: 90%;
        }
        .dashboard-body-partners-section {
          margin-bottom: 50px;
        }
        .dashboard-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
          margin-top: 80px;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }
        .dashboard-body-link {
          text-decoration: underline;
          color: black;
        }
        .dashboard-store-link-section {
          margin-top: 24px;
          background: rgba(0, 0, 0, 0.04);
          padding: 25px 10px;
          width: 100%;
        }
        .dashboard-store-link-title {
          font-size: 18px;
          margin-bottom: 8px;
          font-weight: bold;
        }
        .dashboard-store-link-subtitle {
          width: 90%;
        }
        .dashboard-events-image {
          width: auto;
          height: 200px;
        }
        .dashboard-partners-image {
          width: auto;
          height: 200px;
        }
        .dashboard-body-partners-images-container {
          display: flex;
          gap: 30px;
        }
        .events-container {
          gap: 30px;
        }
        .link-to-payment-container{
          background: rgb(128, 0, 0);
          color: white;
          padding: 8px;
          display: flex;
          align-items:center;
          gap:8px;
          font-size: 14px;
          margin-bottom: -12px;
        }
        .ant-tooltip-inner{
          background: #F9F9F9;
          color: black;
          font-family: 'DomaineSansText-Light';
          font-size: 10px;
          padding: 8px;
        }
        .ant-tooltip-arrow-content{
          --antd-arrow-background-color: white;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .dashboard-body-main {
            padding: 0 16px;
          }
          .dashboard-body-bold-numbers-container {
            gap: 15px;
          }
          .dashboard-body-content-header {
            flex-direction: column;
            gap: 16px;
          }
          .dashboard-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }
          .dashboard-events-image,
          .dashboard-partners-image {
            width: 175px;
            object-fit: cover;
            height: 200px;
          }
          .events-container,
          .dashboard-body-partners-images-container {
            gap: 15px;
          }
          .dashboard-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
        }

        @media (max-width: 830px) and (min-width: 640px) {
          .dashboard-body-content-listings-container,
          .events-container,
          .dashboard-body-partners-images-container {
            flex-wrap: wrap;
          }
        }

        @media (max-width: 640px) {
          .manage-listing-all-content{
            margin-top: ${!details.phone_verify_status ? "154px" :"90px"} ;
          }
          .dashboard-coverall-container {
            margin-left: 0;
          }

          .dashboard-adspace {
            background-image: url(images/seller-stats-banner-mobile.png);
            height: 108px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }

          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }
          .dashboard-body-main {
            padding: 0px;
            padding-bottom: 24px;
            margin-right: 0;
          }
          .dashboard-body-content-header, .dashboard-body-orders-section, .dashboard-body-bold-numbers-container{
            padding-left: 12px;
            padding-right: 12px;
          }
          .dashboard-body-photoshoot-container{
            margin-left: 12px;
            margin-right: 12px;
            width:auto;
          }
          .dashboard-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .dashboard-body-content-header-title {
            font-size: 18px;
            font-family: DomaineSansText-Black;
          }
          .dashboard-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            margin-top: auto;
            border-radius: 0px;
          }
          .dashboard-body-bold-numbers-item {
            width: auto;
            justify-content: center;
            padding: 16px;
            text-align: center;
            border-radius: 4px;
          }
          .dashboard-body-bold-numbers-container {
            gap: 12px;
            margin-bottom: 24px;
            margin-top: 0px;
            flex-wrap: wrap;
          }
          .dashboard-body-bold-numbers-item-number {
            font-size: 20px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-photoshoot-container {
            padding: 16px 8px;
            margin-bottom: 24px;
            background: #FE7972;
            font-size: 12px;
            color: white;
            border-radius: 4px;
          }
          .dashboard-body-photoshoot-title{
            font-size: 16px;
            font-weight: 400;
            font-family: DomaineSansText-Regular;
          }
          .dashboard-body-photoshoot-subtitle{
            font-size: 12px;  
            line-height: 19px;
          }
          .dashboard-body-link{
            color: inherit;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: 400;
            font-family: DomaineSansText-Black;
          }
          .dashboard-body-community-container {
            padding: 16px 10px;
            margin-bottom: 24px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 16px;
            width: 100%;
            font-size: 12px;
            line-height: 19px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 24px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 120px;
          }
          .dashboard-body-partners-images-container {
            gap: 8px;
          }
          .dashboard-store-link-section {
            padding: 16px 10px;
            background: #6F6F6F33;
          }
          .dashboard-store-link-title{
            font-size: 18px;
            font-weight: 400;
            font-family: DomaineSansText-Regular;
          }
          .dashboard-store-link-subtitle {
            width: 100%;
            font-size: 12px;
          }
          .dashboard-store-link-link{
            font-size: 12px;
          }
          .dashboard-events-image {
            width: auto;
            height: 300px;
          }
          .process-icon-container {
            padding: 8px;
            border-radius: 50%;
            border: 1px solid #800000;
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .process-icon-div-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            margin: 16px 0;
          }
          .process-icon {
            color: #800000;
          }
          .dashboard-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .dashboard-body-bold-numbers-item-earned {
            width: 100%;
          }
          .dashboard-body-bold-numbers-item-listed,
          .dashboard-body-bold-numbers-item-sold {
            flex: 2;
          }
          .view-more-mobile{
            display: flex;
            justify-content: center;
            gap: 10px;
            font-size:14px;
            font-family: DomaineSansText-Regular;
            margin-top: 20px;
            padding: 8px;
            cusor:pointer;
            background: #F9F9F9;
          }
          .link-to-payment-container{
            font-size: 10px;
            margin: 0px 12px -12px;
            border-radius:4px
          }
        }
      `}</style>
    </div>
  );
};

export default Dashboard;
