import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import {
  Button,
  Form,
  DatePicker,
  Row,
  Col,
  Input,
  Space,
  Select,
} from "antd";
import { useMediaQuery } from "react-responsive";
import BuyerSideNav from "./BuyerSideNav";
import moment from "moment";
import { countries } from "../../assets/constants";
import triggerNotification from "../../hooks/triggerNotification";

const { Option } = Select;

const Settings = () => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const [personalFormOpen, setPersonalFormOpen] = useState(false);
  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);
  const [phone, setPhone] = useState("");

  const { settings } = useSelector((state) => state.user);

  const { fetchDetails, updateUserInfo, updateUserPassword, updateUserEmail } =
    bindActionCreators(userActionCreators, dispatch);

  const [personalForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const onSubmitInfo = async (values) => {
    const dob = values["dob"].format("YYYY-MM-DD");
    const res = await updateUserInfo({
      ...values,
      dob,
      dial: "+234",
    });
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
      setPersonalFormOpen(false);
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };
  const onSubmitEmail = async (values) => {
    const res = await updateUserEmail(values);
    if (res.status === "ok") {
       triggerNotification({
         type: "success",
         message: res.message,
       });
      setEmailFormOpen(false);
    }
    if (res.status === "not ok")   triggerNotification({
      type: "error",
      message: res.message,
    });;
  };
  const onSubmitPassword = async (values) => {
    const res = await updateUserPassword( values);
    if (res.status === "ok") {
       triggerNotification({
         type: "success",
         message: res.message,
       });
      setPasswordFormOpen(false);
      passwordForm.resetFields();
    }
    if (res.status === "not ok")   triggerNotification({
      type: "error",
      message: res.message,
    });;
  };

  settings?.dob &&
    personalForm.setFieldsValue({ dob: moment(settings?.dob, "YYYY-MM-DD") });
  settings?.phone && phone === "" && setPhone(settings?.phone);

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="account-settings-page-container">
        <div className="account-settings-page-header">
          <div className="account-settings-page-title">Account Details</div>
          <div className="account-settings-page-subtitle">
            View or edit your personal information here. It is important that
            your information is kept up to date
          </div>
          <div className="account-settings-page-header-divider"></div>
        </div>

        <div className="account-settings-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="5" />}

          <div className="account-settings-content-main">
            <div className="account-settings-content-main-body">
              <div className="account-settings-content-section">
                <div className="account-settings-content-section-title">
                  Personal Information
                </div>
                {!personalFormOpen && (
                  <div>
                    <div className="account-settings-content-section-info">
                      <div>
                        <span>Name: </span>
                        <span className="buyer-personal-info">{`${settings?.name}`}</span>
                      </div>
                      <div>
                        <span>Date of Birth: </span>
                        <span className="buyer-personal-info">
                          {settings?.dob ? settings.dob : ""}
                        </span>
                      </div>
                      <div>
                        <span>Phone Number: </span>
                        <span className="buyer-personal-info">
                          {settings?.dial}
                          {settings?.phone}
                        </span>
                      </div>
                    </div>
                    <div
                      className="account-settings-content-section-edit-link"
                      onClick={() => setPersonalFormOpen(true)}
                    >
                      Edit
                    </div>
                  </div>
                )}

                {personalFormOpen && (
                  <Form
                    form={personalForm}
                    name="personalForm"
                    onFinish={onSubmitInfo}
                    initialValues={settings}
                    scrollToFirstError
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row
                      className="form-row"
                      gutter={[48, 0]}
                      style={{
                        background: "white",
                      }}
                    >
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your first name"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your last name"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          label="Date Of Birth"
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <DatePicker
                            className="date"
                            format="YYYY-MM-DD"
                            placeholder="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="phone"
                          label="Phone Number"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone Number!",
                            },
                          ]}
                        >
                          <Space.Compact style={{ width: "100%" }}>
                            <Select
                              defaultValue={"+234"}
                              style={{
                                width: "35%",
                              }}
                            >
                              <Option
                                key={countries[0].dial}
                                value={countries[0].dial}
                              >
                                <img
                                  className="icon-flag"
                                  src={countries[0].icon}
                                  alt={countries[0].country}
                                />
                                {countries[0].dial}
                              </Option>
                            </Select>
                            <Input
                              className="input"
                              type="number"
                              placeholder="Enter phone number"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </Space.Compact>
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Button
                          className="account-settings-content-section-form-button"
                          htmlType="submit"
                        >
                          Update Information
                        </Button>
                      </Col>
                    </Row>

                    <div
                      className="account-settings-content-section-cancel-link"
                      onClick={() => setPersonalFormOpen(false)}
                    >
                      Cancel
                    </div>
                  </Form>
                )}
              </div>

              <div className="account-settings-content-section">
                <div className="account-settings-content-section-title">
                  Email Address
                </div>
                {!emailFormOpen && (
                  <div>
                    <div className="account-settings-content-section-info">
                      <div>
                        <span>Email: </span> <span>{settings?.email}</span>
                      </div>
                    </div>
                    <div
                      className="account-settings-content-section-edit-link"
                      onClick={() => setEmailFormOpen(true)}
                    >
                      Edit
                    </div>
                  </div>
                )}

                {emailFormOpen && (
                  <Form
                    form={emailForm}
                    name="emailForm"
                    onFinish={onSubmitEmail}
                    scrollToFirstError
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row
                      className="form-row"
                      gutter={[48, 0]}
                      style={{
                        background: "white",
                      }}
                    >
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="email"
                          label="New Email Address"
                          rules={[
                            {
                              type: "email",
                              message: "Use a valid email address",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your your new email address"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="password"
                          label="Current Password"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter current password"
                            type="password"
                            className="input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="form-row"
                      gutter={[48, 0]}
                      style={{
                        background: "white",
                      }}
                    >
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Button
                          className="account-settings-content-section-form-button"
                          htmlType="submit"
                        >
                          Update Information
                        </Button>
                      </Col>
                    </Row>

                    <div
                      className="account-settings-content-section-cancel-link"
                      onClick={() => setEmailFormOpen(false)}
                    >
                      Cancel
                    </div>
                  </Form>
                )}
              </div>

              <div className="account-settings-content-section">
                <div className="account-settings-content-section-title">
                  Password
                </div>
                {!passwordFormOpen && (
                  <div>
                    <div className="account-settings-content-section-info">
                      <div>
                        <span>Password: </span>{" "}
                        <span style={{ verticalAlign: "sub" }}>
                          ********************
                        </span>
                      </div>
                    </div>
                    <div
                      className="account-settings-content-section-edit-link"
                      onClick={() => setPasswordFormOpen(true)}
                    >
                      Edit
                    </div>
                  </div>
                )}

                {passwordFormOpen && (
                  <Form
                    form={passwordForm}
                    name="passwordForm"
                    onFinish={onSubmitPassword}
                    scrollToFirstError
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row
                      className="form-row"
                      gutter={[48, 0]}
                      style={{
                        background: "white",
                      }}
                    >
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="old_password"
                          label="Current Password"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter current password"
                            type="password"
                            className="input"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="new_password"
                          className="new-password"
                          label="New Password"
                          extra={
                            <div
                              style={{
                                position: isTabletOrMobile
                                  ? "static"
                                  : "absolute",
                                top: "60px",
                              }}
                            >
                              Password must be longer than 8 characters and must
                              contain at least 1 capital letter, 1 small letter
                              and 1 number
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                            {
                              pattern:
                                /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                              message:
                                "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter New Password"
                            type="password"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="c_password"
                          label="Confirm New Password"
                          dependencies={["new_password"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("new_password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  "The passwords that you entered do not match!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Confirm New Password"
                            type="password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="form-row"
                      gutter={[48, 0]}
                      style={{
                        background: "white",
                      }}
                    >
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Button
                          className="account-settings-content-section-form-button"
                          htmlType="submit"
                        >
                          Update Information
                        </Button>
                      </Col>
                    </Row>

                    <div
                      className="account-settings-content-section-cancel-link"
                      onClick={() => setPasswordFormOpen(false)}
                    >
                      Cancel
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          .account-settings-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-settings-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-settings-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-settings-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-settings-page-title{
            font-size:24px
          }
          .account-settings-page-subtitle{
            font-size:14px
          }
          .account-settings-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #d5d5d5;
            margin: 16px auto 24px auto;
          }
          .account-settings-content-section{
            background:white;
            padding:24px;
            margin-bottom: 24px;
          }
          .account-settings-content-section-title{
            font-size: 18px;
            color: black;
            margin-bottom: 16px;
          }
          .account-settings-content-section-info{
            font-size: 14px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
           .icon-flag {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
          .account-settings-content-section-edit-link{
            cursor:pointer;
            color: #800000;
          }
          .account-settings-content-section-cancel-link{
            cursor:pointer;
            color: #800000;
            margin-top: 40px;
            border-top: 1px solid #d5d5d5;
            padding-top: 16px;
          }

          .ant-form-vertical .ant-form-item-label > label {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: 8px;
          }
          .ant-form-item-extra{
            margin-top: 8px;
            margin-bottom: 4px;
          }
          .input {
            height: 48px;
            border: 1px solid #707070;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }
          .ant-form-item-with-help .ant-form-item-explain{
            margin-top:4px
          }
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .ant-picker-input > input {
            font-size: 14px;
            font-family: "DomaineSansText-Light";
          }
          .new-address-modal-title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
          }
          .account-settings-content-section-form-button{
            width: 100%;
            padding: 0 40px;
            height: 48px;
            background: black;
            color: white;
            font-size: 14px;
            margin-top: 30px;
          }
          .buyer-personal-info{
            color:#92959E;
          }

          .new-password .ant-form-item-extra{
            min-height:0px
          }

          @media(max-width:640px){
            .account-settings-content-main{
              margin-left:0;
              padding:0 16px;
            }
            .account-settings-page-title {
              font-size: 18px;
            }
            .account-settings-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-settings-content-section-title{
              font-size: 16px
            }
            .new-password .ant-form-item-extra{
              min-height:24px
            }


          }

        `}
      </style>
    </div>
  );
};

export default Settings;
