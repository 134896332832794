import React, { useState } from "react";
import { Button, Steps, Radio, Input, Modal, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { InfoCircleOutlined } from "@ant-design/icons";
import SellerSideNav from "./SellerSideNav";
import HelpFeedback from "./HelpFeedback";
import SellerTopBar from "./SellerTopbar";
import formatter from "../../utils/formatter";
import standardizeColor from "../../utils/standardizeColor";

const { Step } = Steps;

const OrderDetails = () => {
  const [rejectedModalVisible, setRejectedModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionConfirm, setRejectionConfirm] = useState("");

  const cancelRejectionModal = () => {
    setRejectedModalVisible(false);
    setRejectionConfirm("");
    setRejectionReason("");
  };
  const { order, details } = useSelector((state) => state.store);

  const { data } = useSelector((state) => state.currency);

  const { code, indicator, rate } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="order-details-container">
      <SellerTopBar />
      {/* <div className='order-details-sidenav-container'>
            
        </div> */}
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="2" />}
        <div className="order-details-coverall-container">
          <div className="order-details-main-content">
            <div style={{ background: "white" }}>
              <div className="order-details-adspace"></div>
            </div>
            <div className="order-details-body-content-header">
              <div className="order-details-body-content-header-container">
                <div className="order-details-body-content-header-title">
                  Order Details
                </div>
                {/* <div className="order-details-body-content-header-subtitle">
                    In your Store settings, you can edit or change your store
                    details and manage general characteristics for your Store.
                    Remember to join the Losode Seller Community here and take
                    advantage of our affordable photoshoot packages
                </div> */}
              </div>
            </div>
            <div className="order-container-padded">
              <div className="section-in-order-details order-info-section">
                <div className="order-details-section-title">
                  Order Information
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td className="order-info-label">Time Placed</td>
                      <td>{order.sold_on}</td>
                    </tr>
                    <tr>
                      <td className="order-info-label">Order Number</td>
                      <td>{order.OrderNumber}</td>
                    </tr>
                    <tr>
                      <td className="order-info-label">Total</td>
                      <td>
                        {formatter(order.totalAmount, rate, code, indicator)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="section-in-order-details delivery-info-section">
                <div className="order-details-section-title">
                  Delivery Information
                </div>
                <div>
                  <div>
                    Estimated delivery time shown to buyer -{" "}
                    {order.EstimatedDeliveryDate}
                  </div>
                  <Steps
                    current={order.status === "Item Sent" ? 1 : 0}
                    labelPlacement="vertical"
                    style={{ marginTop: "20px" }}
                  >
                    <Step title="Paid" description={order.sold_on} />
                    <Step title="Item Sent" description="" />
                    <Step title="Delivered" description="" />
                  </Steps>
                </div>
              </div>

              <div className="section-in-order-details delivery-address-section">
                <div className="order-details-section-title">
                  Delivery Address
                </div>
                <div>
                  <div style={{ width: !isTabletOrMobile ? "80%" : "100%" }}>
                    Ensure your buyer’s address is entered correctly on your
                    shipment label. Include state and country details So that
                    address is matched with ease
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "20px",
                      lineHeight: "2",
                      width: !isTabletOrMobile ? "150px" : "100%",
                    }}
                  >
                    {order.users?.buyer?.address}
                  </div>
                </div>
              </div>

              <div className="section-in-order-details payment-info-section">
                <div className="order-details-section-title">
                  <div>Payment Information</div>
                  <img
                    alt="placeholder"
                    src={order.productImage}
                    className="order-details-payment-image"
                  ></img>

                  <div
                    style={{
                      width: "200px",
                      fontWeight: "normal",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {order?.productName}
                    {order?.productColor && (
                      <div>
                        <div
                          className="product-color-section-box"
                          style={{
                            background: `${standardizeColor(
                              order?.productColor
                            )}`,
                          }}
                        ></div>{" "}
                        {order?.productColor}
                      </div>
                    )}
                    {order?.productColor && (
                      <p style={{ fontSize: 12 }}>{order?.productSize}</p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="order-details-payment-table-container">
                    <table className="order-details-payment-table">
                      <tbody>
                        <tr>
                          <td className="order-info-label">Item Price</td>
                          <td className="order-info-number">
                            {formatter(order.itemPrice, rate, code, indicator)}
                          </td>
                        </tr>

                        <tr>
                          <td className="order-info-label">Quantity</td>
                          <td className="order-info-number">
                            {order.quantity} item(s)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="section-in-order-details action-section">
                <div className="action-section-link">
                  Confirm Item has been sent
                </div>
              
                {/* <div className="action-section-link">View listing</div> */}
                {/* <div className="action-section-divider"></div> */}
                {/* <div
                  className="action-section-link"
                  onClick={() => setRejectedModalVisible(true)}
                >
                  Reject this order
                </div> */}
                <div className="action-section-divider"></div>
                <div className="action-section-link">
                  Contact our Seller Team{" "}
                  <Tooltip title="If there is a problem fulfilling this order or if you cannot fulfil this order">
                    <InfoCircleOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        fontSize: "16px",
                        marginLeft: "10px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={rejectedModalVisible}
        footer={null}
        onCancel={() => cancelRejectionModal()}
        closable={false}
        width={820}
        maskClosable={false}
        style={{ fontFamily: "DomaineSansText-Light" }}
      >
        <div style={{ padding: "0 20px", fontSize: "14px" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: !isTabletOrMobile ? "24px" : "18px",
              textAlign: "center",
              marginBottom: "4px",
            }}
          >
            Why are you rejecting this order?
          </div>
          <div
            style={{
              fontSize: !isTabletOrMobile ? "14px" : "12px",
              textAlign: "center",
            }}
          >
            It is important that your store is kept up to date, multiple order
            rejections may lead to us closing your store
          </div>
          {rejectionConfirm === "" && (
            <div>
              <Radio.Group
                onChange={(e) => setRejectionReason(e.target.value)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "8px",
                  margin: "30px auto 50px",
                }}
              >
                <Radio value="out of stock">
                  The item is out of stock and there are no alternatives
                </Radio>
                <Radio value="longer than planned">
                  The item is available but will take longer than planned
                </Radio>
                <Radio value="alternate colors">
                  The item is available but in a different colour
                </Radio>
                <Radio value="reason not here">My reason is not here</Radio>
              </Radio.Group>
              {rejectionReason === "reason not here" && (
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="no-reason-textbox"
                  placeholder="Please enter your reason for rejecting this order"
                />
              )}
            </div>
          )}

          {rejectionConfirm === "longer than planned" && (
            <div
              style={{
                background: "black",
                color: "white",
                padding: "20px",
                margin: "30px auto 50px",
                fontSize: "14px",
              }}
            >
              We can manage the buyer’s expectations on your behalf by letting
              them know that their order will take longer than planned. Instead
              of rejecting the order, send a message to our seller team at{" "}
              <a
                href="mailto:designers@losode.com"
                style={{ color: "white", textDecoration: "underline" }}
              >
                designers@losode.com
              </a>
            </div>
          )}

          {rejectionConfirm === "alternate colors" && (
            <div
              style={{
                background: "black",
                color: "white",
                padding: "20px",
                margin: "30px auto 50px",
                fontSize: "14px",
              }}
            >
              We can manage the buyer’s expectations on your behalf by letting
              them know that their ordered item is available in a different
              colour. Instead of rejecting the order, send a message to our
              seller team at{" "}
              <a
                href="mailto:designers@losode.com"
                style={{ color: "white", textDecoration: "underline" }}
              >
                designers@losode.com
              </a>
            </div>
          )}

          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              flexDirection: !isTabletOrMobile ? "row" : "column",
            }}
          >
            <Button
              style={{ height: "47px", padding: "0 50px" }}
              onClick={() => cancelRejectionModal()}
            >
              Cancel
            </Button>
            {rejectionConfirm === "" && (
              <Button
                style={{
                  background: "black",
                  color: "white",
                  height: "47px",
                  padding: "0 50px",
                }}
                onClick={() => setRejectionConfirm(rejectionReason)}
              >
                Submit
              </Button>
            )}
            {rejectionConfirm !== "" && (
              <Button
                style={{
                  background: "black",
                  color: "white",
                  height: "47px",
                  padding: "0 50px",
                }}
              >
                Continue Reject Order
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <HelpFeedback />

      <style jsx="true">{`
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }
        .order-details-coverall-container {
          margin-left: 200px;
        }
        .order-details-body-content-header-container {
          margin-bottom: 24px;
        }
        .order-details-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px 0;
          background: white;
        }
        .order-details-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .order-details-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }
        .order-details-container {
          font-family: DomaineSansText-Light;
          background: #f5f5f5;
        }
        .order-details-main-content {
        }
        .order-details-adspace {
          background-image: url("/images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          margin: auto;
        }
        .section-in-order-details {
          padding: 20px 40px;
          background: white;
          display: flex;
          gap: 40px;
          margin-bottom: 40px;
        }
        .order-details-section-title {
          font-weight: bold;
          min-width: 150px;
        }
        .order-info-section table {
          border-collapse: unset;
        }
        .order-info-section td {
          padding: 0 20px 20px 0;
        }
        .order-info-label {
          color: #707070;
        }
        .ant-steps-label-vertical .ant-steps-item-title {
          font-weight: bold;
          font-size: 14px;
        }
        .ant-steps-item-description {
          font-size: 12px;
        }
        .delivery-address-section {
          margin-top: -40px;
        }

        .order-details-payment-table-container {
          padding: 20px;
          background: rgba(0, 0, 0, 0.04);
          margin-top: 40px;
          margin-left: 40px;
        }
        .payment-info-section table {
          border-collapse: unset;
        }
        .payment-info-section td {
          padding: 0 80px 20px 0;
          width: 245px;
        }
        .order-details-payment-image {
          width: 200px;
          height: 200px;
          margin: 20px 0;
          object-fit: contain;
        }
        .order-details-payment-table-divider {
          border-bottom: 0.5px solid black;
          width: 100%;
          margin-bottom: 10px;
        }
        .order-info-number {
          font-family: "DomaineSansText-Black";
        }
        .order-details-sidenav-container {
          padding: 50px 0;
          position: fixed;
          background: white;
          width: 200px;
          border-right: 1px solid #e4e4e4;
        }
        .action-section {
          justify-content: center;
          gap: 20px;
          padding: 40px;
        }
        .action-section-divider {
          border-right: 1px solid black;
        }
        .action-section-link {
          cursor: pointer;
          text-decoration: underline;
        }
        .action-section-link:hover {
          color: #800000;
        }
        .order-container-padded {
          padding: 40px 60px 10px 40px;
        }

        .product-color-section-box {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: sub;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .order-details-body-content {
            width: 85%;
            margin: auto;
            padding: 0 16px;
          }

          .order-details-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .order-details-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }

          .order-details-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
          .action-section {
            padding: 30px 20px;
          }
          .section-in-order-details {
            padding: 20px;
          }
          .order-container-padded {
            padding-left: 20px;
          }
        }

        @media (max-width: 830px) and (min-width: 640px) {
          .order-info-section,
          .delivery-info-section,
          .payment-info-section {
            flex-direction: column;
          }
          .order-details-payment-table-container {
            margin: 24px 0 0;
          }
          .dashboard-content {
            margin-left: 0;
          }
        }

        @media (max-width: 640px) {
          .order-details-coverall-container {
            margin-left: 0;
          }
          .manage-listing-all-content {
            margin-top: ${!details.phone_verify_status ? "154px" : "90px"};
          }
          .order-details-adspace {
            background-image: url("/images/seller-stats-banner.png");
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .order-details-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .order-details-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .order-details-body-content-header-title {
            font-size: 18px;
          }
          .order-details-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
          }

          .order-details-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .order-details-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .order-details-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
            padding-bottom: 0;
          }
          .order-details-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .order-details-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }
          .section-in-order-details {
            padding: 16px;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 24px;
          }
          .order-info-section td {
            padding: 0 8px 10px 0;
          }
          .ant-steps-item-title {
            font-size: 14px;
          }
          .order-details-payment-image {
            margin: 16px 0;
            width: 120px;
            height: 120px;
            object-fit: cover;
          }
          .order-details-payment-table-container {
            margin-top: 24px;
            margin-left: 0;
          }
          .no-reason-textbox {
            margin-top: -30px;
            margin-bottom: 30px;
          }
          .order-container-padded {
            padding: 0px;
          }
        }
      `}</style>
    </div>
  );
};

export default OrderDetails;
