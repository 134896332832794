import { Modal, Button, Form, Select, Radio } from "antd";
// import { useMediaQuery } from "react-responsive";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import formatter from "../../utils/formatter";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { standardize_color } from "../../utils/getColorHex";
import colors from "../../assets/colors";
import { bindActionCreators } from "redux";
import { cartActionCreators } from "../../redux/cart";
import triggerNotification from "../../hooks/triggerNotification";

const ProductModal = ({ modalVisible, setModalVisible, product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { updateCartItem } = bindActionCreators(cartActionCreators, dispatch);

  const [selectedColor, setColor] = useState("");
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState();

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // const isTabletOrMobile = false

  const sliderRef = useRef();

  const images =
    [product?.img1, product?.img2, product?.img3, product?.img4] || [];

  const settingsBigScreen = {
    dots: false,
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    nextArrow: (
      <div>
        <div className="next-slick-arrow" style={{ color: "black" }}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
              fill="black"
            />
            <path
              d="M13.0408 8L19.1277 14.2222L13.0408 20.4444"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow" style={{ color: "black" }}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 1.90735e-06C6.71573 1.90735e-06 1.90735e-06 6.71573 1.90735e-06 15C1.90735e-06 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 1.90735e-06 15 1.90735e-06Z"
              fill="black"
            />
            <path
              d="M16.6523 20.5332L11.377 15.1406L16.6523 9.74802"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    ),
  };
  const [form] = Form.useForm();

  const onSelectColor = (e) => setColor(e.target.value);

  const variantSizeArr = [];
  if (selectedColor) {
    const color = colors.find(
      (color) => color.hex.toLocaleLowerCase() === selectedColor
    );
    product?.variety?.map((variant) => {
      if (variant.color === color?.name) variantSizeArr.push(variant?.size);
      return true;
    });
  }

  let sizes = [];
  if (selectedColor) {
    sizes = variantSizeArr;
  }

  const changeQty = (type) => {
    if (type === "add") {
      setQty((prev) => prev + 1);
      setPrice((prev) => (prev / qty) * (qty + 1)); // update price based on new qty
    }
    if (type === "sub" && qty > 1) {
      setQty((prev) => prev - 1);
      setPrice((prev) => (prev / qty) * (qty - 1)); // update price based on new qty
    }
  };

  const viewProduct = () => {
    navigate(`/product/${product?.slug}`);
  };

  const updateProduct = (values) => {
    const selectedColorObj = colors.find(
      (color) => color?.hex?.toLowerCase() === selectedColor?.toLowerCase()
    );
    const updatedProduct = {
      ...product,
      selectedColor: selectedColorObj,
      quantityWanted: qty,
      totalPrice: price,
      selectedSize: values.selectedSize,
    };
    updateCartItem(updatedProduct);
    triggerNotification({
      type: "success",
      message: "Item has been updated in your shopping bag",
    });
    setModalVisible(false);
  };

  useEffect(() => {
    setColor(product?.selectedColor?.hex);
    setQty(Number(product?.quantityWanted));
    setPrice(product?.price);
  }, [product]);

  return (
    <>
      <Modal
        centered
        className="preview-listing-modal"
        open={modalVisible}
        footer={null}
        width={!isTabletOrMobile ? "70%" : "95%"}
        onCancel={() => setModalVisible(false)}
      >
        <div className="product-container">
          <div className="product-image-more-text-contain">
            <div className="product-images-supercontainer">
              <div
                style={{
                  gridColumn: "1 / 6",
                }}
              >
                <Slider {...settingsBigScreen} ref={sliderRef}>
                  {images?.map((image) =>
                    image ? (
                      <div>
                        <div
                          key={image?.imageURL}
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: "no-repeat",
                            // backgroundPosition: "center",
                            backgroundSize: "cover",
                            // height: !isTabletOrMobile
                            //   ? "clamp(200px, 48vw, 1000px)"
                            //   : "65vh",
                            height: !isTabletOrMobile ? "490px" : "324px",
                          }}
                        ></div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Slider>
                {/* <div className="mobile-item-code-product">
                        Item Code: 38063312418681103
                </div> */}
              </div>
            </div>
          </div>

          <div className="details-container">
            <div style={{ width: !isTabletOrMobile ? "70%" : "100%" }}>
              <div className="product-store-name">
                {product?.store_info?.store_name}
              </div>
              <h2 className="product-title">{product?.name}</h2>
              <div className="price-container">
                <div className="product-price">
                  {formatter(price, rate, code, indicator)}
                </div>
              </div>

              <Form
                className="add-to-cart-form"
                form={form}
                name="add-to-cart"
                onFinish={updateProduct}
                scrollToFirstError
                initialValues={{
                  selectedSize: product?.selectedSize,
                  selectedColor: product?.selectedColor?.hex,
                }}
              >
                <div className="color-container">
                  {product?.sizes?.length > 0 && (
                    <div>
                      <div className="txt-color">
                        Color:{" "}
                        {product?.colors?.map((color, idx) => (
                          <span key={idx}>{color} </span>
                        ))}{" "}
                      </div>

                      <Form.Item
                        name="selectedColor"
                        rules={[
                          {
                            required: true,
                            message: "Please select a color",
                          },
                        ]}
                        style={{ marginBottom: 0 }}
                      >
                        <Radio.Group
                          onChange={onSelectColor}
                          style={{ display: "flex" }}
                          className="colors-radiogroup"
                        >
                          {product?.colors?.map((color) => (
                            <Radio.Button
                              key={color}
                              style={{
                                border: "1px solid #E6E6E6",
                                display: "grid",
                                placeContent: "center",
                                height: 18,
                                width: 18,
                                borderRadius: "50%",
                                marginTop: 4,
                                marginRight: 10,
                                padding: 0,
                              }}
                              value={standardize_color(color)}
                            >
                              <div
                                className="color-box"
                                style={{
                                  background: standardize_color(color),
                                }}
                              />
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  )}

                  <div className="qty-container">
                    <div className="btn-qty" onClick={() => changeQty("sub")}>
                      -
                    </div>
                    <h4 className="txt-qty">{qty}</h4>
                    <div className="btn-qty" onClick={() => changeQty("add")}>
                      +
                    </div>
                  </div>
                </div>

                {product?.colors?.length > 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Size:</div>
                    </div>
                    <div>
                      {!isTabletOrMobile ? (
                        <Form.Item
                          name="selectedSize"
                          rules={[
                            {
                              required: true,
                              message: "Please select a size",
                            },
                          ]}
                        >
                          <Radio.Group
                            className="sizes-radiogroup"
                            disabled={!selectedColor}
                            style={{
                              display: "flex",
                              gap: "8px",
                              flexWrap: "wrap",
                              marginTop: "4px",
                            }}
                          >
                            {product?.sizes?.map((size) => (
                              <Radio.Button
                                value={size}
                                key={size}
                                style={{
                                  border: "1px solid #E6E6E6",
                                  display: "grid",
                                  placeContent: "center",
                                  height: 40,
                                  width: "31%",
                                  lineHeight: "normal",
                                }}
                              >
                                <div className="size-box">{size}</div>
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="selectedSize"
                          rules={[
                            {
                              required: true,
                              message: "Please select a size",
                            },
                          ]}
                        >
                          <Select
                            className="product-page-mobile-size-select"
                            disabled={!selectedColor}
                            placeholder="Select Size"
                          >
                            {sizes?.map((size) => (
                              <Select.Option value={size} key={size}>
                                {size}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                )}

                <div style={{ marginTop: 40 }}>
                  <Button
                    className="btn-product bag-button"
                    style={{
                      color: "white",
                      background: "black",
                      width: "100%",
                      height: 48,
                      marginBottom: "8px",
                    }}
                    htmlType="submit"
                  >
                    Update
                  </Button>
                  <div className="btn-view-more" onClick={viewProduct}>
                    View more details
                  </div>
                </div>
                {/* <ShareAltOutlined className="btn-share" onClick={shareURL} /> */}
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      <style jsx="true">
        {`
          .product-container {
            margin: 20px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .product-images-supercontainer {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
          }
          .other-img-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 40px;
          }
          .img {
            background-size: cover;
            background-repeat: no-repeat;
            width: 75px;
            height: 100px;
          }

          .img-big-screen {
            object-fit: contain;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          .details-container {
            flex: 2;
            display: flex;
            justify-content: center;
            font-family: "DomaineSansText-Light";
          }
          .product-store-name {
            text-transform: uppercase;
            font-size: 28px;
            line-height: 40px;
            font-family: "DomaineSansText-Regular";
            font-weight: 400;
            margin-bottom: 8px;
          }
          .product-title {
            font-size: 16px;
            font-weight: 300;
            margin: 10px 0;
          }
          .price-container {
            margin: 20px 0;
          }
          .product-price {
            font: normal normal normal 18px DomaineSansText-Regular;
          }
          .old-price {
            text-decoration: line-through;
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .discount-price {
            font: normal normal normal 16px DomaineSansText-Regular;
            color: #800000;
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
          }
          .color-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
          }
          .txt-color {
            font-size: 14px;
            font-weight: 300;
            line-height: 18.1px;
          }
          .qty-container {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
          }
          .txt-qty {
            font-family: DomaineSansText-Light;
            font-size: 20px;
            font-weight: 300;
            line-height: 31.03px;
            margin: 0;
          }
          .btn-qty {
            border: 1px solid #000;
            border-radius: 50%;
            cursor: pointer;
            font-size: 18px;
            width: 28px;
            height: 28px;
            display: grid;
            place-content: center;
          }

          .sizes-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            background: #000;
          }
          .color-box {
            border: 1px solid #cccc;
            height: 14px;
            width: 14px;
            border-radius: 50%;
          }
          .size-box {
            font-size: 12px;
            line-height: 17px;
          }
          .sizes-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked
            ~ span
            .size-box {
            color: #fff !important;
          }

          .ant-radio-button-wrapper
            > .ant-radio-button-checked
            ~ span
            .color-box {
            border: 1px solid #000;
          }

          .btn-view-more {
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
            line-height: 18.1px;
            text-align: center;
            margin-top: 10px;
            text-decoration: underline;
            text-underline-offset: 6px;
          }

          .arrow-image-change {
            background: #fff;
            float: right;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-top: 70%;
            margin-right: 30px;
            text-align: center;
          }
          .arrow-image-change:hover {
            background: maroon;
            // border-radius: 50%;
            // padding: 10px;
            color: white;
            transition: 0.5s;
          }
          .slick-slider div {
            height: 100%;
          }
          .slick-arrow {
            height: auto !important;
          }

          .ant-radio-button-wrapper-checked:not(
              .ant-radio-button-wrapper-disabled
            )::before {
            background-color: transparent;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            padding: 12px 0 !important;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding: 0px !important;
          }
          .product-more-text-contain {
            font-family: "DomaineSansText-Light";
            font-size: 16px;
            margin-top: 40px;
          }
          .product-more-text-contain-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
          }

          .slick-next:before,
          .slick-prev:before {
            font-size: 2.5vw;
            display: none;
          }
          .slick-arrow {
            z-index: 2;
            width: auto;
            text-align: center;
          }
          .slick-next {
            right: 3%;
          }
          .slick-prev {
            left: 3%;
          }

          .preview-modal-close-icon {
            color: rgb(0, 0, 0);
            font-size: 30px;
            padding: 15px 10px 0 0;
          }

          .product-details-collapsible {
            margin-top: 40px;
          }
          .product-details-collapsible .ant-collapse-header {
            padding-left: 0px !important;
          }
          .product-details-collapsible ul {
            padding-inline-start: 17px !important;
          }
          .product-details-collapsible
            .ant-collapse-icon-position-end
            > .ant-collapse-item
            > .ant-collapse-header
            .ant-collapse-arrow {
            right: 0px !important;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box
            @media
            (max-width: 1026px)
            and
            (min-width: 640px) {
            .preview-listing-modal {
              width: 90% !important;
            }
            .product-container {
              margin: 24px 8px;
            }
          }

          @media (max-width: 640px) {
            .preview-listing-modal .ant-modal-content {
              overflow: scroll;
              height: 96vh;
            }
            .preview-listing-modal .ant-modal-close-x {
              width: 32px;
              height: 30px;
              line-height: 22px;
              padding: 6px;
              font-size: 20px;
              color: black;
            }
            .product-container {
              margin: 32px 0 16px;
              display: block;
            }
            .mobile-item-code-product {
              color: #00000066;
              font-size: 10px;
            }
            .color-box {
              height: 13px;
              width: 13px;
            }
            .price-container {
              margin-bottom: 16px;
            }
            .other-img-container {
              display: none;
            }
            .product-images-supercontainer {
              display: block;
            }

            .slick-slider div {
              width: 100%;
            }
            .slick-arrow {
              width: auto !important;
            }
            .details-container {
              justify-content: flex-start;
              margin-top: 30px;
            }
            .product-store-name {
              font-size: 18px;
              text-decoration: underline;
              line-height: normal;
            }
            .product-title {
              font-size: 14px;
            }
            .old-price,
            .single-price,
            .discount-price {
              font-size: 14px;
            }
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector {
              height: 48px;
            }
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-item,
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-placeholder {
              line-height: 48px;
            }
            .product-page-mobile-size-select
              .ant-select-item-option-selected:not(
                .ant-select-item-option-disabled
              ) {
              background-color: #f5f5f5;
            }
            .panel-heading {
              font-size: 14px;
            }
            .txt-brand-desc,
            .txt-material {
              font-size: 14px;
            }
            .product-more-text-contain {
              font-size: 12px;
            }
            .other-products-image {
              height: 248px;
              width: 100%;
              margin: auto;
              padding-right: 8px;
            }
            .other-products-container {
              padding: 0 16px;
              margin-top: 40px;
            }
            .other-products-container .slick-arrow {
              display: none !important;
            }
            .preview-listing-modal .ant-modal-body {
              padding: 16px;
            }
            .preview-modal-close-icon {
              font-size: 24px;
              position: relative;
              top: 6px;
              right: -12px;
              padding: 0;
            }
            .ant-collapse-ghost
              > .ant-collapse-item
              > .ant-collapse-content
              > .ant-collapse-content-box {
              padding: 0px !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductModal;
