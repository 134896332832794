//import { useMediaQuery } from "react-responsive";
import Banner from "../components/Banner";
// import MobileSearchBar from "../components/layout/MobileSearchBar";
import Brands from "../components/Brands";
import Ads from "../components/Ads";

import ShopGender from "../components/ShopGender";
import StylistChoice from "../components/StylistChoice";
import RandomCategory from "../components/RandomCategory";
import Occasions from "../components/Occasions";
import Verdict from "../components/Verdict";
import Quality from "../components/Quality";
import Cookies from "../components/Cookies";
import DiscountModal from "../components/DiscountModal";
import { useState } from "react";
import Line from "../components/Line";
import BlackFridayModal from "../components/layout/BlackFridayModal";

const Home = () => {
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const [showBfModal, setShowBfModal] = useState(false);

  return (
    <div
    // style={{overflow:"hidden", position:"fixed"}}
    >
      {/* {isTabletOrMobile ? <MobileSearchBar /> : null} */}
      <Banner />
      <StylistChoice />
      <Line />
      <ShopGender />
      <Line />
      <Brands />
      <Line />
      <RandomCategory />
      <Occasions />
      <Ads />
      <Verdict />
      <Quality />
      <Cookies showDiscountModal={showDiscountModal} />
      <DiscountModal
        showDiscountModal={false}
        setShowDiscountModal={setShowDiscountModal}
      />
      <BlackFridayModal showBfModal={showBfModal} setShowBfModal={setShowBfModal} />
    </div>
  );
};

export default Home;
