import {
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAIL,
  FETCH_SORTED_BRANDS,
  FETCH_MENU_BRANDS,
  FETCH_BRAND_START,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_FAIL,
} from "./type";
import api from "../../api";

export const fetchBrands = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.brands.fetchBrands();
      if (data.status === "ok") {
        dispatch({
          type: FETCH_BRANDS_SUCCESS,
          payload: data.data,
        });
        dispatch(fetchSortedBrands());
      }
    } catch (err) {
      dispatch({
        type: FETCH_BRANDS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchSortedBrands = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_SORTED_BRANDS,
      });
    } catch (err) {
      dispatch({
        type: FETCH_BRANDS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchMenuBrands = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.brands.fetchMenuBrands();
      if (data.status === "ok") {
        dispatch({
          type: FETCH_MENU_BRANDS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BRANDS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchBrand = (slug) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_BRAND_START,
      });

      const { data } = await api.brands.fetchBrand(slug);
      if (data.status === "ok") {
        dispatch({
          type: FETCH_BRAND_SUCCESS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BRAND_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
