import { useMediaQuery } from "react-responsive";
import DiscountHeader from "./DiscountHeader";
import Header from "./Header";
import SubHeader from "./SubHeader";
import MobileHeader from "./MobileHeader";

const Layout = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      {isTabletOrMobile ? (
        <MobileHeader />
      ) : (
        <>
          <DiscountHeader />
          <Header />
          <SubHeader />
        </>
      )}
    </>
  );
};

export default Layout;
