import { StrictMode, useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from "axios";
import App from "./App";

//persistor.purge();

axios.defaults.baseURL = "https://losode.dev/api/v2/"; // the prefix of the URL
axios.defaults.headers.get["Accept"] = "application/json"; // default header for all get request
axios.defaults.headers.post["Accept"] = "application/json"; // default header for all POST request

Sentry.init({
  dsn: "https://b2cbb3cca94d42fcba075ccff39fb868@o1410156.ingest.sentry.io/6747390",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/losode\.dev\/api\/v2/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: localStorage.getItem("currency"),
  intent: "capture",
};

const container = document.getElementById("root");
const root = createRoot(container);

const jsx = (
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <PayPalScriptProvider options={initialOptions}>
          <App />
        </PayPalScriptProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);

root.render(jsx);
