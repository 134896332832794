import { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import { useMediaQuery } from "react-responsive";
import AuthModal from "../components/AuthModal";
import SellerHeader from "./seller/SellerHeader";
import { Footer } from "../components/layout";
import SellerHeaderMobile from "../components/layout/SellerHeaderMobile";

const SellerResetPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 640 });

  const { isAuthenticated, info, resetSuccess } = useSelector(
    (state) => state.user
  );

  const { resetPasswordSellerInitiate, resetPasswordSellerReset } =
    bindActionCreators(userActionCreators, dispatch);

  const onFinish = async (values) => {
    const res = await resetPasswordSellerInitiate(values);
    if (res.status === "not ok") message.error(res.message);
  };

  useEffect(() => {
    if (isAuthenticated && info.role === "2") navigate("/account");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  // useEffect(()=>{
  //   resetPasswordSellerReset();
  // },[])

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);

  return (
    <>
      <div>
        {!isMobile && <SellerHeader />}
        {isMobile && <SellerHeaderMobile />}
        <div className="div-reset-password-seller">
          <div className="reset-register-container">
            <div className="form-container">
              {!resetSuccess && (
                <h2 className="reset-txt-register">Reset Password</h2>
              )}
              {!resetSuccess && (
                <p
                  className="reset-txt-setup"
                  style={{ textAlign: "center" }}
                >
                  Type in your email address below and we will send you an email
                  with instructions on how to create a new password
                </p>
              )}
              <Form
                form={form}
                name="login"
                onFinish={onFinish}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
              >
                <div className="reset-inputs-container">
                  {!resetSuccess && (
                    <Form.Item
                      label="Email address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please use an acceptable email format",
                        },
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input className="reset-input" />
                    </Form.Item>
                  )}

                  {!resetSuccess && (
                    <Form.Item>
                      <Button
                        className="reset-btn-register"
                        type="primary"
                        htmlType="submit"
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                  )}
                  {!resetSuccess && (
                    <Link
                      to="#"
                      onClick={() => {
                        setSellerAuthModalVisible(true);
                        resetPasswordSellerReset();
                      }}
                      className="reset-txt-signin"
                    >
                      <strong>Back to sign in</strong>
                    </Link>
                  )}

                  {resetSuccess && (
                    <h2 className="reset-txt-register">
                      Reset password link has been sent
                    </h2>
                  )}
                  {resetSuccess && (
                    <div>
                      <p className="reset-txt-setup">
                        We have sent you an email to reset your password. To
                        create your new password, click the link in the email
                        and enter a new one.
                      </p>
                      <p className="reset-txt-setup">
                        If you cannot see the email. Check your junk email, any
                        other email addresses linked to your Losode account, or
                        click below to resend the email
                      </p>

                      <Form.Item>
                        <Button
                          className="reset-btn-register"
                          type="primary"
                          onClick={() => {
                            setSellerAuthModalVisible(true);
                            resetPasswordSellerReset();
                          }}
                          // htmlType="submit"
                        >
                          Sign In
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          className="reset-btn-register"
                          type="primary"
                          onClick={() => {
                            resetPasswordSellerReset();
                          }}
                          // htmlType="submit"
                        >
                          Resend Email
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </div>

          <AuthModal
            authModalVisible={sellerAuthModalVisible}
            setAuthModalVisible={setSellerAuthModalVisible}
            activePage="1"
            pageUserType="seller"
          />
        </div>
        <Footer />

        <style jsx="true">
          {`
          .div-reset-password-seller{
            min-height: calc(100vh - 214px);
            background: #F4F4F4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 60px;
            padding-top: 170px;
          }
          .reset-txt-setup{
            font-family: 'DomaineSansText-Regular';
            margin: 16px 3vw;
            text-align:justify
          }
          .ant-form label, .ant-form-item-explain.ant-form-item-explain-error{
              font-size:14px;
              font-family: 'DomaineSansText-Regular'
          }

          .reset-register-container {
            text-align: center;
            justify-content: center;
            background: white;
            padding: 24px;
            width: 45%;
            margin: auto;

          }
          .reset-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
          }

          .reset-inputs-container {
            margin: 0 24px;
            padding-bottom:24px
          }
          .reset-input {
            border: 2px solid #ccc;
            color: #000;
            font-size: 16px;
            height: 60px;
          }
          .ant-form-item-explain.ant-form-item-explain-error{
            text-align:left;
            margin-top:4px;
          }

          .reset-input:focus,
          .reset-input:hover {
            border: 2px solid #000;
          }
          .reset-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 60px;
            margin-top: 8px;
          }

          @media (max-width: 1100px) and (min-width: 600px){
            .reset-register-container{
              width:65%
            }
          }

          

          @media screen and (max-width: 600px) {
            .reset-inputs-container {
              margin: 0px;
              padding-bottom:24px
            }
            .div-reset-password-seller{
                background:white;
            }
            .reset-txt-setup{
                font-size:14px
                margin: 8px 0;
            }
            .reset-txt-signin{
                display:block;
                margin-top:16px
            }
            .ant-input:placeholder-shown, .ant-picker-input > input:placeholder-shown{
                font: normal normal 14px/14px DomaineSansText-Regular !important
            }

            .ant-form-item-explain, .ant-form-item-extra{
              font-size:12px
            }
            .reset-register-container{
                width:auto
            }
            a{
                text-decoration: underline;
                font: normal normal normal 14px DomaineSansText-Regular;
                letter-spacing: 0px;
                color: #000000;
            }
            .reset-btn-register{
                margin-top: 16px;
            }
          }
        `}
        </style>
      </div>
    </>
  );
};

export default SellerResetPassword;
