import { useEffect, useState } from "react";

const usePageTitle = (title = "", description = "", keywords = []) => {
    
  const [pageTitle, setPageTitle] = useState(title);
  const [pageDescription, setPageDescription] = useState(description);
  const [pageKeywords, setPageKeywords] = useState(keywords);

  useEffect(() => {
    document.title = pageTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", pageDescription);
    document
      .querySelector('meta[name="keywords"]')
      ?.setAttribute("content", ...pageKeywords);
  }, [pageTitle, pageDescription, pageKeywords]);

  return [setPageTitle, setPageDescription, setPageKeywords];
};

export default usePageTitle;
