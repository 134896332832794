import { useEffect} from "react";
import {
  // Collapse,
  Tabs,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
// const { Panel } = Collapse;

const StaticPageTemplate = ({
  title,
  children
}) => {
  
  const staticPages =[
    {
      title:'About Us',
      route:'/about-us'
    },
    {
      title:'Terms and Conditions',
      route:'/terms'
    },
    {
      title:'Privacy Policy',
      route:'/policy'
    },
    {
      title:'Delivery Information',
      route:'/delivery'
    },
    {
      title:'Return Policy',
      route:'/returns'
    },
    {
      title:'Contact Us',
      route:'/contact-us'
    },
  ]

  const navigate = useNavigate()

  const changeTab = (key) => {
    console.log("clicked")
    // if (title!==page?.title){
      navigate(staticPages[key]?.route)
    // }
   
  };

  useEffect(() => {
    document.title = `${title} | losode.com`;
  }, [title]);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <div className="static-container">
        <div className="static-header">
          <div className="static-header-text-container">
            <h1 className="static-header-lg-txt">{title}</h1>
          </div>
        </div>

        <Tabs
          defaultActiveKey={() => {
            var activeIndex = 0;
            staticPages?.map((page, index) => {
              if (title === page?.title) {
                activeIndex = index;
              }
              return "";
            });
            return activeIndex.toString();
          }}
          className="tabs"
          tabBarGutter={!isTabletOrMobile ? 50 : 15}
          onTabClick={(key) => changeTab(key)}
        >
          {staticPages?.map((page, index) => {
            return (
              <TabPane className="tab" tab={page?.title} key={index}>
                {title === page?.title ? children : ""}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      <style jsx="true">{`
        .static-container {
          background: #f4f4f4;
        }
        .static-header {
          background-image: url("/images/static-header-image.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 250px;
          position: relative;
        }

        .static-header-text-container {
          padding: 70px 60px;
          z-index: 1;
          display: flex;
          height: 100%;
          align-items: center;
        }
        .sm-txt {
          color: #fff;
          font: normal normal 24px/28px DomaineSansText-Light;
        }
        .static-header-lg-txt {
          color: #fff;
          font-family: "DomaineSansText-Regular";
          font-size: 48px;
          height: fit-content;
          margin-bottom: 0;
        }
        .tabs {
          padding: 16px 0;
          background: white;
        }
        .tab {
        }
        .ant-tabs-tab-btn {
          font: normal normal 16px/146.16% DomaineSansText-Light;
          color: #677189;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #000;
          font-weight: 500;
        }

        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
        .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
          background: #000;
          width: 50px;
        }
        .static-content-container {
          font-size: 16px;
          line-height: 30px;
          font-family: "DomaineSansText-Light";
          padding-bottom: 40px;
          padding: 32px 70px;
          background: #f9f9f9;
        }
        p,
        li {
          font-size: 16px !important;
        }
        ul,
        ol {
          padding-inline-start: 17px;
        }
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }
        .panel {
          font: normal normal 24px/31px DomaineSansText-Light;
        }
        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          top: 15px;
          font-size: 16px;
          right: 0px;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        .ant-tabs-tab:first-child {
          margin-left: 10px;
        }
        .ant-tabs-tab:nth-last-child(2) {
          margin-right: 10px;
        }
        .ant-tabs > .ant-tabs-nav,
        .ant-tabs > div > .ant-tabs-nav {
          margin-bottom: 0;
          padding: 0 60px;
        }

        h3,
        h4 {
          color: #000;
          font-weight: 700;
          font-size: 16px;
        }

        @media (max-width: 640px) {
          .static-content-container {
            padding: 0 20px;
            margin: 10px;
            background: #fff;
            height: auto;
            margin-bottom: 70px;
            font-size: 14px;
          }

          .ant-tabs > .ant-tabs-nav,
          .ant-tabs > div > .ant-tabs-nav {
            padding: 0px;
          }

          .static-header-lg-txt {
            font-size: 40px;
            line-height: 1.2;
            font-family: DomaineSansText-Regular;
            font-weight: 400;
          }
          .static-header-text-container {
            padding: 40px 24px;
            align-items: flex-end;
          }
          .static-header {
            height: 180px;
            background-position: -280px 0;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin-left: 0;
          }
          .tabs {
            padding-left: 0;
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
            justify-content: flex-start;
          }
          p,
          li {
            font-size: 14px !important;
          }
          .vText{
            font-size: 10px !important;
          }
          .tabs {
            padding: 0px;
          }
          .static-content-container {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default StaticPageTemplate;
