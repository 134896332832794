import { fetchOneProduct } from "../api/productsApi";

export const postSavedItemToLocalStorage = async (slug, code) => {
  try {
    const product = await fetchOneProduct(slug, code);
    if (!localStorage.getItem("guestSavedItem")) {
      const savedItemsLocal = [product];
      localStorage.setItem("guestSavedItem", JSON.stringify(savedItemsLocal));
    } else {
      const savedItemsLocal = JSON.parse(
        localStorage.getItem("guestSavedItem")
      );

      const productExist = savedItemsLocal.filter(
        (item) => item.product_id === product.product_id
      );
      if (productExist.length === 0) {
        const updatedSavedItemsLocal = [...savedItemsLocal, product];
        localStorage.setItem(
          "guestSavedItem",
          JSON.stringify(updatedSavedItemsLocal)
        );
      } 
    }
    
  } catch (error) {
    console.log(error);
  }
};

export const deleteSavedItemFromLocalStorage = async (id) => {
  const savedItemsLocal = JSON.parse(localStorage.getItem("guestSavedItem"));
  const updatedSavedItesmLocal = savedItemsLocal.filter(
    (product) => product.product_id !== id
  );
  localStorage.setItem(
    "guestSavedItem",
    JSON.stringify(updatedSavedItesmLocal)
  );
};

export const getSavedItemToLocalStorage = () => {
  return JSON.parse(localStorage.getItem("guestSavedItem"));
};
