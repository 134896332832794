import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";


const Cookies = ({showDiscountModal}) => {
    const [showCookies, setShowCookies] = useState(false);

    const closeCookies = () => {
        setShowCookies(false);
        window.localStorage.setItem("acceptCookies", "true");
    }

    useEffect(() => {
        if(window.localStorage.getItem("acceptCookies")) {
            return setShowCookies(false);
        }
        setShowCookies(true)
    },[])


    // useEffect(() => {
    //     if(window.localStorage.getItem("newUsers") === "true" || window.localStorage.getItem("acceptCookies") === "true") {
    //         return setShowCookies(false);
    //     }
    //     setShowCookies(true);
    // }, [window.localStorage.getItem("newUsers")])
    
  return (<>
  {showCookies && !showDiscountModal && (
    <div className="cookies-container">
        <div className="cookies-content">
            <h1 className="cookies-heading">cookies and privacy</h1>
            <p className="cookies-text-1">Our site uses cookies and similar technologies to offer you a better experience. We use analytical cookies (our own and third party) 
                to understand and improve your browsing experience, 
                and advertising cookies (our own and third party) to send you advertisements in line with your preferences.
            </p>
            <p className="cookies-text-2">
                To modify or opt-out of the use of somecookies-container or all of our cookies, please go to our <Link to="/policy" style={{textDecoration: "underline"}}>Cookie Policy</Link> to find out more. By clicking “Accept All” you consent to the use of these cookies.
            </p>
        </div>
        <div className="cookies-button">
            <Button className="cookies-btn reject-btn" onClick={closeCookies}>Reject</Button>
            <Button className="cookies-btn accept-btn" onClick={closeCookies}>Accept All</Button>
        </div>
        <style jsx="true">{`
        .cookies-container {
            position: fixed;
            bottom: 14px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            width: 90%;
            padding:1.5rem 3rem;
            box-shadow: 2px 2px 2px rgba(132, 132, 132, 0.25), -2px -2px 3px rgba(0, 0, 0, 0.3);
            z-index: 1000;
            max-width:1360px
        }
        .cookies-heading {
          font: normal 600 18px DomaineSansText-Regular;
          letter-spacing: 0.28px;
          color: #000000;      
          text-transform: uppercase;
          margin-bottom: 0.5rem;
          padding: 0;
        }
        .cookies-container p {
            font: normal 300 14px DomaineSansText-Light;
            line-height: 23px;
            color: #000;
            margin: 3px 0; 
        }
        .cookies-line {
            height: 1px;
            width: 100%;
        }
        .cookies-content {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #6F6F6F;
        }
        .cookies-button {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;
        }
        .cookies-btn {
            width: 300px;
            height: 45px;
            font: normal 400 16px DomaineSansText-Light;
            color: #000;
        }
        .cookies-btn.accept-btn {
            background-color: #000;
            color: #fff;
            margin-left: 1rem;
        }
        .cookies-btn.accept-btn:hover {
            background-color: #fff;
            color: #000;
        }
        .cookies-btn.reject-btn {
            background-color: #fff;
            color: #000;
        }
        .cookies-btn.reject-btn:hover {
            background-color: #000;
            color: #fff;
        }

        @media(min-width:768px) and (max-width: 1024px) {
            .cookies-button {
                display: flex;
                justify-content: space-around;
            }
            .cookies-btn {
                width: 300px;
                height: 48px;
            }
            .cookies-btn.accept-btn {
                margin-left: 0;
            }
            .cookies-text-2 {
                padding-top: 5px
            }
        }
        
        @media(max-width:640px){
            .cookies-container {
                padding: 1rem;
                width: 95%;
            }
            .cookies-text-1 {
                padding-bottom: 0.5rem;
            }
            .cookies-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 1rem;
            }
            .cookies-btn {
                width: 100%;
            }
            .cookies-btn.accept-btn {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
      `}</style>
    </div>
      )}
</>
)}

export default Cookies;