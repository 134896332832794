import { Popconfirm } from "antd";

const Prompts = ({ title, children, onConfirm, placement = "right" }) => {
  return (
    <div>
      <Popconfirm
        title={title}
        onConfirm={onConfirm}
        okText="Confirm"
        cancelText="Cancel"
        overlayClassName="delete-popconfirm"
        placement={placement}
      >
        {children}
      </Popconfirm>
      <style>{`
  
    .delete-popconfirm{
              font-family: 'DomaineSansText-Light';
              text-align:center;
              width:auto
            }
            .delete-popconfirm .ant-popover-inner{
              background:black;
            }
            .delete-popconfirm .ant-popover-inner-content{
              background:black;
              padding: 24px;
            }
            .delete-popconfirm .ant-popover-message{
              margin-bottom: 24px;
              padding: 10px 30px 20px;
              border-bottom: 1px solid #d5d5d5;
            }
            .delete-popconfirm .ant-popconfirm-title{
              color:white !important;
               text-align:center;
            }
            .delete-popconfirm .anticon {
              color:#fff !important;
            }
            .ant-popover-arrow{
              filter:invert(1);
              overflow:normal;
            }
            .delete-popconfirm .ant-popover-buttons{
              text-align:center;
            }
            .delete-popconfirm .ant-popconfirm-buttons button{
             padding:0 8px;
              
            }
            .delete-popconfirm .ant-popover-buttons button:first-child{
              background:black;
              color:white;
              border:1px solid white;
            }
            .delete-popconfirm .ant-popover-buttons button:nth-child(2){
              background:white;
              color:black;
              margin-left:24px;
            }
  `}</style>
    </div>
  );
};

export default Prompts;
