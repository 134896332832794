import { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthModal from "../components/AuthModal";
import { useMediaQuery } from "react-responsive";
import SellerHeader from "./seller/SellerHeader";
import { Footer } from "../components/layout";
import SellerHeaderMobile from "../components/layout/SellerHeaderMobile";

const SellerEmailVerified = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 640 });

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);

  const { isAuthenticated, info } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated && info.role === "2") {
      if (info.email_verified === true) {
        if (info.has_created_store === false) {
          navigate("/create-store");
        }
        if (info.has_created_store === true) {
          navigate("/account");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <div>
      {!isMobile && <SellerHeader/>}
      {isMobile && <SellerHeaderMobile />}
      <div className="div-seller-verified-password-seller">
        <div className="seller-verified-register-container">
          <div className="form-container">
            <h2 className="seller-verified-txt-register">
              Email verification successful
            </h2>
            <div className="seller-verified-txt-div">
              <p className="seller-verified-txt-setup">
                Your email address has been verified.
              </p>
              <p className="seller-verified-txt-setup">
                You can now create your store
              </p>
            </div>

            <Button
              className="seller-verified-btn-register"
              type="primary"
              onClick={() => {
                setSellerAuthModalVisible(true);
              }}
              // htmlType="submit"
            >
              Create Store
            </Button>
          </div>
        </div>
      </div>
      <AuthModal
        authModalVisible={sellerAuthModalVisible}
        setAuthModalVisible={setSellerAuthModalVisible}
        activePage="1"
        pageUserType="seller"
      />

      <Footer />

      <style jsx="true">
        {`
          .div-seller-verified-password-seller {
            min-height: calc(100vh - 214px);
            background: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 60px;
            padding-top: 170px;
          }
          .seller-verified-txt-setup {
            font-family: "DomaineSansText-Regular";
            margin: 0;
          }
          .seller-verified-txt-div {
            margin: 16px 3vw;
          }
          .ant-form label,
          .ant-form-item-explain.ant-form-item-explain-error {
            font-size: 14px;
            font-family: "DomaineSansText-Regular";
          }
          .seller-verified-register-container {
            text-align: center;
            justify-content: center;
            background: white;
            padding: 50px;
            width: 45%;
            margin: auto;
            margin: auto;
            max-width: 600px;
          }
          .seller-verified-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
          }

          .seller-verified-inputs-container {
            margin: 0 24px;
            padding-bottom: 24px;
          }
          .seller-verified-input {
            border: 2px solid #ccc;
            color: #000;
            font-size: 16px;
            height: 60px;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            text-align: left;
            margin-top: 4px;
          }

          .seller-verified-input:focus,
          .seller-verified-input:hover {
            border: 2px solid #000;
          }
          .seller-verified-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 320px;
            height: 50px;
            margin-top: 8px;
          }

          @media screen and (max-width: 600px) {
            .seller-verified-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .div-seller-verified-password-seller {
              // background: white;
              padding: 0;
              height: 70vh;
            }
            .seller-verified-txt-register {
              font: normal bold 18px DomaineSansText-Black;
            }
            .seller-verified-txt-setup {
              font-size: 14px;
            }
            .seller-verified-txt-div {
              margin: 8px 0;
            }
            .seller-verified-txt-signin {
              display: block;
              margin-top: 16px;
            }
            .ant-input:placeholder-shown,
            .ant-picker-input > input:placeholder-shown {
              font: normal normal 14px/14px DomaineSansText-Regular !important;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
            .seller-verified-register-container {
              width: auto;
              margin: 0 10px
            }
            a {
              text-decoration: underline;
              font: normal normal normal 14px DomaineSansText-Regular;
              letter-spacing: 0px;
              color: #000000;
            }
            .seller-verified-btn-register {
              margin-top: 16px;
              width: 280px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SellerEmailVerified;
