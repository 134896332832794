import { LosodeSpinner } from "../../public/images";

const LoadingIcon = () => {
  return (
    <div className="loading-wrapper">
      <img className="img-loading" alt="loading" src={LosodeSpinner} />

      <style jsx="true">{`
        .loading-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
        .img-loading {
          width: 50px;
        }
      `}</style>
    </div>
  );
};

export default LoadingIcon;
