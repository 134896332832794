import {
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAIL,
  FETCH_SORTED_BRANDS,
  FETCH_MENU_BRANDS,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_FAIL,
} from "./type";

const initialState = {
  error: "",
  brand:[],
  brands: [],
  menuBrands: {},
  sortedBrands: [],
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANDS_SUCCESS: {
      return { ...state, brands: action.payload };
    }
    case FETCH_MENU_BRANDS: {
      return { ...state, menuBrands: action.payload };
    }
    case FETCH_BRAND_SUCCESS: {
      return { ...state, brand: action.payload };
    }
    case FETCH_SORTED_BRANDS: {
      state.brands.sort(function (a, b) {
        return a.store_name.localeCompare(b.store_name);
      });

      let sortedBrands = {};

      for (let i = 0; i < state.brands.length; i++) {
        let c = state.brands[i].store_name[0].toUpperCase();
        if (sortedBrands[c] && sortedBrands[c].length >= 0)
          sortedBrands[c].push(state.brands[i]);
        else {
          sortedBrands[c] = [];
          sortedBrands[c].push(state.brands[i]);
        }
      }
      return { ...state, sortedBrands: sortedBrands };
    }
    case FETCH_BRANDS_FAIL: {
      return { error: action.payload };
    }
    case FETCH_BRAND_FAIL: {
      return { error: action.payload };
    }

    default:
      return state;
  }
};

export default brandsReducer;
