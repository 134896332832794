import { Button, Modal } from "antd";
import { useMediaQuery } from "react-responsive";

const BuyerAuthChoice = ({
  setAuthChoiceModalVisible,
  authChoiceModalVisible,
  setSellerAuthModalVisible,
  setSellerSignInAuthModalVisible,
}) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      <Modal
        centered
        open={authChoiceModalVisible}
        footer={null}
        width={!isTabletOrMobile ? "clamp(20px, 100%, 780px)" : 400}
        onCancel={() => {
          setAuthChoiceModalVisible(false);
        }}
        transitionName=""
        style={{ opacity: "100%", padding: "0" }}
        className="authchoice-modal"
      >
        <div className="authchoice-overlay">
          <div className="authchoice-container">
            <img
              src="/images/authchoice-image.png"
              className="authchoice-img"
              alt="authchoice-modal"
            />
            <div className="authchoice-content">
              <h1>Grow your business today with Losode</h1>
              <p style={{ marginBottom: "2px" }}>
                Are you a fashion entrepreneur looking to reach global
                audiences? We are always on the lookout for inspiring fashion
                entrepreneurs. Growing any business can be challenging, the good
                news is that we are here to support your journey. <br />
                Click on the button below and a member of our Seller Acquisition
                and Management Team will start the process of onboarding your
                business on Losode.
              </p>
              <Button
                className="authchoice-btn"
                type="primary"
                htmlType="submit"
                style={{ marginTop: "14px" }}
                onClick={() => {
                  setSellerAuthModalVisible(true);
                  setAuthChoiceModalVisible(false);
                }}
              >
                Apply To Join
              </Button>
              <div
                style={{
                  marginTop: "16px",
                  display: isTabletOrMobile ? "block" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: 0 }}>
                  If your business is already active on Losode,{" "}
                </p>
                <Button
                  className="authchoice-btn"
                  htmlType="submit"
                  style={{ marginLeft: isTabletOrMobile ? "0px" : "12px" }}
                  onClick={() => {
                    setSellerSignInAuthModalVisible(true);
                    setAuthChoiceModalVisible(false);
                  }}
                >
                  Sign in
                </Button>
              </div>
            </div>
            {/* <Button
                className="authchoice-modal-cancel"
                shape="circle"
                onClick={closeauthchoiceModal}
                icon={
                <svg width="54" height="54" viewBox="0 0 54 54" fill="black" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="27" cy="27" r="27" fill="black"/>
                    <path d="M34.7137 19.2852L19.2852 34.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.2852 19.2852L34.7137 34.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                }
                /> */}
          </div>
          <style jsx="true">{`
            .authchoice-modal .ant-modal-body {
              padding: 0px;
            }
            .authchoice-modal .ant-modal-close-x {
              display: none;
            }
            .authchoice-overlay {
            }
            .authchoice-container {
              position: relative;
              font-family: DomaineSansText-Regular;
            }
            .authchoice-img {
              width: 100%;
              height: 55%;
              object-fit: cover;
            }
            .authchoice-content {
              text-align: center;
              color: #000000;
              padding: 20px 40px 30px;
            }
            .authchoice-content h1 {
              font: normal 500 24px DomaineSansDisplay-SemiBold;
              text-align: center;
              letter-spacing: 0.03em;
              margin: 0;
              padding: 0;
              margin-bottom: 15px;
              color: black;
              text-transform: capitalize;
            }
            .authchoice-content p {
              font: normal 400 16px DomaineSansText-Regular;
              text-align: center;
              padding: 0 0 4px;
              line-height: 1.7;
              max-width: 650px;
              margin: auto;
            }
            .authchoice-form {
              display: flex;
              justify-content: center;
              padding-top: 20px;
            }
            .input {
              width: 380px;
              height: 45px;
              background: #ffffff;
              border: 1px solid #000000;
              margin-right: 5px;
            }
            .input:placeholder {
              font: normal 400 14px DomaineSansText-Regular;
              color: #92959e;
            }
            .authchoice-btn {
              padding: 0 35px;
              height: 40px;
              font: normal 400 14px DomaineSansText-Regular;
            }

            .authchoice-container .ant-form-item-explain-error {
              margin-top: 4px;
            }

            @media (max-width: 640px) {
              .authchoice-content {
                padding: 20px 20px 30px;
              }
            }
          `}</style>
        </div>
      </Modal>
    </>
  );
};

export default BuyerAuthChoice;
