import { Modal } from "antd";

import { useNavigatingAway } from "../../hooks/useNavigatingAway";

export function RouterPrompt(props) {
  const { title, okText, cancelText, message, canShowDialogLeavingPage } =
    props;

  const [showDialogPrompt, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  return showDialogPrompt ? (
    <Modal
      title={title}
      visible={showDialogPrompt}
      onOk={cancelNavigation}
      okText={okText}
      onCancel={confirmNavigation}
      cancelText={cancelText}
      closable={false}
      maskClosable={false}
      centered
      bodyStyle={{ fontSize: "16px", fontFamily: "DomaineSansText-Light" }}
    >
      {message}
    </Modal>
  ) : null;
}
