export default class Checkout {
  constructor(client) {
    this.client = client;
  }

  fetchCouponDiscount(data) {
    return this.client.post("checkout/apply/voucher", data);
  }

  checkout(values) {
    return this.client.post("checkout/calculate/shipping", values);
  }

  changeCartCurrency(values) {
    return this.client.post("checkout/cart/currency/convert", values);
  }

  payment(values) {
    return this.client.post("payment/link", values);
  }

  orderReceipt(ref) {
    return this.client.post(`order/receipt?txRef=${ref}`, {});
  }
}
