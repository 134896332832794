import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Occasions = () => {
  
  const navigate = useNavigate();

  const { type } = useSelector((state) => state.user);


  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };
  const occasions = [
    {
      id: 1,
      name: "Two-piece Outfits, twice the Fun",
      desc: "Designed for those who love to mix fun with fashion",
      url: "/images/occasions-1.png",
      // cta:'Shop styles',
    },
    {
      id: 2,
      name: "Classic cuts, modern vibes",
      desc: "Keeping you effortlessly cool and always on trend",
      url: "/images/occasions-2.png",
      // cta:'Shop casual shirts',
    },
    {
      id: 3,
      name: "Flirty and Fabulous",
      desc: "Find your perfect look with our adorable dresses",
      url: "/images/occasions-3.png",
      // cta:'Shop fabrics',
    },
  ];

  return (
    <div className="occasions-container">
      {occasions.map((occasion) => (
        <div className="occasion-box" key={occasion.id}>
          <img
            alt={occasion.name}
            className="occasion-img"
            src={occasion.url}
            loading="lazy"
          />
          <h2 className="txt-occasion-name">{occasion.name}</h2>
          <h4 className="txt-occasion-desc">{occasion.desc}</h4>
          <p className="gender-cta" onClick={() => shopNewIn()}>
            {occasion?.cta}
          </p>
        </div>

      ))}

      <style jsx="true">{`
        .occasions-container {
          display: flex;
          padding: 0 100px;
          gap: 16px;
          margin: 40px 0;
          justify-content: space-between;
        }
        .occasion-box {
          display: flex;
          flex-direction: column;
          width: 32%;
        }
        .occasion-img {
          height: auto;
          width: auto;
          object-fit: cover;
          object-position: top;
          // background: #e8eaea;
          // height: 37vw;
        }
        .txt-occasion-name {
          font: normal 400 24px DomaineSansText-Regular;
          line-height: 34px;
          margin-top: 15px;
          margin-bottom: 0px;
          text-transform: capitalize;
        }
        .txt-occasion-desc {
          font: normal normal 14px DomaineSansText-Light;
          line-height: 22px;
          margin-top: 6px;
          margin-bottom: 0px;
          width: 95%;
        }

        @media(max-width:640px){
          .occasions-container{
            padding: 0 16px;
            flex-direction: column;
            gap: 24px;
            margin: 24px 0;
            margin-top: 40px;
          }
          .txt-occasion-name{
            font-size: 16px;
            margin-top: 12px;
            line-height: normal;
            font-family: 'DomaineSansText-Regular';
          }
          .txt-occasion-desc {
            font-size: 14px;
            line-height: 22px;
            margin-top: 2px;
          }
          .occasion-img{
            height: 365px;
          }
          .occasion-box {
            width: 100%;
          }
        }

      `}</style>
    </div>
  );
};

export default Occasions;
