export function getSize(sizeString) {
  if (sizeString) {
    const size = sizeString;
    const seperator = size.indexOf("-");

    if (seperator !== -1) {
      const sizeNumber = parseInt(size.substring(3, seperator));
      const sizeString = size.substring(seperator + 1, size.length);
      return {
        number: sizeNumber,
        alphabet: sizeString,
      };
    } else {
      return {
        number: sizeString.split(" ")[0],
        alphabet: sizeString.split(" ")[1],
      };
    }
  } else {
    return {
      number: "",
      alphabet: "",
    };
  }
}
