import api from "../../api";
import { FETCH_PRODUCTS_FAIL } from "../products/types";

import {
  REGISTER_STORE_START,
  REGISTER_STORE_FAIL,
  GET_STORE,
  GET_STORE_PRODUCTS,
  GET_STORE_SALES,
  GET_STORE_VALUE,
  GET_ORDERS,
  UPDATE_STORE_FAILED,
  CREATE_PRODUCT_FAILED,
  GET_TOTAL_PRODUCTS,
  GET_PRODUCT,
  GET_CATEGORIES,
  GET_PRODUCT_FAILED,
  GET_STORE_DRAFTS,
  GET_DRAFT,
  CLEAR_STORE_DETAILS,
  GET_ORDER,
  GET_SIZE_GUIDE,
  GET_RETURNS_TYPE,
  GET_VACATION_MODE,
  GET_DELIVERY_OPTIONS,
  GET_SETTLEMENT_DETAILS,
  GET_BANKS,
  GET_TRANSACTION_HISTORY,
} from "./type";

export const createStore = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_STORE_START,
      });
      const { data } = await api.store.createStore(values);
      if (data.status === "ok") {
        return data;
      }
    } catch (err) {
      dispatch({
        type: REGISTER_STORE_FAIL,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const createProduct = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.createProduct(values);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: CREATE_PRODUCT_FAILED,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const createDraft = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.createDraft(values);
      return data;
    } catch (err) {
      dispatch({
        type: CREATE_PRODUCT_FAILED,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const fetchStore = () => {
  return async (dispatch) => {
    try {
      const res = await api.store.getStore();
      if (res.status === 200) {
        dispatch({
          type: GET_STORE,
          payload: res.data,
          error: false,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchStoreProducts = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getStoreProducts();
      if (data.status === "ok") {
        dispatch({
          type: GET_STORE_PRODUCTS,
          payload: data,
          error: false,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchStoreDrafts = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getStoreDrafts();
      if (data.status === "ok") {
        dispatch({
          type: GET_STORE_DRAFTS,
          payload: data,
          error: false,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getCategories();
      if (data.status === "ok") {
        dispatch({
          type: GET_CATEGORIES,
          payload: data.data,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchSingleProduct = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getProduct(id);
      if (data.status === "ok") {
        dispatch({
          type: GET_PRODUCT,
          payload: data.data,
          error: false,
        });
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchTotalProducts = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getTotalProducts();
      if (data.status === "ok") {
        dispatch({
          type: GET_TOTAL_PRODUCTS,
          payload: data.data.totalProduct,
          error: false,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchSingleDraft = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getDraft(id);

      if (data.status === "ok") {
        const payload = { ...data.data, draft_id: data.draft_id };
        dispatch({
          type: GET_DRAFT,
          payload,
          error: false,
        });
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchStoreValue = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getStoreValue();
      if (data.status === "ok") {
        dispatch({
          type: GET_STORE_VALUE,
          payload: data.data.storeValue,
          error: false,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchStoreSales = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getTotalSales();
      if (data.status === "ok") {
        dispatch({
          type: GET_STORE_SALES,
          payload: data.data.totalSales,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchOrders = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getOrders();

      if (data.status === "ok") {
        dispatch({
          type: GET_ORDERS,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchOrder = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getOrder(id);
      if (data.status === "ok") {
        dispatch({
          type: GET_ORDER,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchSizeGuide = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getSizeGuide();
      if (data.status === "ok") {
        dispatch({
          type: GET_SIZE_GUIDE,
          payload: data.data,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const updateStoreDetails = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.updateStore(values);
      if (data.status === "ok") dispatch(fetchStore());
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateSellerDetails = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.updateSellerDetails(values);
      if (data.status === "ok") fetchStore();
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const updatePassword = (values) => {
  return async () => {
    try {
      const { data } = await api.store.resetPassword(values);
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const updateProduct = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.updateProduct(values);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.deleteProducts(id);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const deleteDraft = (ids) => {
  return async () => {
    try {
      const { data } = await api.store.deleteDraft(ids);
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const activateProducts = (ids) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.activateProducts(ids);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const deactivateProducts = (ids) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.deactivateProducts(ids);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const duplicateProduct = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.duplicateProduct(id);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const markOrderSent = (saleNo) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.markOrderSent(saleNo);
      if (data.status === "ok") {
        dispatch(fetchOrders());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};
export const declineOrder = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.declineOrder(values);
      if (data.status === "ok") {
        dispatch(fetchOrders());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const changeOrderDelivery = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.changeOrderDelivery(values);
      if (data.status === "ok") dispatch(fetchOrders());
      return data;
    } catch (err) {
      return err.message;
    }
  };
};
export const addSizeGuide = (value) => {
  return async () => {
    try {
      const { data } = await api.store.uploadSizeGuide(value);
      return data;
    } catch (err) {
      return err.message;
    }
  };
};
export const fetchBanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.fetchBanks();
      if (data.status === "success") {
        const sortedBanks = data.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        dispatch({ type: GET_BANKS, payload: sortedBanks });
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const submitBankDetails = (values) => {
  return async () => {
    try {
      const { data } = await api.store.addBankDetails(values);
      return data;
    } catch (err) {
      return err.response.data;
    }
  };
};

export const submitBankDetailsUk = (values) => {
  return async () => {
    try {
      const { data } = await api.store.addBankDetailsUK(values);

      return data;
    } catch (err) {
     return err.response.data;
    }
  };
};

export const removeSizeGuide = (value) => {
  return async () => {
    try {
      const { data } = await api.store.deleteSizeGuide(value);

      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const submitFeedback = (value) => {
  return async () => {
    try {
      const { data } = await api.store.submitFeedback(value);
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const setDelivery = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.setDeliveryType(value);
      if (data.status === "ok") dispatch(fetchDelivery());
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchDelivery = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getDeliveryType();
      if (data.status === "ok") {
        dispatch({
          type: GET_DELIVERY_OPTIONS,
          payload: data.data,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const setReturnsType = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.setReturnsPolicy(value);

      if (data.status === "ok") dispatch(fetchReturnsType());
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchReturnsType = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getReturnsPolicy();
      if (data.status === "ok") {
        dispatch({
          type: GET_RETURNS_TYPE,
          payload: data.data,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchSettlementInfo = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getSettlementInfo();
      if (data.status === "ok") {
        dispatch({
          type: GET_SETTLEMENT_DETAILS,
          payload: data.data,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchTransactionInfo = (month, year) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getTransactionHistory(month, year);
      if (data.status === "ok") {
        dispatch({
          type: GET_TRANSACTION_HISTORY,
          payload: data,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchVacationMode = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.getVacationMode();
      if (data.status === "ok") {
        dispatch({
          type: GET_VACATION_MODE,
          payload: data.isActive,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const activateVacation = (reason) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.activateVacationMode(reason);
      if (data.status === "ok") {
        dispatch(fetchVacationMode());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const deactivateVacation = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.deactivateVacationMode();
      if (data.status === "ok") {
        dispatch(fetchVacationMode());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const reqOtp = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.sendOtpReq(value);
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const verifyOtp = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.verifyOtp(values);
      if (data.status === "ok") {
        dispatch(fetchStore());
      }
      return data;
    } catch (err) {
      return err.message;
    }
  };
};

export const clearStore = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_STORE_DETAILS,
      });
    } catch (err) {
      return err.message;
    }
  };
};

export const updateProductVariant = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.updateVariant(values);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deleteProductVariant = (variantId, productId) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.deleteVariant(variantId, productId);
      if (data.status === "ok") {
        dispatch(fetchStoreProducts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const updateDraftVariant = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.updateDraftVariant(values);
      if (data.status === "ok") {
        dispatch(fetchStoreDrafts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deleteDraftVariant = (variantId, productId) => {
  return async (dispatch) => {
    try {
      const { data } = await api.store.deleteDraftVariant(variantId, productId);
      if (data.status === "ok") {
        dispatch(fetchStoreDrafts());
      }
      return data;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCTS_FAIL,
        error: true,
        payload: err.message,
      });
    }
  };
};

