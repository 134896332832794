import React from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch} from "react-redux";
import { userActionCreators } from "../../redux/user";
import { useMediaQuery } from "react-responsive";
import { Button, Form, Input, Select } from "antd";

import getLatLng from "../../utils/getLatLng";

const Option = Select;

const NewAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  if (!isTabletOrMobile) navigate("/account");

  const { postAddress } = bindActionCreators(userActionCreators, dispatch);

  const onFinish = async (values) => {
    const resp = await getLatLng(values);
    values.latitude = resp.split(",")[0];
    values.longitude = resp.split(",")[1];
    postAddress(values );
    form.resetFields();
    navigate("/address");
  };

  return (
    <div>
      <div className="page-title">
        <img
          src="/images/back-arrow.png"
          alt=""
          className="back-icon"
          onClick={() => navigate(-1)}
        />
        <h3 className="txt-title">Add New Address</h3>
      </div>
      <div className="new-address-container">
        <Form
          className="address-form"
          form={form}
          name="address"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: "Please input your address",
              },
              {
                max: 45,
                message: "Please limit your address to 45 characters or less",
              },
            ]}
          >
            <Input className="input" placeholder="Address" />
          </Form.Item>
          <Form.Item name="address2">
            <Input className="input" placeholder="Address 2" />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: "Please input your city",
              },
            ]}
          >
            <Input className="input" placeholder="City" />
          </Form.Item>

          <Form.Item
            name="state"
            rules={[
              {
                required: true,
                message: "Please input your county/state",
              },
            ]}
          >
            <Input className="input" placeholder="County/State" />
          </Form.Item>
          <Form.Item
            name="postalCode"
            rules={[
              {
                required: true,
                message: "Please input your postal code",
              },
            ]}
          >
            <Input className="input" placeholder="Postal Code" />
          </Form.Item>

          <Form.Item
            name="country"
            rules={[
              {
                required: true,
                message: "Please select your country!",
              },
            ]}
          >
            <Select
              className="select-country"
              style={{ width: "100%" }}
              placeholder="Select Country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Nigeria">Nigeria</Option>
              <Option value="United States">United States</Option>
              <Option value="United Kingdom">United Kingdom</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-new-address"
              type="primary"
              htmlType="submit"
            >
              Add Address
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-cancel"
              type="ghost"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NewAddress;
