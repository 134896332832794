/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Row, Drawer, Button, 
  // Dropdown, Space, 
  Skeleton } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
  // StarOutlined,
  // StarFilled,
  // DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { productsActionCreators } from "../redux/products";

import Ads from "../components/Ads";
// import Product from "../components/Product";

import { isEmptyObject } from "../utils/isEmptyObject";
import Filters from "../components/products/Filters";
import buildfilterQuery from "../utils/buildFilterQuery";
import { FiltersSkeleton, ProductSkeleton } from "../components/skeletons";
import ProductCard from "../components/SalePageProduct";
// import shuffleArray from "../utils/shuffleArray";

const { Input } = Skeleton;

const SalePage = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    products,
    filteredProducts: filtered,
    loading,
  } = useSelector((state) => state.products);
  const { data } = useSelector((state) => state.currency);
  const { code } = data ? data : { code: "" };

  const minPrice = products?.meta_data?.min_price;
  const maxPrice = products?.meta_data?.max_price;

  const [catIds, setCatIds] = useState([]);
  const [sellerIds, setSellerIds] = useState([]);
  const [prodColors, setProdColors] = useState([]);
  const [prodSizes, setProdSizes] = useState([]);
  const [prodFits, setProdFits] = useState([]);
  const [prodOccasions, setProdOccasions] = useState([]);
  const [prodReturns, setProdReturns] = useState([]);
  const [price, setPrice] = useState([minPrice, maxPrice]);
  const [firstFilter, setFirstFilter] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [isFilterCat, setIsFilterCat] = useState(false);
  const [curPage, setCurPage] = useState(1);
  // const [showDesc, setShowDesc] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 640 });

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const path = location.pathname;
  const id = params?.cid;
  const slug = params?.slug;
  const query = location?.search;

  let cat = "";
  if (path.includes("/category")) cat = "category";
  if (path.includes("/subcategory")) cat = "subcategory";
  if (path.includes("/designers")) cat = `brand/${slug}`;
  if (path.includes("/new-in")) cat = "shop/new/in";
  if (path.includes("/women-fashion") || path.includes("/men-fashion"))
    cat = "shop/main/category";

  const { type } = useSelector((state) => state.user);

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref?.current?.offsetTop - 120, behavior: "smooth" });
  const topOfPageRef = useRef(null);

  const dispatch = useDispatch();
  const {
    fetchCategoryProducts,
    fetchBrandProducts,
    fetchGenderProducts,
    fetchNewProducts,
    filterProductsByCat,
    filterProductsByOptions,
    filterNewIns,
    // sortByPrice,
  } = bindActionCreators(productsActionCreators, dispatch);

  // const handleButtonClick = (e) => {
  //   if (e.key === "0") sortByPrice("descending");
  //   if (e.key === "1") sortByPrice("ascending");
  // };

  const filters = {
    sellerId: sellerIds,
    catId: catIds,
    color: prodColors,
    size: prodSizes,
    fit: prodFits,
    occasion: prodOccasions,
    returns: prodReturns,
    price: price,
  };

  // const items = [
  //   {
  //     key: "0",
  //     label: "Price High to Low",
  //   },
  //   {
  //     key: "1",
  //     label: "Price Low to High",
  //   },
  // ];

  // const menuProps = {
  //   items,
  //   onClick: handleButtonClick,
  // };

  const priceFilter = (value) => {
    setPrice(value);
    setIsFilter(true);
  };

  // If firstFilter is null, determine it
  if (firstFilter === null) {
    for (const key in filters) {
      if (
        filters.hasOwnProperty(key) &&
        Array.isArray(filters[key]) &&
        filters[key].length > 0
      ) {
        setFirstFilter(key);
        break;
      }
    }
  }

  const fetchPageData = () => {
    if (path.includes("/products"))
      return fetchCategoryProducts(id, cat, query, code);
    if (path.includes("/designers"))
      return fetchBrandProducts(slug, query, code);
    if (path.includes("/new-in")) return fetchNewProducts(id, query, code);
    if (path.includes("all")) return fetchGenderProducts(id, query, code);
    if (path.includes("/women") || path.includes("/men"))
      return fetchCategoryProducts(id, cat, query, code);
  };

  useEffect(() => {
    fetchPageData();
    setCurPage(products?.meta_data?.current_page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, query, code]);

  const checkCategory = (catId) => {
    if (catIds.includes(catId)) {
      setCatIds(catIds.filter((id) => id !== catId));
    } else {
      setCatIds([...catIds, catId]);
    }
    setIsFilterCat(true);
  };

  const filterCat = async () => {
    filterProductsByCat(id, catIds, cat, curPage, code);
  };

  const filterOptions = async (query) => {
    filterProductsByOptions(query);
    setTimeout(() => setIsFilter(true), 3000);
  };

  const filterNewIn = async (query) => {
    filterNewIns(query);
    setTimeout(() => setIsFilter(true), 3000);
  };

  useEffect(() => {
    if (isFilterCat) {
      filterCat();
    }
  }, [catIds]);

  useEffect(() => {
    if (isFilter) {
      const query = buildfilterQuery(
        cat,
        id,
        curPage,
        code,
        filters,
        firstFilter
      );

      cat !== "shop/new/in" ? filterOptions(query) : filterNewIn(query);
    }
  }, [
    sellerIds,
    prodColors,
    prodSizes,
    price,
    prodFits,
    prodOccasions,
    prodReturns,
  ]);

  const checkBrand = (sellerId) => {
    if (sellerIds.includes(sellerId)) {
      setSellerIds(sellerIds.filter((id) => id !== sellerId));
    } else {
      setSellerIds([...sellerIds, sellerId]);
    }
    setIsFilter(true);
  };

  const checkColor = (color) => {
    if (prodColors.includes(color)) {
      setProdColors(prodColors.filter((colr) => colr !== color));
    } else {
      setProdColors([...prodColors, color.toLowerCase()]);
    }
    setIsFilter(true);
  };

  const checkSize = (size) => {
    if (prodSizes.includes(size)) {
      setProdSizes(prodSizes.filter((colr) => colr !== size));
    } else {
      setProdSizes([...prodSizes, size]);
    }
    setIsFilter(true);
  };

  const checkFits = (fit) => {
    if (prodFits.includes(fit)) {
      setProdFits(prodFits.filter((item) => item !== fit));
    } else {
      setProdFits([...prodFits, fit]);
    }
    setIsFilter(true);
  };

  const checkOccasions = (occasion) => {
    if (prodOccasions.includes(occasion)) {
      setProdOccasions(prodOccasions.filter((colr) => colr !== occasion));
    } else {
      setProdOccasions([...prodOccasions, occasion]);
    }
    setIsFilter(true);
  };

  const checkReturns = (returns) => {
    if (prodOccasions.includes(returns)) {
      setProdReturns(prodReturns.filter((ret) => ret !== returns));
    } else {
      setProdReturns([...prodReturns, returns]);
    }
    setIsFilter(true);
  };

  const shopCategory = (page) => {
    let prefix = `${type.toLowerCase()}/${cat}/${products?.category_info?.subchild_category_name.toLowerCase()}`;
    if (cat === "shop/new/in")
      prefix = `new-in/${type.toLowerCase()}/name=new-in-${type.toLowerCase()}`;

    navigate(
      `/${prefix}/cid=${id}?sellerId=${sellerIds.join(
        ","
      )}&color=${prodColors.join(",")}&size=${prodSizes.join(
        ","
      )}&fit=${prodFits.join(",")}&occasion=${prodOccasions.join(
        ","
      )}&returns=${prodReturns.join(",")}&price=${parseFloat(
        price[0]
      )},${parseFloat(price[1])}&page=${page}`
    );
  };

  const shopBrand = (page) => {
    navigate(
      `/designers/${products?.brands.slug}?catIds=${catIds.join(
        ","
      )}&page=${page}`
    );
  };

  const changePage = (page) => {
    if (page <= products?.meta_data?.last_page && !page < 1) {
      if (cat !== "brand") {
        shopCategory(page);
      } else if (cat) shopBrand(page);
    }
  };

  return (
    <div>
      {!isMobile ? (
        <>
            <div className="cat-text-container">
              <h1 className="cat-text" ref={topOfPageRef}>
                SALE
              </h1>
              {/* <h6 className="cat-desc">
                {loading ? (
                  <Skeleton active />
                ) : (
                  <>{products?.page_heading?.description}</>
                )}
              </h6> */}
            </div>

          <div className="products-container">
            <aside
              className="filters-container"
              style={{ position: "relative", top: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                  top: "-8px",
                }}
              >
                {/* <div style={{ color: "#92959E" }}>Clear All</div> */}
                <div
                  style={{
                    // fontSize: "16px",
                    fontFamily: "DomaineSansText-Regular",
                    lineHeight: "1",
                  }}
                >
                  {loading ? (
                    <Input size="small" />
                  ) : (
                    <> {products?.meta_data?.total} Items</>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                {isMobile && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="filter-header-title-text">Filter</span>
                  </div>
                )}
                {/* <div style={{ cursor: "pointer", color: "#92959E" }}>
                  Clear All
                </div> */}
              </div>
              {loading ? (
                <FiltersSkeleton />
              ) : (
                <>
                  {" "}
                  <Filters
                    products={products}
                    checkBrands={checkBrand}
                    checkCategory={checkCategory}
                    checkColor={checkColor}
                    checkOccasions={checkOccasions}
                    checkSize={checkSize}
                    checkFits={checkFits}
                    checkReturns={checkReturns}
                    priceFilter={priceFilter}
                    cat={cat}
                  />{" "}
                </>
              )}
            </aside>
            <div className="products-row">
            {/* <div className="sort-container">
                <div>
                  <Dropdown menu={menuProps}>
                    <Button>
                      <Space>
                        Sort
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </div>
              </div> */}
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {loading ? (
                  <>
                    {Array.from({ length: 60 }).map(() => (
                      <ProductSkeleton />
                    ))}
                  </>
                ) : (
                  <>
                    {filtered?.map((product, index) => (
                      <ProductCard
                      key={index}
                        product={product}
                        currency={data}
                        productIndex={index}
                      />
                    ))}
                  </>
                )}
              </Row>
              {!isEmptyObject(products) && (
            <div className="pagination-container">
              <div className="pagination-top-icon-container">
                <svg
                  width="33"
                  height="36"
                  viewBox="0 0 33 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => scrollToRef(topOfPageRef)}
                  style={{ cursor: "pointer" }}
                >
                  <circle
                    cx="16.0928"
                    cy="18.3921"
                    r="15.8054"
                    stroke="#D9D9D9"
                    stroke-width="0.574743"
                  />
                  <path
                    d="M22.9896 25.289L16.0927 18.3921L9.1958 25.289"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.04639 13.7939H24.1392"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="rows-box">
                <p
                  className="txt-next"
                  style={{
                    color:
                      products?.meta_data?.current_page !== 1
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-start",
                  }}
                >
                  <DoubleLeftOutlined
                    onClick={() => {
                      changePage(1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <LeftOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.current_page - 1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <span
                    onClick={() => {
                      changePage(products?.meta_data?.current_page - 1);
                      scrollToRef(topOfPageRef);
                    }}
                  >
                    Previous
                  </span>
                </p>

                <p className="num-text">
                  Page {products?.meta_data?.current_page} of{" "}
                  {products?.meta_data?.last_page}
                </p>

                <p
                  className="txt-next"
                  style={{
                    color:
                      products?.meta_data?.current_page !==
                      products?.meta_data?.last_page
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    onClick={() => {
                      changePage(products?.meta_data?.current_page + 1);
                      scrollToRef(topOfPageRef);
                    }}
                  >
                    Next
                  </span>
                  <RightOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.current_page + 1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <DoubleRightOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.last_page);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                </p>
              </div>
            </div>
          )}
            </div>
          </div>
         
          <div>
            <Ads />
          </div>

          {/* <div style={{ margin: "20em" }} /> */}
        </>
      ) : (
        <div className="mobile-categories-container">

            <>
              <div className="mob-cat-text-container">
                <h1 className="mob-cat-text" ref={topOfPageRef}>
                  <div style={{ fontSize: "22px", marginBottom: "8px" }}>
                    {loading ? (
                      <Input size="small" />
                    ) : (
                      <>SALE</>
                    )}
                  </div>
                  {/* <div style={{ textAlign: "justify" }}>
                    {loading ? (
                      <Skeleton active />
                    ) : (
                      <> {products?.page_heading?.description}</>
                    )}
                  </div> */}
                </h1>
              </div>
            </>
          {/* <div className="mobile-sort-container">
            <div onClick={() => setFilterDrawerOpen(true)}>
              {loading ? (
                <Input size="small" />
              ) : (
                <>
                  <svg
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 7.5H13.5M4.5 0.5C4.76522 0.5 5.01957 0.605357 5.20711 0.792893C5.39464 0.98043 5.5 1.23478 5.5 1.5V3.5C5.5 3.76522 5.39464 4.01957 5.20711 4.20711C5.01957 4.39464 4.76522 4.5 4.5 4.5C4.23478 4.5 3.98043 4.39464 3.79289 4.20711C3.60536 4.01957 3.5 3.76522 3.5 3.5V1.5C3.5 1.23478 3.60536 0.98043 3.79289 0.792893C3.98043 0.605357 4.23478 0.5 4.5 0.5V0.5ZM16.5 2.5H5.5H16.5ZM3.5 2.5H0.5H3.5ZM4.5 10.5C4.76522 10.5 5.01957 10.6054 5.20711 10.7929C5.39464 10.9804 5.5 11.2348 5.5 11.5V13.5C5.5 13.7652 5.39464 14.0196 5.20711 14.2071C5.01957 14.3946 4.76522 14.5 4.5 14.5C4.23478 14.5 3.98043 14.3946 3.79289 14.2071C3.60536 14.0196 3.5 13.7652 3.5 13.5V11.5C3.5 11.2348 3.60536 10.9804 3.79289 10.7929C3.98043 10.6054 4.23478 10.5 4.5 10.5ZM16.5 12.5H5.5H16.5ZM3.5 12.5H0.5H3.5ZM12.5 5.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5V8.5C13.5 8.76522 13.3946 9.01957 13.2071 9.20711C13.0196 9.39464 12.7652 9.5 12.5 9.5C12.2348 9.5 11.9804 9.39464 11.7929 9.20711C11.6054 9.01957 11.5 8.76522 11.5 8.5V6.5C11.5 6.23478 11.6054 5.98043 11.7929 5.79289C11.9804 5.60536 12.2348 5.5 12.5 5.5V5.5ZM11.5 7.5H0.5H11.5Z"
                      stroke="black"
                      stroke-width="0.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="filter-header-title-text">Filter</span>
                </>
              )}
            </div>
            <div>
            </div>
          </div> */}

          <div
            style={{
              fontFamily: "DomaineSansText-Light",
              fontSize: 12,
              margin: "16px 0",
            }}
          >
            {products?.meta_data?.total} Items
          </div>
          <div className="mob-products-container">
            <Row gutter={[22]}>
              {loading ? (
                <>
                  {Array.from({ length: 60 }).map(() => (
                    <ProductSkeleton />
                  ))}
                </>
              ) : (
                <>
                  {filtered?.map((product, index) => (
                    <ProductCard
                      product={product}
                      currency={data}
                      productIndex={index}
                      loading={loading}
                    />
                  ))}
                </>
              )}
            </Row>
          </div>
          {!isEmptyObject(products) && (
            <div className="pagination-container">
              <div className="pagination-top-icon-container">
                <svg
                  width="33"
                  height="36"
                  viewBox="0 0 33 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => scrollToRef(topOfPageRef)}
                  style={{ cursor: "pointer" }}
                >
                  <circle
                    cx="16.0928"
                    cy="18.3921"
                    r="15.8054"
                    stroke="#D9D9D9"
                    stroke-width="0.574743"
                  />
                  <path
                    d="M22.9896 25.289L16.0927 18.3921L9.1958 25.289"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.04639 13.7939H24.1392"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="rows-box">
                <p
                  className="txt-next"
                  style={{
                    color:
                      products?.meta_data?.current_page !== 1
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-start",
                  }}
                >
                  <DoubleLeftOutlined
                    onClick={() => {
                      changePage(1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <LeftOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.current_page - 1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <span
                    onClick={() => {
                      changePage(products?.meta_data?.current_page - 1);
                      scrollToRef(topOfPageRef);
                    }}
                  >
                    Previous
                  </span>
                </p>

                <p className="num-text">
                  Page {products?.meta_data?.current_page} of{" "}
                  {products?.meta_data?.last_page}
                </p>

                <p
                  className="txt-next"
                  style={{
                    color:
                      products?.meta_data?.current_page !==
                      products?.meta_data?.last_page
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    onClick={() => {
                      changePage(products?.meta_data?.current_page + 1);
                      scrollToRef(topOfPageRef);
                    }}
                  >
                    Next
                  </span>
                  <RightOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.current_page + 1);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                  <DoubleRightOutlined
                    onClick={() => {
                      changePage(products?.meta_data?.last_page);
                      scrollToRef(topOfPageRef);
                    }}
                  />
                </p>
              </div>
            </div>
          )}
          {/* <Pagination total={500} style={{ margin: "40px 0" }} /> */}

          <Ads />
        </div>
      )}

      <Drawer
        placement="left"
        title="Filter"
        width="100%"
        onClose={() => setFilterDrawerOpen(false)}
        open={filterDrawerOpen}
        className="products-filter-drawer"
      >
        {
          <Filters
            products={products}
            checkBrands={checkBrand}
            checkCategory={checkCategory}
            checkColor={checkColor}
            checkOccasions={checkOccasions}
            checkSize={checkSize}
            checkFits={checkFits}
            checkReturns={checkReturns}
            priceFilter={priceFilter}
            cat={cat}
          />
        }
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "32px",
            marginTop: "24px",
          }}
        >
          <Button className="filter-drawer-btn">Apply</Button>
          <Button
            className="filter-drawer-btn"
            style={{ background: "#fff", color: "#000" }}
          >
            Clear All
          </Button>
        </div>
      </Drawer>

      <style jsx="true">{`
        .cat-text-container {
          text-align: center;
          padding: 40px 0px 40px 0px;
          border-bottom: 1px solid #8080805c
        }
        .cat-text {
          font: normal normal normal 40px DomaineSansText-Regular;
          color: #000000;
          margin-bottom: 10px;
        }
        .cat-desc {
          text-align: center;
          width: 90%;
          margin: auto;
          font-size: 16px;
          font-family: "DomaineSansText-Light";
          line-height: 26px;
        }

        .cat-desc .ant-skeleton-content,
        .mob-cat-text .ant-skeleton-content {
          display: flex;
          width: 80%;
          flex-direction: column;
          align-items: center;
          margin: auto;
        }

        .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
          display: contents;
        }

        .all-product-text-container {
          text-align: center;
          background-color: ${type === "Men" ? "#6B1113" : "#e7d4c3"};
          //background-image: url("https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599045/website-pictures-optimized/men1_uvcdwj_l44bg2.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 300px;
          position: relative;
        }
        .cat-details {
          background: #fff;
          height: 250px;
          position: absolute;
          padding: 50px;
          top: 30px;
          left: 50px;
          width: 40%;
        }
        .all-product-text {
          font: normal normal bold 40px DomaineSansText-Regular;
          color: #000000;
          margin-bottom: 10px;
          text-align: left;
        }
        .all-product-desc {
          font: normal normal normal 15px DomaineSansText-Regular;
          margin-top: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .brand-img-container {
          background-color: #fff;
          display: grid;
          place-items: center;
          height: 200px;
          width: 200px;
          position: absolute;
          bottom: 24px;
          right: calc(2em + 16px);
          border-radius: 50%;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .brand-img {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 180px;
          border-radius: 50%;
          width: 180px;
        }

        .breadcrumb-container {
          background: #fff;
          margin-bottom: 8em;
        }

        .products-container {
          display: flex;
          margin-right: 3em;
          padding-top: 40px;
        }

        .filters-container {
          flex: 0.5;
          margin-left: 2em;
          margin-right: 1em;
          font-family: "DomaineSansText-Light";
          // text-transform: uppercase;
        }
        .filter-header-title-text {
          margin-left: 8px;
          font-family: "DomaineSansText-Regular";
        }
        .products-row {
          flex: 2;
        }
        .brands-container {
          height: max-content;
          overflow-y: scroll;
        }
        .sort-container {
          align-items: flex-end;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 8px;
        }
        .box {
          border-bottom: 1px solid #e6e6e6;
          // padding: 8px 0px;
          // margin-top: 16px;
        }

        .box
          .ant-collapse-ghost
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding-top: 0;
        }

        element.style {
          font-family: DomaineSansText-Regular;
          margin-top: 20px;
          font-size: 17px;
        }
        .ant-checkbox-wrapper {
          // align-items: center;
        }
        // .category-filter-item .ant-checkbox-inner {
        //   width: 16px;
        //   height: 16px;
        //   border-radius: 50%;
        // }

        .size-filter-item .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #000;
        }
        .searchBox.ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        // .ant-slider-step {
        //   background: #000;
        //   height: 2px;
        // }
        .ant-slider-handle {
          border: solid 1px #000;
        }
        .ant-slider-track {
          height: 2px;
        }
        .multi-color-container {
          border: 1px solid #d4d4d4;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
        }
        .multi-color-box {
          background: transparent
            linear-gradient(231deg, #177cef 0%, #f61313 100%);
          width: 20px;
          height: 20px;
          margin-left: 10px;
          margin-right: 30px;
        }
        .color-row {
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 22px;
          height: 250px;
          // width: 250px;
          padding-right: 10px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        .color-row::-webkit-scrollbar {
          width: 15px;
        }
        .color-row::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .color-row::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }
        .brands-container::-webkit-scrollbar {
          width: 15px;
        }
        .brands-container::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .brands-container::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }
        .color-container .ant-checkbox {
          top: 0;
        }
        .color-box {
          width: 16px;
          height: 16px;
          margin-left: 0px;
          margin-right: 5px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          )::before {
          background-color: #000 !important;
        }
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ) {
          border: 1px solid #000 !important;
          color: #000;
        }
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)
          > span {
          display: flex;
          align-items: center;
        }
        .pagination-container {
          display: flex;
          justify-content: center;
          margin: 60px auto;
        //   width: 80%;
          font-family: "DomaineSansText-Light";
          font-size: 14px;
          flex-direction: column;
          gap: 40px;
        }
        .pagination-top-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 16px;
        }
        .rows-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .num-text {
          color: #000000;
          flex-basis: 33%;
          text-align: center;
        }
        .txt-next {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 24px;
          flex-basis: 33%;
        }
        .btn-num {
          background: rgba(0, 0, 0, 0.1);
          height: 30px;
          width: 80px;
        }

        .sort-dropdown-box {
          cursor: pointer;
          text-transform: capitalize;
          font-size: 13px;
          font-family: DomaineSansText-Light;
          margin-left: 10px;
          padding: 8px;
          border: 0.5px solid #d9d9d9;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 130px;
        }

        .store-header-banner-section {
          background: url(https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599051/website-pictures-optimized/hom-car_m61oic_awnuxf.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          height: calc(100vh - 428px);
          position: relative;
        }
        .store-header-banner-text-section {
          background: #fff;
          color: #000;
          display: flex;
          padding: 2em;
          font-size: 16px;
          font-family: "DomaineSansText-Light";
          border-bottom: 1px solid #000;
          padding-right: calc(2em + 16px);
        }
        .store-header-banner-text-header {
          min-width: 25%;
          padding-right: 24px;
          margin-right: 24px;
          border-right: 1px solid #000;
        }
        .store-header-banner-text-header-store-name {
          font-size: 28px;
          font-family: "DomaineSansText-Regular";
          text-decoration: underline;
        }
        .store-header-banner-text-header-flex-section {
          display: flex;
          gap: 8px;
          padding: 8px 0;
          align-items: center;
        }
        .store-header-banner-text-header-flex-section img {
          width: 20px;
          height: 20px;
        }
        .brand-div-panel .ant-collapse-header {
          padding: 0px !important;
          margin: 0px;
        }
        .brand-div-panel .ant-collapse-content-box {
          padding: 16px 0;
          padding-bottom: 0px !important;
        }
        .brands-container
          .ant-collapse-icon-position-right
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          right: 0;
        }

        @media screen and (max-width: 640px) {
          .mobile-categories-container {
            margin: 16px;
          }
          .mob-cat-text-container,
          .mob-cat-details {
            text-align: center;
          }
          .mob-cat-text {
            font: normal normal 12px/18px DomaineSansText-Regular;
            margin: 0px;
            line-height: normal;
          }
          .mob-search-result-num {
            font: normal normal 12px/18px DomaineSansText-Regular;
            margin: 0px;
          }
          .breadcrumb-title {
            color: #b2b2b2;
            font: normal normal 11px/14px DomaineSansText-Regular;
            margin: 0px;
          }
          .mob-cat-details {
            margin-top: 12px;
          }
          .mob-txt-cat {
            border: 0.5px solid #bababa;
            font: normal normal 12px/16px DomaineSansText-Regular;
            height: 26px;
            margin: 0px;
            padding: 5px 8px;
            width: auto;
          }
          .icon-filter {
            width: 22px;
            height: 16px;
          }
          .mob-products-container {
            margin-top: 14px;
          }
          .all-product-text-container {
            height: 188px !important;
            width: 100vw;
            left: -22px;
            margin-bottom: 72px;
          }
          .cat-details {
            height: 120px !important;
            top: 10px !important;
            left: 10px !important;
            padding: 9px !important;
            width: 95vw !important;
          }
          .mob-brand-img {
            background-color: #fff;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid #000000;
            height: 72px;
            position: absolute;
            right: 8%;
            top: 75%;
            width: 72px;
          }
          .all-product-text {
            font: normal 700 24px/33px DomaineSansText-Regular !important;
          }
          .all-product-desc {
            font: normal 600 9px/12px DomaineSansText-Regular !important;
          }
          .product-img {
            height: 230px !important;
          }
          .product-name {
            font: normal normal 14px/18px DomaineSansText-Regular !important;
          }
          .product-price {
            color: #000000;
            font: normal normal 14px/18px DomaineSansText-Regular !important;
          }
          .btn-wishlist {
            min-width: 28px !important;
            width: 28px !important;
            height: 28px !important;
            top: 6% !important;
          }
          .icon-wishlist {
            width: 12px !important;
          }
          .mobile-sort-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 16px 0;
          }
          .filter-header-title-text {
            font-size: 12px;
            font-family: DomaineSansText-Light;
          }
          .sort-dropdown-box {
            font-size: 12px;
            font-family: DomaineSansText-Light;
            padding: 8px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 100px;
          }
          .products-filter-drawer .ant-drawer-body {
            padding: 0;
          }
          .products-filter-drawer .ant-drawer-header {
            padding: 16px;
            font-family: "DomaineSansText-Light";
            padding-right: 0;
          }
          .products-filter-drawer .ant-drawer-header-title {
            flex-direction: row-reverse;
          }
          .products-filter-drawer .ant-collapse-header {
            color: black !important;
          }
          .mobile-filter-tag {
            background: black;
            color: white;
            padding: 8px;
            position: relative;
            font-family: "DomaineSansText-Light";
            font-size: 12px;
            width: 95px;
          }
          .mobile-filter-tags-container {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
          }

          .store-header-banner-text-section {
            padding: 16px 8px 8px;
            display: block;
            font-size: 14px;
          }
          .store-header-banner-text-header {
            border: 0;
            margin: 0;
            padding: 0;
          }
          .store-header-banner-text-header-store-name {
            font-size: 18px;
          }
          .store-stars-container {
            padding-bottom: 8px;
          }
          .store-header-banner-text-details {
            border-top: 1px solid #6e6e6e;
            margin-top: 12px;
            padding-top: 12px;
          }
          .brand-img-container {
            height: 100px;
            width: 100px;
            bottom: 8px;
            right: 8px;
          }

          .brand-img {
            height: 90px;
            width: 90px;
          }
          .filter-drawer-btn {
            width: 130px;
            height: 48px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 14px DomaineSansText-Light;
          }
          .txt-next {
            gap: 8px;
          }
          .pagination-container {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default SalePage;
