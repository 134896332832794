import { useState } from "react";
import { Button, Dropdown, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { storeActionCreators } from "../../redux/stores";
import { useMediaQuery } from "react-responsive";
import {
  PlusOutlined,
  DownOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import ListingView from "./ListingView";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import triggerNotification from "../../hooks/triggerNotification";

const ManageListings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [checkedList, setCheckedList] = useState(new Set([]));
  // const [indeterminate, setIndeterminate] = useState(true);
  // const [checkAll, setCheckAll] = useState(false);

  const { products, details } = useSelector((state) => state.store);

  const [sortedProducts, setSortedProducts] = useState(products?.data);

  const allStatus =
    [...new Set(products?.data?.map((product) => product.status))] || [];

  const {
    activateProducts,
    fetchSingleProduct,
    deactivateProducts,
    deleteProduct,
    duplicateProduct,
  } = bindActionCreators(storeActionCreators, dispatch);

  //   const currency = useSelector((state) => state.currency[0]);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  // const onChange = (id) => {
  //   checkedList?.has(id) ? checkedList.delete(id) : checkedList.add(id);
  //   setIndeterminate(!!checkedList.size && checkedList.size < products.length);
  //   setCheckAll(checkedList.size === products.length);
  //   setCheckedList(new Set(checkedList));
  // };

  // const onCheckAllChange = (e) => {
  //   const ids = products?.data
  //     ?.filter((product) => product.status === "deactivated")
  //     .map((product) => product.product_id);
  //   setCheckedList(e.target.checked ? new Set(ids) : checkedList.clear());
  //   setIndeterminate(false);
  //   setCheckAll(e.target.checked);
  // };

  // const activateItems = async () => {
  //   const res = await activateProducts( Array.from(checkedList));
  //   if (res.status === "ok") {
  //     message.success(res.message);
  //   }
  // };

  const items = [
    {
      key: "0",
      label: "All",
    },
  ];
  allStatus?.forEach((status, index) => {
    items.push({
      key: `${index + 1}`,
      label: status,
    });
  });

  const handleButtonClick = ({ key }) => {
    const option = items.find((item) => item.key === key)?.label;
    setSortedProducts((prevProducts) => {
      if (option === "All") {
        return products?.data || [];
      } else {
        return (products?.data || []).filter((item) => item.status === option);
      }
    });
  };

  const menuProps = {
    items,
    onClick: handleButtonClick,
  };

  const deactivateItems = async (id) => {
    // onChange(id);
    const res = await deactivateProducts([id]);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
    }
  };

  const activateItems = async (id) => {
    const res = await activateProducts([id]);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
    }
  };

  const copyItem = async (id) => {
    const res = await duplicateProduct(id);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
    }
  };
  const editItem = async (id) => {
    const res = await fetchSingleProduct(id);
    if (res.status === "ok") {
      navigate("/edit-listing", { state: "listing" });
    }
  };
  const deleteItem = async (id) => {
    const res = await deleteProduct([id]);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
    }
  };

  // function itemRender(current, type, originalElement) {
  //   if (type === "prev") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Previous" : ""}
  //       </span>
  //     );
  //   }
  //   if (type === "next") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Next" : ""}
  //       </span>
  //     );
  //   }
  //   return originalElement;
  // }

  return (
    <div className="manage-listings-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="4" />}
        <div className="manage-listings-coverall-container">
          <div className="manage-listings-adspace"></div>
          <div className="manage-listings-body-content-header">
            <div className="manage-listings-body-content-header-container">
              <div className="manage-listings-body-content-header-title">
                <div>My Listings</div>
              </div>
              {!isTabletOrMobile && (
                <div className="manage-listings-body-content-header-subtitle">
                  View or edit items in your Store. Make the most of our
                  affordable photoshoot services.
                </div>
              )}
              {isTabletOrMobile && (
                <div className="manage-listings-body-content-header-subtitle">
                  View or edit items in your Store. Remember to join the Losode Seller Community  to gain access to education and tools focused on growing your business and take advantage of our affordable photoshoots
                </div>
              )}
            </div>
            <div className="manage-listing-header-buttons-container ">
              <Dropdown menu={menuProps}>
                <Button className="btn-sort">
                  <Space>
                    Sort
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <Button
                icon={
                  <PlusOutlined
                    style={{ fontSize: "17px", verticalAlign: "middle" }}
                  />
                }
                className="manage-listings-body-sidebar-add-listing-button"
                onClick={() => navigate("/list-item")}
              >
                List A New Item
              </Button>
            </div>
          </div>
          <div className="manage-listings-body-container">
            <div className="manage-listings-body-content">
              {/* {products?.data?.length > 0 && (
                <div className="manage-listings-body-content-top-actions">
                  <div className="top-actions-checkbox-container">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                      style={{ marginRight: "8px" }}
                    />
                    <ArrowDownOutlined />
                  </div>
                  <div>
                    <Button
                      className="activate-delete-button"
                      onClick={activateItems}
                    >
                      Activate
                    </Button>
                  </div>
                </div>
              )} */}

              <div className="manage-listings-body-content-listings-container">
                {sortedProducts?.length > 0 &&
                  sortedProducts?.map((product) => (
                    <ListingView
                      // checked={checkedList?.has(product.product_id)}
                      // onChange={() => onChange(product.product_id)}
                      key={product.product_id}
                      product={product}
                      copyItem={() => copyItem(product.product_id)}
                      editItem={() => editItem(product.product_id)}
                      deactivateItem={() => deactivateItems(product.product_id)}
                      activateItem={() => activateItems(product.product_id)}
                      deleteItem={() => deleteItem(product.product_id)}
                    />
                  ))}
                {products?.length === 0 && (
                  <div className="no-listings-to-show">
                    <div>
                      <ExclamationCircleFilled
                        style={{ fontSize: "50px", marginBottom: "16px" }}
                      />
                      <div>
                        You do not have any items listed in your Store.{" "}
                        <div
                          className="no-listings-to-show-link"
                          onClick={() => navigate("/list-item")}
                        >
                          List a new item
                        </div>{" "}
                        now to start generating income{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="manage-listings-body-sidebar">
              {/* <div className='manage-orders-body-sidebar-content'>
                <div className='manage-orders-body-sidebar-content-title'>
                  Sort
                </div>
                <Select
                  defaultValue="latest"
                  placeholder="Latest"
                  style={{ width: "100%" }}
                  className="manage-listings-body-sidebar-content-select"
                >
                  <Select.Option value="latest">Most Recent</Select.Option>
                  <Select.Option value="price">Price</Select.Option>
                  <Select.Option value="alphabetical">A-Z</Select.Option>
                </Select>
              </div>
              {!isTabletOrMobile && <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "8px",
                  }}
                >
                  Listing Status
                </div>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Saved Listings</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Active</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Inactive</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Sold out</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
              </div>}*/}
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .manage-listings-container {
          font-family: DomaineSansText-Light;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }
        .manage-listings-coverall-container {
          margin-left: 200px;
        }
        .manage-orders-body-sidebar-content {
          margin: 10px 0px 16px;
        }

        .manage-listings-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .manage-listings-body-container {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 100px;
        }
        .manage-listings-body-content-header-container {
          margin-bottom: 24px;
        }
        .manage-listings-body-content-header {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-listings-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .manage-listings-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }

        .manage-listing-header-buttons-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .manage-listings-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-listings-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
        }

        .manage-listings-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
          align-items: center;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
          height: 34px;
          width: 175px;
        }
        .manage-listings-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-listings-body-sidebar {
          width: 17%;
          padding-left: 10px;
          border-left: 1px solid #d4d4d4;
          margin-top: 60px;
          margin-right: 10px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-listings-body-content {
          width: 80%;
          padding: 0 20px;
          margin: auto;
        }
        .manage-listings-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
          // gap: 21px;
        }

        .manage-listings-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }

        .no-listings-to-show {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 500px;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          width: 80%;
          margin: auto;
        }
        .no-listings-to-show-link {
          text-decoration: underline;
          display: inline-block;
        }
        .no-listings-to-show-link:hover {
          cursor: pointer;
          color: #800000;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .manage-orders-body-sidebar-content-title {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 8px;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .manage-listings-body-content {
            width: 85%;
            margin: auto;
            padding: 0 16px;
          }

          .manage-listings-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .manage-listings-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }

          .manage-listings-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
        }

        @media (max-width: 640px) {
          .manage-listing-all-content{
            margin-top: ${!details.phone_verify_status ? "154px" :"90px"} ;
          }
          .manage-listings-coverall-container {
            margin-left: 0;
          }
          .manage-listings-adspace {
            background-image: url(images/seller-stats-banner-mobile.png);
            height: 108px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .btn-sort {
            width: 80px;
          }

          .manage-listings-body-main {
            padding: 0px;
            padding-bottom: 24px;
            margin-right: 0;
          }
          .manage-listings-body-content-header {
            padding: 24px 12px;
            flex-direction: column;
          }
          .manage-listings-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .manage-listings-body-content-header-title {
            font-size: 18px;
            font-family: DomaineSansText-Black;
          }
          .manage-listings-body-content-header-subtitle {
            font-size: 12px;
            width: 100%;
          }
          .btn-sort{
          }
          .manage-listings-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
            margin-top: 0;
          }
          .manage-listings-body-sidebar-add-listing-button > .anticon + span {
            font-size:12px
          }

          .manage-listings-body-content-listings-container {
            display: block;
            margin-bottom: 24px;
            padding-bottom:20px
          }
          .manage-listings-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .manage-listings-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }
          .manage-listings-body-sidebar {
            display:none;
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            // display: flex;
            justify-content: flex-end;
          }
          .manage-listings-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .manage-listings-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .manage-listings-body-sidebar-content-select {
            width: auto;
          }
          .manage-listings-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .manage-listings-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-listings-body-content {
            width: 100%;
            padding: 0 12px;
          }
          .manage-listings-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }

          .manage-orders-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }

          .manage-orders-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default ManageListings;
