import { useNavigate } from "react-router-dom";

const ShopGender = () => {
  const navigate = useNavigate();

  const shopMen = () => {
    navigate(`/men-fashion/all/cid=${1}&name=men-products`);
  };
  const shopWomen = () => {
    navigate(`/women-fashion/all/cid=${2}&name=women-products`);
  };

  const brands = [
    {
      id: 1,
      imageUrl:
        "/images/shop-gender-1.png",
      name: "Men’s Style Redefined",
      description: "Making you the center of attention wherever you go",
      action: "Shop men",
    },
    {
      id: 2,
      imageUrl:
        "/images/shop-gender-2.png",
      name: "An Affair with Fashion",
      description:
        "Flirty dresses and divine accessories guaranteed to leave you smitten",
      action: "Shop women",
    },
  ];

  return (
    <div className="gender-main-container">
      <div className="shop-gender-container">
        {brands.map((brand) => (
          <div key={brand.id} className="gender-container">
            <img
              className="img-gender"
              alt={brand.name}
              src={brand.imageUrl}
              loading="lazy"
            />
            <h3 className="gender-title">{brand.name}</h3>
            <h5 className="gender-desc">{brand.description}</h5>
            <p
              className="gender-cta"
              onClick={() => (brand.id === 1 ? shopMen() : shopWomen())}
            >
              {brand.action}
            </p>
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .gender-main-container {
          padding: 0 100px;
        }

        .shop-gender-container {
          display: flex;
          justify-content: space-between;
          gap: 24px;
          padding-bottom: 0;
          margin: 40px 0;
        }
        .img-gender {
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .gender-title {
          font: normal 400 24px DomaineSansText-Regular;
          line-height: 34px;
          margin-top: 15px;
          margin-bottom: 0px;
          text-transform: capitalize;
        }
        .gender-desc {
          font: normal normal 14px DomaineSansText-Light;
          line-height: 22px;
          margin-top: 1px;
          margin-bottom: 0px;
        }
        .gender-cta {
          cursor: pointer;
          font: normal 300 14px DomaineSansText-Light;
          margin-top: 15px;
          text-decoration-line: underline;
          color: #555555;
        }
        .gender-cta:hover {
          text-decoration: none;
        }
        .gender-container {
          flex-basis: 50%;
        }

       

        @media (max-width: 640px) {
          .gender-main-container {
            padding: 0 16px;
          }
          .shop-gender-container {
            flex-direction: column;
            gap: 32px;
          }
          .gender-title {
            font-size: 16px;
            margin-top: 14px;
            line-height: 22px;
          }
          .gender-desc {
            font-size: 14px;
            margin: 0;
          }
          .gender-cta {
            margin-top: 12px;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .img-gender {
            height: 280px;
          }
        }
      `}</style>
    </div>
  );
};

export default ShopGender;
