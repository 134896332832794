import api from "../../api";

import {
  REGISTER_USER_FAIL,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_SELLER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  POST_USER_ADDRESS_FAIL,
  UPDATE_USER_ADDRESS_FAIL,
  UPDATE_USER_DETAILS_FAIL,
  DELETE_USER_ADDRESS_FAIL,
  GET_USER_ITEMS,
  GET_USER_ITEMS_FAIL,
  LOGOUT_USER,
  UPDATE_FIRST_TIME,
  SET_USER_TYPE,
  RESET_PASSWORD_SELLER_INITIATE,
  RESET_PASSWORD_SELLER_FAIL,
  RESET_PASSWORD_SELLER_RESET,
  RESET_PASSWORD_SELLER_CONFIRM,
  RESET_PASSWORD_SELLER_CONFIRM_FAIL,
  GET_EMAIL_PREF,
  GET_ORDERS,
  GET_ORDERS_DETAILS,
  GET_ORDER_ITEM,
} from "./type";

export const joinMailList = (option) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.addMailList(option);
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const joinNewsLetter = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.joinNewsLetter(values);
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const registerUser = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_START,
      });
      const { data } = await api.user.registerUser(values);
      if (data.status === "ok") {
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const registerGuest = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_START,
      });
      const { data } = await api.user.registerGuest(values);
      if (data.status === "ok") {
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const registerSeller = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_START,
      });
      const { data } = await api.user.registerSeller(values);
      if (data.status === "ok") {
        dispatch({
          type: REGISTER_SELLER_SUCCESS,
          payload: data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const completeUserRegistration = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.completeUserAccount(values);
      return data;
    } catch (err) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const resetPasswordSellerInitiate = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.resetPasswordSellerInitiate(values);
      if (data.status === "ok") {
        dispatch({
          type: RESET_PASSWORD_SELLER_INITIATE,
          payload: data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_SELLER_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const resetPasswordSellerConfirm = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.resetPasswordSellerConfirm(values);
      if (data.status === "ok") {
        dispatch({
          type: RESET_PASSWORD_SELLER_CONFIRM,
          payload: data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_SELLER_CONFIRM_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const resetPasswordSellerReset = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_SELLER_RESET,
    });
  };
};

export const loginBuyer = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_USER_START,
      });
      const { data } = await api.user.loginUser(values);

      if (data.status === "ok") {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: data,
          error: false,
        });
        dispatch(fetchAddress());
      }
      return data;
    } catch (err) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const loginSeller = (values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_USER_START,
      });
      const { data } = await api.user.loginSeller(values);
      if (data.status === "ok") {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: data,
          error: false,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: err.message,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.logOutUser();
      if (data.status === "ok") {
        dispatch({
          type: LOGOUT_USER,
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchAddress = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getAddress();
      if (data.status === "ok") {
        dispatch({
          type: GET_USER_ADDRESS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_ADDRESS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const addAddress = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.postAddress(values);
      if (data.status === "ok") {
        dispatch(fetchAddress());
      }
      return data;
    } catch (err) {
      dispatch({
        type: POST_USER_ADDRESS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const updateAddress = (id, values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.updateAddress(id, values);
      if (data.status === "ok") {
        dispatch(fetchAddress());
      }
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_ADDRESS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const setPrimaryAddress = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.makePrimaryAddress(id);
      if (data.status === "ok") {
        dispatch(fetchAddress());
      }
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_ADDRESS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const deleteAddress = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.deleteAddress(id);
      if (data.status === "ok") {
        dispatch(fetchAddress());
      }
    } catch (err) {
      dispatch({
        type: DELETE_USER_ADDRESS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchDetails = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getUserDetails();
      if (data.status === "ok") {
        dispatch({
          type: GET_USER_DETAILS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_DETAILS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const updateUserInfo = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.updateUserInfo(values);
      if (data.status === "ok") {
        dispatch(fetchDetails());
      }
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const updateUserEmail = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.updateUserEmail(values);
      if (data.status === "ok") {
        dispatch(fetchDetails());
      }

      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const updateUserPassword = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.updateUserPassword(values);
      return data;
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchItems = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getUserItems();
      if (data.status === "ok") {
        dispatch({
          type: GET_USER_ITEMS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const postItem = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.addItem(id);
      if (data.status === "ok") {
        dispatch(fetchItems());
      }
      return data;
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
      return err;
    }
  };
};

export const deleteSavedItem = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.deleteSavedItem(id);
      if (data.status === "ok") {
        dispatch(fetchItems());
      }
      return data;
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const updateFirstTime = (bol) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FIRST_TIME,
      payload: bol,
    });
  };
};
export const setUserType = (type) => {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_TYPE,
      payload: type,
    });
  };
};

export const getEmailPref = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getEmailPref();
      if (data.status === "ok") {
        const val = data.data === 1 ? true : false;
        dispatch({
          type: GET_EMAIL_PREF,
          payload: val,
        });
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const activateEmailList = (email) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.activateEmailList(email);
      if (data.status === "ok") {
        dispatch(getEmailPref());
      }
    } catch (err) {
      return err.message;
    }
  };
};
export const deactivateEmailList = (email) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.deactivateEmailList(email);
      if (data.status === "ok") {
        dispatch(getEmailPref());
      }
    } catch (err) {
      return err.message;
    }
  };
};

export const fetchOrders = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getOrders();
      if (data.status === "ok") {
        dispatch({
          type: GET_ORDERS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchSortedOrders = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getOrder(id);
      if (data.status === "ok") {
        dispatch({
          type: GET_ORDERS_DETAILS,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};
export const fetchOrderItem = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getOrderItem(id);
      if (data.status === "ok") {
        dispatch({
          type: GET_ORDER_ITEM,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: GET_USER_ITEMS_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};