export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_SELLER_SUCCESS = "REGISTER_SELLER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_ADDRESS_FAIL = "GET_USER_ADDRESS_FAILED";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAILED";
export const GET_USER_ITEMS = "GET_USER_ITEMS";
export const GET_USER_ITEMS_FAIL = "GET_USER_ITEMS_FAILED";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const GET_USER_ORDERS_FAIL = "GET_USER_ORDERS_FAILED";
export const UPDATE_USER_ADDRESS_FAIL = "UPDATE_USER_ADDRESS_FAILED";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";
export const DELETE_USER_ADDRESS_FAIL = "DELETE_USER_ADDRESS_FAILED";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const POST_USER_ADDRESS_FAIL = "POST_USER_ADDRESS_FAILED";
export const UPDATE_FIRST_TIME = "UPDATE_FIRST_TIME";
export const UPDATE_FIRST_TIME_FAILED = "UPDATE_FIRST_TIME_FAILED";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const RESET_PASSWORD_SELLER_INITIATE = "RESET_PASSWORD_SELLER_INITIATE";
export const RESET_PASSWORD_SELLER_FAIL = "RESET_PASSWORD_SELLER_FAIL";
export const RESET_PASSWORD_SELLER_RESET = "RESET_PASSWORD_SELLER_RESET";
export const RESET_PASSWORD_SELLER_CONFIRM = "RESET_PASSWORD_SELLER_CONFIRM";
export const RESET_PASSWORD_SELLER_CONFIRM_FAIL =
  "RESET_PASSWORD_SELLER_CONFIRM_FAIL";
export const GET_EMAIL_PREF = "GET_EMAIL_PREF";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_DETAILS = "GET_ORDERS_DETAILS";
export const GET_ORDER_ITEM = "GET_ORDER_ITEM";
