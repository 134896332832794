import {
  ADD_TO_CART,
  APPLY_COUPON,
  APPLY_COUPON_FAILED,
  CHECK_OUT,
  DELETE_PRODUCT_IN_CART,
  ORDER_RECEIPT,
  ORDER_RECEIPT_FAILED,
  UPDATE_PRODUCT_ALREADY_IN_CART,
  SET_TRANSACTION_RESULT,
  DELETE_TRANSACTION_RESULT,
  RESET,
  DELETE_STORE_ITEMS,
  CHANGE_ITEMS_CURRENCY,
  ON_CHECK_OUT_REQUEST,
  GET_USER_CART,
  UPDATE_ITEM,
} from "./type";

const initialState = {
  checkoutItems: [],
  orderDetails: {},
  discount: "",
  error: "",
  items: [],
  sum: 0,
  txref: "",
  isSuccess: false,
};

const reducer = (state = initialState, action) => {
  let updateProductQuantityById = (id, quantities, price, size, color) =>
    state.items.map((item) => {
      return item.product_id === id &&
        item?.selectedSize === size &&
        item?.selectedColor?.hex === color?.hex
        ? { ...item, quantityWanted: quantities, totalPrice: price }
        : item;
    });
  let updateProductById = (id, quantities, price, size, color) =>
    state.items.map((item) => {
      return item.product_id === id
        ? {
            ...item,
            quantityWanted: quantities,
            totalPrice: price,
            selectedSize: size,
            selectedColor: color,
          }
        : item;
    });

  switch (action.type) {
    case ADD_TO_CART:
      localStorage.setItem("cartUpdateTime", Date.now());
      const prices = [];
      state.items.map((item) => prices.push(Number(item.totalPrice)));
      const totalPrices =
        prices.length > 1 ? prices.reduce((a, b) => a + b) : state.sum;

      return {
        ...state,
        items: [...state.items, action.payload],
        sum: totalPrices + Number(action.payload.totalPrice),
      };

    case GET_USER_CART:
      const cartPrices = [];
      // remove duplicates
      const filteredCart = action.payload.filter(
        (item1) =>
          !state.items.some((item2) => item2.product_id === item1.product_id)
      );
      filteredCart?.map((item) => cartPrices.push(Number(item.totalPrice)));
      const totalCartPrice =
        cartPrices.length > 1
          ? cartPrices.reduce((a, b) => a + b)
          : filteredCart[0].totalPrice;
      return {
        ...state,
        items: [...state.items, ...filteredCart],
        sum: state.sum + totalCartPrice,
      };

    case CHANGE_ITEMS_CURRENCY:
      const newPrices = [];
      action.payload.map((item) => newPrices.push(Number(item.totalPrice)));
      const totalNewPrices =
        newPrices.length > 1
          ? newPrices.reduce((a, b) => a + b)
          : Number(action.payload.totalPrice);
      return {
        ...state,
        items: action.payload,
        sum: totalNewPrices ? totalNewPrices : 0,
      };
    case APPLY_COUPON:
      return { ...state, discount: action.payload };
    case ON_CHECK_OUT_REQUEST:
      return { ...state, checkoutItems: [] };
    case CHECK_OUT:
      return { ...state, checkoutItems: action.payload };
    case APPLY_COUPON_FAILED:
      return { ...state, error: action.payload };

    case ORDER_RECEIPT:
      return { ...state, orderDetails: action.payload };

    case ORDER_RECEIPT_FAILED:
      return { ...state, error: action.payload };

    case SET_TRANSACTION_RESULT:
      return {
        ...state,
        txref: action.payload.txref,
        isSuccess: action.payload.message === "success" ? true : false,
      };

    case DELETE_TRANSACTION_RESULT:
      return { ...state, txref: "", isSuccess: false };

    case UPDATE_PRODUCT_ALREADY_IN_CART:
      const itemPrices = [];
      state.items.map((item) =>
        item.product_id === action.payload.product_id &&
        item?.selectedSize === action.payload.selectedSize &&
        item?.selectedColor?.hex === action.payload.selectedColor?.hex
          ? itemPrices.push(Number(action.payload.totalPrice))
          : itemPrices.push(item.totalPrice)
      );
      const totalItemPrices = itemPrices.reduce((a, b) => a + b);
      return {
        ...state,
        items: updateProductQuantityById(
          action.payload.product_id,
          action.payload.quantityWanted,
          Number(action.payload.totalPrice),
          action.payload.selectedSize,
          action.payload.selectedColor
        ),
        sum: totalItemPrices,
      };

    case UPDATE_ITEM:
      const newItemPrices = [];
      state.items.map((item) =>
        item.product_id === action.payload.product_id
          ? newItemPrices.push(Number(action.payload.totalPrice))
          : newItemPrices.push(item.totalPrice)
      );
      const updatedPrices = newItemPrices.reduce((a, b) => a + b);
      return {
        ...state,
        items: updateProductById(
          action.payload.product_id,
          action.payload.quantityWanted,
          Number(action.payload.totalPrice),
          action.payload.selectedSize,
          action.payload.selectedColor
        ),
        sum: updatedPrices,
      };

    case DELETE_PRODUCT_IN_CART:
      return {
        ...state,
        items: state.items.filter(
          (item) =>
            !(
              item.product_id === action.payload.product_id &&
              item?.selectedSize === action.payload.selectedSize &&
              item?.selectedColor?.hex === action.payload.selectedColor?.hex
            )
          // product.product_id !== action.payload.product_id
        ),
        sum: state.sum - parseFloat(action.payload.totalPrice),
      };

    case DELETE_STORE_ITEMS:
      return {
        ...state,
        items: state.items.filter(
          (product) => product.store_info.store_name !== action.payload.name
        ),
        sum:
          state.items.length === 0
            ? 0
            : state.sum - parseFloat(action.payload.price),
      };

    case RESET:
      return {
        ...state,
        discount: "",
        error: "",
        items: [],
        sum: 0,
        txref: "",
        isSuccess: false,
      };

    default:
      return state;
  }
};

export default reducer;
