import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Form, Button, Input, Select, Row, Col, Space, DatePicker } from "antd";
import { useMediaQuery } from "react-responsive";
import { countries } from "../../assets/constants";
import { fetchCities, fetchStates } from "../../utils/getStates";
import getLatLng from "../../utils/getLatLng";
import triggerNotification from "../../hooks/triggerNotification";

const { Option } = Select;

const CompleteBuyerAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [countryValue, setCountryValue] = useState("Nigeria");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { completeUserRegistration, fetchAddress } = bindActionCreators(
    userActionCreators,
    dispatch
  );

  const onSelectCountry = async () => {
    if (countryValue && states.length === 0) {
      const res = await fetchStates(countryValue);
      setStates(res);
    }
  };

  const getCities = async () => {
    setCities(await fetchCities("nigeria"));
  };

  const onFinish = async () => {
    const { dob, address, state, country } = form.getFieldsValue();
    const values = {
      dial: "+234",
      dob: dob.format("YYYY-MM-DD"),
      address,
      state,
      country,
    };
    const latLng = await getLatLng({ address, state, country });
    const latitude = latLng.split(",")[0];
    const longitude = latLng.split(",")[1];

    const data = {
      ...form.getFieldsValue(),
      ...values,
      latitude,
      longitude,
    };

    const res = await completeUserRegistration(data);
    if (res.status === "ok") {
      fetchAddress();
      triggerNotification({
        type: "success",
        message: res.message,
      });
      form.resetFields();
      navigate("/account");
    }
    if (res.status !== "ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  useEffect(() => {
    onSelectCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  useEffect(() => {
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="complete-account-container">
      <div className="complete-account-box">
        <h1 className="seller-welcome-txt-title">Welcome to Losode</h1>
        <p className="seller-welcome-txt-more">
          We are excited to see you join! The latest trends, awesome bargains
          and up to date fashion and style edits have been carefully curated for
          you. Fill the form below to complete your account set-up.
        </p>

        <Form
          form={form}
          initialValues={{ country: "Nigeria" }}
          name="user"
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
        >
          <Col span={!isTabletOrMobile ? 12 : 24} style={{ margin: "auto" }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "This is a required field",
                },
              ]}
            >
              <Select
                className="select"
                placeholder="Select Country"
                optionFilterProp="children"
                defaultValue={countryValue}
                value={countryValue}
                onChange={(value) => setCountryValue(value)}
                disabled
              >
                {countries.map(({ country, icon }) => (
                  <Option key={country} value={country}>
                    <img className="icon-flag" src={icon} alt={country} />
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <hr className="seller-welcome-line" />

          <h2 className="seller-welcome-txt-about">About You</h2>
          <p className="seller-welcome-txt-info">
            To get your account up and running quickly, we need some additional
            information from you
          </p>

          <Row className="form-row" gutter={[48, 12]}>
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                label="Date Of Birth"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <DatePicker className="date" placeholder="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Select className="select" placeholder="Select your gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone Number!",
                  },
                  {
                    min: 10,
                    message: "Please check the number of digits entered",
                  },
                  {
                    max: 11,
                    message: "Please check the number of digits entered",
                  },
                ]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    defaultValue={"+234"}
                    style={{
                      width: "45%",
                    }}
                  >
                    <Option key={countries[0].dial} value={countries[0].dial}>
                      <img
                        className="icon-flag"
                        src={countries[0].icon}
                        alt={countries[0].country}
                      />
                      {countries[0].dial}
                    </Option>
                  </Select>
                  <Input
                    className="input"
                    type="number"
                    placeholder="Enter phone number"
                    onChange={(e) =>
                      form.setFieldsValue({ phone: e.target.value })
                    }
                    value={form.getFieldValue("phone")}
                  />
                </Space.Compact>
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    max: 60,
                    message:
                      "Please limit your address to 60 characters or less",
                  },
                ]}
              >
                <Input className="input" placeholder="19 East Drive, Agodi" />
              </Form.Item>
            </Col>

            {(countryValue === "Nigeria" ||
              countryValue === "United States of America") && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select your state"
                    showSearch
                  >
                    {states?.map(({ state_name: state }) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {countryValue !== "United Kingdom" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select your city"
                    showSearch
                  >
                    {cities?.map((city, index) => (
                      <Option key={index} value={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {countryValue === "Nigeria" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item name="area" label="Area (Optional)">
                  <Input className="input" placeholder="Enter your area" />
                </Form.Item>
              </Col>
            )}

            {countryValue === "United Kingdom" && (
              <Col span={12}>
                <Form.Item
                  name="area"
                  label="County"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter your area" />
                </Form.Item>
              </Col>
            )}
            {countryValue === "Nigeria" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item name="postalCode" label="Postal Code (Optional)">
                  <Input
                    className="input"
                    placeholder="Enter your postal code"
                  />
                </Form.Item>
              </Col>
            )}
            {countryValue === "USA" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="postalCode"
                  label="Zip Code"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Enter your zip code" />
                </Form.Item>
              </Col>
            )}
            {countryValue === "UK" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item name="postalCode" label="Post Code (Optional)">
                  <Input className="input" placeholder="Enter your post code" />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item>
                <Button
                  className="seller-welcome-btn-submit"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <style jsx="true">{`
        .complete-account-container {
          background: rgb(249, 249, 249);
        }
        .complete-account-box {
          margin: 79px;
          padding: 44px 200px;
          background: #fff;
        }
        .seller-welcome-txt-title {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .seller-welcome-txt-more {
          font: normal normal 14px DomaineSansText-Light;
          color: #707070;
          width: 80%;
          text-align: center;
          margin: 8px auto;
          margin-bottom: 30px;
        }
        .seller-welcome-line {
          border: 0.8px solid #b7b7b7;
          opacity: 0.63;
          width: 75%;
          margin: 32px auto;
        }
        .seller-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .seller-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: #707070;
          width: ${!isTabletOrMobile ? "50%" : "85%"};
          text-align: center;
          margin: 8px auto;
        }

        .icon-flag {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 48px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 48px;
          border: 1px solid #707070;
          padding: 4px 0px 4px 16px;
          font-size: 14px;
          font-family: DomaineSansText-Light;
        }

        .complete-account-box
          .select-city.ant-select-show-search.ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector
          input {
          height: 48px;
          padding: 0px;
        }

        .complete-account-box
          .select-city.ant-select-single
          .ant-select-selector
          .ant-select-selection-item,
        .ant-select-single
          .ant-select-selector
          .ant-select-selection-placeholder {
          padding: 10px;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker {
          height: 48px !important;
          width: 100%;
        }
        .ant-picker-input > input {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }

        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }

        .btn-upload,
        .btn-upload:hover {
          background: #800000;
          color: #fff;
          height: 56px;
          width: 151px;
        }
        .seller-welcome-btn-submit {
          font-size: 18px;
          height: 56px;
          width: 50%;
          margin: 30px 25%;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-search {
          top: 10px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }

        @media (max-width: 800px) and (min-width: 640px) {
          .complete-account-box {
            padding: 24px;
            margin: 24px;
          }
        }

        @media (max-width: 640px) {
          .complete-account-box {
            padding: 16px;
            margin: 0;
            background: #fff;
          }
          .account-container {
            margin: 12px 24px;
            margin-top: 52px;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
            line-height: 1.2;
          }
          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};

export default CompleteBuyerAccount;
