import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import NewDashboard from "../pages/seller/NewDashboard";
import SellerAccount from "../pages/seller/RegisterBusiness";
import SellerVerifyEmail from "../pages/SellerVerifyEmail";
import BuyerAccount from "../pages/user/NewAccount";

const Account = () => {
  const { info, isAuthenticated } = useSelector((state) => state.user);

  const userAccount = () => {
    switch (info.role) {
      case 3:
        return <BuyerAccount />;
      case 2:
        if (isAuthenticated && info.role === 2) {
          if (info.email_verified === true) {
            if (info.has_created_store === false) {
              return (
                <Suspense fallback={null}>
                  <SellerAccount />
                </Suspense>
              );
            }
            if (info.has_created_store === true) {
              return (
                <Suspense fallback={null}>
                  <NewDashboard />
                </Suspense>
              );
            }
          }
          if (info.email_verified !== true) {
            return (
              <Suspense fallback={null}>
                <SellerVerifyEmail />
              </Suspense>
            );
          }
        }
        return;

      default:
        break;
    }
  };
  return isAuthenticated ? userAccount() : <Navigate to="/" />;
};

export default Account;
