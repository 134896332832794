import {
  GET_STORE,
  GET_STORE_VALUE,
  GET_STORE_PRODUCTS,
  GET_STORE_SALES,
  GET_STORE_MATRIX,
  GET_ORDERS,
  UPDATE_STORE_DETAILS,
  UPDATE_STORE_FAILED,
  CREATE_PRODUCT_FAILED,
  GET_TOTAL_PRODUCTS,
  GET_PRODUCT,
  GET_CATEGORIES,
  GET_STORE_DRAFTS,
  GET_DRAFT,
  CLEAR_STORE_DETAILS,
  GET_ORDER,
  GET_SIZE_GUIDE,
  GET_RETURNS_TYPE,
  GET_VACATION_MODE,
  GET_DELIVERY_OPTIONS,
  GET_SETTLEMENT_DETAILS,
  GET_BANKS,
  GET_TRANSACTION_HISTORY,
} from "./type";

const initialState = {
  banks: [],
  categories: [],
  details: {},
  deliveryOption: "",
  error: false,
  errorMessage: "",
  sizeGuide: {},
  sales: "",
  totalProducts: "",
  value: "",
  drafts: [],
  draft: {},
  products: [],
  product: {},
  returnType: [],
  orders: [],
  order: {},
  vacationMode: "",
  settlementInfo: {},
  transactions: [],
  matrix: [
    {
      name: "Jan",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Feb",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Mar",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Apr",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "May",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Jun",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Jul",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Aug",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Sep",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Oct",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Nov",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: "Dec",
      Sales: 0,
      pv: 0,
      amt: 0,
    },
  ],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANKS: {
      return { ...state, banks: action.payload };
    }
    case GET_STORE: {
      return { ...state, details: action.payload };
    }
    case GET_PRODUCT: {
      return { ...state, product: action.payload };
    }
    case GET_DRAFT: {
      return { ...state, draft: action.payload };
    }
    case GET_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    case GET_STORE_VALUE: {
      return { ...state, value: action.payload };
    }
    case GET_STORE_PRODUCTS: {
      return { ...state, products: action.payload };
    }
    case GET_STORE_DRAFTS: {
      return { ...state, drafts: action.payload };
    }
    case GET_TOTAL_PRODUCTS: {
      return { ...state, totalProducts: action.payload };
    }
    case GET_STORE_SALES: {
      return { ...state, sales: action.payload };
    }
    case GET_STORE_MATRIX: {
      return { ...state, matrix: action.payload };
    }
    case GET_ORDERS: {
      return { ...state, orders: action.payload };
    }
    case GET_ORDER: {
      return { ...state, order: action.payload };
    }
    case GET_SIZE_GUIDE: {
      return { ...state, sizeGuide: action.payload };
    }
    case GET_RETURNS_TYPE: {
      return { ...state, returnType: action.payload };
    }
    case GET_DELIVERY_OPTIONS: {
      return { ...state, deliveryOption: action.payload };
    }
    case GET_VACATION_MODE: {
      return { ...state, vacationMode: action.payload };
    }
    case GET_SETTLEMENT_DETAILS: {
      return { ...state, settlementInfo: action.payload };
    }
    case GET_TRANSACTION_HISTORY: {
      return { ...state, transactions: action.payload };
    }
    case UPDATE_STORE_DETAILS: {
      return { ...state, error: action.error, details: action.payload };
    }
    case UPDATE_STORE_FAILED: {
      return { ...state, error: action.error, errorMessage: action.payload };
    }
    case CREATE_PRODUCT_FAILED: {
      return { ...state, error: action.error, errorMessage: action.payload };
    }
    case CLEAR_STORE_DETAILS: {
      return (state = initialState);
    }
    default:
      return state;
  }
};

export default userReducer;
