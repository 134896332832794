import { FETCH_CURRENCY_SUCEESS, FETCH_CURRENCY_FAIL } from "./types";

const initialState = {
  data: {},
  error: {},
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_SUCEESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_CURRENCY_FAIL: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

export default currencyReducer;
