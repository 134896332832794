import { useEffect } from "react";
import { Checkbox } from "antd";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import BuyerSideNav from "./BuyerSideNav";
import { useMediaQuery } from "react-responsive";

const EmailPreferences = () => {
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const { emailPref, info } = useSelector((state) => state.user);

  const { activateEmailList, deactivateEmailList, getEmailPref } =
    bindActionCreators(userActionCreators, dispatch);


  const onChange = async (e) => {
    const val = e.target.checked;
    if (val) {
      await activateEmailList( info?.email);
    } else {
      await deactivateEmailList(info?.email);
    }
  };

  useEffect(() => {
    getEmailPref();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPref]);

  return (
    <div>
      <div className="account-email-page-container">
        <div className="account-email-page-header">
          <div className="account-email-page-title">Email Preferences</div>
          <div className="account-email-page-subtitle">
          Get regular update from Losode. View or edit your email preferencees
          </div>
          <div className="account-email-page-header-divider"></div>
        </div>

        <div className="account-email-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="6" />}

          <div className="account-email-content-main">
            <div className="account-email-content-main-body">
              <div>
                <Checkbox checked={emailPref} onChange={onChange}>
                  <div className="account-email-content-main-body-item-title">
                    Never miss out on Offers and Promotions
                  </div>
                  <div className="account-email-content-main-body-item-subtitle">
                    You will be first to find out about new arrivals, offers and
                    promotions from your favorite designers. In addition, you
                    will get notified on new editions of the verdict
                  </div>
                </Checkbox>
              </div>

              <div className="account-email-body-divider"></div>

              {/* <Button className="account-email-content-main-button">Update Preferences</Button> */}
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          .account-email-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-email-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-email-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-email-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-email-page-title{
            font-size:24px
          }
          .account-email-page-subtitle{
            font-size:16px
          }
          .account-email-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #d5d5d5;
            margin: 16px auto 24px auto;
          }
          .account-email-content-main-body{
            background:white;
            padding:24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
          .account-email-content-main-body-item-title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom:8px
          }
          .account-email-content-main-body-item-subtitle{
            font-size: 16px;
          }
          .account-email-content-main-button{
            padding: 0 40px;
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: fit-content;
            margin-top: 40px;
            margin-left: 40px;
          }
          .account-email-content .ant-checkbox-wrapper{
              gap:16px
          }
          .account-email-content .ant-checkbox-wrapper .ant-checkbox-inner{
              width:20px;
              height:20px
          }
          .account-email-body-divider{
            height: 2px;
            width: 100%;
            border-bottom: 1px solid #d5d5d5;
            margin: 16px auto 24px auto;
          }

          @media(max-width:640px){
            .account-email-content-main{
              margin-left:0;
              padding:0 16px;
            }
            .account-email-page-title {
              font-size: 18px;
            }
            .account-email-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-email-content-section-title{
              font-size: 16px
            }
            .account-email-content{
              margin: 0;
              background: white;
            }
            .account-email-page-container{
              background: white;
            }
            .account-email-content-main{
              padding: 0;
            }
            .account-email-content-main-body{
              padding: 16px;
            }
            .account-email-body-divider{
              display:none
            }


          }

        `}

      </style>
    </div>
  );
};

export default EmailPreferences;
