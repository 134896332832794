/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { storeActionCreators } from "../../redux/stores";

import { useMediaQuery } from "react-responsive";
import triggerNotification from "../../hooks/triggerNotification";
import { fetchCities } from "../../utils/getStates";
import getLatLng from "../../utils/getLatLng";
import axios from "axios";
import { categoryOptions, countries } from "../../assets/constants";
import capitalizeStr from "../../utils/capitalizeStr";

const SellerAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const country = capitalizeStr(searchParams?.get("country"));
  const state = capitalizeStr(searchParams?.get("state"));
  const [cities, setCities] = useState([]);
  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const { createStore } = bindActionCreators(storeActionCreators, dispatch);

  const [showModal, setShowModal] = useState(false);

  const getCities = async () => {
    setCities(await fetchCities("nigeria"));
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name);
    uploadImage(fileUploaded);
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };

  useEffect(() => {
    form.setFieldsValue({
      brand_logo_url: fileName,
    });
  }, [fileName]);

  useEffect(() => {
    getCities();
  }, []);

  const onFinish = async (values) => {
    const { brand_address: address } = values;
    const resp = await getLatLng({ address, state, country });
    values.brand_latitude = resp?.split(",")[0];
    values.brand_longitude = resp?.split(",")[1];
    values.brand_logo_url = imageUrl;
    const data = {
      unique_id: id,
      ...values,
    };

    const res = await createStore(data);

    if (res.status === "ok") {
      setShowModal(true);
      form.resetFields();
      setTimeout(() => navigate("/sell"), 5000);
    }
    if (res.status !== "ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const SuccessModal = () => {
    return (
      <div className="apply-to-join-success-overlay">
        <div className="apply-to-join-success-div">
          <h3>Congratulations</h3>
          <p style={{ padding: "0 10px" }}>
            Your registration is now complete. You can sign in to our platform
            using your login details and an email will be sent to you for
            further instructions.
          </p>
        </div>
      </div>
    );
  };
  return (
    <div>
      {showModal && <SuccessModal />}
      <div className="create-store-container">
        <div className="apply-to-join">
          <header className="apply-to-join-navbar-content">
            <div className="apply-to-join-navbar-div">
              <div className="apply-to-join-navbar-logo">
                <img src="/images/losode-no-tagline.png" alt="losode-logo" />
              </div>
            </div>
          </header>
        </div>
        <section className="apply-to-join-section">
          <div className="apply-to-join-section-content">
            <div className="title-section">
              <h1 className="seller-welcome-txt-title">Welcome to Losode</h1>
              <p className="seller-welcome-txt-more">
                Your journey to owning a globally trading business is filled
                with promise. Take the next step by completing your registration
                below. This will set up your store and open the doors to selling
                your items to a vast community of eager customers.
              </p>
            </div>
            <div className="apply-to-join-section-form">
              <Form
                form={form}
                initialValues={{ brand_country: country, brand_state: state }}
                name="user"
                onFinish={onFinish}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
              >
                <Row
                  className="form-row"
                  gutter={[!isTabletOrMobile ? 48 :0, 0]}
                  style={{
                    background: "white",
                    padding: !isTabletOrMobile ? "50px" : "12px",
                    marginBottom: !isTabletOrMobile ? 40 : 24,
                    marginTop: 0,
                  }}
                >
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item
                      name="brand_country"
                      label="Country"
                      labelAlign="top"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select country-select"
                        placeholder="Your country"
                        optionFilterProp="children"
                        value={country}
                        disabled
                      >
                        <Select.Option key={country} value={country}>
                          <img
                            className="icon-flag icon-flag-country"
                            src={countries[0]?.icon}
                            alt={country}
                          />
                          {country}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <hr className="seller-welcome-line" />
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_description"
                      label="Tell us about your brand"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className="input"
                        autoSize={{ minRows: 2, maxRows: 5 }}
                        style={{
                          padding: "12px",
                          minHeight: "80px",
                          border: "1px solid #0000007a",
                        }}
                        placeholder="Give a detailed description of your brand"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_logo_url"
                      label="Upload Brand Logo"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Use a professionally designed logo"
                        onChange={uploadImage}
                        suffix={
                          <>
                            <Button
                              className="btn-upload"
                              onClick={handleClick}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "end",
                                }}
                              >
                                <img alt="upload" src="/images/upload.png" />
                                <span
                                  style={{
                                    fontSize: `${
                                      !isTabletOrMobile ? "14px" : "12px"
                                    }`,
                                  }}
                                >
                                  Browse
                                </span>
                              </div>
                            </Button>
                            <input
                              accept="image/*"
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: "none" }}
                            />
                          </>
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_address"
                      label="Address"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your brand address"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_state"
                      label="State"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Your brand state"
                        value={state}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_city"
                      label="City"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Select your city"
                        showSearch
                      >
                        {cities?.map((city, index) => (
                          <Select.Option key={index} value={city}>
                            {city}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_has_website"
                      label="Do you have a website?"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        className="select"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <Select.Option value="yes">Yes</Select.Option>
                        <Select.Option value="no">No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {selectedOption === "yes" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brand_website_url"
                        label="Please provide your website detail"
                        labelAlign="top"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          placeholder="www.example.com"
                          className="input"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_postal_code"
                      label="Postal Code (Optional)"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                    >
                      <Input className="input" placeholder="Enter post code" />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_established_date"
                      label="When was your brand Established"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          type: "object",
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <DatePicker className="reg-date" />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brand_what_i_sell"
                      label="What Does Your Brand Sell?"
                      labelAlign="top"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select categories"
                        optionLabelProp="label"
                        className="select-multiple"
                        value={selectedItems}
                        onChange={setSelectedItems}
                      >
                        {categoryOptions.map((category) => (
                          <Select.Option
                            key={category}
                            value={category}
                            label={category}
                          >
                            <Checkbox>{category}</Checkbox>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <hr className="seller-welcome-line" />
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          pattern:
                            /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                          message:
                            "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                        },
                      ]}
                    >
                      <Input.Password
                        className="input-password"
                        // placeholder="Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="confirm_password"
                      label="Confirm password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              "The passwords that you entered do not match!"
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="input-password"
                        // placeholder="Confirm Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="form-row" gutter={[!isTabletOrMobile ? 48 :0, 0]}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: !isTabletOrMobile ?"10px":0,
                      marginLeft: !isTabletOrMobile ?"20px":0,
                      padding:!isTabletOrMobile ? 0 :"12px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form.Item
                      name="agreed_seller_terms"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Checkbox
                        onChange={(e) =>
                          form.setFieldsValue({
                            agreed_seller_terms: e.target.checked,
                          })
                        }
                      >
                        <p
                          style={{
                            fontSize: !isTabletOrMobile ?"18px":"16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            margin: 0,
                            width: "100%",
                            fontFamily: "DomaineSansText-Light",
                          }}
                        >
                          I confirm that I have read and accepted the Losode{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            <Link to="/sell-terms">Seller Agreement</Link>{" "}
                          </span>
                        </p>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="btn-bank-submit"
                        type="primary"
                        htmlType="submit"
                      >
                        Create Your Store
                      </Button>
                    </Form.Item>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </section>
      </div>

      <style jsx="true">{`
        .create-store-container {
          background: rgb(249, 249, 249);
        }
        .apply-to-join {
          font-family: "DomaineSansText-Light";
          background: #f9f9f9;
        }
        .icon-flag-country {
          margin-right: 24px;
        }
        .apply-to-join-navbar-content {
          display: flex;
          justify-content: center;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
          box-shadow: 0 2px 10px 0 #05050526;
          z-index: 1000;
          position: fixed;
          top: 0;
        }

        .title-section {
          background: white;
          padding: 50px 50px 0;
          margin: 0 -24px;
        }

        .apply-to-join-navbar-div {
          width: 80%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .apply-to-join-navbar-logo img {
          height: 30px;
          width: 180px;
          object-fit: contain;
        }

        .apply-to-join-navbar-title {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          height: 100%;
          gap: 8px;
        }

        .apply-to-join-section {
          width: 100%;
          padding-top: 100px;
          padding-bottom: 30px;
        }

        .btn-upload {
          margin-right: 0px !important;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #000000;
          color: #fff;
          height: 54px;
          width: 130px;
        }
        .apply-to-join-section-content {
          width: 80%;
          margin: 20px auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title {
          background-color: #fff;
          width: 100%;
          margin: 0px auto 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .apply-to-join-section-content-title h3 {
          font-size: 24px;
          font-family: "DomaineSansText-Black";
          font-weight: normal;
          margin-bottom: 4px;
        }
        .apply-to-join-section-content-title p {
          text-align: center;
          width: 500px;
          font-weight: 500;
          font-size: 16px;
        }
        .apply-to-join-section-form {
          width: 100%;
        }

        .apply-to-join-section-form .ant-input-affix-wrapper {
          padding: 0px 0px 0px 10px;
        }

        .seller-welcome-txt-title {
          font-family: DomaineSansText-Regular;
          font-size: 24px;
          font-weight: 700;
          line-height: 31.03px;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .seller-welcome-txt-more {
          font: 300 normal 18px DomaineSansText-Light;
          color: #000;
          line-height: 29.88px;
          text-align: center;
          margin: 8px auto;
          width: 80%;
        }
        .seller-welcome-line {
          border: 0.8px solid #b7b7b7;
          opacity: 0.63;
          width: 100%;
          margin: 20px auto 32px;
        }
        .seller-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .seller-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: #707070;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input-password .ant-input-suffix {
          margin-right: 10px;
        }
        .input,
        .input-password {
          height: 56px;
          border: 1px solid #707070;
          padding: 4px 0px 4px 16px;
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker {
          height: 56px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 56px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .ant-picker-input > input {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-radio-group {
          height: 56px;
          display: flex;
          align-items: center;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }
        .ant-radio-wrapper {
          margin-right: 40px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          height: 56px;
          padding: 0px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 18px;
        }

        .btn-bank-submit {
          background: #000;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.69px;
          width: 338px;
          height: 56px;
          margin-top: 30px;
          font-family: DomaineSansText-Regular;
        }

        .btn-bank-submit:hover {
          background: #fff;
          border: 1px solid #000;
          color: #000;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          margin-top: 60px;
          padding: 30px 50px;
          width: 50%;
        }
        .apply-to-join-success-div h3 {
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px DomaineSansText-Light;
          color: white;
          text-align: center;
          line-height: 30px;
        }

        @media (max-width: 1200px) and (min-width: 640px) {
          .account-container {
            padding: 24px;
            margin: 24px;
          }
        }

        @media (max-width: 640px) {
          .account-container {
            padding: 52px 24px 40px;
            margin: 0;
          }
          .input,
          .ant-select,
          .ant-input-affix-wrapper > input.ant-input,
          span.ant-radio + *,
          .ant-picker-input > input {
            font-size: 14px;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .seller-welcome-txt-more,
          .seller-welcome-txt-info {
            width: 100%;
            font-size: 16px;
            line-height: 1.5;
          }
          .form-row {
            row-gap: 16px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 54px;
            width: 100px;
            margin: 0;
            border-radius: 0;
            padding: 5px;
          }
          .btn-upload span {
          }
          line-height: 1.2;

          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            background: #000000;
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .apply-to-join-success-div {
            width: auto;
            margin: 12px;
            padding: 20px;
          }
          .apply-to-join-success-overlay {
            align-items: center;
            padding: 24px;
          }
          .apply-to-join-section-content{
            width:100%
          }
          .title-section {
            padding: 0 12px;
            margin: 0;
          }
          .ant-form-item-explain-error{
            padding-top:4px
          }
          .seller-welcome-line{
            margin: 16px auto;
          }
          .btn-bank-submit{
            width: calc(100vw - 24px);
          }
        }
      `}</style>
    </div>
  );
};

export default SellerAccount;
