import { useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { storeActionCreators } from "../../redux/stores";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import triggerNotification from "../../hooks/triggerNotification";
// import { useMediaQuery } from "react-responsive";

const HelpFeedback = ({sideNavFeedbackVisible, setSideNavFeedbackVisible}) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

 
  const { submitFeedback } = bindActionCreators(storeActionCreators, dispatch);

  const onSubmitFeedback = async (value) => {
    const res = await submitFeedback( { rating: value });
    if (res.status === "ok") {
      setFeedbackModalVisible(false);
      triggerNotification({
        message:'Thank you for reviewing Losode! Your feedback is valuable to us',
        type:'success'
      })
    } else{
      console.log(res)
      triggerNotification({
        message:'We could not send your feedback at this time. Try again later',
        type:'error'
      })
    }
  };

  const changeFAQsTab =(value) =>{
    if (value){
      localStorage.setItem("FAQsTab", value)
    }
  }

  const feedbackIcons = [
    {
      rating:5,
      inactiveIcon:'./images/beaming face with smiling eyes.png',
      activeIcon:'./images/active beaming face with smiling eyes.png'
    },
    {
      rating:4,
      inactiveIcon:'./images/slightly smiling face.png',
      activeIcon:'./images/active slightly smiling face.png'
    },
    {
      rating:3,
      inactiveIcon:'./images/neutral face.png',
      activeIcon:'./images/active neutral face.png'
    },
    {
      rating:2,
      inactiveIcon:'./images/frowning face.png',
      activeIcon:'./images/active frowning face.png'
    },
    {
      rating:1,
      inactiveIcon:'./images/angry face.png',
      activeIcon:'./images/active angry face.png'
    },
  ]

  // const isTabletOrMobile = useMediaQuery({maxWidth:640})

  return (
    <div>
      <div className="help-banner">
        
        <div
          // onClick={() => navigate("/sell", {state:{section:'faq', device:isTabletOrMobile?'mobile':'pc'}})}
          style={{ background: "black", padding: "7px" }}
          onClick={()=>changeFAQsTab("seller")}
        >
          <Link to="/faqs" target="_blank" style={{color:'white'}}>
            FAQs
          </Link>
        </div>
        <div
          style={{
            writingMode: "vertical-rl",
            margin: "5px 0px",
            background: "black",
            padding: "10px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setFeedbackModalVisible(true)}
        >
          Feedback
        </div>
      </div>

      <Modal
        centered
        visible={sideNavFeedbackVisible?sideNavFeedbackVisible:feedbackModalVisible}
        footer={null}
        onCancel={() => sideNavFeedbackVisible? setSideNavFeedbackVisible(false):setFeedbackModalVisible(false)}
        width={700}
        style={{ fontFamily: "DomaineSansText-Light" }}
        title={
          <div style={{display:'flex', alignItems:'center', gap:'16px', paddingTop:'16px'}}>
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13V15C12 15.2652 11.8946 15.5196 11.7071 15.7071C11.5196 15.8946 11.2652 16 11 16H4L1 19V9C1 8.73478 1.10536 8.48043 1.29289 8.29289C1.48043 8.10536 1.73478 8 2 8H4M19 12L16 9H9C8.73478 9 8.48043 8.89464 8.29289 8.70711C8.10536 8.51957 8 8.26522 8 8V2C8 1.73478 8.10536 1.48043 8.29289 1.29289C8.48043 1.10536 8.73478 1 9 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div style={{lineHeight:'1'}}>Feedback</div>
          </div>
        }
        closeIcon={
          <CloseCircleOutlined
            style={{
              color: "#000",
              padding: "28px 16px 0px 0px",
              fontSize: "20px",
            }}
          />
        }
      >
        <div className='feedback-modal-content'>
          <div className='feedback-title'>
            We value your feedback
          </div>
          <div className='feedback-subtitle'>
            Tell us about your experience on Losode today
          </div>
          <div className="feedback-icon-all-container" >
           {
              feedbackIcons?.map((item, index)=>(
                <div className="feedback-icon-contain" key={index}>
                  <div className="feedback-icon" id={`feeback-icon-${index}`} onClick={() => onSubmitFeedback(item.rating)}/>
                  <style jsx="true">
                    {`
                      #feeback-icon-${index} {
                        background-image:url("${item.inactiveIcon}");
                      }
                      #feeback-icon-${index}:hover {
                        background-image:url("${item.activeIcon}");
                      }
                    `}
                  </style>
                </div>
              ))
            } 
          </div>
          {/* <div
            style={{
              marginTop: "40px",
              display: "flex",
              gap: "8px",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                background: "black",
                color: "white",
                height: "47px",
                padding: "0 50px",
                fontSize: "16px",
              }}
            >
              Submit
            </Button>
          </div> */}
        </div>
      </Modal>

      <style jsx="true">{`
        .help-banner {
          position: fixed;
          color: white;
          top: 70%;
          right: 0;
          cursor: pointer;
          font-size: 12px;
          z-index:2000
        }
        .feedback-icon-all-container{
          display: flex;
          margin-top: 80px;
          margin-bottom: 30px;
          padding: 0px 20px;
          min-height: 80px;
          justify-content: space-between;
        }
        .feedback-icon-contain{
          min-width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .feedback-icon {
          cursor: pointer;
          width:60px;
          height:60px;
          transition: width 0.2s, height 0.2s, background-image 0.2s;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .feedback-icon:hover{
          width:80px;
          height:80px;
        }
        .feedback-modal-content{
          padding: 0px 20px;
          font-size: 18px;
          text-align: center;
        }
        .feedback-title{
          font-weight: bold;
          font-size: 32px;
          font-family: DomaineSansText-Regular
        }
        .feedback-modal-cancel-button{
          color: rgb(0, 0, 0);
          padding: 15px 10px 0px 0px;
          font-size: 30px;
        }
        .feedback-subtitle{
          font-size:20px;
          color:#6f6f6f
        }


        @media (max-width: 640px) {
          .help-banner {
            display:none
          }
          .feedback-modal-content{
            padding: 0
          }
          .feedback-title{
            font-weight: bold;
            font-size: 16px;
          }
          .feedback-modal-cancel-button{
            font-size: 24px;
          }
          .feedback-icon-all-container{
            margin-top: 40px;
            margin-bottom: 10px;
            padding: 0px 8px;
            min-height: 40px
          }
          .feedback-icon-contain{
            min-width: auto;
          }
          .feedback-icon {
            width:40px;
            height:40px
          }
          .feedback-icon:hover{
            width:60px;
            height:60px;
          }
          .feedback-subtitle{
            font-size:14px
          }
        }
      `}</style>
    </div>
  );
};

export default HelpFeedback;
