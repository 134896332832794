import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Button, Tag } from "antd";
import { useMediaQuery } from "react-responsive";
import BuyerSideNav from "./BuyerSideNav";
import { ExclamationCircleFilled } from "@ant-design/icons";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useSelector((state) => state.user);

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // if (!isTabletOrMobile) navigate("/account");

  const {  orders } = useSelector((state) => state.user);

  const { fetchOrders, fetchSortedOrders } = bindActionCreators(
    userActionCreators,
    dispatch
  );

  const viewOrder = async (id) => {
    const res = await fetchSortedOrders( id);
    if (res.status === "ok") {
      navigate(`/orders/${id}/details`);
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Status = ({ order }) => {
    const status = order?.delivery_status;
    let color = "success";

    if (status?.toLowerCase() === "paid") color = "#8ed6cb";
    if (status?.toLowerCase() === "sent") color = "orange";
    if (
      status?.toLowerCase() === "cancelled" ||
      status?.toLowerCase() === "rejected"
    )
      color = "#D00202";
    return (
      <Tag
        color={color}
        className="account-orders-list-item-status"
       
      >
        {status}
      </Tag>
    );
  };

  return (
    <div>
      <div className="account-orders-page-container">
        <div className="account-orders-page-header">
          {/* <img
              src="/images/back-arrow.png"
              alt=""
              className="back-icon"
              onClick={() => navigate(-1)}
            /> */}
          <div className="account-orders-page-title">My Orders</div>
          {/* <div className="account-orders-page-subtitle">Manage your delivery addresses here</div> */}
          <div className="account-orders-page-header-divider"></div>
        </div>

        <div className="account-orders-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="1" />}

          <div className="account-orders-content-main">
            <div className="account-orders-list">
              {orders && orders?.length > 0 ? (
                orders?.map((order, idx) => (
                  <div className="account-orders-list-item" key={idx}>
                    <div className="account-orders-list-item-heading">
                      {order.delivery_status === "Paid"
                        ? `Your Order has been Confirmed`
                        : `Your Order has been ${order.delivery_status}`}
                    </div>
                    <Status order={order} />
                    <div className="account-orders-list-item-subheader">
                      <div className="account-orders-list-item-subheader-designer">
                        Designer: <strong>{order.designer}</strong>{" "}
                      </div>
                      <div className="account-orders-list-item-subheader-item-number">
                        {order.item_quantity}{" "}
                        {order?.item_quantity > 1 ? "Items" : "Item"}
                      </div>
                    </div>
                    <div className="account-orders-list-item-divider"></div>
                    <div className="account-orders-list-item-subsection">
                      <div className="account-orders-list-item-subsection-image-section">
                        {order.order_images?.slice(0, 3).map((img, idx) => (
                          <div
                            className="account-orders-list-item-subsection-image"
                            key={idx}
                            style={{ backgroundImage: `url(${img})` }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="account-orders-list-item-divider"></div>
                    <div className="account-orders-list-item-details">
                      <div className="account-orders-list-item-details-table-container">
                        <table className="account-orders-list-item-details-table">
                          <tbody>
                            <tr>
                              <td className="account-orders-list-item-details-table-info-label">
                                Item Quantity:
                              </td>
                              <td className="account-orders-list-item-details-table-info-data">
                                {order.item_quantity}
                              </td>
                            </tr>
                            <tr>
                              <td className="account-orders-list-item-details-table-info-label">
                                Order Number:
                              </td>
                              <td className="account-orders-list-item-details-table-info-data">
                                {order.sale_no}
                              </td>
                            </tr>
                            <tr>
                              <td className="account-orders-list-item-details-table-info-label">
                                Shipped Date:
                              </td>
                              <td className="account-orders-list-item-details-table-info-data">
                                {order.shipped_date}
                              </td>
                            </tr>
                            {order?.delivery_date ? (
                              <tr>
                                <td className="account-orders-list-item-details-table-info-label">
                                  Delivery date:
                                </td>
                                <td className="account-orders-list-item-details-table-info-data">
                                  {order.delivery_date}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td className="account-orders-list-item-details-table-info-label">
                                  Scheduled Delivery date:
                                </td>
                                <td className="account-orders-list-item-details-table-info-data">
                                  {order.scheduled_delivery_date}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "120px",
                          justifyContent: "space-between",
                        }}
                      >
                        {!isTabletOrMobile && (
                          <Button
                            className="account-orders-list-item-details-view-btn"
                            onClick={() => viewOrder(order.sale_no)}
                          >
                            View Order
                          </Button>
                        )}
                        {!isTabletOrMobile && (
                          <Button
                            disabled
                            className="account-orders-list-item-subsection-track-btn"
                            onClick={() =>
                              window
                                .open(
                                  order?.history?.packages_tracking_url,
                                  "_blank"
                                )
                                .focus()
                            }
                          >
                            Track Parcel
                          </Button>
                        )}
                      </div>
                    </div>
                    {/* <div className="account-orders-list-item-exchange-info">
                      The seller does not allow returns or exchanges on this
                      item. You can request an amendment on this order.
                    </div> */}
                    {isTabletOrMobile && (
                      <div>
                        <Button
                          className="account-orders-list-item-details-view-btn"
                          onClick={() => viewOrder(order.sale_no)}
                        >
                          View Order
                        </Button>
                        <Button
                          disabled
                          className="account-orders-list-item-subsection-track-btn"
                          onClick={() =>
                            window
                              .open(
                                order?.history?.packages_tracking_url,
                                "_blank"
                              )
                              .focus()
                          }
                        >
                          Track Parcel
                        </Button>
                      </div>
                    )}
                    <div className="account-orders-list-item-divider"></div>
                  </div>
                ))
              ) : (
                <div className="no-order-div">
                  <ExclamationCircleFilled style={{ fontSize: 50 }} />
                  <div className="no-order-text">
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      You do not currently have any orders
                    </div>
                    <div>
                      Once you have checked out, you can view and track your
                      order here
                    </div>
                    <div
                      style={{
                        color: "#6F6F6F",
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => shopNewIn()}
                    >
                      Shop New In
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="no-order-div">
              <ExclamationCircleFilled style={{ fontSize: 50 }} />
              <div className="no-order-text">
                You haven’t placed any order(s), when you do their status will
                appear here.
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <style>
        {`
          .account-orders-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-orders-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-orders-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-orders-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-orders-page-title{
            font-size:24px
          }
          .account-orders-page-subtitle{
            font-size:16px
          }
          .account-orders-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #e4e4e4;
            margin: 16px auto 24px auto;
          }


          .no-order-div{
            background: white;
            padding: 20px;
            font-size: 16px;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
          }

          .no-order-text{
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            justify-content: center;
            max-width: 330px;
            text-align: center;
          }

          .account-orders-list{
            display:flex;
            gap:40px;
            flex-direction: column;
          }
          .account-orders-list-item{
            background: white;
            padding: 24px;
            max-width: 800px;
          }
          .account-orders-list-item-heading{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .account-orders-list-item-status{
            font-size: 12px;
            font-weight:bold;
            padding: 4px;
            margin-bottom: 8px;
            width:100px;
            text-align:center;

          }
          .account-orders-list-item-subheader{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
          }
          .account-orders-list-item-subheader-item-number{
            font-weight:bold
          }
          .account-orders-list-item-divider{
            border: 1px solid #f4f4f4;
            margin: 16px 0;
          }
          .account-orders-list-item-subsection, .account-orders-list-item-details{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
          }
          .account-orders-list-item-subsection-image-section{
            display: flex;
            gap: 16px;
          }
          .account-orders-list-item-subsection-image{
            height: 130px;
            width: 130px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
          .account-orders-list-item-subsection-track-btn, .account-orders-list-item-details-view-btn{
            background: black;
            width: 200px;
            color: white;
            font-size: 14px;
            height: 48px;
          }
          .account-orders-list-item-details-table {
            border-collapse: unset;
          }
          .account-orders-list-item-details-table td {
            padding: 0 20px 20px 0;
          }
          .account-orders-list-item-details-table-info-label {
            color: #707070;
          }
          .account-orders-list-item-details-table-info-data{
            font-family: 'DomaineSansText-Black'
          }
          .account-orders-list-item-exchange-info{
            font-size: 14px;
            font-weight: bold;
          }

          @media(max-width:640px){
            .account-orders-content{
              margin-top:0
            }
            .account-orders-content-main{
              margin-left:0;
              padding:0;
            }
            .account-orders-page-title {
              font-size: 18px;
            }
            .account-orders-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-orders-list-item{
              padding:16px
            }
            .account-orders-list-item-details-table td{
              padding:0px 0px 20px 0
            }
            .account-orders-list-item-details-table,
            .account-orders-list-item-details-table-container{
              width:100%
            }
            .account-orders-list-item-subsection-track-btn, .account-orders-list-item-details-view-btn{
              width:100%;
              margin-top: 16px;
            }
            .account-orders-list-item-subsection-track-btn{
              background:white;
              color:black
            }
            .account-orders-list{
              gap:0
            }
            .account-orders-page-container{
              background:white
            }

          }

        `}
      </style>
    </div>
  );
};

export default Orders;
