import api from "../../api";

import { FETCH_CURRENCY_SUCEESS, FETCH_CURRENCY_FAIL } from "./types";

export const fetchCurrency = (code) => {
  localStorage.setItem("currency", code);
  return async (dispatch) => {
    try {
      const { data } = await api.currency.fetchCurrency(code);
      if (data?.status === "ok") {
        dispatch({
          type: FETCH_CURRENCY_SUCEESS,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CURRENCY_FAIL,
        payload: err,
      });
    }
  };
};
