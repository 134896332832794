import { createRef } from "react";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";

const Quality = () => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
  };

  const carouselRef = createRef();

  const items = [
    {
      title: "Quick & Secure Deliveries",
      desc: "We have taken the utmost care in defining efficient logistics processes ",
      url: "/images/icon-truck.svg",
    },
    {
      title: "Fair Returns Policy",
      desc: "Designers have fair policies in place to protect customers",
      url: "/images/icon-returns.svg",
    },
    {
      title: "Easy to Use Platform",
      desc: "Navigate the Losode Platform with ease ",
      url: "/images/icon-search.svg",
    },
    {
      title: "24/7 Support",
      desc: "Visit our contact us page for all the ways to reach us ",
      url: "/images/icon-support.svg",
    },
  ];

  return (
    <div className="quality-container">
      <h1 className="quality-title">Quality remains our utmost priority</h1>
      <h4 className="quality-desc">
        <span className="quality-desc-bold">
          ‘What you see is definitely, what you get'.
        </span>
        We understand how important this is to our customers and our business and
        have put in measures to ensure every purchase leaves you completely
        satisfied or we will rectify it with ease.
      </h4>

      {!isTabletOrMobile && <div className="quality-items-container">
        {items.map((item, idx) => (
          <div className="quality-item-div" key={idx}>
            <img className="quality-icon" alt={item.title} src={item.url} />
            <h1 className="quality-item-title">{item.title}</h1>
            <h4 className="quality-item-desc">{item.desc}</h4>
          </div>
        ))}
      </div>}

      {isTabletOrMobile && (
            <div style={{marginTop:"24px", marginBottom:"80px"}}>
              <Carousel {...carouselSettings} ref={carouselRef}>
                {items.map((item, idx) => (
                  <div>
                    <div className="quality-item-div" key={idx}>
                      <img className="quality-icon" alt={item.title} src={item.url} />
                      <h1 className="quality-item-title">{item.title}</h1>
                      <h4 className="quality-item-desc">{item.desc}</h4>
                    </div>
                  </div>
                ))}
              
              </Carousel>
            </div>
          )}

      <style jsx="true">{`
        .quality-container {
          background: #fff;
          padding: 32px 24px;
        }
        .quality-title {
          font: normal 400 37px DomaineSansText-Black;
          line-height: 60px;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
        .quality-desc {
          font: normal 300 15px DomaineSansText-Light;
          line-height: 25px;
          width: 64%;
        }
        .quality-desc-bold {
          font: normal 400 15px DomaineSansText-Black;
        }
        .quality-items-container {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .quality-item-div {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 20%;
        }
        .quality-icon {
          width: 36px;
          height: 36px;
        }
        .quality-item-title {
          font: normal 700 18px DomaineSansText-Black;
          line-height: 25px;
          text-align: center;
          margin-top: 8px;
          text-transform: capitalize;
        }
        .quality-item-desc {
          font: normal 300 15px DomaineSansText-Light;
          line-height: 25px;
          text-align: center;
        }

        @media(max-width:640px){
          .quality-container {
            padding: 0 16px;
            margin: 24px 0;
          }
          .quality-title {
            font-size: 16px;
            font-family: 'DomaineSansText-Regular';
            line-height: normal;
          }
          .quality-desc {
            width: 100%;
            line-height: 22px;
            font-size: 14px;
          }
          .quality-desc-bold {
            font-size: 14px;
          }
          .quality-items-container{       
            flex-direction: column;
            gap: 24px;
            margin-bottom: 60px;
            margin-top: 24px;
          }
          .quality-item-div{
            width:100%
          }
          .quality-item-title{
            font-family: 'DomaineSansText-Regular';
            font-weight: 400;
            margin-bottom: 2px;
            font-size: 16px;
          }
          .quality-item-desc{
            font-size: 14px;
            width: 80%;
          }
        }

      `}</style>
    </div>
  );
};

export default Quality;
