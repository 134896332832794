import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import productsReducer from "./products/products.reducer";
import productReducer from "./product/product.reducer";
import bestSellerReducer from "./bestSellers/bestSellers.reducer";
import cartReducer from "./cart/cart.reducer";
import currencyReducer from "./currency/currency.reducer";
import userReducer from "./user/user.reducer";
import brandsReducer from "./brands/brands.reducer";
import storeReducer from "./stores/store.reducer";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["cart"],
};
const rootReducer = combineReducers({
  products: productsReducer,
  product: productReducer,
  bestSellers: bestSellerReducer,
  cart: cartReducer,
  currency: currencyReducer,
  user: userReducer,
  brands: brandsReducer,
  store: storeReducer,
});
export default persistReducer(persistConfig, rootReducer);
