import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { useSelector } from "react-redux";

import { Button, Radio } from "antd";

import CartItems from "../components/CartItems";
import formatter from "../utils/formatter";

const Shipping = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [totalPrice, setPrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [count, setCount] = useState(0);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  if (!isTabletOrMobile) navigate("/checkout");

  const currency = useSelector((state) => state.currency[0]);
  const { items, checkoutItems } = useSelector((state) => state.cart);

  const onRadioChange = (e, idx) => {
    checkoutItems[idx].delivery_option = checkoutItems[
      idx
    ].delivery_detail.find((option) => option.id === e.target.value);
    shippingTotal();
    checkoutItems[idx].ordered_quantity = items[idx].quantityWanted;
    checkoutItems[idx].brand = items[idx].store_info?.store_name;
    checkoutItems[idx].product_price = items[idx].totalPrice;
    setValue(e.target.value);
  };

  const sumTotal = () => {
    const totalPrices = [];
    items.map((item) => totalPrices.push(parseFloat(item.totalPrice)));
    const total = totalPrices.reduce((a, b) => a + b, 0);
    setPrice(total);
  };

  const shippingTotal = () => {
    const totalPrices = [];
    checkoutItems?.map((item) =>
      item.delivery_option
        ? totalPrices.push(parseFloat(item.delivery_option?.price))
        : null
    );
    const total = totalPrices.reduce((a, b) => a + b, 0);
    setShippingPrice(total);
  };

  const grandTotal = () => {
    const sum = totalPrice + shippingPrice;
    return formatter(sum, currency?.rate, currency?.code, currency?.indicator);
  };

  const updateCount = (num) => setCount(num);

  useEffect(() => {
    sumTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <div>
      <div className="page-title">
        <img
          src="/images/back-arrow.png"
          alt=""
          className="back-icon"
          onClick={() => navigate(-1)}
        />
        <h3 className="txt-title">Shipping</h3>
      </div>
      <div className="mobile-shipping-container">
        {items?.map((item, idx) => (
          <div className="shipping-details-container" key={idx}>
            <div className="index-container">
              <h3 className="txt-shipping-index">
                Shipment {idx + 1} of {items.length}
              </h3>
              <h3 className="txt-shipping-state">Lagos</h3>
            </div>
            <CartItems
              item={item}
              currency={currency}
              updateCount={updateCount}
              isDelete={false}
            />
            <h3 className="txt-del-options">Delivery options</h3>
            <Radio.Group
              className="delivery-options"
              onChange={(e) => onRadioChange(e, idx)}
              value={value}
            >
              {checkoutItems[idx]?.delivery_detail?.map((option, idx) => (
                <Radio
                  className="radio-del-options"
                  value={option.id}
                  key={idx}
                >
                  <div className="del-options-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "71vw",
                      }}
                    >
                      <h3 className="txt-option-name">{option.name}</h3>
                      <h3 className="txt-option-price">
                        {formatter(
                          option.price,
                          currency?.rate,
                          currency?.code,
                          currency?.indicator
                        )}
                      </h3>
                    </div>
                    <h3 className="del-option-desc">
                      {option.description} No delivery on Public Holidays. All
                      orders are subject to Customs and Duty charges, payable by
                      the recipient of the order.
                    </h3>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          </div>
        ))}
      </div>
      <div className="checkout-container">
        <div className="total-price-div">
          <div>
            <h3 className="txt-subtotal">
              Subtotal:{" "}
              <strong>
                {formatter(
                  totalPrice,
                  currency?.rate,
                  currency?.code,
                  currency?.indicator
                )}
              </strong>
            </h3>
            <h3 className="txt-subtotal">
              Shipping:{" "}
              <strong>
                {formatter(
                  shippingPrice,
                  currency?.rate,
                  currency?.code,
                  currency?.indicator
                )}
              </strong>
            </h3>
          </div>
          <h3 className="txt-subtotal">
            Total: <strong>{grandTotal()}</strong>
          </h3>
        </div>
        <Button
          className="btn-checkout"
          type="primary"
          onClick={() =>
            navigate("/payment", {
              checkoutItems,
              totalPrice,
              shippingPrice,
            })
          }
        >
          Pay and Confirm order
        </Button>
      </div>

    </div>
  );
};

export default Shipping;


