import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button, Select } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

const Option = Select;

const RecentViews = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  if (!isTabletOrMobile) navigate("/account");

  const items = [
    {
      name: "BRAND NAME",
      brand: "Loose Knit 3/4 sleeve, with chiffon stiches",
      imgUrl: "/images/pexels-rodnae.png",
    },
    {
      name: "BRAND NAME",
      brand: "Loose Knit 3/4 sleeve, with chiffon stiches",
      imgUrl: "/images/pexels-rodnae.png",
    },
  ];

  return (
    <div>
      <div className="page-title">
        <img
          src="/images/back-arrow.png"
          alt=""
          className="back-icon"
          onClick={() => navigate(-1)}
        />
        <h3 className="txt-title">Recently Viewed</h3>
      </div>
      <div className="items-container">
        {items.map((item) => (
          <div className="item-container">
            <img src={item.imgUrl} alt="" className="item-img" />
            <h4 className="item-brand">{item.name}</h4>
            <h4 className="item-name">{item.brand}</h4>
            <Select
              className="size-select"
              placeholder="Select Size"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="XS">XS</Option>
              <Option value="S">S</Option>
              <Option value="M">M</Option>
              <Option value="L">L</Option>
              <Option value="XL">XL</Option>
              <Option value="XXL">XXL</Option>
            </Select>
            <Button
              className="btn-add-to-bag"
              icon={
                <ShoppingOutlined
                  className="btn-add-icon"
                  style={{ float: "right" }}
                />
              }
              type="primary"
            >
              Add to bag
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentViews;
