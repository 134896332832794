import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Modal, Button, Input } from "antd";
// import { bindActionCreators } from "redux";
// import { useDispatch } from "react-redux";
// import { userActionCreators } from "../redux/user";
// import { useMediaQuery } from "react-responsive";
// import { CloseCircleFilled } from "@ant-design/icons";
// import Notification from "./Notification";

export default function KidsClosedModal({
    kidsClosedModalVisible,
    // setKidsClosedModalVisible
}){

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
  
    const [email, setEmail] = useState("");
    // const [showError, setShowError] = useState(false);
    // const [subscribedModalVisible, setSubscribedModalVisible] = useState(false);
    // const [notifModalVisible, setNotifModalVisible] = useState(false);
  
    // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  
    // const { joinMailList } = bindActionCreators(userActionCreators, dispatch);
  
    const onChange = (e) => setEmail(e.target.value);
  
    // const subscribe = async (option) => {
    //   if (email === "") setShowError(true);
    //   const value = { email, sub_type: option };
    //   const res = await joinMailList(value);
    //   if (res.status === "ok") {
    //     setSubscribedModalVisible(false);
    //     setEmail("");
    //     setNotifModalVisible(true);
    //     setTimeout(() => setNotifModalVisible(false), 3000);
    //   }
    // };

    return(
        <>
          <Modal
            centered
            visible={kidsClosedModalVisible}
            closable={false}
            footer={null}
            // width={!isTabletOrMobile ? "50vw" : "94%"}
            // onCancel={() => setKidsClosedModalVisible(false)}
            className="website-closed-modal"
            // closeIcon={
            //   <CloseCircleFilled
            //     style={{
            //       color: "#000",
            //       fontSize: "20px",
            //       position: "relative",
            //       bottom: "8px",
            //       left: "8px",
            //     }}
            //   />
            // }
        >
            <div className="closed-subscribed-container" style={{display:'flex', alignItems:'center', background:"url(/images/kids-closed-bg.png)"}}>
              <div className="kids-modal-text-container"> 
                <div className="closed-subscribed-title">The Kids Store</div>
                <div className="closed-subscribed-subtitle">
                    <div style={{fontWeight:'bold', fontFamily: 'DomaineSansText-Black', margin: "16px 0 8px", fontSize:'16px'}}>Brilliant styles your kids will love</div>
                    <div style={{marginTop:'4px'}} >Your kids won't remain this age forever, they deserve all the love and care.</div>
                    <div style={{marginTop:'4px'}}>Our kids' category will be launching soon. Join the list to be reminded when we are live.</div>
                </div>

                <div className="closed-subscribe-btn-container">
                    <Input
                    className="closed-container-subscribe-input"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={onChange}
                    type="email"
                    />
                    {/* {showError && (
                    <p style={{ fontSize: 12, color: "red" }}>
                        Please use a valid email address
                    </p>
                    )} */}
                    <div className="closed-subscribe-btn-container-singles">
                    <Button
                        className="closed-subscribe-btn"
                        // onClick={() => subscribe("buyer")}
                    >
                        Subscribe
                    </Button>
                    {/* <Button
                        className="closed-subscribe-btn"
                        onClick={() => subscribe("seller")}
                    >
                        The Seller List
                    </Button> */}
                    </div>

                    {/* <Button
                    className="closed-subscribe-btn both-button"
                    onClick={() => subscribe("both")}
                    >
                    Buyer and Seller Lists
                    </Button> */}
                </div>

              </div>
                <img src="/images/kid-jump.png" alt="" width={"240px"} height="fit-content" className="kid-jump-image"/>
           
            </div>
        </Modal>
        {/* <Notification
            visible={notifModalVisible}
            title="Thank You !"
            message="You are now subscribed to Losode, and your preferences saved"
        /> */}

<style jsx="true">
        {`
          .ant-modal {
            color: #000;
            font-family: "DomaineSansText-Light";
          }
          .website-closed-modal .ant-modal-body {
            padding: 0px;
            // height: 70vh;
          }
          .closed-container {
            display: flex;
            font-family: "DomaineSansText-Light";
            font-size: 18px;
            display: flex;
            position: relative;
          }
          .closed-style-title {
            position: absolute;
            text-align: center;
            width: 100%;
            font-size: 54px;
            font-family: "DomaineSansText-Black";
            margin-top: 16px;
            line-height: 58px;
          }

          .subscribe-error {
            color: #800000;
            font-size: 11px;
            font-family: "DomaineSansText-Light";
            margin-top: -11px;
            margin-bottom: 12px;
            margin-left: 1px;
          }
          // .form-container {
          //   flex: 1;
          //   padding: 80px 0px 0px 40px;
          // }
          .closed-container-title {
            font-family: "DomaineSansDisplay-SemiBold";
            text-transform: capitalize;
            font-size: 80px;
            line-height: 90px;
          }
          .closed-container-image {
            background: url(https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1659085471/website-pictures-optimized/popup_1-no_input_1_ua4rqc.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;
            flex: 1;
            height: 70vh;
          }
          .closed-container-subscribe-btn {
            height: 44px;
            width: 36%;
            max-width: 360px;
            background: black;
            color: white;
            // margin-top: 10px;
            font-size: 18px;
          }
          .ant-btn[disabled],
          .ant-btn[disabled]:hover,
          .ant-btn[disabled]:focus,
          .ant-btn[disabled]:active {
            background: black;
            color: white;
          }
          .closed-container-subscribe-input {
            height: 40px;
          }
          .closed-container-subscribe-form-container {
            gap: 16px;
            max-width: 500px;
            margin: auto;
            margin-top: 20px;
          }
          .closed-container-body-text {
            font-family: "DomaineSansText-Light";
            font-size: 18px;
            width: 90%;
            word-spacing: 4px;
          }
          .closed-subscribed-container {
            padding: 24px;
            margin: auto;
          }
          .closed-subscribed-title {
            font-family: 'DomaineSansDisplay-SemiBold';
            font-size: 46px;
            margin-bottom: 8px;
            line-height: 1.3;
            letter-spacing: 3px;
          }
          .closed-subscribed-subtitle {
            font-size: 14px;
          }
          .closed-subscribe-btn-container-singles {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
          }
          .closed-subscribe-btn {
            height: 40px;
            background: black;
            color: white;
            font-family: "DomaineSansText-Light";
          }
          .both-button {
            width: 100%;
          }
          .closed-subscribe-btn-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px auto;
          }

          @media(min-width: 640px){
            .seller-container-closed div, .buyer-container-closed div{
              height: 84px;
            }
            .kid-jump-image{
              position:absolute;
              width:60%;
              right:0
            }
            .kids-modal-text-container{
              width:50%
            }
          }
          @media (max-width: 1024px) {
            .seller-container-closed, .buyer-container-closed{
              height: 70vh;
              background-position: center;
            }
            .seller-container-closed div, .buyer-container-closed div{
              width:80%
            }
            .closed-container-subscribe-btn{
              width:45%
            }
          }

          @media (max-width: 640px) {
            .website-closed-modal .ant-modal-body {
              // height: 50vh;
              margin: 16px auto;
            }
            // .form-container {
            //   padding: 50px 0px 0px 20px;
            // }
            .closed-container {
              gap: 5px;
              display: block;
              padding-bottom: 18px;
            }
            .closed-style-title {
              font-size: 24px;
              line-height: normal;
              position: relative;
              padding-top: 18px;
              margin: 0 auto 12px;
            }
            .buyer-container-closed,
            .seller-container-closed {
              height: 475px;
              margin: 0px 18px;
              width: auto;
            }
            .seller-container-closed div,
            .buyer-container-closed div {
              margin-top: 0;
              font-size: 16px;
              width: 90%;
              min-width: 20px;
              padding-top: 24px;
            }
            .seller-container-closed {
              margin-top: 24px;
            }
            .closed-container-title {
              font-size: 20px;
              position: relative;
              line-height: normal;
            }
            .closed-container-image {
              background: url(https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1659084662/website-pictures-optimized/Rectangle_1032_li9co7.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              flex: 1;
              height: 50vh;
            }
            .closed-container-body-text {
              padding: 0px;
              font-size: 14px;
            }
            .closed-container-subscribe-form-container {
              margin-top: 10px;
              width: 100%;
            }
            .closed-container-subscribe-btn {
              height: 40px;
              width: 55%;
              font-size: 14px;
            }
            .closed-subscribe-btn {
              width: 120px;
              height: 40px;
            }
            .both-button {
              width: 100%;
            }
            .kid-jump-image{
              display:none
            }
          }
        `}
      </style>
      </>
    )
}