import {
  fetchOneProduct as fetchProductApi,
  swapImage as swapImageApi,
  deleteImage as deleteImageApi,
} from "../../api/productsApi";

import {
  FETCH_PRODUCT_FAIL,
  FETCH_PRODUCT_START,
  FETCH_PRODUCT_SUCCESS,
} from "./types";

export const fetchProduct = (slug, code) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_START });
    try {
      const product = await fetchProductApi(slug, code);
      dispatch({
        type: FETCH_PRODUCT_SUCCESS,
        payload: product,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCT_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const swapImage = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await swapImageApi(token, data);
      if (res.status === "ok") {
        dispatch(fetchProduct(data.slug));
      }
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCT_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};

export const deleteImage = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await deleteImageApi(token, data);
      if (res.status === "ok") {
        dispatch(fetchProduct(data.slug));
      }
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCT_FAIL,
        payload: err,
        error: true,
      });
    }
  };
};


