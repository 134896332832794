import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';

const DiscountModal = ({showDiscountModal, setShowDiscountModal}) => {
  

    const onFinish = (values) => {
        console.log('Form submitted:', values);
    };

    const closeDiscountModal = () => {
      setShowDiscountModal(false);
      // window.localStorage.setItem("newUsers", "false");
    }

    useEffect(() => {
      if(!window.localStorage.getItem("boughtItem")){
      //  setShowDiscountModal(true);
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // useEffect(() => {
    //   if(!window.localStorage.getItem("newUsers") || window.localStorage.getItem("boughtItem") === "false"){
    //    setShowDiscountModal(true);
    //    window.localStorage.setItem("newUsers", "true");
    //    window.localStorage.setItem("boughtItem", "false");
    // } else {
    //   setShowDiscountModal(false)
    // }
    // },[])

  return (<>
    {showDiscountModal && (
      <div className="discount-overlay">
      <div className="discount-container">
          <img src="/images/discount_modal.png" className="discount-img" alt="discount-modal" />
          <div className="discount-content">
              <h1>Get 10% off your first purchase</h1>
              <p style={{marginBottom: "2px"}}>To access your discount code now enter your email address below.</p>
              <p style={{color: "#6F6F6F"}}>Terms and conditions apply</p>
          </div>
          <div className="discount-form-container">
          <Form onFinish={onFinish} className="discount-form">
              <Form.Item 
              name="email"
              rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please enter your E-mail!",
                  },
                ]}
              >
                  <Input className="input" placeholder="Enter Email Address" />
              </Form.Item>

              <Form.Item>
                  <Button className="discount-btn" type="primary" htmlType="submit">
                  Get Discount
                  </Button>
              </Form.Item>
          </Form>
          </div>
          <Button
          className="discount-modal-cancel"
          shape="circle"
          onClick={closeDiscountModal}
          icon={
          <svg width="54" height="54" viewBox="0 0 54 54" fill="black" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27" cy="27" r="27" fill="black"/>
            <path d="M34.7137 19.2852L19.2852 34.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.2852 19.2852L34.7137 34.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          }
          />
      </div>
      <style jsx="true">{`
          .discount-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 1500;
            }
          .discount-container {
            width: 70%;
            height: 600px;
            background: #F9F9F9;
            position: relative;
            font-family:DomaineSansText-Light;
          }
          .discount-img {
            width: 100%;
            height: 55%;
            object-fit:cover;
          }
          .discount-content {
            text-align: center;
            color: #000000;
            padding: 20px 0;
          }
          .discount-content h1 {
            font: normal 500 40px DomaineSansDisplay-SemiBold;
            text-align: center;
            letter-spacing: 0.03em;
            margin: 0;
            padding: 0;
            margin-bottom: 15px;
          }
          .discount-content p {
            font: normal 400 16px DomaineSansText-Light;
            text-align: center;
            padding: 0 0 4px;
            margin: 0;
          }
          .discount-form {
            display: flex;
            justify-content: center;
            padding-top: 20px;
          }
          .input {
            width: 380px;
            height: 45px;
            background: #FFFFFF;
            border: 1px solid #000000;
            margin-right: 5px;
          }
          .input:placeholder {
            font: normal 400 14px DomaineSansText-Regular;
            color: #92959E;
          }
          .discount-btn {
            width: 180px;
            height: 45px;
            font: normal 400 16px DomaineSansText-Regular;
            margin-left: 5px;
          }
          .discount-modal-cancel {
          position: absolute;
          right: 9px;
          top: 5%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          }

          .discount-container .ant-form-item-explain-error{
            margin-top:4px          }

          @media(min-width:768px) and (max-width: 1024px) {
            .discount-container {
              width: 95%;
            }
            .discount-img {
              height: 50%;
            }
            .discount-content h1 {
              font: normal 500 42px DomaineSansDisplay-Bold;
            }
            .discount-content p {
              font: normal 400 20px DomaineSansText-Regular;
            }
            .input {
              width: 380px;
              height: 45px;
              background: #FFFFFF;
              border: 1px solid #000000;
              margin-right: 5px;
            }
            .discount-btn {
              width: 150px;
              margin-left: 5px;
            }
          }
          @media(max-width:640px){
            .discount-container {
              width: 95%;
            }
            .discount-img {
              height: 35%;
            }
            .discount-content h1 {
              font: normal 500 30px DomaineSansDisplay-Bold;
            }
            .discount-content p {
              font: normal 400 16px DomaineSansText-Regular;
            }
            .discount-form {
              flex-direction: column;
              align-items: center;
              padding-top: 10px;
            }
            .input {
              width: 350px;
              height: 45px;
              margin-right: 0px;
            }
            .discount-btn {
              width: 150px;
              margin-left: 0px;
            }
          }
      `}</style>
  </div>
    )}
  </>
  )
}

export default DiscountModal