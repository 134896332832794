import { createRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const StylistChoice = () => {
  // const navigate = useNavigate();

  // const shopBrand = (id, slug) => {
  //   navigate(`/brands/id=${id}&name=${slug}`);
  // };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [activeSlide, setActiveSlide] = useState(0);

  // const shopStylistChoice = (id, slug) => {
  //   navigate(`/brands/id=${id}&name=${slug}`);
  // };

  const carouselSettings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
    afterChange: (current) => setActiveSlide(current),
  };

  const carouselRef = createRef();

  const brands = [
    {
      id: 181,
      title: "Kaliki By Ete",
      imageUrl:
        "./images/kids_choice_1.jpg",
      slug: "Kaliki By Ete",
    },
    {
      id: 381,
      title: "Ero",
      imageUrl:
        "./images/kids_choice_2.jpg",
      slug: "Ero",
    },
    {
      id: 381,
      title: "Elizabetha",
      imageUrl:
        "./images/kids_choice_3.jpg",
      slug: "elizabetha",
    },
    {
      id: 5,
      title: "Kola Kuddus",
      imageUrl:
        "./images/kids_choice_4.jpg",
      slug: "Kola-Kuddus",
    },
  ];

  return (
    <div className="choice-container">
      <div className="cta-btn-box">
      <span className="txt-new-title">EXQUISITE FASHION</span>
        <h1 className="txt-style-main">Shop From The Best Male Clothiers</h1>
        <h3 className="txt-style-desc">
        Carefully selected and specifically curated pieces from independent designers across Africa. Never seen style
        </h3>
        <Button className="btn-shop-choice">
        Shop our Stylists’ Picks
        </Button>
      </div>
      {!isTabletOrMobile && <div className="cards-container">
        {brands.map((brand) => (
          <div
            className="brand-card"
            key={brand.imageUrl}
            // onClick={() => shopBrand(brand.id, brand.slug)}
          >
            <img className="brand-image" alt="brand" src={brand.imageUrl} loading="lazy" />
            <h2 className="brand-title">{brand.title}</h2>
          </div>
        ))}
      </div>
      }
      {isTabletOrMobile && (
            <div>
              <Carousel {...carouselSettings} ref={carouselRef}>
              {brands.map((brand) => (
                <div
                  className="brand-card"
                  key={brand.imageUrl}
                  // onClick={() => shopBrand(brand.id, brand.slug)}
                >
                  <img className="brand-image" alt="brand" src={brand.imageUrl} loading="lazy" />
                  <h2 className="brand-title">{brand.title}</h2>
                </div>
              ))}
              
              </Carousel>

              <div className="process-icon-div-mobile">
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide > 0) carouselRef.current.prev();
                  }}
                >
                  <LeftOutlined className="process-icon" />
                </div>
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide !== 3) carouselRef.current.next();
                  }}
                >
                  <RightOutlined className="process-icon" />
                </div>
              </div>
              <div style={{textAlign:'center', marginTop:'40px'}}>
                <Button className="mobile-btn-new-in">
                Shop our Stylists’ Picks
                </Button>
              </div>

              
            </div>
          )}

      <style jsx="true">
        {`
          .choice-container {
            align-items: center;
            display: flex;
            margin: 60px 0;
            padding: 0 24px;
          }
          .txt-style-main {
            font: normal normal 32px DomaineSansText-Regular;
            margin-bottom: 16px;
          }
          .txt-style-desc {
            font: normal normal 16px DomaineSansText-Light;
            line-height: 27px;
            margin-bottom: 32px;
            padding-right: 4px;
          }
          .btn-shop-choice {
            width: 254px;
            height: 48px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 17px DomaineSansText-Light;
          }

          @media(max-width:640px){
            .choice-container{
              padding: 0 16px;
              display: block;
              margin: 24px 0;
            }
            .txt-style-main{
              font-size: 16px;
              margin-bottom: 4px;
            }
            .txt-style-desc {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 8px;
            }
            .btn-shop-choice{
              display:none
            }
          
          }
        `}
      </style>
    </div>
  );
};

export default StylistChoice;
