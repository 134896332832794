import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, Button, Drawer } from "antd";
import { DownOutlined } from "@ant-design/icons";
import AuthModal from "../AuthModal"

const SellerHeaderMobile = ({
  scrollToRef,
  myTipsRef,
  myFAQRef,
  ourSellersRef,
  myBasicRef,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);
  const [sellerSignInAuthModalVisible, setSellerSignInAuthModalVisible] = useState(false);

  const accountMenu = (
    <Menu className="seller-account-menu">
      <Link
        className="btn-dropdown"
        style={{ backgroundColor: "#cccccc" }}
        to=""
        onClick={() => setSellerAuthModalVisible(true)}
      >
        Join Losode
      </Link>
      <Link
        to=""
        className="btn-dropdown"
        onClick={() => setSellerSignInAuthModalVisible(true)}
      >
        Sign In
      </Link>
    </Menu>
  );

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const signIn = () => {
    onClose();
    setSellerSignInAuthModalVisible(true);
  }

  const joinLosode = () => {
    onClose();
    setSellerAuthModalVisible(true);
  }

  const scrollToRefMobile = (ref) => {
    onClose();
    scrollToRef(ref);
  }

  // const ourSellersRef = useRef(null);
  // const myTipsRef = useRef(null);
  // const myFAQRef = useRef(null);

  return (
    <div className="seller-mobile-nav-container">
      <AuthModal
        authModalVisible={sellerAuthModalVisible}
        setAuthModalVisible={setSellerAuthModalVisible}
        activePage="2"
        pageUserType="seller"
      />
      <AuthModal
        authModalVisible={sellerSignInAuthModalVisible}
        setAuthModalVisible={setSellerSignInAuthModalVisible}
        activePage="1"
        pageUserType="seller"
      />

      <div className="sellpage-discount-header">
          <p className="sellpage-vText">
            New Designers! 10% off our affordable photoshoots package. Book your
            session{" "}
            <a
              className="sellpage-discount-link"
              href="/book-photoshoot"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: "bold" }}
            >
              here
            </a>
          </p>
        </div>

      <div className="seller-mobile-nav-menu-container">
        <div className="seller-menu">
          <Dropdown overlay={accountMenu} placement="bottomLeft">
            <div style={{ marginLeft: 20 }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.6663 28V25.3333C26.6663 23.9188 26.1044 22.5623 25.1042 21.5621C24.104 20.5619 22.7475 20 21.333 20H10.6663C9.25185 20 7.8953 20.5619 6.8951 21.5621C5.89491 22.5623 5.33301 23.9188 5.33301 25.3333V28"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0003 14.6667C18.9458 14.6667 21.3337 12.2789 21.3337 9.33333C21.3337 6.38781 18.9458 4 16.0003 4C13.0548 4 10.667 6.38781 10.667 9.33333C10.667 12.2789 13.0548 14.6667 16.0003 14.6667Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <DownOutlined
                style={{ marginLeft: 5, fontSize: 13, fontWeight: 600 }}
              />
            </div>
          </Dropdown>
        </div>

        <img
          src="/images/losode-no-tagline.png"
          alt="logo"
          className="logo"
          onClick={() => navigate("/")}
        />

        <Button
          className="btn-drawer"
          style={{}}
          icon={
            <img
              className="menu-icon"
              alt="drawer"
              src="/images/open-drawer.png"
            />
          }
          onClick={showDrawer}
          ghost
        />

        <Drawer
          className="main-drawer mobile-navbar"
          placement="left"
          width="100%"
          onClose={onClose}
          visible={visible}
        >
          <div style={{ position: "absolute", top: "10px", left: "16px" }}>
            {/* <img src="/images/mobile-drawer-logo.png" alt="logo" onClick={()=>navigate('/')} /> */}
            <div className="mobile-navbar-logo" onClick={() => navigate("/")}>
              <svg
                width="110"
                height="38"
                viewBox="0 0 110 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_21937_32770)">
                  <path
                    d="M3.94913 31.1145H-0.235352V5.00195H3.94913V31.1145Z"
                    fill="black"
                  />
                  <path
                    d="M8.05894 21.849C8.05894 19.2892 9.02584 16.8342 10.7469 15.0242C12.4681 13.2141 14.8024 12.1973 17.2364 12.1973C19.6704 12.1973 22.0047 13.2141 23.7258 15.0242C25.4469 16.8342 26.4138 19.2892 26.4138 21.849C26.4138 27.3114 22.3036 31.4846 17.2167 31.4846C16.011 31.4951 14.8153 31.2529 13.6995 30.7723C12.5836 30.2917 11.57 29.5823 10.7176 28.6854C9.86514 27.7885 9.19105 26.7221 8.73459 25.5483C8.27814 24.3746 8.04845 23.1171 8.05894 21.849ZM12.4947 21.849C12.4947 28.6006 14.1903 31.1422 17.2189 31.1422C20.2475 31.1422 21.9453 28.6006 21.9453 21.849C21.9453 15.0215 20.2496 12.4822 17.2189 12.4822C14.1882 12.4822 12.4947 15.0307 12.4947 21.849Z"
                    fill="black"
                  />
                  <path
                    d="M31.9711 15.8276C31.9711 17.7235 33.1598 18.445 35.288 19.015L38.7143 19.9617C41.9242 20.881 43.9083 22.161 43.9083 25.1967C43.9083 28.9884 40.8054 31.4933 36.4789 31.4933C32.8713 31.4933 29.6264 29.5583 28.8311 26.2974L29.1916 26.1457C29.9476 29.4067 33.2668 31.1141 36.4767 31.1141C39.1098 31.1141 41.2359 29.7858 41.2359 27.32C41.2359 25.3851 39.7216 24.5509 37.7026 24.0201L34.1671 23.0733C31.3264 22.3126 29.515 20.8718 29.515 18.1026C29.515 14.5752 32.5457 12.1484 36.4767 12.1484C39.5359 12.1484 41.9941 13.5135 42.9315 16.3217L42.6082 16.4733C41.6336 13.7042 39.3611 12.5276 36.4767 12.5276C33.8087 12.5276 31.9711 13.9317 31.9711 15.8276Z"
                    fill="black"
                  />
                  <path
                    d="M46.7201 21.849C46.7201 20.5815 46.9575 19.3264 47.4187 18.1554C47.8799 16.9844 48.5559 15.9204 49.4081 15.0242C50.2603 14.1279 51.272 13.417 52.3855 12.932C53.4989 12.4469 54.6923 12.1973 55.8975 12.1973C57.1027 12.1973 58.2961 12.4469 59.4096 12.932C60.523 13.417 61.5347 14.1279 62.387 15.0242C63.2392 15.9204 63.9152 16.9844 64.3764 18.1554C64.8376 19.3264 65.075 20.5815 65.075 21.849C65.075 27.3114 60.9648 31.4846 55.8779 31.4846C54.6721 31.4951 53.4765 31.2529 52.3606 30.7723C51.2448 30.2917 50.2311 29.5823 49.3787 28.6854C48.5263 27.7885 47.8522 26.7221 47.3957 25.5483C46.9393 24.3746 46.7096 23.1171 46.7201 21.849ZM51.1558 21.849C51.1558 28.6006 52.8515 31.1422 55.88 31.1422C58.9086 31.1422 60.6064 28.6006 60.6064 21.849C60.6064 15.0215 58.9108 12.4822 55.88 12.4822C52.8493 12.4822 51.1558 15.0307 51.1558 21.849Z"
                    fill="black"
                  />
                  <path
                    d="M81.7388 5.00195H85.9233V31.1145H81.8831L81.7388 26.4862C81.0177 29.2439 78.7452 31.4937 75.5004 31.4937C71.5322 31.4937 67.8896 27.8903 67.8896 22.0097C67.8896 16.129 71.3858 12.1488 75.5725 12.1488C78.6316 12.1488 80.9828 14.1964 81.7388 17.1172V5.00195ZM81.776 19.4796C81.6667 15.8372 79.5406 13.1439 76.9076 13.1439C73.9861 13.1439 72.3626 16.7863 72.3626 21.943C72.3626 27.0998 73.9489 30.5538 76.8704 30.5538C79.3593 30.5538 81.6777 27.9754 81.776 24.2204V19.4796Z"
                    fill="black"
                  />
                  <path
                    d="M98.979 31.5011C94.5782 31.5011 90.0332 27.8978 90.0332 21.9803C90.0332 15.9503 94.2527 12.1562 98.7977 12.1562C104.027 12.1562 106.948 16.2146 106.948 21.0312H94.469V22.0792C94.469 27.8449 96.3809 30.8806 100.133 30.8806C103.054 30.8806 105.687 28.9824 106.47 25.2665L106.83 25.3424C105.939 29.2559 103.126 31.5011 98.979 31.5011ZM94.469 20.6521H102.772C102.772 15.647 101.87 12.4987 98.8042 12.4987C96.4531 12.4918 94.6853 14.7254 94.469 20.6452V20.6521Z"
                    fill="black"
                  />
                  <path
                    d="M18.4929 34.3184H15.9844V37.8504H18.4929V34.3184Z"
                    fill="black"
                  />
                  <path
                    d="M54.4365 10.3012L57.3645 5.00195H54.4365V10.3012Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_21937_32770">
                    <rect width="109.571" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              {/* <img src="/images/losode-no-tagline.png" alt="losode-logo"  /> */}
            </div>
          </div>
          <div className="seller-drawer-menus">
            {/* <div className="seller-menu-sign-in-options">
                    <div className="seller-menu-sign-in" onClick={signIn} >Sign In</div>
                    <div className="seller-menu-join" onClick={joinLosode}>Join Losode</div>
                </div> */}
            <div className="seller-menu-links">
              <div
                className="seller-menu-link"
                onClick={() => scrollToRefMobile(myBasicRef)}
              >
                Get Started
              </div>
              <div
                className="seller-menu-link"
                onClick={() => scrollToRefMobile(myTipsRef)}
              >
                Tips For Listing
              </div>
              <div
                className="seller-menu-link"
                onClick={() => scrollToRefMobile(myFAQRef)}
              >
                FAQS
              </div>
              <div
                className="seller-menu-link"
                onClick={() => scrollToRefMobile(ourSellersRef)}
              >
                Our Sellers
              </div>
              <div className="seller-menu-link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/book-photoshoot"
                >
                  Book A Photoshoot
                </a>
              </div>

              <div
                className="seller-menu-link"
                style={{
                  fontFamily: "DomaineSansText-Regular",
                  fontWeight: "bold",
                  width: "fit-content",
                  borderTop: "1px solid #d4d4d4",
                  marginTop: "38px",
                  paddingTop: "16px",
                }}
                onClick={() => navigate("/")}
              >
                The Losode Marketplace
              </div>
            </div>

            {/* {!isAuthenticated && ( */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
              }}
            >
              <Button
                style={{
                  width: "100%",
                  height: "40px",
                  marginTop: "15px",
                  background: "#000",
                  color: "#fff",
                }}
                onClick={signIn}
              >
                Sign In
              </Button>
              <Button
                style={{ width: "100%", height: "40px", marginTop: "15px" }}
                onClick={joinLosode}
              >
                Join Losode
              </Button>
            </div>
            {/* )} */}
          </div>
        </Drawer>
      </div>

      <style jsx="true">{`
      .seller-mobile-nav-container{
          background: #fff;
          // position: fixed;
          top: 0;
          width: 100%;
          z-index: 1000;
      }
        .seller-mobile-nav-menu-container {
          align-items: center;
          background: #fff;
          display: flex;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
        }
        // .mobile-navbar.main-drawer .ant-drawer-header{
        //   box-shadow: 0 2px 10px 0 #05050526;
        // }
        .ant-drawer-close {
          color: black;
        }
        .seller-menu {
          position: absolute;
          right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .sign-in-option {
          cursor: pointer;
        }
        .logo {
          cursor: pointer;
          width: 112px;
          width: 102px;
          padding: 12px 0;
        }
        .btn-drawer {
          position: absolute;
          left: 16px;
        }
        .main-drawer {
          font-family: DomaineSansText-Light;
          color: black;
        }
        .main-drawer .ant-drawer-body {
          padding: 16px;
        }
        .main-drawer .ant-drawer-close {
          right: 0;
          margin: 0 0 8px;
          padding: 0;
        }
        .main-drawer .ant-drawer-header-title {
          justify-content: flex-end;
        }
        .seller-account-menu {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin-top: 20px;
        }

        .btn-dropdown {
          width: 120px;
          padding: 7px 0;
          text-align: center;
          margin: 0;
          font-family: DomaineSansText-Regular;
          font-size: 14px;
        }
        .ant-dropdown {
          position: fixed;
        }
        .seller-drawer-menus {
          width: 100%;
        }
        .seller-menu-sign-in-options {
          font-size: 16px;
          width: 100px;
        }
        .seller-menu-sign-in {
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
        }
        .seller-menu-join {
          padding: 10px 0;
        }
        .seller-menu-links {
          font-size: 14px;
          padding: 10px 0;
        }
        .seller-menu-link {
          padding: 10px 0;
          font: normal 300 15px/146.16% DomaineSansText-Light;
        }
        .seller-menu-contacts {
          width: 120px;
          border-top: 1px solid #ccc;
          font-size: 16px;
          padding-top: 10px;
        }

        .seller-mobile-nav-container .sellpage-discount-header {
          padding: 8px 12px;
          text-align: center;
          height: auto;
        }
        .seller-mobile-nav-container .sellpage-vText {
          font-size: 12px;
          margin: 0 auto;
        }
      `}</style>
    </div>
  );
};

export default SellerHeaderMobile;
