export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_SELLER_SUCCESS = "REGISTER_SELLER_SUCCESS";
export const REGISTER_STORE_START = "REGISTER_STORE_START";
export const REGISTER_STORE_SUCCESS = "REGISTER_STORE_SUCCESS";
export const REGISTER_STORE_FAIL = "REGISTER_STORE_FAIL";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const GET_STORE = "GET_STORE";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_DRAFT = "GET_DRAFT";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_STORE_FAILED = "GET_STORE_FAILED";
export const GET_STORE_PRODUCTS = "GET_STORE_PRODUCTS";
export const GET_STORE_DRAFTS = "GET_STORE_DRAFTS";
export const GET_TOTAL_PRODUCTS = "GET_TOTAL_PRODUCTS";
export const GET_STORE_SALES = "GET_STORE_SALES";
export const GET_STORE_VALUE = "GET_STORE_VALUE";
export const GET_STORE_MATRIX = "GET_STORE_MATRIX";
export const GET_STORE_PRODUCTS_FAILED = "GET_STORE_PRODUCTS_FAILED";
export const GET_STORE_SALES_FAILED = "GET_STORE_SALES_FAILED";
export const GET_STORE_VALUE_FAILED = "GET_STORE_VALUE_FAILED";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const GET_COMPLETED_ORDERS_FAILED = "GET_COMPLETED_ORDERS_FAILED";
export const GET_PENDING_ORDERS_FAILED = "GET_PENDING_ORDERS_FAILED";
export const GET_CANCELED_ORDERS_FAILED = "GET_CANCELED_ORDERS_FAILED";
export const UPDATE_STORE_DETAILS = "UPDATE_STORE_DETAILS";
export const UPDATE_STORE_FAILED = "UPDATE_STORE_FAILED";
export const CLEAR_STORE_DETAILS = "CLEAR_STORE_DETAILS";
export const GET_SIZE_GUIDE = "GET_SIZE_GUIDE";
export const GET_RETURNS_TYPE = "GET_RETURNS_TYPE";
export const GET_DELIVERY_OPTIONS = "GET_DELIVERY_OPTIONS";
export const GET_VACATION_MODE = "GET_VACATION_MODE";
export const GET_SETTLEMENT_DETAILS = "GET_SETTLEMENT_DETAILS";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_BANKS = "GET_BANKS";
export const GET_PHOTOSHOOTS = "GET_PHOTOSHOOTS";
