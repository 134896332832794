import { Carousel } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

const Verdict = () => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div className="verdict-container">
      {!isTabletOrMobile ?
      <div className="verdict-images-container">
        <img src={"/images/losode-no-tagline.png"} style={{position:"absolute", height:'70px', top:'-35px', left:'32px'}} alt="" />
        <img src={"/images/fashion-banner-mobile-1.png"} alt="fashion-banner" className="verdict-box box-1" />
        <img src={"/images/fashion-banner-mobile-2.png"} alt="fashion-banner" className="verdict-box box-2" />
        <img src={"/images/fashion-banner-mobile-3.png"} alt="fashion-banner" className="verdict-box box-3" />
        <img src={"/images/fashion-banner-mobile-4.png"} alt="fashion-banner" className="verdict-box box-4" />
        <div style={{position:"absolute", font:'normal 400 5vw DomaineSansText-Regular', color:'white', bottom:'32px', left:'32px'}}>Fashion for <br/> Everyone</div>
      </div>
        :
      <div style={{position:"relative"}}>
      <Carousel effect="fade" autoplay dots={false}>
        <img src={"/images/fashion-banner-mobile-1.png"} alt="fashion-banner" className="verdict-box" />
        <img src={"/images/fashion-banner-mobile-2.png"} alt="fashion-banner" className="verdict-box" />
        <img src={"/images/fashion-banner-mobile-3.png"} alt="fashion-banner" className="verdict-box" />
        <img src={"/images/fashion-banner-mobile-4.png"} alt="fashion-banner" className="verdict-box" />
      </Carousel>
      <img src={"/images/losode-no-tagline.png"} style={{position:"absolute", height:'40px', top:'-20px', left:'16px'}} alt="" />
      <div style={{position:"absolute", font:'normal 400 40px DomaineSansText-Regular', color:'white', bottom:'60px', left:'20px'}}>Fashion for <br/> Everyone</div>
      </div>
      }
      <style jsx="true">{`
        .verdict-container {
          position: relative;
          margin:64px 100px 40px;
        }
        .verdict-images-container {
          position:relative;
          transition: 800ms;
          display: grid;
          grid-template-columns: 2.5fr 1fr 1fr 1fr;
        }

        .verdict-box {
          width: 100%;
          transition: 300ms;
          object-fit: cover;
          height: calc(55vw - 75px);
          object-position: center;
        }
        .box-2{
        object-position: left;
        }

        .verdict-images-container:has(.box-2:hover) {
          grid-template-columns: 1fr 2.5fr 1fr 1fr;
        }
        .verdict-images-container:has(.box-3:hover) {
          grid-template-columns: 1fr 1fr 2.5fr 1fr;
        }
        .verdict-images-container:has(.box-4:hover) {
          grid-template-columns: 1fr 1fr 1fr 2.5fr;
        }

        @media (max-width: 640px) {
          .verdict-container {
            margin: 24px 16px;
          }
          .verdict-box {
            height: 100%;
          }

        }
      `}</style>
    </div>
  );
};

export default Verdict;
