export default class Store {
  constructor(client) {
    this.client = client;
  }

  createStore(values) {
    return this.client.post("application/register", values);
  }

  createProduct(values) {
    return this.client.post("seller/create/product", values);
  }

  createDraft(values) {
    return this.client.post("seller/listing/draft", values);
  }

  getProduct(id) {
    return this.client.get(`seller/single/listing/${id}`);
  }

  getDraft(id) {
    return this.client.get(`seller/view/single/draft/${id}`);
  }

  getCategories() {
    return this.client.get("categories");
  }

  getStore() {
    return this.client.get("seller/store/details");
  }

  getStoreProducts() {
    return this.client.get("seller/listing");
  }

  getStoreDrafts() {
    return this.client.get("seller/listing/callback");
  }

  getTotalProducts() {
    return this.client.get("seller/total/products");
  }

  getTotalSales() {
    return this.client.get("seller/total/sales");
  }

  getStoreValue() {
    return this.client.get("seller/store/value");
  }

  getOrders() {
    return this.client.get(`seller/order/list`);
  }

  getOrder(id) {
    return this.client.get(`seller/order/info/${id}`);
  }

  resetPassword(values) {
    return this.client.post("seller/reset/password ", values);
  }

  updateStore(values) {
    return this.client.put("seller/update/brand", values);
  }

  updateSellerDetails(values) {
    return this.client.post("seller/update/details", values);
  }

  updateProduct(values) {
    return this.client.post(`seller/update/product`, values);
  }

  fetchBanks() {
    return this.client.get(`get/banks/NG`);
  }

  addBankDetails(values) {
    return this.client.post(`seller/save/bank/info`, values);
  }

  addBankDetailsUK(values) {
    return this.client.post(`seller/save/uk/bank/info`, values);
  }

  deleteProducts(ids) {
    return this.client.post(`seller/delete/listing`, { product_ids: ids });
  }

  deleteDraft(ids) {
    return this.client.delete(`seller/delete/draft`, {
      data: { draft_id: ids },
    });
  }

  activateProducts(ids) {
    return this.client.post(`seller/activate/listing`, { product_ids: ids });
  }

  deactivateProducts(ids) {
    return this.client.post(`seller/deactivate/listing`, { product_ids: ids });
  }

  duplicateProduct(id) {
    return this.client.get(`seller/copy/listing/${id} `);
  }

  markOrderSent(value) {
    return this.client.post("seller/mark/order/sent", value);
  }

  declineOrder(values) {
    return this.client.post("seller/mark/order/rejected", values);
  }

  changeOrderDelivery(values) {
    return this.client.post("seller/change/order/delivery", values);
  }

  setDeliveryType(value) {
    return this.client.post("seller/set/delivery", value);
  }

  getDeliveryType() {
    return this.client.get("seller/get/delivery");
  }

  getReturnsPolicy() {
    return this.client.get("seller/get/return");
  }

  setReturnsPolicy(value) {
    return this.client.post("seller/return/policy", value);
  }

  getSizeGuide() {
    return this.client.get("seller/get/size/guide");
  }

  uploadSizeGuide(values) {
    return this.client.post("seller/upload/size/guide", values);
  }

  deleteSizeGuide() {
    return this.client.delete("seller/delete/size/guide");
  }

  submitFeedback(value) {
    return this.client.post("seller/feedback", value);
  }

  activateVacationMode(value) {
    return this.client.post("seller/activate/vacation", value);
  }

  deactivateVacationMode() {
    return this.client.post("seller/deactivate/vacation", {});
  }

  getVacationMode() {
    return this.client.get("seller/get/vacation");
  }

  getSettlementInfo() {
    return this.client.get("seller/settlement/details");
  }

  getTransactionHistory(month, year) {
    return this.client.get(
      `seller/transaction/history?year=${year}&month=${month}`
    );
  }

  sendOtpReq(number) {
    return this.client.post("seller/send/otp", { phone: number });
  }

  verifyOtp(values) {
    return this.client.post("seller/verify/otp", values);
  }

  updateVariant(values) {
    return this.client.post(`seller/update/listing/variety`, values);
  }

  updateDraftVariant(values) {
    return this.client.post(`seller/listing/update/draft/variety`, values);
  }

  deleteVariant(variant_id, product_id) {
    return this.client.post(`seller/delete/variant`, {
      variant_id,
      product_id,
    });
  }
  deleteDraftVariant(variant_id, product_id) {
    return this.client.post(`seller/listing/delete/draft/variety`, {
      variant_id,
      product_id,
    });
  }

  bookPhotoshoot(payload) {
    return this.client.post(`photoshoot/pay`, payload);
  }
  getPhotoshoots() {
    return this.client.get(`seller/photoshoots`);
  }
}


