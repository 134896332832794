import { useRef, useState } from "react";
import { Button, Row, Col, Select, Modal } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { cartActionCreators } from "../redux/cart";
import formatter from "../utils/formatter";

const Option = Select;

const CartItems = ({ item, isDelete = true, updateCount }) => {
  const dispatch = useDispatch();
  const quantityRef = useRef([]);
  const [sum, setSum] = useState(item.price);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currenId, setId] = useState("");

  const { code, indicator, rate } = useSelector((state) => state.currency[0]);

  const { deleteProductInCart } = bindActionCreators(
    cartActionCreators,
    dispatch
  );

  const showModal = (id) => {
    setId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteProductInCart(currenId);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const incrementValue = (e, id) => {
    e.preventDefault();
    quantityRef.current[id].value++;
    item.quantityWanted++;
    setSum(parseFloat(item.price) * item.quantityWanted);
    item.totalPrice = parseFloat(item.price) * item.quantityWanted;
    updateCount(quantityRef.current[id].value++);
  };

  const decrementValue = (e, id) => {
    e.preventDefault();
    const value = quantityRef.current[id].value;
    if (!isNaN(value) && value > 1) {
      quantityRef.current[id].value--;
      item.quantityWanted--;
      setSum(parseFloat(item.price) * item.quantityWanted);
      item.totalPrice = parseFloat(item.price) * item.quantityWanted;
      updateCount(quantityRef.current[id].value--);
    }
  };

  return (
    <div>
      <Row className="cart-item-container" key={item.product_id}>
        <img src={item.img1} alt="" className="cart-img" />
        <div className="cart-item-details">
          <p className="item-brand-name">{item.store_info.store_name}</p>
          <h3 className="cart-item-name">{item.name}</h3>
          <h3 className="item-price">
            {formatter(sum, rate, code, indicator)}
          </h3>
          <Row gutter={[5, 0]}>
            <Col span={10}>
              <Select
                className="size-select"
                placeholder="Select Size"
                optionFilterProp="children"
                defaultValue={item.selectedSize?.substring(0, 8)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {item.sizes?.map((size) => (
                  <Option value={size} key={size}>
                    {size}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={10}>
              <div className="input-group">
                <input
                  type="button"
                  value="-"
                  className="button-minus"
                  onClick={(e) => decrementValue(e, item.product_id)}
                />
                <input
                  ref={(el) => (quantityRef.current[item.product_id] = el)}
                  type="number"
                  step="1"
                  className="quantity-field"
                  readOnly
                  value={item.quantityWanted}
                />
                <input
                  type="button"
                  value="+"
                  className="button-plus"
                  onClick={(e) => incrementValue(e, item.product_id)}
                />
              </div>
            </Col>
            <Col span={4}>
              <div
                style={{
                  border: "1px solid #E6E6E6",
                  height: 37,
                  width: 37,
                  marginLeft: 5,
                }}
              >
                <div
                  className="color-box"
                  style={{
                    background: `${item.selectedColor}`,
                    margin: "20% auto",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {isDelete && (
          <DeleteFilled
            onClick={() => showModal(item.product_id)}
            style={{ color: "#CF0000", marginTop: "2em" }}
          />
        )}
      </Row>
      <Modal
        className="cart-item-modal"
        visible={isModalVisible}
        closable={false}
        footer={null}
      >
        <div className="cart-item-prompt">
          <img src="/images/exclam_vector.png" alt="exclamation" />
          <p className="txt-cart-prompt">
            Are you sure you want to remove this item from your cart?
          </p>
          <Button
            className="btn-cancel-modal"
            type="ghost"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="btn-del-cart-item"
            type="primary"
            onClick={handleOk}
          >
            Yes, Remove
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CartItems;
