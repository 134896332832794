import { useLayoutEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input, Table, Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import { storeActionCreators } from "../../redux/stores";
import { useMediaQuery } from "react-responsive";
import formatter from "../../utils/formatter";
import PreviewLisitingModal from "./PreviewLisitingModal";
import capitalizeStr from "../../utils/capitalizeStr";
import standardizeColor from "../../utils/standardizeColor";

const ListingPreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productId = window.location.pathname.split("/")[2];
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const { product, details } = useSelector((state) => state.store);
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data;

  const { fetchSingleProduct } = bindActionCreators(
    storeActionCreators,
    dispatch
  );

  const editItem = async () => {
    const res = await fetchSingleProduct(productId);
    if (res.status === "ok") {
      navigate("/edit-listing", { state: "listing" });
    }
  };

  useLayoutEffect(() => {
    fetchSingleProduct(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const columns = [
    {
      title: "Item name",
      dataIndex: "itemName",
      key: "name",
      width: 300,
      render: (text, record) => <p>{product?.name}</p>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{ color: "white", backgroundColor: "black", padding: "10px" }}
        >
          {record.size}
        </div>
      ),
      width: !isTabletOrMobile ? 200 : 150,
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "110px", height: "45px" }}
        >
          <div
            style={{ background: standardizeColor(record?.color) }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record?.color}</div>
        </div>
      ),
      width: !isTabletOrMobile ? 250 : 150,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "16px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            disabled
            value={record.quantity}
            className="input"
            type="number"
            style={{ height: "45px" }}
          />
        </div>
      ),
    },
  ];

  const mobileColumns = [
    {
      title: "Item name",
      dataIndex: "itemName",
      key: "name",
      width: 300,
      render: (text, record) => <p style={{fontSize:'12px', width:'90px', marginBottom:"0px"}}>{product?.name}</p>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{
            color: "white",
            backgroundColor: "black",
            width: "60px", height: "40px", fontSize: "9px" 
          }}
        >
          {record.size}
        </div>
      ),
      width: !isTabletOrMobile ? 200 : 150,
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "90px", height: "40px", fontSize: "9px" }}
        >
          <div
            style={{ background: standardizeColor(record?.color) }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record?.color}</div>
        </div>
      ),
      width: !isTabletOrMobile ? 250 : 150,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "9px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            disabled
            value={record.quantity}
            className="input"
            type="number"
            style={{ height: "40px", width: "40px", fontSize: "9px" }}
          />
        </div>
      ),
    },
  ];

  const Status = () => {
    const status = product?.status?.toLowerCase();
    let color = "success";

    if (status === "pending approval") color = "warning";
    if (status === "rejected") color = "error";
    if (status === "deactivated") color = "magenta";
    return (
      <Tag color={color} className="txt-product-status">
        {status}
      </Tag>
    );
  };

  return (
    <div className="manage-payment-container">
      <SellerTopBar searchBar={false} />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && (
          <SellerSideNav
            currentPageKey="4"
            helpFeedbackSideMenuVisible={false}
          />
        )}
        <div className="manage-payment-coverall-container">
          {/* <div className="manage-payment-adspace"></div> */}

          <div className="manage-payment-body-content-header">
            <div className="manage-payment-body-content-header-container">
              <div className="manage-payment-body-content-header-title">
                Listings Preview
              </div>
              {/* <div className="manage-payment-body-content-header-subtitle">
                  You can see access seller transactions here
                </div> */}
              <Status />
            </div>
          </div>
          <div className="manage-payment-body-container">
            <div className="manage-payment-body-content">
              <div
                className="manage-payment-body-content-section"
                style={{ position: "relative" }}
              >
                {/* <Status /> */}

                <div className="product-main-container">
                  <div className="images-section">
                    <div className="image-large">
                      {/* <div className="product-image-tag-label">
                          {product?.group}
                        </div> */}
                    </div>
                   { ((product?.image2 || product?.image3 || product?.image4) || !isTabletOrMobile) &&
                   <div className="images-thumbnail-container">
                      <div
                        style={{ backgroundImage: `url(${product?.image2})` }}
                      ></div>
                      <div
                        style={{ backgroundImage: `url(${product?.image3})` }}
                      ></div>
                      <div
                        style={{ backgroundImage: `url(${product?.image4})` }}
                      ></div>
                    </div>
                    }
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="manage-payment-body-container">
            <div className="manage-payment-body-content">
              <div
                className="manage-payment-body-content-section"
                style={{ position: "relative" }}
              >
                <div className="preview-main-content-header">
                  <div
                    style={{
                      fontSize: "18px",
                      fontFamily: "DomaineSansText-Regular",
                    }}
                  >
                    {product?.brand_name}
                  </div>
                  {/* <div style={{ cursor: "pointer" }}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.332 1.50004C10.5071 1.32494 10.715 1.18605 10.9438 1.09129C11.1725 0.996527 11.4177 0.947754 11.6654 0.947754C11.913 0.947754 12.1582 0.996527 12.387 1.09129C12.6157 1.18605 12.8236 1.32494 12.9987 1.50004C13.1738 1.67513 13.3127 1.883 13.4074 2.11178C13.5022 2.34055 13.551 2.58575 13.551 2.83337C13.551 3.08099 13.5022 3.32619 13.4074 3.55497C13.3127 3.78374 13.1738 3.99161 12.9987 4.16671L3.9987 13.1667L0.332031 14.1667L1.33203 10.5L10.332 1.50004Z"
                        stroke="black"
                        stroke-width="0.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span style={{ marginLeft: "4px" }} onClick={editItem}>
                      Edit
                    </span>
                  </div> */}
                </div>
                <div className="preview-main-content">
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Item title
                    </div>
                    <div className="preview-main-content-row-data">
                      {product?.name}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Ready to wear or Bespoke
                    </div>
                    <div
                      className="preview-main-content-row-data txt-type"
                      style={{ textTransform: "capitalize" }}
                    >
                      {product?.product_type}
                    </div>
                  </div>
                  {product?.product_type === "bespoke" && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Number of Days
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.bespoke_duration} Days
                      </div>
                    </div>
                  )}
                  {product?.product_type !== "bespoke" && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Return policy
                      </div>
                      <div className="preview-main-content-row-data">
                      <ul style={{ paddingInlineStart: "0px", listStyle:'none' }}>
                      {product?.product_return_type?.map((type) => (
                          <li>{type}</li>
                        ))}
                      </ul>
                       
                      </div>
                    </div>
                  )}
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Main Category
                    </div>
                    <div className="preview-main-content-row-data">
                      {product?.main_cat}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Subcategory
                    </div>
                    <div className="preview-main-content-row-data">
                      {product?.sub_cat}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Classification
                    </div>
                    <div className="preview-main-content-row-data">
                      {product?.category_name}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Occasions
                    </div>
                    <div className="preview-main-content-row-data">
                      <ul style={{ paddingInlineStart: "16px" }}>
                        {product?.occassion_type?.map((type) => (
                          <li>{type}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {product?.group && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Exclusive Group
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.group}
                      </div>
                    </div>
                  )}
                  {product?.size_and_fit && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Fit</div>
                      <div className="preview-main-content-row-data">
                        {product?.size_and_fit}
                      </div>
                    </div>
                  )}
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Item Description
                    </div>
                    <div className="preview-main-content-row-data">
                      {product?.description}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">
                      Material and Care Information
                    </div>
                    <div className="preview-main-content-row-data">
                      {capitalizeStr(product?.material_info)}
                    </div>
                  </div>
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">Price</div>
                    <div className="preview-main-content-row-data">
                      {formatter(product?.price, rate, code, indicator)}
                    </div>
                  </div>
                  {product?.colors && product?.colors?.length > 0 && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Color</div>
                      <div className="preview-main-content-row-data">
                        <div style={{ display: "flex", gap: "24px" }} className="product-color-section-container">
                          {product?.colors?.map((color) => (
                            <div className="product-color-section">
                              <div
                                className="product-color-section-box"
                                style={{
                                  background: `${standardizeColor(color)}`,
                                }}
                              ></div>
                              <div>{color}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {product?.sizes && product?.sizes?.length > 0 && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Size</div>
                      <div className="preview-main-content-row-data">
                        <div style={{ display: "flex", gap: "24px" }}>
                          <ul style={{ paddingInlineStart: "16px" }}>
                            {product?.sizes?.map((size) => (
                              <li>{size}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {product?.variety && product?.variety?.length > 0 && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key quantity-table-key">
                        {" "}
                      </div>
                      <div className="preview-main-content-row-data">
                        <Table
                          dataSource={product?.variety}
                          columns={!isTabletOrMobile ? columns : mobileColumns}
                          pagination={false}
                          className="preview-variety-table"
                        />
                      </div>
                    </div>
                  )}
                  {product?.has_discount && (
                    <>
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key">
                          Discount
                        </div>
                        <div className="preview-main-content-row-data">
                          {product?.discounts_percent}%
                        </div>
                      </div>
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key">
                          Discount Date
                        </div>
                        <div className="preview-main-content-row-data">
                          {product?.discounts_start_date.split(" ")[0]} - {" "}
                          {product?.discounts_end_date.split(" ")[0]}
                        </div>
                      </div>
                    </>
                  )}
                  {product?.delivery_type && (
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Delivery
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.delivery_type &&
                          capitalizeStr(
                            product?.delivery_type === "losode"
                              ? "We work with third-party delivery providers to handle your delivery"
                              : product?.delivery_type
                          )}
                      </div>
                    </div>
                  )}
                  <div className="preview-main-content-row">
                    <div className="preview-main-content-row-key">Weight</div>
                    <div className="preview-main-content-row-data">
                      {product?.product_weight} kg
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="preview-listing-btn-contain">
            <Button className="preview-button-black" onClick={editItem}>
              Edit
            </Button>

            <Button
              className="preview-button-white"
              onClick={() => setPreviewModalVisible(true)}
            >
              Preview
            </Button>
          </div>
        </div>
      </div>

      

      <PreviewLisitingModal
        product={product}
        modalVisible={previewModalVisible}
        setModalVisible={setPreviewModalVisible}
      />
      <style jsx="true">{`
        .manage-payment-container {
          font-family: DomaineSansText-Light;
          color: black;
          margin-top: -32px;
        }
        .manage-payment-coverall-container {
          margin-left: 200px;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 186px;
        }

        .manage-payment-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .manage-payment-body-container {
          margin-bottom: 40px;
          padding: 0 16px;
        }
        .manage-payment-body-content-header-container {
          position: relative;
        }
        .manage-payment-body-content-header {
          background: white;
          padding: 24px 40px;
        }
        .manage-payment-body-content-header-title {
          font-size: 24px;
          font-family: "DomaineSansText-Regular";
        }
        .manage-payment-body-content-header-subtitle {
          font-size: 16px;
          color: #707070;
        }
        .manage-payment-body-sidebar-add-listing-button {
          height: 47px;
          background: white;
          color: black;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .confirm-listing-button {
          height: 48px;
          background: black;
          color: white;
          font-size: 16px;
          width: 180px;
          margin-left: 20px;
        }

        .manage-payment-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
        }

        .manage-payment-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
          align-items: center;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
        }

        .manage-payment-body-content {
          width: 100%;
        }
        .manage-payment-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
          // gap: 21px;
        }

        .manage-payment-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
        }

        .no-listings-to-show {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 500px;
          font-weight: bold;
          font-size: 18px;
        }
        .no-listings-to-show-link {
          text-decoration: underline;
        }
        .no-listings-to-show-link:hover {
          cursor: pointer;
          color: #800000;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }

        .manage-payment-payment-summary-section {
          display: flex;
          justify-content: space-between;
          width: 88%;
        }
        .payment-summary-grey-box {
          background: #f9f9f9;
          padding: 24px;
        }
        .payment-summary-pink-box {
          background: rgba(128, 0, 0, 0.05);
          padding: 24px;
          width: 450px;
        }
        .payment-summary-big-number {
          font-size: 24px;
          font-weight: bold;
          font-family: "DomaineSansText-Black";
          margin-top: 8px;
        }
        .payment-summary-pink-box-button {
          background: black;
          color: white;
          height: 47px;
          font-size: 16px;
          margin-top: 4px;
        }
        .payment-summary-underline-link {
          margin-top: 10px;
          padding: 16px 0;
          border-top: 1px solid #d4d4d4;
          font-size: 12px;
          color: #6f6f6f;
          text-decoration: underline;
        }
        .manage-payment-body-content-section-title {
          font-size: 18px;
          margin-bottom: 16px;
          font-weight: bold;
        }

        .manage-payment-body-content-trade-section-title {
          font-size: 18px;
          font-weight: bold;
        }

        . .manage-payment-body-content-section-subtitle {
          font-size: 16px;
        }

        .manage-payment-body-content-section {
          margin-top: 24px;
          background: white;
          padding: 40px 24px;
        }
        .recent-transaction-image {
          background-repeat: no-repeat;
          width: 40px;
          height: 40px;
          background-size: cover;
          border-radius: 50%;
        }
        .ant-input-search
          > .ant-input-group
          > .ant-input-group-addon:last-child
          .ant-input-search-button {
          background: #000;
          border: 1px solid #000;
          color: #fff;
          height: 48px;
          font-family: "DomaineSansText-Light";
          padding: 0 24px;
        }
        .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          height: 48px;
        }

        .images-section {
          display: flex;
          gap: 16px;
        }
        .images-thumbnail-container {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .images-thumbnail-container div {
          width: 140px;
          height: 140px;
          background-size: cover;
          background-repeat: no-repeat;
          // background-position: center;
        }
        .image-large {
          width: 250px;
          height: 250px;
          background-image: url(${product?.image1});
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          // background-position: center;
        }
        .txt-product-status {
          text-transform: capitalize;
          padding: 12px 45px;
          position: absolute;
          right: 0;
        }
        .product-image-tag-label {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          background: #0000004d;
          font-size: 28px;
          color: white;
        }
        .product-main-container {
          display: flex;
          gap: 16px;
        }
        .product-content-section {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
        .product-color-section {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .product-color-section-box {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
        .product-designer {
          font-family: "DomaineSansText-Regular";
          font-size: 18px;
          font-weight: bold;
        }
        .product-extra-container {
          display: flex;
          margin-top: 40px;
          border-top: 1px solid #d4d4d4;
          padding-top: 40px;
          gap: 4px;
        }
        .product-extra {
          width: 24%;
        }
        .product-extra-divider {
          border-left: 1px solid #d4d4d4;
          margin: 0 8px;
        }
        .product-extra-title {
          font-size: 16px;
          font-family: "DomaineSansText-Regular";
          margin-bottom: 8px;
        }
        .product-extra-details {
          font-size: 12px;
          margin-bottom: 8px;
        }
        .store-preview-status {
          position: absolute;
          right: 24px;
          top: 40px;
          font-size: 16px;
          font-family: "DomaineSansText-Light";
          padding: 16px 32px;
          border: 0;
        }
        .preview-main-content-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgb(212, 212, 212);
          padding-bottom: 16px;
          align-items: center;
        }
        .preview-main-content {
          margin: 16px 0 0;
        }
        .preview-main-content-row {
          padding: 16px 0;
          display: grid;
          grid-template-columns: 30% 1fr;
          grid-gap: 24px;
        }
        .preview-main-content-row-data {
          font-family: "DomaineSansText-Regular";
          width: 90%;
        }
        .preview-button-black {
          height: 48px;
          background: black;
          color: white;
          font-size: 16px;
          width: 30%;
        }
        .preview-button-white {
          height: 48px;
          color: black;
          background: white;
          font-size: 16px;
          width: 30%;
        }
        .color-container {
          padding: 8px;
          border: 1px solid black;
          width: 120px;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          white-space: nowrap;
          margin: 0 auto;
        }
        .color-hex-box {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        .table-quantity-container .ant-input[disabled] {
          color: #000;
          background-color: #fff;
        }
        .preview-listing-btn-contain {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 40px;
          padding: 0px 40px;
        }
        .size-letter {
          border: 1px solid black;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
        ul{
          margin-bottom: 0px;
        }

        @media (max-width: 640px) {
          .manage-listing-all-content{
            margin-top: ${!details.phone_verify_status ? "154px" :"120px"} ;
          }
          .manage-payment-coverall-container {
            margin-left: 0;
          }
          .manage-payment-adspace {
            background-image: url(images/seller-stats-banner-mobile.png);
            height: 108px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
            display:none
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .manage-payment-body-main {
            padding: 0px;
            padding-bottom: 24px;
            margin-right: 0;
          }
          .manage-payment-body-content-header-title {
            font-size: 16px;
          }
          .manage-payment-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 180px;
          }

          .manage-payment-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .manage-payment-body-content-header-container {
            align-items: center;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header {
            padding: 0 12px;
            margin-bottom: 24px;
          }
          .manage-payment-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .txt-product-status {
            padding: 4px 12px;
            font-size: 10px;
          }
          .manage-payment-body-container {
            margin: 0 0 40px;
            padding: 0 12px;
          }
          .preview-main-content-header{
            padding-bottom: 8px;
          }
          .preview-main-content-header div{
            font-size:14px !important;
          }
          .preview-main-content{
            margin: 12px 0;
          }
          .preview-main-content-row{
            padding: 12px 0;
            grid-template-columns: 1fr 1fr;
            grid-gap: 60px;
          }
          .preview-variety-table .ant-table-thead > tr > th{
            font-size:9px;
            padding: 6px;
            text-align: center;
          }
          .preview-variety-table .ant-table-thead > tr > th:first-child{
            text-align: left;
          }
          .preview-variety-table .ant-table-tbody > tr > td{
            font-size:9px;
            padding-top: 6px;
            padding-bottom:6px
          }
          .preview-variety-table .ant-table-tbody > tr > td:first-child{
            padding-right:0px;
            padding-left:0px
          }
          .product-color-section-container{
            gap: 4px !important;
            flex-direction: column;
          }
          .manage-payment-body-sidebar {
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            display: flex;
            justify-content: flex-end;
          }
          .manage-payment-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .manage-payment-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .manage-payment-body-sidebar-content-select {
            width: auto;
          }
          .manage-payment-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .manage-payment-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-payment-body-content {
            width: 100%;
            padding: 0;
          }
          .manage-payment-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }
          .manage-payment-payment-summary-section {
            width: 100%;
            flex-direction: column;
            gap: 16px;
          }
          .payment-summary-grey-box {
            padding: 16px;
            font-size: 12px;
          }
          .payment-summary-pink-box {
            padding: 16px;
            width: auto;
            font-size: 12px;
          }
          .payment-summary-big-number {
            font-size: 14px;
          }
          .payment-summary-pink-box-button {
            font-size: 12px;
            padding: 16px;
            line-height: 1;
          }
          .manage-payment-body-content-section {
            padding: 0;
            margin-top: 0;
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 14px;
          }
          .payment-summary-underline-link {
            padding-bottom: 0px;
          }
          .manage-payment-transaction-table .ant-table {
            font-size: 12px;
          }
          .expanded-row-item-container {
            display: flex;
            gap: 8px;
            margin-bottom: 8px;
          }
          .expanded-row-item-container-title {
            min-width: 75px;
          }
          .expanded-row-item-container-data {
            font-weight: bold;
          }
          .images-section {
            width: 100%;
            flex-direction: column;
          }
          .image-large {
            width: 100%;
            height: 275px;
          }
          .images-thumbnail-container {
            width: 100%;
            justify-content: space-between;
          }
          .images-thumbnail-container div {
            width: 30%;
            height: 122px;
          }
          .product-main-container{
            padding-bottom: 20px;
          }
          .quantity-table-key {
            display: none;
          }
          .color-container {
            font-size: 12px;
            width: 48%;
          }
          .preview-listing-btn-contain {
            gap: 20px;
            padding: 16px;
            flex-direction: column;
            margin-bottom:80px;
          }
          .preview-button-black,
          .preview-button-white {
            width: 100%;
          }
          .preview-main-content-row-data {
            width: 100%;
          }
          .size-letter {
            font-size: 13px;
            height: fit-content;
            padding: 6px;
          }
        }
      `}</style>
    </div>
  );
};

export default ListingPreview;