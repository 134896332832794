import { useSelector } from "react-redux";
import { Button, Select, Table } from "antd";
import formatter from "../../utils/formatter";
import BuyerSideNav from "./BuyerSideNav";
import { useMediaQuery } from "react-responsive";
import { PlusCircleFilled } from "@ant-design/icons";

const LosodeCredit = () => {
  const { data } = useSelector((state) => state.currency);
  const { rate, code, indicator } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const transactions = [
    {
      image:
        "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1652863286/website-pictures-optimized/olan_zdwhwg.jpg",
      transaction_id: "13245e843AD",
      transaction_date: "16 march 2022",
      transaction_type: "WITDRAWAL",
      description: "listing: 11811899",
      amount: 20690,
      transaction_status: "Payment made with credits on the 26th of May",
    },
    {
      image:
        "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599086/website-pictures-optimized/men7_udunea_wg5icl.jpg",
      transaction_id: "13245e843AD",
      transaction_date: "16 march 2022",
      transaction_type: "WITDRAWAL",
      description: "listing: 11811899",
      amount: 20690,
      transaction_status: "Payment made with credits on the 26th of May",
    },
    {
      image:
        "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1644515728/website-pictures-optimized/Anya_1_hdyico.jpg",
      transaction_id: "13245e843AD",
      transaction_date: "16 march 2022",
      transaction_type: "WITDRAWAL",
      description: "listing: 11811899",
      amount: 20690,
      transaction_status: "Payment made with credits on the 26th of May",
    },
    {
      image:
        "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1652863286/website-pictures-optimized/olan_zdwhwg.jpg",
      transaction_id: "13245e843AD",
      transaction_date: "16 march 2022",
      transaction_type: "REFUND",
      description: "listing: 11811899",
      amount: 20690,
      transaction_status: "Payment made with credits on the 26th of May",
    },
    {
      image:
        "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1652863286/website-pictures-optimized/olan_zdwhwg.jpg",
      transaction_id: "13245e843AD",
      transaction_date: "16 march 2022",
      transaction_type: "purchase",
      description: "listing: 11811899",
      amount: 20690,
      transaction_status: "Payment made with credits on the 26th of May",
    },
  ];

  const months = [
    { month: "January", value: "01" },
    { month: "February", value: "02" },
    { month: "March", value: "03" },
    { month: "April", value: "04" },
    { month: "May", value: "05" },
    { month: "June", value: "06" },
    { month: "July", value: "07" },
    { month: "August", value: "08" },
    { month: "September", value: "09" },
    { month: "October", value: "10" },
    { month: "November", value: "11" },
    { month: "December", value: "12" },
  ];

  const getPrevMonth = () => {
    const current = new Date();
    current.setMonth(current.getMonth());
    const prevMonth = current.toLocaleString("default", { month: "long" });
    const prevVal = months.find((item) => item.month === prevMonth);
    return prevVal.value;
  };

  const columns = [
    {
      dataIndex: "image",
      key: "transactionImage",
      render: (image) => (
        <div
          className="recent-transaction-image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ),
    },
    {
      align: "center",
      title: "TRANSACTION ID",
      dataIndex: "transaction_id",
      key: "transactionId",
      width: 170,
    },
    {
      align: "center",
      title: "TRANSACTION DATE",
      dataIndex: "transaction_date",
      key: "transactionDate",
      render: (date) => <p className="txt-table-data">{date}</p>,
      width: 190,
    },
    {
      title: "TYPE",
      dataIndex: "transaction_type",
      key: "transactionType",
      render: (type) => <p className="txt-table-data">{type}</p>,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "transactionDescription",
    },
    {
      align: "center",
      title: "AMOUNT",
      dataIndex: "amount",
      key: "transactionAmount",
      render: (amount) => (
        <p className="txt-transaction-amount">
          {formatter(amount, rate, code, indicator)}
        </p>
      ),
    },
    {
      align: "center",
      title: "STATUS",
      dataIndex: "transaction_status",
      key: "transactionStatus",
    },
  ];

  const mobileColumns = [
    {
      title: "Item",
      dataIndex: "image",
      key: "transactionImage",
      render: (text, record) => (
        <div>
          <div
            className="recent-transaction-image"
            style={{
              backgroundImage: `url(${record.image})`,
              margin: "auto",
            }}
          ></div>
          {/* <div
            style={{ textAlign: "center", marginTop: "4px", fontSize: "12px" }}
          >
            ID: {record.transaction_id}
          </div> */}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "transactionDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "transactionAmount",
    },
    Table.EXPAND_COLUMN,
    // {
    //   key: "see_details",
    //   render: (text, record) => (
    //     <div style={{color: "#49BDEF", textDecoration:"underline"}}>
    //       See Details
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <div className="account-credit-page-container">
        <div className="account-credit-page-header">
          <h1 className="account-credit-page-title">Losode Credits</h1>
          <h4 className="account-credit-page-subtitle">
            Losode credits are generated when you have paid for an order and the
            seller rejects it. Credits can be withdrawn at any time.
          </h4>
          <div className="account-credit-page-header-divider"></div>
          {isTabletOrMobile && (
            <div className="account-credit-buy-mobile">
              <PlusCircleFilled />
              <span>Buy More Credits</span>
            </div>
          )}
        </div>

        <div className="account-credit-content">
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="4" />}

          <div className="more-credits-info-container">
            <div className="add-credit-container">
              <h1 className="available-credit-txt">Your Losode Credits</h1>
              <h1 className="available-credit-desc">
                You currently have{" "}
                <span className="txt-credit-value">N23,327.97</span> Credit
                balance on Losode for this month. You had deductions of{" "}
                <span className="txt-credit-value">-N15,000</span>
              </h1>
            </div>

            <span className="more-credits-info-line" />
            <div className="user-credits-container">
              <div className="availabe-credit-box">
                <h1 className="txt-available-credit">CREDITS BALANCE</h1>
                <h4 className="txt-available-credit-value">N23,327.97</h4>
              </div>
              <div className="availabe-credit-box">
                <h1 className="txt-available-credit">
                  CREDITS RECEIVED (30 DAYS)
                </h1>
                <h4 className="txt-available-credit-value">N3,327.97</h4>
              </div>
            </div>
          </div>
          <div className="credit-summary-container">
            <h1 className="available-credit-txt">Credits Summary</h1>
            <h4 className="available-credit-desc">
              You currently have{" "}
              <span className="txt-credit-value">N23,327</span> in Losode
              credits, you have spent{" "}
              <span className="txt-credit-value">N13,879</span>
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: !isTabletOrMobile ? "40px" : "16px",
              }}
            >
              <Select
                className="trade-summary-date-select"
                defaultValue={getPrevMonth()}
              >
                {months.map((item, i) => (
                  <Select.Option key={i} value={item.value}>
                    {`${item.month}  2022`}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="credit-transaction-history-container">
            <h1 className="available-credit-txt">Credits History</h1>
            <h3 className="txt-recent-activities">Recent Activities</h3>
            <Table
              className="manage-payment-transaction-table"
              columns={!isTabletOrMobile ? columns : mobileColumns}
              dataSource={transactions}
              rowKey={(record) => record.transaction_id}
              pagination={false}
              expandRowByClick={true}
              expandable={
                isTabletOrMobile
                  ? {
                      expandedRowRender: (record) => (
                        <div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              ID:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.transaction_id}
                            </div>
                          </div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              Date:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.transaction_date}
                            </div>
                          </div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              Type:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.transaction_type}
                            </div>
                          </div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              Description:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.description}
                            </div>
                          </div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              Amount:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.amount}
                            </div>
                          </div>
                          <div className="expanded-row-item-container">
                            <div className="expanded-row-item-container-title">
                              Status:
                            </div>
                            <div className="expanded-row-item-container-data">
                              {record.transaction_status}
                            </div>
                          </div>
                        </div>
                      ),
                      rowExpandable: (record) =>
                        record.transaction_id !== "Not Expandable",
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <div
                            style={{
                              color: "#49BDEF",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => onExpand(record, e)}
                          >
                            Close Details
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "#49BDEF",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => onExpand(record, e)}
                          >
                            See Details
                          </div>
                        ),
                    }
                  : ""
              }
            />
            <div style={{ fontSize: "12px", marginTop: "24px" }}>
              Do you have questions?{" "}
              <span style={{ textDecoration: "underline" }}>
                Read our payment FAQ's
              </span>{" "}
            </div>
          </div>

          <div className="withdrawal-btn-container">
            <Button className="btn-withdraw-credit">Withdraw</Button>
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
          .account-credit-page-container {
            background: #f9f9f9;
            padding-bottom: 50px;
          }
          .account-credit-content {
            position: relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background: #f9f9f9;
            margin-top: 40px;
          }
          .account-credit-content-main {
            margin-left: 200px;
            padding: 40px;
            padding-top: 0;
          }
          .account-credit-page-header {
            display: grid;
            place-items: center;
            font-family: "DomaineSansText-Light";
            padding-top: 24px;
            background: white;
            padding-bottom: 4px;
          }
          .account-credit-page-title {
            font-size: 24px;
          }
          .account-credit-page-subtitle {
            font-size: 16px;
            margin-bottom: 0px;
            text-align: center;
            width: 36%;
          }
          .account-credit-page-header-divider {
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #e4e4e4;
            margin: 16px auto 24px auto;
          }
          .user-credits-container {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            width: max-content;
            gap: 24px;
          }
          .availabe-credit-box {
            width: 350px;
            height: 138px;
            background: #000000;
            padding: 37px 13px;
          }
          .txt-available-credit {
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            color: #ffffff;
          }
          .txt-available-credit-value {
            font-family: "DomaineSansText-Regular";
            font-weight: 700;
            font-size: 28px;
            line-height: 53px;
            color: #ffffff;
          }

          .txt-credit-value {
            color: #000;
            font-family: "DomaineSansText-Black";
          }

          .more-credits-info-container {
            background: #ffffff;
            height: 340px;
            margin-left: 240px;
            margin-right: 60px;
            margin-top: 32px;
            padding: 30px;
            position: relative;
          }
          .available-credit-txt {
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 34px;
            color: #000000;
            margin-bottom: 0px;
          }
          .available-credit-desc {
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 34px;
            color: #6f6f6f;
          }
          .btn-add-credit {
            border: 0px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #92959e;
            padding: 0px;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 120px;
          }
          .more-credits-info-line {
            border: 0.5px solid rgba(146, 149, 158, 0.5);
            height: 0px;
            left: 30px;
            top: 100px;
            right: 30px;
            position: absolute;
          }
          .credit-summary-container {
            background: #ffffff;
            height: 211px;
            margin-top: 35px;
            margin-left: 240px;
            margin-right: 60px;
            padding: 30px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 180px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }
          .credit-transaction-history-container {
            background: #ffffff;
            height: 832px;
            margin-left: 240px;
            margin-right: 60px;
            margin-top: 27px;
          }
          .withdraw-credits-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 8rem;
          }
          .credit-info-title {
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #92959e;
          }
          .credit-info-value {
            font-family: "DomaineSansText-Regular";
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            color: #92959e;
            margin-top: 12px;
          }
          .btn-withdraw-credit {
            background: #000000;
            color: #fff;
            font-family: "DomaineSansText-Light";
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            width: 201px;
            height: 50px;
          }
          .credit-transaction-history-container {
            background: #ffffff;
            height: auto;
            margin-left: 240px;
            margin-right: 60px;
            margin-top: 27px;
            padding: 34px 40px;
          }
          .txt-recent-activities {
            font-family: "DomaineSansText-Light";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 31px;
            color: #32353c;
            margin-bottom: 25px;
          }
          .manage-payment-transaction-table .ant-table-thead > tr > th {
            background: #fff;
            border: 0px;
            box-shadow: inset 0px -1.45663px 0px rgba(0, 0, 0, 0.05);
            font-family: "DomaineSansText-Light";
          }
          .manage-payment-transaction-table
            .ant-table-thead
            > tr
            > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            height: 0px;
          }
          .recent-transaction-image {
            background-size: cover;
            background-position: top;
            width: 76px;
            height: 76px;
          }
          .manage-payment-transaction-table .ant-table-tbody > tr > td {
            font-family: "DomaineSansText-Light";
            font-size: 13px;
            font-weight: 300;
          }
          .txt-table-data {
            font-family: "DomaineSansText-Light";
            font-size: 13px;
            font-weight: 300;
            text-transform: uppercase;
          }
          .txt-transaction-amount {
            font-family: "DomaineSansText-Text";
            font-weight: 700;
            line-height: 16px;
          }
          .withdrawal-btn-container {
            background: #ffffff;
            margin-left: 240px;
            margin-right: 60px;
            margin-top: 27px;
            display: grid;
            place-items: center;
            height: 150px;
          }

          @media (max-width: 640px) {
            .credit-transaction-history-container,
            .withdrawal-btn-container,
            .credit-summary-container,
            .more-credits-info-container {
              margin-right: 0;
              margin-left: 0;
              padding: 16px;
              height: auto;
              margin-top: 0;
            }
            .account-credit-content,
            .account-credit-page-container {
              background: white;
            }
            .account-credit-content {
              margin-top: 0;
            }
            .account-credit-page-subtitle {
              width: 100%;
              padding: 0 16px;
              font-size: 12px;
            }
            .account-credit-page-title {
              font-size: 18px;
            }
            .available-credit-txt {
              font-size: 16px;
            }
            .available-credit-desc {
              line-height: normal;
            }
            .availabe-credit-box {
              width: 48%;
              height: auto;
              padding: 8px;
            }
            .txt-available-credit {
              font-size: 9px;
              line-height: normal;
            }
            .txt-available-credit-value {
              font-size: 14px;
              line-height: normal;
            }
            .more-credits-info-line {
              display: none;
            }
            .available-credit-desc {
              padding-bottom: 16px;
              border-bottom: 1px solid #a7a7a7;
            }
            .user-credits-container {
              margin-top: 24px;
              width: 100%;
              gap:8px
            }
            .txt-recent-activities {
              display: none;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
            .manage-payment-transaction-table {
              border-top: 1px solid #a7a7a7;
              margin-top: 16px;
            }
            .account-credit-page-header-divider {
              border-bottom: 1px solid #a7a7a7;
              margin: 16px auto 8px;
            }
            .account-credit-buy-mobile {
              font-size: 12px;
              width: 100%;
              padding: 0px 16px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 4px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LosodeCredit;
