/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Select, Col, Input, Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  fetchBanks,
  fetchSettlementInfo,
  submitBankDetails,
} from "../../redux/stores/store.actions";
import { useDispatch, useSelector } from "react-redux";
import triggerNotification from "../../hooks/triggerNotification";

const BankModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const { banks } = useSelector((state) => state.store);

  const onSubmit = async (values) => {
    const res = await dispatch(submitBankDetails(values));
    if (res.status === "ok") {
      dispatch(fetchSettlementInfo());
      setVisible(false);
      setSubmitSuccess(true);
    }
    if (res.status === "not ok") {
      triggerNotification({
        type: "error",
        message: res.message,
      });
    }
  };

  const redirect = () => {
    if (submitSuccess === true) {
      setSubmitSuccess(false);
    }
  };

  useEffect(() => {
    const myTimeout = setTimeout(redirect, 5000);

    return () => {
      clearTimeout(myTimeout);
    };
  }, [submitSuccess]);

  useEffect(() => {
    dispatch(fetchBanks());
  }, []);

  const SubmitBankSuccess = () => {
    return (
      <div className="apply-to-join-success-overlay">
        <div className="apply-to-join-success-div">
          <h3>Success!</h3>
          <p style={{ padding: "20px 10px" }}>
            You have successfully added your bank details{" "}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {submitSuccess && <SubmitBankSuccess />}
      <Modal
        centered
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={!isTabletOrMobile ? "80%" : "100%"}
        styles={{ padding: `${!isTabletOrMobile ? 24 : 10}` }}
        maskClosable={false}
        className="bank-modal"
      >
        <div className="modal-title">Add Bank Details</div>
        <div className="modal-desc">
          To avoid payment disruptions, please add your bank details here
        </div>
        <div className="bank-modal modal-form-wrapper">
          <Form
            form={form}
            name="bankForm"
            onFinish={onSubmit}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={[48, isTabletOrMobile ? 12 : 0]}>
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="bankName"
                  label="Bank Name"
                  labelAlign="top"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select your bank"
                    className="select"
                    showSearch
                  >
                    {banks?.map((bank) => (
                      <Select.Option value={bank.name} key={bank.id}>
                        {bank.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="accountName"
                  label="Account Name"
                  labelAlign="top"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="Enter your account name"
                  />
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="accountNumber"
                  label="Account Number"
                  labelAlign="top"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      min: 8, // Adjust minimum length as needed
                      message:
                        "Account number must be at least 8 characters long",
                    },
                    {
                      max: 20, // Adjust maximum length as needed
                      message: "Account number cannot exceed 20 characters",
                    },
                    {
                      pattern: /^[a-zA-Z0-9]+$/, // Allow only alphanumeric characters
                      message:
                        "Account number can only contain letters and numbers",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="Enter your account number"
                  />
                </Form.Item>
              </Col>
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item>
                  <Button
                    className="btn-bank-submit"
                    type="primary"
                    htmlType="submit"
                  >
                    Add Bank Details
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <style>
          {`

        .bank-modal{
            padding:10px 90px;
        }
        .modal-form-wrapper {
            margin-top:30px;
        }
          
          .ant-form-vertical .ant-form-item-label > label {
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: 8px;
          }

          .input {
            height: 48px;
            border: 1px solid #6F6F6F;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }

          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #6F6F6F;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }
          .ant-form-item-with-help .ant-form-item-explain{
            margin-top:4px
          }
          .bank-modal .modal-title {
            font-size: 24px;
            font-weight: 600;
            font-family:DomaineSansText-Black;
            line-height:31.03px;
            margin-bottom: 5px;
            text-align:center;
          }
          .bank-modal .modal-desc {
            font-size: 16px;
            font-weight: 400;
            font-family:DomaineSansText-Light;
            line-height:23.27px;
            text-align:center;
          }

        .btn-bank-submit {
          background: #000;
          font-size: 16px;
          font-weight: 600;
          font-family:DomaineSansText-Light;
          line-height: 20.69px;
          width: 100%;
          height: 48px;
          margin-top: 30px;
        }

        .btn-bank-submit:hover {
          background: #fff;
          border: 1px solid #000;
          color: #000;
        }
        `}
        </style>
      </Modal>

      <style jsx="true">{`
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #6f6f6f;
          font-size: 16px;
          height: 48px;
          padding: 0px;
        }
        .prefix-select {
          width: 100px;
          height: 48px;
          border: none;
          outline: none;
        }
        .prefix-select .ant-select {
          width: 90px;
          height: 48px;
          border: 0;
          outline: 0;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-search {
          top: 10px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          font-size: 13px;
          padding: 7px 10px;
        }
        .ant-input:placeholder-shown,
        .ant-picker-input > input:placeholder-shown,
        .ant-select-multiple .ant-select-selection-placeholder {
          font-size: 13px;
        }
        .ant-picker:hover,
        .ant-picker-focused,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: black;
          border-right-width: 1px !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 48px;
          border: 1px solid #6f6f6f;
        }
        .ant-input-group-addon:first-child {
          border: 0;
          background: transparent;
          border-right: 1px solid #6f6f6f;
        }

        .apply-to-join-navbar-title-text {
          margin: auto;
          text-transform: capitalize;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          padding: 30px 50px;
        }
        .apply-to-join-success-div h3 {
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px/24px DomaineSansText-Light;
          color: white;
          text-align: center;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          font-family: DomaineSansText-Light;
        }

        .apply-to-join-success-overlay {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3000;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .apply-to-join-success-div {
          position: absolute;
          background: black;
          color: white;
          padding: 30px 50px;
        }
        .apply-to-join-success-div h3 {
          font: normal 24px DomaineSansText-Regular;
          color: white;
          text-align: center;
        }
        .apply-to-join-success-div p {
          font: normal 16px/24px DomaineSansText-Light;
          color: white;
          text-align: center;
        }

        @media (max-width: 640px) {
          .bank-modal {
            width: 100% !important;
            padding: 0 !important;
          }
        }
      `}</style>
    </>
  );
};

export default BankModal;
