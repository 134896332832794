import { useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ExclamationCircleFilled } from "@ant-design/icons";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import OrderView from "./OrderView";

const ManageOrders = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const { orders, details } = useSelector((state) => state.store);

  // function itemRender(current, type, originalElement) {
  //   if (type === "prev") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Previous" : ""}
  //       </span>
  //     );
  //   }
  //   if (type === "next") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Next" : ""}
  //       </span>
  //     );
  //   }
  //   return originalElement;
  // }

  useEffect(() => {}, [orders]);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(details.storeLink);
      message.success("Your Store link has been copied to clipboard");
    } catch (err) {
      message.error("Failed to copy: ", err.message);
    }
  };

  return (
    <div className="manage-orders-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="2" />}
        <div className="manage-orders-coverall-container">
          <div className="manage-orders-adspace"></div>
          <div className="manage-orders-body-content-header">
            <div className="manage-orders-body-content-header-container">
              <div className="manage-orders-body-content-header-title">
                Manage Orders
              </div>
              {!isTabletOrMobile && (
                <div className="manage-orders-body-content-header-subtitle">
                  Manage orders from your buyers here. You will need to accept
                  received orders so that we can confirm receipt to your buyer.
                  We generally ask that orders are confirmed within 48 hours.
                  Come back here to tell us when the item is ready to ship
                </div>
              )}
              {isTabletOrMobile && (
                <div className="manage-orders-body-content-header-subtitle">
                  View and Edit orders from buyers here.
                </div>
              )}
            </div>
          </div>
          <div className="manage-orders-body-container">
            <div className="manage-orders-body-content">
              {/* {orders?.length > 0 && (
                <div className="manage-orders-body-content-top-actions">
                  <div className="top-actions-checkbox-container">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                      style={{ marginRight: "8px" }}
                    />
                    <ArrowDownOutlined />
                  </div>

                  <div>
                    <Button className="activate-delete-button">Accept</Button>
                  </div>
                </div>
              )} */}

              <div className="manage-orders-body-content-listings-container">
                {orders?.length > 0 ? (
                  orders.map((order, idx) => (
                    <OrderView key={idx} product={order} />
                  ))
                ) : (
                  <div className="no-listings-to-show">
                    <div>
                      <ExclamationCircleFilled
                        style={{ fontSize: "50px", marginBottom: "16px" }}
                      />
                      <div>
                        You do not have any new orders. Share a link to your
                        Store to your social media platforms so that buyers know
                        you are now on Losode.{" "}
                        <span
                          style={{ color: "#800000", cursor: "pointer" }}
                          onClick={() => copyLink()}
                        >
                          Click here
                        </span>{" "}
                        to copy your unique Store link
                      </div>
                      {/* <div
                        className="no-listings-to-show-link"
                        onClick={() => history.push("/list-item")}
                        >
                        list an item now{" "}
                        </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="manage-orders-body-sidebar">
              {/* <div className="manage-orders-body-sidebar-content">
                <div className="manage-orders-body-sidebar-content-title">
                  Sort
                </div>
                <Select
                  defaultValue="latest"
                  placeholder="Latest"
                  className="manage-orders-body-sidebar-content-select"
                >
                  <Select.Option value="latest">Most Recent</Select.Option>
                  <Select.Option value="price">Price</Select.Option>
                  <Select.Option value="alphabetical">A-Z</Select.Option>
                </Select>
              </div> */}
              {/* <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "8px",
                  }}
                >
                  Order Status
                </div>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>New</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Accepted</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Ready to ship</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Completed</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Rejected</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .manage-orders-container {
          font-family: DomaineSansText-Light;
        }
        .manage-orders-coverall-container {
          margin-left: 200px;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }

        .manage-orders-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          margin: auto;
        }

        .manage-orders-body-container {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
        }

        .manage-orders-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-orders-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .manage-orders-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
          width: 80%;
        }
        .manage-orders-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-orders-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
        }

        .manage-orders-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
          align-items: center;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
          height: 34px;
          width: 175px;
        }
        .manage-orders-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-orders-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .manage-orders-body-sidebar {
          width: 17%;
          padding-left: 10px;
          border-left: 1px solid #d4d4d4;
          margin-top: 60px;
          margin-right: 10px;
        }
        .manage-orders-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-orders-body-content {
          width: 80%;
          padding: 0 20px;
          margin: auto;
        }

        .manage-orders-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }

        .no-listings-to-show {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 500px;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          width: 80%;
          margin: auto;
        }
        .no-listings-to-show-link {
          text-decoration: underline;
        }
        .no-listings-to-show-link:hover {
          cursor: pointer;
          color: #800000;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }
        .manage-orders-body-sidebar-content {
          margin: 10px 0px 16px;
        }
        .manage-orders-body-sidebar-content-title {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 8px;
        }
        .manage-orders-body-sidebar-content-select {
          width: 100%;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .manage-orders-body-content {
            width: 85%;
            margin: auto;
            padding: 0 16px;
          }

          .manage-orders-body-content-header {
            padding: 24px 16px;
          }
          .manage-orders-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }

          .manage-orders-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
        }

        @media (max-width: 640px) {
          .manage-orders-coverall-container {
            margin-left: 0;
          }
          .manage-listing-all-content{
            margin-top: ${!details.phone_verify_status ? "154px" :"90px"} ;
          }
          .manage-orders-adspace {
            background-image: url(images/seller-stats-banner.png);
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .manage-orders-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .manage-orders-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .manage-orders-body-content-header-title {
            font-size: 18px;
          }
          .manage-orders-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            margin-top: auto;
          }

          .manage-orders-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .manage-orders-body-content-header-container {
            display: block;
            align-items: center;
          }
          .manage-orders-body-content-header {
            padding: 24px 16px;
          }
          .manage-orders-body-content-header-subtitle {
            width: 100%;
          }
          .manage-orders-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }
          .manage-orders-body-sidebar {
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            display: flex;
            justify-content: flex-end;
          }
          .manage-orders-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .manage-orders-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .manage-orders-body-sidebar-content-select {
            width: auto;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-content {
            width: 100%;
            padding: 0 16px;
          }
          .manage-orders-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default ManageOrders;
