import {
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAIL,
  FETCH_PRODUCT_START,
} from "./types";

const initialState = {
  item: {},
  message: {},
  loading: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_START: {
      return { ...state, loading: true };
    }
    case FETCH_PRODUCT_SUCCESS: {
      return { ...state, item: action.payload, loading: false };
    }
    case FETCH_PRODUCT_FAIL: {
      return { ...state, message: action.payload, loading: false };
    }

    default:
      return state;
  }
};

export default productReducer;
