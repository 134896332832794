import { notification } from "antd";
import {
  CheckCircleFilled,
  InfoCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

const triggerNotification = (config) => {
  notification[config?.type]({
    //   message: 'Notification Title',
    description: config?.message,
    duration: 3.0,
    className: `losode-notif ${config?.extraClass}`,
    icon:
      config?.type === "success" ? (
        <CheckCircleFilled />
      ) : config?.type === "error" ? (
        <CloseCircleFilled />
      ) : config?.type === "info" ? (
        <InfoCircleFilled />
      ) : config?.type === "warning" ? (
        <ExclamationCircleFilled />
      ) : (
        ""
      ),
  });
};

export default triggerNotification;
