import React from "react";

const Verdict = () => {
  return (
    <div className="verdict-container">
      <div className="verdict-text-box">
        <h4 className="verdict-title">Watch Out For</h4>
        <h1 className="verdict-main">The Verdict</h1>
        <h4 className="verdict-desc">A fashion and style editorial</h4>
      </div>
      <div className="verdict-box">
        <div className="left-verdict-box">
          <img
            className="verdict-left-img-1"
            loading="lazy"
            alt="verdict"
            src="https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599094/website-pictures-optimized/wom2_hsu1uf_b5j2j3.jpg"
          />
          <img
            className="verdict-left-img-2"
            loading="lazy"
            alt="verdict"
            src="https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1646382956/website-pictures-optimized/hom5_fvhu39.jpg"
          />
        </div>
        <div className="right-verdict-box">
          <img
            className="verdict-right-img"
            alt="verdict"
            loading="lazy"
            src="https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599034/website-pictures-optimized/group_govguz_qvqzjc.jpg"
          />
        </div>
      </div>
      <style jsx="true">{`
        .verdict-container {
          position: relative;
        }
        .verdict-text-box {
          display: flex;
          flex-direction: column;
          position: absolute;
          padding-left: 3.4%;
          bottom: 24px;
        }
        .verdict-title {
          font: normal 400 30px DomaineSansText-Regular;
          line-height: 45px;
          color: #ffffff;
          margin-bottom: 0px;
        }
        .verdict-main {
          font: normal 700 115px DomaineSansDisplay-Bold;
          // line-height: 187px;
          color: #ffffff;
          margin-bottom: 0px;
        }
        .verdict-desc {
          font: normal 300 30px DomaineSansText-Regular;
          line-height: 45px;
          color: #ffffff;
          text-transform: capitalize;
          margin-bottom: 0px;
          text-align: right;
        }
        .verdict-box {
          display: flex;
        }
        .left-verdict-box {
          display: flex;
          width: 60vw;
        }
        .verdict-left-img-1 {
          object-fit: cover;
          object-position: left;
          width: 40%;
          height: 600px;
        }
        .verdict-left-img-2 {
          object-fit: cover;
          object-position: center;
          width: 60%;
          height: 600px;
        }
        .right-verdict-box {
          width: 40vw;
        }
        .verdict-right-img {
          height: 600px;
          object-fit: cover;
          object-position: left;
        }
        @media(max-width:640px){
          .verdict-container{
            margin: 24px 0;
          }
          .verdict-left-img-1, .verdict-left-img-2, .verdict-right-img{
            height: 250px;
          }
          .verdict-title{
            font-size:18px
          }
          .verdict-main{
            line-height: normal;
            font-size: 40px;
            width: 168px;
          }
          .verdict-desc{
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
          }

        }
      `}</style>
    </div>
  );
};

export default Verdict;
