import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Ads = () => {
  const navigate = useNavigate();
  const { type } = useSelector((state) => state.user);

  const shopShoes = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="ads-container">
      <div className="btn-container">
        <div>
          <h2 className="txt-ads-title">For Dainty Feet</h2>
          <h4 className="ads-desc">Timeless and Classic</h4>
          <p className="ads-btn" onClick={() => shopShoes()}>
            Shop Shoes
          </p>
        </div>
      </div>
      <div className="ads-img" />

      <style jsx="true">
        {`
          .ads-container {
            background: #f9f9f9;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 250px;
            margin: 75px 0px;
          }
          .btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            margin: 0 60px;
          }
          .ads-text {
            font: normal 600 45px DomaineSansText-Light;
            line-height: 67px;
            margin: 0;
            color: #000;
          }
          .ads-desc {
            font: normal 400 18px DomaineSansText-Light;
            line-height: 41px;
            margin: -16px 0;
            color: #000;
          }
          .ads-btn {
            font: normal 400 16px DomaineSansText-Light;
            margin-top: 40px;
            margin-bottom: 0;
            color: #555555;
            cursor: pointer;
            text-decoration: underline;
          }
          .ads-img {
            flex-basis: 50%;
            background: url("/images/adbanner.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
          }
          @media(max-width:640px){
            .ads-container{
              margin: 24px 0;
              height:10rem
            }
            .btn-container{
              margin:0;
              margin-left: 16px;
              justify-content: flex-start;
              height: 46px;
            }
            .txt-ads-title {
              font-size: 16px;
              margin-bottom: 4px;
              line-height: normal;
              font-family: 'DomaineSansText-Regular';
            }        
            .ads-desc {
              font-size: 14px;
              margin: 0;
              line-height: normal;
            }
            .ads-btn {
              text-decoration: underline;
              margin: 16px 0;
              font-size: 12px;
            }
            .ads-img{
              margin-right: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Ads;
