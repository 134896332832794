export const occasion = [
  "Weddings",
  "Formal Wear",
  "The Beach",
  "Working Out",
  "Sports wear",
  "Party wear",
  "Work wear",
  "Casual Wear",
];

export const kidsOccasion = (s) => {
  if (s.toLowerCase().includes("0 to 24 months"))
    return [
      "Formal wear",
      "The beach",
      "Sport wear",
      "Party wear",
      "Casual wear",
    ];
  if (s.toLowerCase().includes("2 to 14 years"))
    return [
      "Weddings",
      "Formal wear",
      "The beach ",
      "Working out",
      "Sport wear ",
      "Party wear ",
      "Work wear ",
      "Casual wear",
    ];
};
