import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Rewards = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  if (!isTabletOrMobile) navigate("/account");

  return <div>Rewards</div>;
};

export default Rewards;
