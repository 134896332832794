import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Radio,
  Menu,
  Modal,
  Tag,
  Collapse,
} from "antd";
import { useMediaQuery } from "react-responsive";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Link, useNavigate } from "react-router-dom";
import { ExclamationCircleFilled, UpOutlined } from "@ant-design/icons";
import formatter from "../utils/formatter";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
// import { currencyActionCreators } from "../redux/currency";
import { cartActionCreators } from "../redux/cart";
import getLatLng from "../utils/getLatLng";
import { countries } from "../assets/constants";
import capitalizeStr from "../utils/capitalizeStr";
import { AddressModal } from "../components/modals";
import triggerNotification from "../hooks/triggerNotification";

const { Panel } = Collapse;

const Checkout = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SuccessRef = useRef(null);

  const [discountForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [userSignInForm] = Form.useForm();
  const [guestSignInForm] = Form.useForm();

  // const [{ paypalOptions, isPending }, paypalDispatch] =
  //   usePayPalScriptReducer();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isShow, setIsShow] = useState("none");
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [isRepayVisible, setIsRepayVisible] = useState(false);
  // const [showPopover, setShowPopover] = useState(false);
  const [mode, setMode] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [paymentOption, setPaymentOption] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [options, setOptions] = useState([]);
  const [cartContents, setCartContents] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountSuccess, showDiscountSuccess] = useState(false);
  const [country, setCountry] = useState("");
  const [isAddressModal, setAddressModalVisible] = useState(false);
  const [differentAddressModalVisible, setDifferentAddressModalVisible] =
    useState(false);

  const [editAddressModalVisible, setEditAddressModalVisible] = useState(false);
  const [currentAddress, setCurrentAddress] = useState();

  const {
    loginBuyer,
    logout,
    registerGuest,
    addAddress,
    setPrimaryAddress,
    updateAddress,
  } = bindActionCreators(userActionCreators, dispatch);
  // const { fetchCurrency } = bindActionCreators(
  //   currencyActionCreators,
  //   dispatch
  // );
  const { payment, getOrderReceipt, checkout, reset, fetchDiscount, deleteCartItem } =
    bindActionCreators(cartActionCreators, dispatch);

  const [editAddressForm] = Form.useForm();

  const editAddress = (address) => {
    setCurrentAddress(address);
    editAddressForm.setFieldsValue(address);
    setEditAddressModalVisible(true);
  };

  const { checkoutItems, items, sum, orderDetails } = useSelector(
    (state) => state.cart
  );
  const { info, isAuthenticated, contact, type } = useSelector(
    (state) => state.user
  );
  const [itemSum, setItemSum] = useState(
    sum || JSON.parse(localStorage.getItem("itemsSum"))
  );

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", indicator: "", rate: "" };

  const pageItems =
    items.length > 0 ? items : JSON.parse(localStorage.getItem("cartItems"));
  //  setItemSum(sum ? sum : JSON.parse(localStorage.getItem("itemsSum")) || sum);

  const txref = localStorage.getItem("txref");
  const isSuccess = localStorage.getItem("isSuccess");

  const order_items = [];
  items.map((item) => {
    const variant = item?.variety?.find(
      (variant) =>
        variant.color === item.selectedColor?.name &&
        variant.size === item.selectedSize
    );

    order_items.push({
      ordered_quantity: item.quantityWanted,
      product_id: item.product_id,
      product_name: item.name,
      product_size: item?.selectedSize,
      product_color: item?.selectedColor?.name,
      variant_sku: variant?.variant_sku || null,
    });
    return true;
  });

  // const handleVisibleChange = (newVisible) => {
  //   setShowPopover(newVisible);
  // };

  const total = formatter(
    Math.max(0, deliveryPrice + (itemSum - discountAmount)),
    rate,
    code,
    indicator
  );

  const onCreateOrder = (data, actions) => {
    const strPrice = total.toString();
    const totalAmount = strPrice.slice(1, strPrice.length);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalAmount,
            breakdown: {
              item_total: {
                currency_code: code,
                value: totalAmount,
              },
            },
          },
        },
      ],
    });
  };

  const updateOptions = () => {
    checkoutItems?.map((item) => {
      const element = options.find((e) => e.name === item.store_name);
      if (element) {
        element.option = item.delivery_option;
      } else {
        if (item.delivery_option)
          setOptions([
            ...options,
            { name: item.store_name, option: item.delivery_option },
          ]);
      }
      return true;
    });

    sumDeliveryFee();
  };

  const sumDeliveryFee = () => {
    let totalFee = 0;
    options?.map(({ option }) => (totalFee += option?.price));
    localStorage.setItem("deliveryOption", JSON.stringify(options));
    setDeliveryPrice(totalFee);
  };

  const getOrderDetails = () => {
    localStorage.setItem("cartItems", JSON.stringify(items));
    localStorage.setItem("itemsSum", JSON.stringify(sum));
    const orders = checkoutItems.map((item) => {
      let orderObj = {};
      const products = [];

      orderObj.delivery_option = item.delivery_option;
      item.products.map((product) => {
        products.push({
          product_id: product.productId,
          product_color: product.productColor,
          product_name: product.productName,
          product_size: product.productSize,
          price: product.productPrice,
          variant_sku: product.productVariantSku,
          ordered_quantity: product.productOrderQuantity,
        });
        return true;
      });

      orderObj.products = products;

      return orderObj;
    });

    const values = {
      coupon_code: discountForm.getFieldValue("code"),
      payment_gateway: paymentOption,
      payment_currency: code,
      order_items: orders,
    };

    return values;
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const values = getOrderDetails();
      values.payment_gateway = "paypal";
      values.trans_reference = details.id;

      if (details.status === "COMPLETED") {
        payment(values).then((res) => {
          if (res.status === "ok")
            window.open(res.data.redirect_url, "_blank")?.focus();
        });
      }

      // const name = details.payer.name.given_name;
      // console.log(details);
      // alert(`Transaction completed by ${name}`);
    });
  };

  const getId = () => {
    if (contact.length > 0) {
      const pryAddress = contact.find((contact) => contact.status === 1);
      return pryAddress.id;
    }
  };

  const onApplyDiscount = async (val) => {
    const products = items.reduce((acc, item) => {
      const {
        product_id,
        product_name,
        quantityWanted: ordered_quantity,
        selectedSize: product_size,
        selectedColor,
        price,
        variety,
      } = item;
      const variant = variety.find(
        (variant) =>
          variant.size === product_size && variant.color === selectedColor.name
      );
      const variant_sku = variant?.variant_sku;
      return [
        ...acc,
        {
          product_id,
          product_name,
          ordered_quantity,
          product_color: selectedColor?.name,
          variant_sku,
          price,
          product_size,
        },
      ];
    }, []);
    const res = await fetchDiscount({
      payment_currency: code,
      coupon_code: val.code,
      products,
    });
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: "Coupon code successfully applied",
      });
      const { applied_amount } = res.data;
      // if (voucher_type === "flat") {
      //   setDiscountAmount(value);
      // }

      setDiscountAmount(applied_amount);

      showDiscountSuccess(true);
    }
  };

  const onFinish = async (values) => {
    const res = await loginBuyer(values);
    if (res.status === "ok") {
      setIsShow("block");
      onSignIn();
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const onRegisterGuest = async (values) => {
    const res = await registerGuest(values);
    if (res.status === "ok") setIsShow("block");
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const setMainAddress = async (id) => {
    const res = await setPrimaryAddress(id);
    setDifferentAddressModalVisible(false);
    if (res.status === "ok") {
      const data = { id, order_items, currency: code };
      checkout(data);
    }
  };

  const onSubmitAddress = async (values) => {
    const resp = await getLatLng(values);
    const [latitude, longitude] = resp?.split(",");

    values.latitude = latitude;
    values.longitude = longitude;
    values.dial = "+234";
    const res = await addAddress(values);

    if (res.status === "ok") {
      addressForm.resetFields();
      if (mode !== "guest") {
        setAddressModalVisible(false);
      } else {
        const existing_address_id = res.data.id;
        const data = { existing_address_id, order_items, currency: code };
        const resp = await checkout(data);

        if (resp.status === "ok") setAddressModalVisible(false);
        if (resp.status === "not ok")
          triggerNotification({
            type: "error",
            message: resp.message,
          });
      }
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const onUpdateAddress = async (values) => {
    try {
      const resp = await getLatLng(values);
      const [latitude, longitude] = resp.split(",");

      values.latitude = latitude;
      values.longitude = longitude;
      values.dial = "+234";

      const res = await updateAddress(currentAddress.id, values);

      if (res.status === "ok") {
        if (currentAddress.status === 1) {
          const existingAddressId = getId();
          const data = {
            existing_address_id: existingAddressId,
            order_items,
            currency: code,
          };
          const checkoutResp = await checkout(data);

          if (checkoutResp.status === "ok") {
            setEditAddressModalVisible(false);
          } else {
            triggerNotification({
              type: "error",
              message: checkoutResp.message,
            });
          }
        } else {
          setEditAddressModalVisible(false);
        }
      } else {
        triggerNotification({
          type: "error",
          message: res.message,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      triggerNotification({
        type: "error",
        message: "An error occurred while updating the address.",
      });
    }
  };

  //console.log(editAddressModalVisible);

  const onSubmitPayment = async (gateway) => {
    const values = getOrderDetails();
    values.payment_gateway = gateway;
    try {
      const res = await payment(values);
      if (res.status === "ok") {
        setPaymentLink(res.data.link);
        triggerNotification({
          type: "info",
          message: 'The payment provider will add a small fee to your transaction cost. This will be seen on the next page. ',
        });
      }
      if (res.status === "not ok")
        triggerNotification({
          type: "error",
          message: res.message,
        });
    } catch (error) {
      triggerNotification({
        type: "error",
        message: error,
      });
    }
  };

  const onSuccess = async () => {
    const res = await getOrderReceipt(txref);
    resetCart();
    setIsSectionVisible(true);
    if (res.status === "ok") {
      localStorage.removeItem("txref");
      localStorage.removeItem("isSuccess");
    }
  };

  const onFailed = async () => {
    const res = await getOrderReceipt(txref);
    setIsRepayVisible(true);
    if (res.status === "not ok") {
      localStorage.removeItem("txref");
      localStorage.removeItem("isSuccess");
    }
  };

  const resetCart = () => {
    items.map((item) => item?.cart_id && deleteCartItem(item.cart_id));
    reset();
  };

  if (isSuccess === "success" && txref) {
    SuccessRef?.current?.scrollIntoView({ behavior: "smooth" });
    onSuccess();
  }
  if (isSuccess === "failed" && txref) {
    onFailed();
  }

  const showCartContents = () => {
    setCartContents((prevVal) => !prevVal);
  };

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);

    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  useEffect(() => {
    setCountry(countries.find((country) => country.code === code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const onSignIn = async () => {
    const existing_address_id = getId();
    const values = { existing_address_id, order_items, currency: code };
    checkout(values);
  };

  useEffect(() => {
    const prices = [];
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      checkoutItems.map(({ products }) =>
        products.map(({ productPrice, productOrderQuantity }) => {
          return prices.push(productPrice * productOrderQuantity);
        })
      );
      setItemSum(prices.length > 0 ? prices.reduce((a, b) => a + b) : itemSum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutItems]);

  // useEffect(() => {
  //   if (isAuthenticated && options.length === 0) {
  //     onSignIn();
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuthenticated, options]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      const existing_address_id = getId();
      const data = { existing_address_id, order_items, currency: code };
      checkout(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    sumDeliveryFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const groupCartItems = (itemArray) => {
    if (itemArray && itemArray?.length > 0) {
      const cartItems = [...itemArray];
      const result = cartItems.reduce(function (r, a) {
        r[a?.store_info?.store_name] = r[a?.store_info?.store_name] || [];
        r[a?.store_info?.store_name].push(a);
        return r;
      }, Object.create(null));
      // console.log(result);
      return result;
    } else return [];
  };

  const [groupedCartItems, setGroupedCartItems] = useState([]);

  useEffect(() => {
    if (pageItems?.length > 0) {
      setGroupedCartItems(Object.entries(groupCartItems(pageItems)));
    }
  }, [pageItems]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div style={{ background: "#F9F9F9" }}>
      <div className="checkout-top-nav">
        <Dropdown
          trigger={["hover"]}
          open={dropdownOpen}
          overlay={
            <Menu>
              <div className="checkout-logo-dropdown">
                <div>Are you sure you want to return to your Shopping Bag?</div>
                <div
                  style={{ display: "flex", gap: "16px", marginTop: "16px" }}
                >
                  <Button
                    className="btn-stay"
                    onClick={() => navigate("/cart")}
                  >
                    Return to Bag
                  </Button>
                  <Button
                    className="btn-return"
                    onClick={() => setDropdownOpen(false)}
                  >
                    Continue Checkout
                  </Button>
                </div>
              </div>
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <img
            src="/images/losode-no-tagline.png"
            alt="losode-logo"
            width={isTabletOrMobile ? "80px" : "100px"}
            style={{ zIndex: 201, cursor: "pointer" }}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
        </Dropdown>

        <div
          style={{ position: "absolute", width: "100%", textAlign: "center" }}
        >
          Checkout {`(${pageItems?.length} Items)`}
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div className="checkout-container">
        <div className="checkout-section">
          {!isSectionVisible && !isRepayVisible && (
            <section>
              {!isAuthenticated && (
                <>
                  <Radio.Group
                    onChange={(e) => setMode(e.target.value)}
                    value={mode}
                  >
                    <div className="checkout-subsection checkout-login-subsection">
                      <div className="checkout-section-header">
                        <Radio value="sign-in">
                          <div className="checkout-section-title">Sign In</div>
                          <div className="checkout-section-subtitle">
                            To check out faster and track your order. Sign in
                            now!
                          </div>
                        </Radio>
                      </div>
                    </div>
                    {mode === "sign-in" && (
                      <div
                        className="checkout-subsection checkout-proceed-subsection"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="checkout-section-header">
                          <div className="checkout-section-title">Sign In</div>
                          <div className="checkout-section-subtitle">
                            To check out faster and track your order. Sign in
                            now!
                          </div>
                        </div>
                        <div className="checkout-section-body">
                          <Form
                            scrollToFirstError
                            layout="vertical"
                            requiredMark={false}
                            form={userSignInForm}
                            onFinish={onFinish}
                          >
                            <Form.Item
                              label="Email address"
                              name="email"
                              rules={[
                                {
                                  type: "email",
                                  message:
                                    "Please use an acceptable email format",
                                },
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="checkout-signin-form-input"
                                placeholder="Email Address"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Password"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                              extra={
                                <Link
                                  to="/reset-password"
                                  style={{ color: "black" }}
                                >
                                  Forgot password?
                                </Link>
                              }
                            >
                              <Input
                                className="checkout-signin-form-input"
                                placeholder="Password"
                                type="password"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                className="checkout-signin-form-button"
                                htmlType="submit"
                              >
                                Sign In
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    )}

                    <div className="checkout-subsection checkout-login-subsection">
                      <div className="checkout-section-header">
                        <Radio value="guest">
                          <div className="checkout-section-title">
                            Continue As Guest
                          </div>
                          <div className="checkout-section-subtitle">
                            You can choose to continue as a guest now and set up
                            your account after your purchase
                          </div>
                        </Radio>
                      </div>
                    </div>
                    {mode === "guest" && (
                      <div className="checkout-subsection checkout-proceed-subsection">
                        <div className="checkout-section-header">
                          <div className="checkout-section-title">
                            Continue As Guest
                          </div>
                          <div className="checkout-section-subtitle">
                            You can choose to continue as a guest now and set up
                            your account after your purchase
                          </div>
                        </div>
                        <div className="checkout-section-body">
                          <Form
                            scrollToFirstError
                            layout="vertical"
                            requiredMark={false}
                            form={guestSignInForm}
                            onFinish={onRegisterGuest}
                          >
                            <Form.Item
                              label="Email address"
                              name="email"
                              rules={[
                                {
                                  type: "email",
                                  message:
                                    "Please use an acceptable email format",
                                },
                                {
                                  required: true,
                                  message: "This is a required field",
                                },
                              ]}
                            >
                              <Input
                                className="checkout-signin-form-input"
                                placeholder="Email Address"
                              />
                            </Form.Item>

                            {/* <Form.Item>
                              <Checkbox>
                                Sign up for fashion and style editorials
                              </Checkbox>
                            </Form.Item> */}

                            <Form.Item
                              extra={
                                <Link
                                  to="/policy"
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                    marginTop: "8px",
                                    display: "block",
                                  }}
                                >
                                  Find out more about our{" "}
                                  <span style={{ textDecoration: "underline" }}>
                                    privacy policy
                                  </span>
                                </Link>
                              }
                            >
                              <Button
                                className="checkout-signin-form-button"
                                htmlType="submit"
                              >
                                Proceed To Checkout
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    )}
                  </Radio.Group>
                </>
              )}

              <div
                className="non-auth-container"
                style={{ display: `${!isAuthenticated ? isShow : "block"}` }}
              >
                <div className="checkout-subsection checkout-proceed-subsection">
                  <div className="checkout-section-header">
                    <div className="checkout-section-title">
                      Delivery Country
                    </div>
                  </div>
                  <div className="checkout-section-body">
                    <div className="checkout-section-body-country-container">
                      <div className="checkout-section-body-country-content">
                        <img src={country?.icon} alt="NG" />
                        <div>{country?.country}</div>
                      </div>
                      {/* <Dropdown
                        overlay={countryContent}
                        title="Change Country"
                        trigger={["click"]}
                        visible={showPopover}
                        onVisibleChange={handleVisibleChange}
                        placement="bottom"
                      >
                        <Button className="checkout-short-button">
                          Change Country
                        </Button>
                      </Dropdown> */}
                    </div>
                  </div>
                </div>
                <div className="checkout-subsection checkout-proceed-subsection">
                  <div className="checkout-section-header">
                    <div className="checkout-section-title">Email Address</div>
                  </div>
                  <div className="checkout-section-body">
                    <div className="checkout-section-body-country-container">
                      <div className="checkout-section-body-country-content">
                        <div>{info?.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="checkout-subsection checkout-proceed-subsection"
                  style={{
                    background: discountSuccess && "#D1FAE5",
                  }}
                >
                  {!discountSuccess && (
                    <Collapse
                      style={{ border: 0 }}
                      className="voucher-collapse"
                    >
                      <Panel
                        header={
                          <div className="checkout-section-title">
                            Add a Voucher Code
                          </div>
                        }
                        key="1"
                      >
                        <div>
                          <Form
                            scrollToFirstError
                            layout="vertical"
                            requiredMark={false}
                            form={discountForm}
                            onFinish={onApplyDiscount}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "16px",
                                marginBottom: "24px",
                              }}
                            >
                              <Form.Item
                                name="code"
                                rules={[
                                  {
                                    required: true,
                                    message: "This is a required field",
                                  },
                                ]}
                                style={{ width: "100%" }}
                              >
                                <Input
                                  placeholder="Enter code here"
                                  style={{ width: "100%", height: "48px" }}
                                />
                              </Form.Item>
                              <Form.Item style={{ width: "100%" }}>
                                <Button
                                  className="checkout-short-button"
                                  style={{ width: "100%", height: "48px" }}
                                  htmlType="sumit"
                                >
                                  Apply code
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Need to know
                            </div>
                            <div>
                              You can only use one voucher code per order.
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  )}
                  {discountSuccess && (
                    <div className="discount-success-wrapper">
                      <h4 className="txt-discount-success">Congratulations</h4>
                      <span className="span-discount-desc">
                        <span className="txt-coupon">
                          {discountForm.getFieldValue("code")}{" "}
                        </span>{" "}
                        has been applied. Your discount has been added below
                      </span>
                    </div>
                  )}
                </div>

                <div className="checkout-subsection checkout-proceed-subsection">
                  <div className="checkout-section-header">
                    <div className="checkout-section-title">
                      Delivery Address
                    </div>
                    <div className="checkout-section-subtitle">
                      Add an address so that we can get your items to you
                    </div>
                  </div>
                  <div className="checkout-section-body">
                    {contact.length === 0 && (
                      <div className="checkout-section-body-address-button-container">
                        <Button
                          className="checkout-short-button"
                          onClick={() => setAddressModalVisible(true)}
                        >
                          Add Address
                        </Button>
                      </div>
                    )}
                    <div className="checkout-section-body-address-container">
                      {contact.length > 0 && (
                        <div className="checkout-section-body-address-content">
                          <Radio.Group style={{ flex: 2 }}>
                            {contact.slice(0, 1).map((address) => (
                              <Radio>
                                <div className="checkout-section-body-address-content-main">
                                  <h4 className="checkout-section-body-address-content-main-txt-user-name">
                                    {address?.firstname} {address?.lastname}
                                  </h4>
                                  <h4 className="checkout-section-body-address-content-main-address">
                                    {address?.address}
                                    <br />
                                    {address?.city}, <br />
                                    {address?.state} <br />
                                    {address?.postalCode} <br />
                                    {address?.country}
                                    <br />
                                  </h4>
                                  <h4
                                    className="checkout-section-body-address-content-main-address"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {address?.dial}
                                    {address?.phone?.replace(/^0+/, "")}
                                  </h4>
                                </div>
                              </Radio>
                            ))}
                          </Radio.Group>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "column",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              width: "100%",
                              gap: "14px",
                            }}
                          >
                            {contact.length > 1 ? (
                              <Button
                                className="checkout-short-button"
                                onClick={() =>
                                  setDifferentAddressModalVisible(true)
                                }
                              >
                                Change Address
                              </Button>
                            ) : (
                              <Button
                                className="checkout-short-button"
                                onClick={() => setAddressModalVisible(true)}
                              >
                                Change Address
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {!isTabletOrMobile && contact.length !== 0 && (
                  <div className="checkout-select-delivery-option-text">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.0013 13.3327C10.223 13.3327 12.8346 10.721 12.8346 7.49935C12.8346 4.27769 10.223 1.66602 7.0013 1.66602C3.77964 1.66602 1.16797 4.27769 1.16797 7.49935C1.16797 10.721 3.77964 13.3327 7.0013 13.3327Z"
                        stroke="#A3A3A3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 9.83333V7.5"
                        stroke="#A3A3A3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 5.16602H7.00583"
                        stroke="#A3A3A3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Select a delivery option before you can make payments</p>
                  </div>
                )}

                {!isTabletOrMobile && contact.length !== 0 && (
                  <>
                    <div className="checkout-subsection checkout-proceed-subsection">
                      {code === "NGN" ? (
                        <>
                          <div className="checkout-section-header">
                            <div className="checkout-section-title">
                              Select Your Payment Option
                            </div>
                          </div>
                          <div className="checkout-section-body">
                            <div className="checkout-section-body-payment-content">
                              <Radio.Group
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "20px",
                                }}
                                onChange={(e) => {
                                  setPaymentOption(e.target.value);
                                  onSubmitPayment(e.target.value);
                                }}
                              >
                                {/* <Radio
                          value="losodepay"
                          className="checkout-section-body-payment-content-radio"
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img
                                src="/images/losodepay.svg"
                                alt="losodepay"
                              />
                            </div>
                            <div>LosodePay</div>
                          </div>
                        </Radio> */}
                                <Radio
                                  value="paystack"
                                  className="checkout-section-body-payment-content-radio"
                                  disabled={
                                    options.length !== checkoutItems?.length
                                  }
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="checkout-section-body-payment-content-logo-container">
                                      <img
                                        src="/images/paystack.svg"
                                        alt="paystack"
                                      />
                                    </div>
                                    <div>Paystack</div>
                                  </div>
                                </Radio>

                                <Radio
                                  value="flutterwave"
                                  className="checkout-section-body-payment-content-radio"
                                  style={{ border: "0" }}
                                  disabled={
                                    options.length !== checkoutItems?.length
                                  }
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="checkout-section-body-payment-content-logo-container">
                                      <img
                                        src="/images/flutterwave.svg"
                                        alt="flutterwave"
                                      />
                                    </div>
                                    <div>Flutterwave</div>
                                  </div>
                                </Radio>
                              </Radio.Group>

                              <Button
                                className="checkout-signin-form-button"
                                // disabled={
                                //   options.length !== checkoutItems?.length &&
                                //   paymentOption === ""
                                // }
                                disabled={paymentLink === ""}
                                style={{ marginTop: "24px" }}
                                // onClick={() => onSubmitPayment()}
                                onClick={() =>
                                  window.open(paymentLink, "_blank")
                                }
                              >
                                Pay Now
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="checkout-section-header">
                            <div className="checkout-section-title">
                              Payment Options
                            </div>
                          </div>
                          <div className="checkout-section-body">
                            <div className="checkout-section-body-payment-content"></div>
                            <PayPalButtons
                              style={{ layout: "vertical", color: "black" }}
                              disabled={deliveryPrice <= 0}
                              forceReRender={[deliveryPrice]}
                              fundingSource="paypal"
                              createOrder={(data, actions) =>
                                onCreateOrder(data, actions)
                              }
                              onApprove={(data, actions) =>
                                onApproveOrder(data, actions)
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </section>
          )}

          {isSectionVisible && (
            <div
              className="checkout-subsection checkout-proceed-subsection"
              ref={SuccessRef}
            >
              <div className="checkout-section-header">
                <div className="checkout-section-title">
                  Thank you for Shopping With Losode
                </div>
                <div className="checkout-section-subtitle">
                  We have sent you an order confirmation email. Once your Order
                  has been sent, you can track it in your{" "}
                  <Link to="/orders">order history</Link>
                </div>
              </div>
              <div className="checkout-section-body">
                <div className="checkout-section-body-success-content">
                  <table className="checkout-section-body-success-content-table">
                    <tbody>
                      <tr>
                        <td className="checkout-section-body-success-content-table-info-label">
                          Order ID:
                        </td>
                        <td className="checkout-section-body-success-content-table-info-data">
                          {orderDetails?.order_detail?.order_no}
                        </td>
                      </tr>
                      <tr>
                        <td className="checkout-section-body-success-content-table-info-label">
                          Delivery date:
                        </td>
                        <td className="checkout-section-body-success-content-table-info-data">
                          {orderDetails?.order_detail?.expected_pickup_date}
                        </td>
                      </tr>
                      <tr>
                        <td className="checkout-section-body-success-content-table-info-label">
                          Order Status:
                        </td>
                        <td className="checkout-section-body-success-content-table-info-data">
                          <Tag color="success" style={{ padding: " 0 24px" }}>
                            Paid
                          </Tag>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Button
                  className="checkout-signin-form-button"
                  style={{ marginTop: "24px" }}
                  onClick={() => shopNewIn()}
                >
                  Continue Shopping
                </Button>
                <Button
                  className="checkout-signin-form-button"
                  style={{
                    marginTop: "8px",
                    color: "black",
                    background: "white",
                  }}
                  onClick={logout}
                >
                  Sign Out
                </Button>
              </div>
            </div>
          )}

          {isRepayVisible && (
            <>
              <div className="checkout-subsection checkout-proceed-subsection">
                <div className="checkout-section-failure-header">
                  <ExclamationCircleFilled
                    style={{ fontSize: "70px", marginBottom: "24px" }}
                  />
                  <div>
                    Your payment has not gone through on this occasion and you
                    have not been debited. Please use a different payment option
                    or contact your financial institution. We look forward to
                    serving you on Losode
                  </div>
                </div>
                {contact.length !== 0 && (
                  <>
                    <div className="checkout-subsection checkout-proceed-subsection">
                      {code === "NGN" ? (
                        <>
                          <div className="checkout-section-header">
                            <div className="checkout-section-title">
                              Select Your Payment Option
                            </div>
                          </div>
                          <div className="checkout-section-body">
                            <div className="checkout-section-body-payment-content">
                              <Radio.Group
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "20px",
                                }}
                                onChange={(e) => {
                                  setPaymentOption(e.target.value);
                                  onSubmitPayment(e.target.value);
                                }}
                              >
                                {/* <Radio
                          value="losodepay"
                          className="checkout-section-body-payment-content-radio"
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img
                                src="/images/losodepay.svg"
                                alt="losodepay"
                              />
                            </div>
                            <div>LosodePay</div>
                          </div>
                        </Radio> */}
                                <Radio
                                  value="paystack"
                                  className="checkout-section-body-payment-content-radio"
                                  disabled={
                                    options.length !== checkoutItems?.length
                                  }
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="checkout-section-body-payment-content-logo-container">
                                      <img
                                        src="/images/paystack.svg"
                                        alt="paystack"
                                      />
                                    </div>
                                    <div>Paystack</div>
                                  </div>
                                </Radio>
                                {/* <Radio
                          value="paypal"
                          className="checkout-section-body-payment-content-radio"
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img src="/images/paypal.svg" alt="paypal" />
                            </div>
                            <div>PayPal</div>
                          </div>
                        </Radio> */}
                                <Radio
                                  value="flutterwave"
                                  className="checkout-section-body-payment-content-radio"
                                  style={{ border: "0" }}
                                  disabled={
                                    options.length !== checkoutItems?.length
                                  }
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="checkout-section-body-payment-content-logo-container">
                                      <img
                                        src="/images/flutterwave.svg"
                                        alt="flutterwave"
                                      />
                                    </div>
                                    <div>Flutterwave</div>
                                  </div>
                                </Radio>
                              </Radio.Group>
                            </div>
                            <Button
                              className="checkout-signin-form-button"
                              // disabled={
                              //   options.length !== checkoutItems?.length &&
                              //   paymentOption === ""
                              // }
                              disabled={paymentLink === ""}
                              style={{ marginTop: "24px" }}
                              // onClick={() => onSubmitPayment()}
                              onClick={() => window.open(paymentLink, "_blank")}
                            >
                              Pay Now
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="checkout-section-header">
                            <div className="checkout-section-title">
                              Payment Options
                            </div>
                          </div>
                          <div className="checkout-section-body">
                            <div className="checkout-section-body-payment-content"></div>
                            <PayPalButtons
                              style={{ layout: "vertical", color: "black" }}
                              disabled={deliveryPrice <= 0}
                              forceReRender={[deliveryPrice]}
                              fundingSource="paypal"
                              createOrder={(data, actions) =>
                                onCreateOrder(data, actions)
                              }
                              onApprove={(data, actions) =>
                                onApproveOrder(data, actions)
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {isTabletOrMobile && cartContents ? (
          <div className="checkout-order-mini-info-section">
            <div
              className="checkout-order-mini-info-section-title"
              onClick={showCartContents}
            >
              <UpOutlined />
              <div>{pageItems?.length}</div>
            </div>
            <div className="checkout-mini-products-images">
              {pageItems?.slice(0, 2).map((item, idx) => (
                <div className="checkout-mini-products-image">
                  <img
                    src={item.img1}
                    style={{ height: "100%", width: "100%" }}
                    alt=""
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "5px",
              }}
            >
              <div>Total</div>
              <div>{deliveryPrice ? total : "--"}</div>
            </div>
          </div>
        ) : (
          <div className="checkout-order-info-section">
            <div className="checkout-order-info-section-title">
              {/* {isTabletOrMobile && (
                <DownOutlined
                  style={{ marginRight: "8px" }}
                  onClick={showCartContents}
                />
              )} */}
              <div>Your Order Information</div>
              <div>{`(${pageItems?.length} Items)`}</div>
            </div>

            {isAuthenticated &&
            contact.length > 0 &&
            checkoutItems?.length > 0 ? (
              <>
                <div className="checkout-order-info-products-section">
                  {checkoutItems.map((items, index) => {
                    return (
                      <Collapse
                        defaultActiveKey={["1"]}
                        key={index}
                        className="cart-items-collapse"
                      >
                        <Panel
                          header={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: "bold" }}>
                                <span>{items?.store_name}</span>{" "}
                                <span style={{ color: "#6f6f6f" }}>{`(${
                                  items?.products?.length
                                } ${
                                  items?.products?.length === 1
                                    ? "item"
                                    : "items"
                                })`}</span>
                              </div>
                            </div>
                          }
                          key="1"
                        >
                          <div className="right-panel-section-container">
                            <div>
                              {items?.products?.map((item, idx) => (
                                <div
                                  className="cart-page-bag-section-body-item"
                                  key={idx}
                                  style={{
                                    borderBottom:
                                      idx !== items?.products?.length - 1
                                        ? "1px solid #a7a7a7"
                                        : "none",
                                    marginBottom:
                                      idx !== items?.products?.length - 1
                                        ? "24px"
                                        : "none",
                                  }}
                                >
                                  <div className="cart-page-bag-section-body-item-main">
                                    <div
                                      className="cart-page-bag-section-body-item-image"
                                      style={{
                                        backgroundImage: `url(${item.productImage})`,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        flex: 2,
                                      }}
                                    >
                                      <div className="cart-page-bag-section-body-item-text">
                                        {/* <div className="cart-page-bag-section-body-item-text-store-name">
                                  {item?.store_info?.store_name}
                                </div> */}
                                        <div className="cart-item-name">
                                          {capitalizeStr(item.productName)}
                                        </div>
                                        {item.productColor &&
                                          item.productSize && (
                                            <div
                                              style={{
                                                fontFamily:
                                                  "DomaineSansText-Light",
                                                display: "flex",
                                                gap: "8px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  borderRight: "1px solid grey",
                                                  paddingRight: "8px",
                                                }}
                                              >
                                                {item.productColor}
                                              </div>
                                              <div>{item.productSize}</div>
                                            </div>
                                          )}

                                        {/* Quantity changed to select for change */}
                                        <div>
                                          Qty: {item.productOrderQuantity}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {formatter(
                                            item.productPrice *
                                              item.productOrderQuantity,
                                            rate,
                                            code,
                                            indicator
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {contact[0] && (
                              <div className="delivery-section-product">
                                <div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    Select your Delivery Option
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <Radio.Group
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "3px",
                                      }}
                                      onChange={(e) => {
                                        items.delivery_option = e.target.value;
                                        updateOptions();
                                      }}
                                    >
                                      {items.delivery_detail?.map((option) => (
                                        <Radio
                                          value={option}
                                          style={{ display: "flex" }}
                                        >
                                          <div>
                                            <div>
                                              <strong
                                                style={{
                                                  fontSize: "12px",
                                                  fontFamily:
                                                    "DomaineSansText-Regular",
                                                }}
                                              >
                                                {option.name}
                                              </strong>
                                            </div>
                                            <div
                                              style={{
                                                fontSize: "12px",
                                              }}
                                            >
                                              {option.description}
                                            </div>
                                          </div>
                                        </Radio>
                                      ))}
                                    </Radio.Group>

                                    {/* {checkoutItems.length > 1 && (
                                <Radio.Group
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                  onChange={(e) =>
                                    setDeliveryOption(e.target.value)
                                  }
                                >
                                  <Radio
                                    value="standard"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <div>
                                        <strong
                                          style={{
                                            fontSize: "12px",
                                            fontFamily:
                                              "DomaineSansText-Regular",
                                          }}
                                        >
                                          Standard Delivery
                                        </strong>
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        {options[0]?.data[0]?.description}
                                      </div>
                                    </div>
                                  </Radio>
                                  <Radio
                                    value="sameday"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <div>
                                        <strong
                                          style={{
                                            fontSize: "12px",
                                            fontFamily:
                                              "DomaineSansText-Regular",
                                          }}
                                        >
                                          Express Delivery
                                        </strong>
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        {options[1]?.data[0]?.description}
                                      </div>
                                    </div>
                                  </Radio>
                                  <Radio
                                    value="nextday"
                                    style={{ display: "flex" }}
                                  >
                                    <div>
                                      <div>
                                        <strong
                                          style={{
                                            fontSize: "12px",
                                            fontFamily:
                                              "DomaineSansText-Regular",
                                          }}
                                        >
                                          Next Day Delivery Delivery
                                        </strong>
                                      </div>
                                      <div style={{ fontSize: "12px" }}>
                                        {options[2]?.data[0]?.description}
                                      </div>
                                    </div>
                                  </Radio>
                                </Radio.Group>
                              )} */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Panel>
                      </Collapse>
                    );
                  })}
                </div>

                <div className="checkout-order-info-section-body-container">
                  <div className="checkout-order-info-section-body">
                    <div className="checkout-order-info-section-body-items">
                      <div>Sub - Total</div>
                      <div> {formatter(itemSum, rate, code, indicator)}</div>
                    </div>

                    <div className="checkout-order-info-section-body-delivery">
                      <div>Delivery</div>
                      <div>
                        {deliveryPrice
                          ? `${formatter(deliveryPrice, rate, code, indicator)}`
                          : "--"}
                      </div>
                    </div>
                    <div className="checkout-order-info-section-total">
                      <div>Discount</div>
                      <div>
                        {discountAmount ? (
                          <span style={{ color: "#800000" }}>
                            -{formatter(discountAmount, rate, code, indicator)}
                          </span>
                        ) : (
                          "--"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="checkout-order-info-section-total">
                    <div>Total</div>
                    <div>{deliveryPrice ? total : "--"}</div>
                  </div>
                </div>
              </>
            ) : (
              <div className="checkout-order-info-products-section">
                {groupedCartItems?.map((items, index) => {
                  return (
                    <Collapse
                      defaultActiveKey={["1"]}
                      key={index}
                      className="cart-items-collapse"
                    >
                      <Panel
                        header={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ fontWeight: "bold" }}>
                              <span>{items[0]}</span>{" "}
                              <span style={{ color: "#6f6f6f" }}>{`(${
                                items[1]?.length
                              } ${
                                items[1]?.length === 1 ? "item" : "items"
                              })`}</span>
                            </div>
                          </div>
                        }
                        key="1"
                      >
                        <div className="right-panel-section-container">
                          <div>
                            {items[1]?.map((item, idx) => (
                              <div
                                className="cart-page-bag-section-body-item"
                                key={idx}
                                style={{
                                  borderBottom:
                                    idx !== items[1]?.length - 1
                                      ? "1px solid #a7a7a7"
                                      : "none",
                                  marginBottom:
                                    idx !== items[1]?.length - 1
                                      ? "24px"
                                      : "none",
                                }}
                              >
                                <div className="cart-page-bag-section-body-item-main">
                                  <div
                                    className="cart-page-bag-section-body-item-image"
                                    style={{
                                      backgroundImage: `url(${item.img1})`,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      flex: 2,
                                    }}
                                  >
                                    <div className="cart-page-bag-section-body-item-text">
                                      {/* <div className="cart-page-bag-section-body-item-text-store-name">
                                  {item?.store_info?.store_name}
                                </div> */}
                                      <div
                                        className="cart-item-name"
                                        style={{ maxWidth: "max-content" }}
                                      >
                                        {capitalizeStr(item.name)}
                                      </div>
                                      {item.selectedColor?.name &&
                                        item.selectedSize && (
                                          <div
                                            style={{
                                              fontFamily:
                                                "DomaineSansText-Light",
                                              display: "flex",
                                              gap: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                borderRight: "1px solid grey",
                                                paddingRight: "8px",
                                              }}
                                            >
                                              {item.selectedColor?.name}
                                            </div>
                                            <div>{item.selectedSize}</div>
                                          </div>
                                        )}

                                      {/* Quantity changed to select for change */}
                                      <div>Qty: {item.quantityWanted}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formatter(
                                          item.totalPrice,
                                          rate,
                                          code,
                                          indicator
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {isTabletOrMobile && contact.length !== 0 && (
          <div className="checkout-select-delivery-option-text">
            <svg
              width="18"
              height="19"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0013 13.3327C10.223 13.3327 12.8346 10.721 12.8346 7.49935C12.8346 4.27769 10.223 1.66602 7.0013 1.66602C3.77964 1.66602 1.16797 4.27769 1.16797 7.49935C1.16797 10.721 3.77964 13.3327 7.0013 13.3327Z"
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 9.83333V7.5"
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 5.16602H7.00583"
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Select a delivery option before you can make payments</p>
          </div>
        )}

        {isTabletOrMobile && contact.length !== 0 && (
          <>
            <div className="checkout-subsection checkout-proceed-subsection">
              {code === "NGN" ? (
                <>
                  <div className="checkout-section-header">
                    <div className="checkout-section-title">
                      Select Your Payment Option
                    </div>
                  </div>
                  <div className="checkout-section-body">
                    <div className="checkout-section-body-payment-content">
                      <Radio.Group
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                        onChange={(e) => {
                          setPaymentOption(e.target.value);
                          onSubmitPayment(e.target.value);
                        }}
                      >
                        {/* <Radio
                  value="losodepay"
                  className="checkout-section-body-payment-content-radio"
                >
                  <div style={{ display: "flex" }}>
                    <div className="checkout-section-body-payment-content-logo-container">
                      <img
                        src="/images/losodepay.svg"
                        alt="losodepay"
                      />
                    </div>
                    <div>LosodePay</div>
                  </div>
                </Radio> */}
                        <Radio
                          value="paystack"
                          className="checkout-section-body-payment-content-radio"
                          disabled={options.length !== checkoutItems?.length}
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img src="/images/paystack.svg" alt="paystack" />
                            </div>
                            <div>Paystack</div>
                          </div>
                        </Radio>

                        <Radio
                          value="flutterwave"
                          className="checkout-section-body-payment-content-radio"
                          style={{ border: "0" }}
                          disabled={options.length !== checkoutItems?.length}
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img
                                src="/images/flutterwave.svg"
                                alt="flutterwave"
                              />
                            </div>
                            <div>Flutterwave</div>
                          </div>
                        </Radio>
                      </Radio.Group>

                      <Button
                        // disabled={
                        //   options.length !== checkoutItems?.length &&
                        //   paymentOption === ""
                        // }
                        disabled={paymentLink === ""}
                        // onClick={() => onSubmitPayment()}
                        onClick={() => window.open(paymentLink, "_blank")}
                        className="checkout-signin-form-button"
                        style={{ marginTop: "24px" }}
                      >
                        Pay Now
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="checkout-section-header">
                    <div className="checkout-section-title">
                      Payment Options
                    </div>
                  </div>
                  <div className="checkout-section-body">
                    <div className="checkout-section-body-payment-content"></div>
                    <PayPalButtons
                      style={{ layout: "vertical", color: "black" }}
                      disabled={deliveryPrice <= 0}
                      forceReRender={[deliveryPrice]}
                      fundingSource="paypal"
                      createOrder={(data, actions) =>
                        onCreateOrder(data, actions)
                      }
                      onApprove={(data, actions) =>
                        onApproveOrder(data, actions)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {}
      </div>

      <AddressModal
        visible={isAddressModal}
        setVisible={setAddressModalVisible}
        onSubmit={onSubmitAddress}
        type="new"
      />

      <Modal
        centered
        open={differentAddressModalVisible}
        footer={null}
        onCancel={() => setDifferentAddressModalVisible(false)}
        width={600}
        style={{ fontFamily: "DomaineSansText-Light" }}
        maskClosable={false}
        className="checkout-different-address-modal"
      >
        <div>
          <div className="new-address-modal-title">Change Delivery Address</div>
          <div>
            <div>
              <Radio.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gap: "16px",
                }}
                onChange={(e) => setMainAddress(e.target.value)}
                defaultValue={contact[0]?.id}
              >
                {contact.map((address) => (
                  <Radio value={address.id} className="address-edit-radio-item">
                    <div style={{ marginBottom: "4px" }}>
                      {address.status === 1
                        ? "Main Address"
                        : "Additional Address"}
                    </div>
                    <div className="">
                      <h4 className="">
                        {address?.address}
                        <br />
                        {address?.city}, <br />
                        {address?.state} <br />
                        {address?.postalCode} <br />
                        {address?.country}
                        <br />
                      </h4>
                      <h4
                        className="checkout-section-body-address-content-main-address"
                        style={{ paddingTop: "4px" }}
                      >
                        {address?.dial}
                        {address?.phone?.replace(/^0+/, "")}
                      </h4>
                    </div>
                    <div
                      style={{
                        color: "#800000",
                        fontSize: "14px",
                        cursor: "pointer",
                        textAlign: "right",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        editAddress(address);
                      }}
                    >
                      Edit
                    </div>
                  </Radio>
                ))}
              </Radio.Group>

              <div
                style={{
                  borderTop: "1px solid #a7a7a7",
                  marginTop: "40px",
                  paddingTop: "24px",
                }}
              >
                <div style={{ fontSize: "18px" }}>Add A New Address</div>
                <div className="checkout-section-subtitle">
                  To add an address we do not already have saved, click on the
                  button
                </div>
                <div className="new-address-button-container">
                  <Button
                    className="checkout-short-button"
                    onClick={() => {
                      setDifferentAddressModalVisible(false);
                      setAddressModalVisible(true);
                    }}
                  >
                    Add New Address
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <AddressModal
            address={currentAddress}
            visible={editAddressModalVisible}
            setVisible={setEditAddressModalVisible}
            onSubmit={onUpdateAddress}
            type="edit"
          />
        </div>
      </Modal>

      <style>
        {`
          .checkout-top-nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            position: fixed;
            width: 100%;
            background: white;
            font-family: 'DomaineSansText-Light';
            font-size: 18px;
            font-weight: bold;
            height: 80px;
            z-index: 100;
            box-shadow: 0 2px 10px 0 #05050526;
          }
          .checkout-logo-dropdown{
            background:#fff;
            font-family: 'DomaineSansText-Light';
            padding:16px
          }
          .checkout-logo-dropdown .btn-stay{
            font-size: 14px;
            height: 40px;
            background: white;
            color: black;
            width:100%
          }
          .checkout-logo-dropdown .btn-return{
            font-size: 14px;
            height: 40px;
            background: black;
            color: white;
            width:100%
          }
          .checkout-order-mini-info-section{
            width: 100%;
            display: flex;
            padding: 18px 8px;
            background-color: #FFFFFF;
          }
          .checkout-order-mini-info-section-title{
            margin-right: 7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           }
           .checkout-mini-products-images{
            display: flex;
            flex: 2;
           }
           .checkout-mini-products-image {
            height: 90px;
            width: 70px;
            margin-left: 15px;
           }
          .checkout-container{
            font-family: 'DomaineSansText-Light';
            font-size: 14px;
            background: #F9F9F9;
            padding: 40px 60px;
            display: flex;
            gap: 40px;
            justify-content: space-between;
            width: 100vw;
            padding-top:120px;
            max-width: 1360px;
            margin: auto;
          }
          .checkout-section{
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 100%;
            max-width: 45vw;
          }
          .checkout-section-header{
            max-width: 75%;
          }
          .checkout-login-subsection{
            background: white;
            margin-bottom: 10px;
            padding: 24px 8px;
            width: 100%;
          }
          .checkout-subsection{
            background: white;
            padding: 24px;
            width: 100%;
            margin-bottom:24px
          }
          .checkout-section-title{
            font-size: 20px;
            font-family: 'DomaineSansText-Regular';
          }
          .checkout-section-subtitle{
            font-size:14px;
            margin-top:8px;
          }

          .discount-success-wrapper {
            background:#D1FAE5;
          }
          .txt-discount-success {
            font-family: DomaineSansText-Regular;
            font-size: 18px;
            font-weight: 600;
            line-height: 23.27px;
            text-align: left;
            margin-bottom:20px;
          }

          .txt-coupon {
            font-family: DomaineSansText-Regular;
            font-size: 16px;
            font-weight: 600;
            line-height:20.99px;
          }
          .txt-discount-success span{
            font-family: DomaineSansText-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.69px;
            text-align: left;

          }
          .checkout-order-info-section{
            background: white;
            padding: 24px;
            height: fit-content;
            width: 100%;
            max-width: 45vw;
          }
          .checkout-order-info-section-title{
            font-size: 20px;
            text-align: center;
            font-family: 'DomaineSansText-Regular';
            padding: 16px;
            border-bottom: 1px solid #a7a7a7;
          }
          .checkout-order-info-section-body{
            border-bottom: 1px solid #a8a8a8;
            padding-bottom: 24px;
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
          .checkout-order-info-section-body-items, 
          .checkout-order-info-section-body-delivery,
          .checkout-order-info-section-total{
            display: flex;
            justify-content: space-between;
          }

          .checkout-order-info-products-section{
            display: flex;
            flex-direction: column;
            padding: 24px 0;
            gap:16px;
            background: white;
          }
          .checkout-order-info-products-section-item{
            background: white;
            position: relative;
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid #a7a7a7;
          }
          .checkout-order-info-products-section-item-main{
            display: flex;
            gap: 16px;
          }
          .checkout-order-info-products-section-item-image{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 230px;
            width: 160px;
          }

          .checkout-login-subsection .ant-radio-inner{
            height: 30px;
            width: 30px;
          }
          .checkout-login-subsection .ant-radio{
            padding: 016px
          }
          .checkout-login-subsection .ant-radio-wrapper{
            display: flex;
            align-items: center;
          }
          .checkout-login-subsection .ant-radio-checked .ant-radio-inner::after{
            transform: scale(1.8);
          }

          .checkout-signin-form-input{
            height: 48px;
          }

      

          .checkout-signin-form-button{
            height:48px;
            width:100%;
            background:black;
            color:white;
            font-size:16px
          }
          .ant-form-item-explain,
          .ant-form-item-extra {
            margin: 4px 0
          }

          .checkout-section-body-country-container{
            display:flex;
            justify-content:space-between;
            align-items:center
          }
          .checkout-section-body-country-content{
            display:flex;
            gap:16px;
            align-items:center
          }
          .checkout-short-button{
            width: 225px;
            font-size: 14px;
            height:48px;
            background:black;
            color:white;
          }
          .checkout-proceed-subsection .checkout-section-header{
            padding-left:16px
          }
          .checkout-proceed-subsection .checkout-section-title{
            font-size: 20px;
            font-family: 'DomaineSansText-Regular';
          }
          .checkout-proceed-subsection .checkout-section-body {
            padding: 24px 16px 0;
            padding-right: 0;
          }

          .checkout-section-body-address-content{
            display:flex;
            justify-content:space-between;
            // align-items:flex-end
          }

          .checkout-section-body-address-button-container{
            display:flex;
            justify-content:flex-end;
            flex-direction:column;
            align-items:flex-end;
            margin-top:16px;
            padding-top:16px;
            border-top: 1px solid #a7a7a7;
          }

          .delivery-section-product .ant-radio-wrapper{
            margin-right:0px;
          }
          .delivery-section-product .ant-radio{
            height:10px;
          }



          .checkout-address-modal .input {
            height: 48px;
            border: 1px solid #707070;
            padding: 4px 0px 4px 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .checkout-address-modal .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .checkout-address-modal .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .checkout-address-modal .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .checkout-address-modal .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }
          .checkout-address-modal .ant-form-item-with-help .ant-form-item-explain{
            margin-top:4px
          }
          .checkout-address-modal .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            line-height:45px
          }
          .new-address-modal-title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
          }
          .new-address-modal-save-button{
            width: 100%;
            padding: 0 40px;
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            margin: 24px 0;
          }
          .address-modal-cancel-button{
            font-size: 28px;
            color: black;
            margin-top: 16px;
            margin-right: 16px;
          }
          .checkout-section-body-payment-content .checkout-section-body-payment-content-logo-container{
            width: 200px;
            margin-left: 40px;
          }
          .checkout-section-body-payment-content .checkout-section-body-payment-content-radio{
            display: flex;
            border-bottom: 1px solid rgb(167, 167, 167);
            padding-bottom: 16px;
            align-items: center;
          }
          .checkout-section-body-success-content-table {
            border-collapse: unset;
          }
          .checkout-section-body-success-content-table td {
            padding: 0 60px 20px 0;
          }
          .checkout-section-body-success-content-table-info-label {
            color: black;
            font-weight: bold;
            font-family: 'DomaineSansText-Black';
          }
          .checkout-section-body-success-content-table-info-data{
            // font-family: 'DomaineSansText-Black'
          }
          .checkout-section-failure-header{
            max-width: 600px;
            font-size: 20px;
            text-align: center;
            padding: 24px;
            margin:auto;
          } 

          h4, h3{
            color:black
          }
          
          .checkout-different-address-modal{
            color:black
          }

          .checkout-different-address-modal .new-address-button-container{
            display:flex;
            justify-content:flex-end;
            flex-direction:column;
            align-items:flex-end;
            margin-top:16px;
            padding-top:16px;
          }
       

          .checkout-page-bag-section-body-item-text{
            display: flex;
            gap: 16px;
            flex-direction: column;
          }
          .checkout-page-bag-section-body-item-text-store-name{
            text-transform: uppercase;
            font-family: DomaineSansText-Regular;
            font-weight: bold;
            font-size: 16px;
          }
          .select-city.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
            height:100%
          }

          .cart-page-bag-section-body-item{
            background: white;
            position: relative;
            // margin-bottom: 24px;
            padding-bottom: 24px;
            // border-bottom: 1px solid #a7a7a7;
          }

          .cart-item-name {
            text-transform:capitalize;
            font-family: DomaineSansText-Regular;
            font-weight:bold;
            font-size: 12px;
            max-width: 160px;
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 3.6em;
            line-height: 1.8em;
          }
          .cart-page-bag-section-body-item-main{
            display: flex;
            gap: 16px;
          }
            .quantity-select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
            border:0px;
          }
          .cart-page-bag-section-body-item-image{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 125px;
            width: 90px;
          }
          .cart-page-bag-section-body-item-delete-btn{
            position: absolute;
            top: 0;
            right: 0;
            font-size: 20px;
            cursor:pointer
          }
          .cart-page-bag-section-body-item-delete-btn:hover{
            color:#800000
          }

          .cart-page-bag-section-body-item-text{
            display: flex;
            gap: 8px;
            flex-direction: column;
            font-size: 12px;
          }
          .cart-page-bag-section-body-item-text-store-name{
            text-transform: uppercase;
            font-family: DomaineSansText-Regular;
            font-weight: bold;
            font-size: 14px;
          }

          .cart-items-collapse .ant-collapse-content{
            border-color:#6f6f6f5e
          }
          .cart-items-collapse{
            border: 1px solid #6f6f6f5e
          }
          .cart-items-collapse .ant-collapse-item{
            border:0
          }
          .cart-items-collapse .ant-collapse-header{
            background:#fff
          }
          .voucher-collapse .ant-collapse-header{
            background:#fff
          }


          .voucher-collapse, .voucher-collapse .ant-collapse-item, 
          .voucher-collapse .ant-collapse-header, .voucher-collapse .ant-collapse-content{
            border:0
          }
          .voucher-collapse .ant-collapse-header{
            background:#fff;
            flex-direction: row-reverse;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding:0 16px !important
          }

          .delivery-section-product{
            font-size: 12px;
            border-left: 1px solid #6f6f6f5e;
            height: fit-content;
            padding-left: 16px;
          }

          .right-panel-section-container{
            display:flex;
            gap:16px
          }

          .address-edit-radio-item span.ant-radio + *{
            width:100%
          }

          .checkout-select-delivery-option-text {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px; 
          }

          .checkout-select-delivery-option-text p {
            color: #6F6F6F;
            font-size: 16px;
            margin: 0;
            margin-left: 8px;
          }

          @media(max-width:640px){
            .checkout-select-delivery-option-text {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 5px; 
            }
  
            .checkout-select-delivery-option-text p {
              color: #6F6F6F;
              font-size: 14px;
              margin: 0;
              margin-left: 4px;
            }
            .checkout-container{
              padding: 16px 8px;
              height: fit-content;
              flex-direction: column;
              padding-top:100px;
            }
            .checkout-section{
              width:100%;
              max-width:100%;
            }
            .checkout-subsection{
              // margin-bottom: 0px;
              padding:16px;
            }
            .checkout-proceed-subsection .checkout-section-body{
              min-width:200px;
              width:100%;
              padding: 0;
            }
            .checkout-proceed-subsection .checkout-section-header{
              padding:0 0 16px
            }
            .checkout-section-header {
              max-width: 100%;
            }
            .checkout-proceed-subsection .checkout-section-body{
              max-width: 100%;
            }
            .checkout-section-title, .checkout-proceed-subsection .checkout-section-title{
              font-size:18px
            }
            .checkout-login-subsection .ant-radio{
              padding:8px
            }
            .checkout-login-subsection .ant-radio-inner {
              height: 24px;
              width: 24px;
            }
            .checkout-section-body-address-content{
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
            }

            .checkout-short-button {
              width: auto;
              padding: 0 16px;
            }
            .checkout-order-info-section{
              padding:0;
              width:100%;
              max-width:100%;
            }
            .checkout-section-body-payment-content .checkout-section-body-payment-content-logo-container{
              width: 150px;
            }
            
            .address-different-btn{
              align-self: flex-end;
              width:215px;
            }

            .checkout-section-body-success-content-table td{
              padding: 0 40px 20px 0;
            }
            .checkout-section-failure-header{
              font-size: 16px;
              padding: 0;
            }

            .checkout-order-info-section-title{
              font-size: 16px;
              text-align: left;
              display: flex;
              gap: 8px;
              flex-wrap: wrap;
              padding: 0 0 14px;
              margin: 8px;
              align-items: center;
            }

            .checkout-page-bag-section-body-item{
              font-size:13px
            }
            .checkout-order-info-products-section-item-image{
              height: 160px;
              background-position: top
            }
            .checkout-page-bag-section-body-item-text{
              gap: 8px;
            }
            .checkout-page-bag-section-body-item-text-store-name{
              font-size: 14px;
            }
            .checkout-order-info-products-section{
              padding:24px 12px
            }
            .checkout-order-info-section-body-container{
              padding: 12px;
            }
            .checkout-order-info-section-contents-container{
              display:flex;
              flex-direction:column-reverse
            }
            .checkout-order-info-section-total{
              border-bottom: 1px solid #a8a8a8;
              padding-bottom: 24px;
            }
            .mobile-collapsed-info-section{
              display:flex;
              justify-content:space-between;
              font-size:14px;
              align-items:center
            }
            .mobile-collapsed-info-section img{
              width: 55px;
              height: 70px;
              margin: 0 8px
            }

            .cart-page-bag-section-body-item{
              font-size:13px
            }
            .cart-page-bag-section-body-item-image{
              height: 140px;
              background-position: top
            }
            .cart-page-bag-section-body-item-text{
              gap: 8px;
            }
            .cart-page-bag-section-body-item-text-store-name{
              font-size: 14px;
            }
            .cart-page-bag-section-body-item-delete-btn{
              display:none
            }

            .delivery-section-product{
              border-left:0;
              border-top: 1px solid #6f6f6f5e;
              padding-left: 0;
              padding-top: 16px;
            }
            .right-panel-section-container{
              flex-direction:column
            }



          }


        `}
      </style>
    </div>
  );
};

export default Checkout;
