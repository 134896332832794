// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StaticPageTemplate from "./StaticPageTemplate";

const Delivery = () => {

  const navigate = useNavigate()

  return (
    <div>
      <StaticPageTemplate title={"Delivery Information"}>
      <div className="static-content-container">
        <p>At Losode, our goal is to ensure that you receive your orders on time and that they meet our quality criteria. We have arrangements in place with the leading delivery providers to ensure that your products are delivered to you on time, all the time.</p>
        
        <p>We offer four delivery options as follows:</p>
        <ul>
          <li>Same Day Delivery</li>
          <li>Express Next Day Delivery</li>
          <li>Express International Delivery</li>
          <li>Standard Delivery</li>
        </ul>
        
        <p>Your shipping cost is based on the delivery option you select, i.e., same day, next day, or standard, the number of designers you shop from, the number of items that you purchase, the distance between your delivery address and the designer’s store, and the total weight of all the items. The total shipping cost is displayed during the checkout process before you proceed to payment.</p>
        
        <p>When you place an order with Losode, we will process your order as soon as we have verified payment. You will receive an email confirming your purchase and delivery timescales.</p>
        
        <p>When your order has been dispatched, you will receive a shipping notification and tracking number, where available. You may also get information on which carrier will deliver your goods and any other pertinent information.</p>
        
        <p>All timeframes are estimates and exclude holidays or weekends.</p>
        
        <p>If you have any questions, please contact The Team at Losode at <a href="mailto:hello@losode.com"  style={{fontWeight:'bold', fontFamily:'DomaineSansText-Black', textDecoration:'underline'}}>hello@losode.com</a> quoting your order number or by using our <span onClick={() => navigate("/contact-us")} style={{fontWeight:'bold', cursor:"pointer", fontFamily:'DomaineSansText-Black', textDecoration:'underline'}}>contact form</span>.</p>
        
        <p>For further information, please refer to the <span onClick={() => navigate("/faqs", {state:{activeFAQ:'delivery'}})} style={{fontWeight:'bold', cursor:"pointer", fontFamily:'DomaineSansText-Black', textDecoration:'underline'}} >delivery FAQs</span> .</p>
      </div>
      </StaticPageTemplate>

      <style jsx="true">{`
        .privacy-container{
          font-family: DomaineSansText-Light;
          font-size:16px;
          padding: 32px;
          background: #f9f9f9;
        }
        .privacy-container p {
          font: normal normal 16px/30px DomaineSansText-Light;
        }
        .privacy-container h4 {
          font: normal bold 18px/24px DomaineSansText-Light;
        }
        .privacy-container ul{
          padding-inline-start:17px
        }
        .privacy-footer {
          display: flex;
          justify-content: flex-end;
        }
        .social-links {
          display: flex;
          justify-content: space-between;
          width: 140px;
        }
        @media(max-width:640px){
          .privacy-container p{
            font-size:13px;
            margin:0px;
          }
          .privacy-container li{
            font-size:13px !important;
          }
          .lg-txt{
            font-size:24px;
            line-height:26px;
            font-family:DomaineSansText-Light;
            font-weight:700;
          }
          .text-container{
            padding:20px 10% ;
          }
          .overlay::before, .title-div-privacy{
            height:124px
          }
          .privacy-container {
            margin: 40px 10%;
            margin-bottom:70px;
          }
          .sm-txt{
            font-size:11px;
            margin:0;
            font-family: DomaineSansText-Light;
          }
          h4{
            font-weight:700;
            margin:0;
            margin-top:20px !important;
            font-size:14px !important;
            color: black !important;
          }
          h3{
            font-weight:700;
            font-size:20px !important;
            margin-top:2em !important;
          }
          ul{
            padding-left:30px;
          }
          .hello-losode{
            margin:0 !important;
          }
          
        }
      `}</style>
    </div>
  );
};

export default Delivery;
