import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { productsActionCreators } from "../redux/products";

const HoverMenu = ({ visible, category, showCatMenu, hideCatMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { type } = useSelector((state) => state.user);

  const titles = {
    Clothing: { title: "Style that suits", desc: " For every occasion" },

    Accessories: {
      title: "Accentuate your look",
      desc: "Bags, jewellery and hats ",
    },

    Footwear: {
      title: "Step out in style",
      desc: "Footwear that accentuates ",
    },

    "Skin and Beauty": {
      title: type === "Women" ? " Care for you" : "Good things happen",
      desc: type === "Women" ? "Skin and beauty" : "When men groom",
    },
  };

  const imageMen = {
    Clothing:
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599045/website-pictures-optimized/men1_uvcdwj_l44bg2.jpg",
    Accessories: "/images/category_accessories.png",
    Footwear:
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1639599193/product-images/xy86nokzp1eavc2hlqop.png",
    Beauty:
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599085/website-pictures-optimized/men3_grrld4_mbwswq.jpg",
  };
  const imageWomen = {
    Clothing:
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599051/website-pictures-optimized/hom-car_m61oic_awnuxf.jpg",
    Accessories:
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1646382956/website-pictures-optimized/hom5_fvhu39.jpg",
    Footwear: "/images/category_footwear.png",
    "Skin and Beauty":
      "https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599029/website-pictures-optimized/hom-car1_pqwzui_wmg4ag.png",
  };

  const { subs = [], menu_data = {} } = useSelector(
    (state) => state.products.headerCategories
  );

  const { fetchHeaderCategories } = bindActionCreators(
    productsActionCreators,
    dispatch
  );

  const images = type === "Women" ? imageWomen : imageMen;

  const shopCategory = (id, slug) => {
    navigate(`/products/category/cid=${id}&name=${slug}?page=1`);
    hideCatMenu();
  };

  const shopBrands = (slug) => {
    navigate(`/designers/${slug}`);
    hideCatMenu();
  };

  useEffect(() => {
    fetchHeaderCategories(category, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div className="hover-menu-container">
      <div className="menu-overlay" />
      <div
        className="menu-content"
        onMouseEnter={() => showCatMenu(category)}
        onMouseLeave={() => hideCatMenu()}
      >
        <div className="subs-design-cat-container">
          <div className="subs-cat">
            <h3 className="cat-title">{category}</h3>
            {subs?.slice(0, 8).map((sub) => (
              <p
                className="sub-item"
                key={sub.id}
                onClick={() => shopCategory(sub.id, sub.slug)}
              >
                {sub.category}
              </p>
            ))}
          </div>
          <div className="designers-cat">
            <h3 className="cat-title">Designers</h3>
            {menu_data?.designers?.slice(0, 8).map((designer) => (
              <p
                className="sub-item"
                key={designer.store_id}
                onClick={() => shopBrands(designer.slug)}
              >
                {designer.store_name}
              </p>
            ))}
          </div>
        </div>
        {/* <div className="shop-by-cat">
          <h3 className="cat-title">Shop By brand</h3>
          {menu_data?.shop_by_brand?.map((shop) => (
            <p
              className="sub-item"
              key={shop.store_id}
              onClick={() => shopBrands(shop.store_id, shop.slug)}
            >
              {shop.store_name}
            </p>
          ))}
        </div> */}
        <div className="cat-img-container">
          {/* <div className="menu-divider"></div> */}
          <div>
            <img className="cat-img" src={images[category]} alt="brand" />
            <p
              className="txt-featured-brand"
              style={{ marginBottom: "5px", fontSize: "18px", color: "#000" }}
            >
              {titles[category]?.title}
            </p>
            <p className="txt-shop-featured-brand"> {titles[category]?.desc}</p>
          </div>
        </div>

        <style jsx="true">{`
          .menu-overlay {
            position: fixed;
            left: 0px;
            top: 214px;
            height: 80%;
            width: 100%;
            background-color: black;
            opacity: 0.5;
            z-index: 998;
          }
          .hover-menu-container {
            display: ${visible};
            width: 100%;
          }

          .menu-content {
            display: flex;
            justify-content: space-between;
            min-height: 50vh;
            background: #ffffff;
            z-index: 999;
            padding: 40px 50px;
            position: absolute;
            width: 100%;
          }
          .sub-item {
            cursor: pointer;
            font: normal normal 13px/16px DomaineSansText-Light;
            margin-top: 16px;
            height: 25px;
            margin-bottom: 0;
            color: #000;
          }
          .sub-item:hover {
            color: #800000;
          }
          .cat-title {
            color: #777777;
            font: normal normal 14px/18px DomaineSansText-Regular;
            text-transform: uppercase;
          }
          .cat-img {
            object-fit: cover;
            object-position: center;
            height: 282px;
            width: 282px;
          }
          // .subs-cat {
          //   flex-basis: 20%;
          // }
          // .designers-cat {
          //   flex-basis: 20%;
          // }
          .subs-design-cat-container {
            display: flex;
            // justify-content: space-between;
            // gap: 43px;
          }
          .shop-by-cat {
            flex-basis: 20%;
          }
          .cat-img-container {
            flex-basis: 40%;
            display: flex;
            justify-content: flex-end;
          }
          // .menu-divider {
          //   width: 1px;
          //   background: #a5a5a5;
          // }
        `}</style>
      </div>
    </div>
  );
};

export default HoverMenu;
