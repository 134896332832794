import { useState } from "react";
import { Badge, Button, Dropdown, Menu, Popover, Layout } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { currencyActionCreators } from "../../redux/currency";
import { useSelector, useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import "../../styles/header.css";
import formatter from "../../utils/formatter";
import { countries } from "../../assets/constants";
import AuthModal from "../AuthModal";

const { Header } = Layout;

const HeaderComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [buyerAuthModalVisible, setBuyerAuthModalVisible] = useState(false);
  const [buyerSignInAuthModalVisible, setBuyerSignInAuthModalVisible] =
    useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const { info, isAuthenticated, type } = useSelector((state) => state.user);
  const { items: products, sum } = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };

  const { fetchCurrency } = bindActionCreators(
    currencyActionCreators,
    dispatch
  );

  const { logout } = bindActionCreators(userActionCreators, dispatch);

  const signOut = () => {
    logout();
    navigate("/");
  };

  const goHome = () => {
    if (isAuthenticated && info.role === "2") {
      if (info.email_verified === true) {
        if (info.has_created_store === false) {
          navigate("/create-store");
        }
        if (info.has_created_store === true) {
          navigate("/account");
        }
      }
      if (info.email_verified !== true) {
        navigate("/seller/email-verification");
      }
    } else {
      navigate("/");
    }
  };

  const handleVisibleChange = (newVisible) => {
    setShowPopover(newVisible);
  };

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const cartMenu = (
    <Menu>
      {products.length !== 0 ? (
        <div
          style={{
            padding: "16px",
            fontFamily: "DomaineSansText-Light",
            width: "400px",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              borderBottom: " 1px solid #bfbfbf",
              paddingBottom: "4px",
            }}
          >
            <div>My Shopping Bag</div>
            <div>({products.length})</div>
          </div>
          <div>
            {products.map((item) => (
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  fontSize: "12px",
                  borderBottom: "1px solid #bfbfbf",
                  padding: "12px 0",
                }}
              >
                <div
                  className="cart-item-img"
                  style={{
                    backgroundImage: `url(${item.img1})`,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flex: 2,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textTransform: "uppercase",
                        fontFamily: "DomaineSansText-Regular",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.store_info?.store_name}
                    </div>
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "DomaineSansText-Light",
                      }}
                    >
                      {item.name}
                    </div>
                    {item.selectedColor && item.selectedSize && (
                      <div
                        style={{
                          fontFamily: "DomaineSansText-Light",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: "1px solid grey",
                            paddingRight: "8px",
                          }}
                        >
                          {item.selectedColor.name}
                        </div>
                        <div>{item.selectedSize}</div>
                      </div>
                    )}
                    <div>Qty: {item.quantityWanted}</div>
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {formatter(item.price, rate, code, indicator)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
            >
              <div>Total</div>
              <div style={{ fontWeight: "bold" }}>
                {" "}
                {formatter(sum, rate, code, indicator)}
              </div>
            </div>
            <Button
              style={{
                color: "white",
                background: "black",
                width: "100%",
                fontSize: "12px",
                height: "40px",
                marginTop: "16px",
              }}
              onClick={() => navigate("/cart")}
            >
              Proceed To Bag
            </Button>
          </div>
        </div>
      ) : (
        <div className="header-cart-page-no-item-supercontainer">
          <div className="header-cart-page-no-item-container">
            <img
              className="header-cart-page-no-item-bag-icon"
              src="./images/icon-cart.svg"
              alt=""
            />
            <div className="header-cart-page-no-item-title">
              Your Shopping Bag is Empty
            </div>
            <div className="header-cart-page-no-item-text">
              Add items to your Shopping Bag
            </div>
            <div className="header-cart-page-no-item-button">
              <Button
                className="btn-view-saved-items"
                onClick={() => navigate("/saved-items")}
              >
                View Saved Items
              </Button>
            </div>
            <div
              className="header-cart-page-no-item-shop-link"
              onClick={shopNewIn}
            >
              Continue Shopping
            </div>
          </div>
        </div>
      )}
    </Menu>
  );

  const menu = (
    <Menu>
      {countries.map((currency, idx) => (
        <Menu.Item
          key={idx}
          onClick={() => {
            fetchCurrency(currency.code);
          }}
        >
          <img className="icon-cur" src={currency.icon} alt={currency.name} />
          <span className="txt-cur">{currency.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const accountMenu = (
    <Menu className="account-menu" onClick={() => setShowPopover(false)}>
      <Menu.Item key="20">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/account")}
        >
          My Dashboard
        </h4>
      </Menu.Item>
      <Menu.Item key="0">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/orders")}
        >
          My Orders
        </h4>
      </Menu.Item>
      <Menu.Item key="1">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/saved-items")}
        >
          My Saved Items
        </h4>
        hello there
      </Menu.Item>
      <Menu.Item key="2">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/address")}
        >
          My Addresses
        </h4>
      </Menu.Item>
      <Menu.Item key="3">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/settings")}
        >
          Account Details
        </h4>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/losode-credits")}
        >
          Losode Credits
        </h4>
      </Menu.Item> */}
      <Menu.Item key="5">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/email-preferences")}
        >
          Email Preference
        </h4>
      </Menu.Item>
      <span className="menu-line" />
      <Menu.Item key="6">
        <Button className="btn-sign-out" type="ghost" onClick={signOut}>
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const guestMenu = (
    <Menu
      className="account-menu"
      onClick={() => setShowPopover(false)}
      style={{ margin: 22, textAlign: "center" }}
    >
      <Menu.Item key="26">
        <Button
          type="primary"
          onClick={signOut}
          style={{
            color: "#fff",
            height: 35,
            width: 250,
            fontFamily: "DomaineSansText-Regular",
            fontSize: 15,
          }}
        >
          Sign Out
        </Button>
      </Menu.Item>
      <h5
        style={{
          color: "#000",
          marginTop: 15,
          fontFamily: "DomaineSansText-Regular",
          fontWeight: 600,
          fontSize: 12,
        }}
      >
        Don’t have an account with us?{" "}
        <span
          style={{ color: "#6B1214", cursor: "pointer" }}
          onClick={() => {
            signOut();
            setBuyerAuthModalVisible(true);
          }}
        >
          Register
        </span>
      </h5>
    </Menu>
  );

  return (
    <Header className="homepage-header-layout" style={{ paddingRight: "0px" }}>
      <Menu className="menu-items-list" mode="horizontal" style={{ border: 0 }}>
        <Menu.Item key="1" style={{ border: 0 }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              href="##"
              onClick={(e) => e.preventDefault()}
              style={{
                textDecoration: "none",
                fontSize: 18,
                fontFamily: "DomaineSansText-Light",
              }}
            >
              <img
                className="icon-cur"
                src={countries.find((curr) => curr.code === code)?.icon}
                alt=""
              />{" "}
              <DownOutlined style={{ fontSize: 10 }} />
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="2" style={{ border: 0 }}>
          <Link
            to="/women"
            className={type === "Women" ? "nav-item-active" : "nav-item"}
          >
            Women
          </Link>
        </Menu.Item>
        <Menu.Item key="3" style={{ border: 0 }}>
          <Link
            to="/men"
            className={type === "Men" ? "nav-item-active" : "nav-item"}
          >
            Men
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="4" style={{ border: 0 }}>
          <Link
            to="/kids"
            className={type === "Men" ? "nav-item-active" : "nav-item"}
          >
            Kids
          </Link>
        </Menu.Item> */}
      </Menu>
      <div className="menu-logo-container">
        <img
          src="/images/logo.png"
          alt="logo"
          className="logo"
          onClick={goHome}
        />
      </div>

      <Menu
        className="account-menu-items"
        mode="horizontal"
        style={{
          border: 0,
          width: 270,
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
        }}
      >
        {info.role !== "2" && (
          <>
            <div
              className="menu-icon"
              key="2"
              onClick={() => navigate("/saved-items")}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.3913 18H7.49348L0 8.6411V2.84371L2.2769 0H6.91875L7.83342 1.14543L6.97011 2.22366L6.4125 1.52724H2.78315L1.22283 3.47599V8.00882L7.99973 16.4728H8.88505L15.6522 8.01799V3.48515L14.0894 1.53334L10.4649 1.5364L4.79103 8.64415L3.92527 7.56593L9.95625 0.0122179L14.5957 0.00610894L16.875 2.85593V8.65026L9.3913 18Z"
                  fill="#6B1214"
                />
              </svg>
            </div>
            <div className="menu-icon" key="3">
              <Dropdown overlay={cartMenu} placement="bottomRight" arrow>
                <Badge
                  count={products.length > 0 ? products.length : ""}
                  offset={[0, 20]}
                  showZero
                  size="small"
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                  onClick={() => navigate("/cart")}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5306 18H0.469373L0 17.5553L1.82079 4.88115L2.29016 4.53174H10.3042V5.32586H2.70914L1.00175 17.2059H17.0046L15.5523 5.32586H14.9475V4.53174H15.9776L16.4501 4.88909L18 17.5632L17.5306 18Z"
                      fill="#010101"
                    />
                    <path
                      d="M13.3968 6.18353H11.8217V2.12559L10.8672 1.32353H7.13114L6.17664 2.12559V3.82765H4.60156V1.57765L6.47591 0H11.5193L13.3968 1.57765V6.18353Z"
                      fill="#6B1214"
                    />
                    <path
                      d="M13.0882 7.70042H12.1274L11.3398 7.03866V6.22866L12.1274 5.56689H13.0882L13.8757 6.22866V7.03866L13.0882 7.70042Z"
                      fill="#6B1214"
                    />
                  </svg>
                </Badge>
              </Dropdown>
            </div>
          </>
        )}

        {info.fname && (
          <Menu.Item key="4" style={{ border: 0 }}>
            <div>
              <Popover
                className="account-popover"
                placement="topLeft"
                title={`Hello ${info.fname}`}
                content={accountMenu}
                trigger="click"
                arrow={{ pointAtCenter: true }}
                open={showPopover}
                onOpenChange={handleVisibleChange}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49992 8.49984C10.4559 8.49984 12.0416 6.91418 12.0416 4.95817C12.0416 3.00216 10.4559 1.4165 8.49992 1.4165C6.54391 1.4165 4.95825 3.00216 4.95825 4.95817C4.95825 6.91418 6.54391 8.49984 8.49992 8.49984Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5847 15.5833C14.5847 12.8421 11.8576 10.625 8.50011 10.625C5.14261 10.625 2.41553 12.8421 2.41553 15.5833"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Popover>
            </div>
          </Menu.Item>
        )}
        {!info.fname && info.token && (
          <Menu.Item key="24" style={{ border: 0 }}>
            <div>
              <Popover
                className="account-popover"
                placement="topLeft"
                content={guestMenu}
                trigger="click"
                arrow={{ pointAtCenter: true }}
                open={showPopover}
                onOpenChange={handleVisibleChange}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49992 8.49984C10.4559 8.49984 12.0416 6.91418 12.0416 4.95817C12.0416 3.00216 10.4559 1.4165 8.49992 1.4165C6.54391 1.4165 4.95825 3.00216 4.95825 4.95817C4.95825 6.91418 6.54391 8.49984 8.49992 8.49984Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5847 15.5833C14.5847 12.8421 11.8576 10.625 8.50011 10.625C5.14261 10.625 2.41553 12.8421 2.41553 15.5833"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Popover>
            </div>
          </Menu.Item>
        )}
        {!info.token && (
          <Menu.Item key="34" style={{ border: 0 }}>
            <div className="links" style={{ gap: "16px" }}>
              <span
                className="auth-span"
                onClick={() => setBuyerAuthModalVisible(true)}
              >
                Register
              </span>
              <span
                style={{
                  width: 1.5,
                  height: 18,
                  background: "#D18F8F",
                  marginTop: "1.5rem",
                }}
              />
              <span
                className="auth-span"
                onClick={() => setBuyerSignInAuthModalVisible(true)}
              >
                Sign In
              </span>
            </div>
          </Menu.Item>
        )}
        {info.role === "2" && (
          <>
            <Menu.Item
              onClick={() => navigate("/account")}
              style={{ color: "black", textDecoration: "underline" }}
            >
              My Dashboard
            </Menu.Item>
            <span style={{ fontWeight: "bold" }}>|</span>
            <Menu.Item
              onClick={signOut}
              style={{ color: "#800000", textDecoration: "underline" }}
            >
              Sign Out
            </Menu.Item>
          </>
        )}
      </Menu>

      <AuthModal
        authModalVisible={buyerAuthModalVisible}
        setAuthModalVisible={setBuyerAuthModalVisible}
        activePage="2"
        pageUserType="buyer"
      />
      <AuthModal
        authModalVisible={buyerSignInAuthModalVisible}
        setAuthModalVisible={setBuyerSignInAuthModalVisible}
        activePage="1"
        pageUserType="buyer"
      />

      <style jsx="true">{`
        .homepage-header-layout {
          align-items: center;
          background: #fff;
          display: flex;
          height: 96px;
          justify-content: space-between;
          padding: 0;
          font-family: "DomaineSansText-Regular";
        }
        .homepage-header-layout
          .ant-menu-horizontal:not(.ant-menu-dark)
          > .ant-menu-item:hover::after,
        .homepage-header-layout .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > .ant-menu-submenu::after {
          border-bottom: 0;
        }
        .user-account-dropdown {
        }
        .account-menu {
        }

        .account-menu .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
          border: 0px;
        }
        .menu-line {
          display: block;
          border: 0.3px solid #92959e;
          opacity: 0.3;
          margin-bottom: 24px;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background-color: transparent;
        }
        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
          background: transparent;
        }
        .btn-sign-out {
          color: #677189;
          font: normal normal 16px/146.16% DomaineSansText-Light;
          width: 100%;
          height: 40px;
        }
        .btn-sign-out:hover {
          color: #fff;
          background: #000;
        }

        .btn-menu {
          color: #677189;
          font: normal normal 16px/146.16% DomaineSansText-Light;
          width: 100%;
        }
        .btn-menu:hover {
          color: #be002b !important;
          font-weight: 700;
        }
        .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > . ant-menu-submenu::after {
          display: none;
        }
        .icon-cur {
          height: 18px;
          width: 18px;
        }
        .txt-cur {
          font: normal normal 12px/22px DomaineSansText-Light;
          margin-left: 12px;
        }
        .nav-item {
          text-decoration: none;
          font-size: 15px;
          font-family: DomaineSansText-Light;
        }
        .nav-item-active {
          text-decoration: none;
          font-size: 16px;
          font-family: DomaineSansText-Black;
        }
        .menu-icon {
          border: 0px;
          cursor: pointer;
          margin: 0px;
          padding: 0px 10px;
        }
        .menu-items-list {
          flex: 1;
          position: absolute;
          z-index: 201;
          width: 35vw;
        }
        .account-menu-items {
          right: 0;
          position: absolute;
          z-index: 201;
        }
        .account-menu .ant-menu-item:hover {
          background-color: transparent !important;
        }
        .menu-logo-container {
          place-items: left;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          z-index: 200;
        }
        .auth-span {
          color: #000;
          text-decoration: underline;
          font-size: 14px;
          font-family: DomaineSansText-Light;
        }
        .auth-span:hover {
          color: #be002b;
          text-decoration: none;
        }

        .ant-popover-placement-bottomRight .ant-popover-arrow {
          display: none;
        }
        .ant-popover-title {
          border-bottom: 1px solid #f0f0f0 !important;
          font-family: DomaineSansText-Regular;
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          padding: 15px 25px !important;
        }
        .ant-popover {
          width: 25vw;
          // left: 75% !important;
          padding-top: 10px;
        }

        .ant-popover-inner-content {
          width: auto;
        }

        .header-cart-page-no-item-supercontainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 16px 0;
          font-family: "DomaineSansText-Light";
        }
        .header-cart-page-no-item-container {
          background: white;
          padding: 30px 50px;
          font-size: 14px;
          text-align: center;
        }
        .header-cart-page-no-item-bag-icon {
          height: 24px;
        }
        .header-cart-page-no-item-title {
          font-size: 18px;
          margin-top: 14px;
          font-weight: 600;
        }
        .header-cart-page-no-item-text {
          font-size: 14px;
          margin-top: 10px;
        }
        .header-cart-page-no-item-button {
          margin-top: 30px;
        }
        .btn-view-saved-items {
          color: #fff;
          background: #000;
          font: normal normal 16px/146.16% DomaineSansText-Light;
          width: 200px;
          height: 40px;
        }
        .header-cart-page-no-item-shop-link {
          text-decoration: underline;
          cursor: pointer;
          margin-top: 10px;
        }

        .cart-item-img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 138px;
          width: 96px;
        }
      `}</style>
    </Header>
  );
};

export default HeaderComponent;
