import axios from "axios";
import Currency from "./currencyApi";
import Brands from "./brandsApi";
import Checkout from "./checkoutApi";
import User from "./userApi";
import Store from "./storesApi";

const attachToken = (config) => {
  let token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const resolveError = (error) => {
  return Promise.reject(error);
};

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
});

axiosInstance.interceptors.request.use(attachToken, resolveError);

const api = {
  currency: new Currency(axiosInstance),
  brands: new Brands(axiosInstance),
  checkout: new Checkout(axiosInstance),
  user: new User(axiosInstance),
  store: new Store(axiosInstance),
};
export default api;
