import { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const Brands = () => {
  const navigate = useNavigate();

  const { type } = useSelector((state) => state.user);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [activeSlide, setActiveSlide] = useState(0);

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  // const shopBrand = (id, slug) => {
  //   navigate(`/brands/id=${id}&name=${slug}`);
  // };

  const carouselSettings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
    afterChange: (current) => setActiveSlide(current),
  };

  const carouselRef = createRef();

  const brands = [
    {
      id: 6141,
      title: "Aura Nigeria",
      imageUrl: "images/shop-brand-1.png",
      slug: "aura-nigeria",
    },
    {
      id: 6307,
      title: "Kaabis",
      imageUrl: "images/shop-brand-2.png",
      slug: "kaabis",
    },

    {
      id: 6264,
      title: "Bkco",
      imageUrl: "images/shop-brand-3.png",
      slug: "bkco-1",
    },

    {
      id: 4704,
      title: "Jeda Sanni",
      imageUrl: "images/shop-brand-4.png",
      slug: "jeda-sanni",
    },
  ];
  return (
    <div className="brands-container">
      {/* <h1 className="brand-text">Classic Style, Invigorating Seasons</h1>
      <h3 className="brand-desc">
        Adventurous pieces from independent designers globally
      </h3>
      <p className="txt-shop-now" onClick={() => shopNewIn()}>
        Shop Now
      </p> */}

      <div className="main-brands-box">
        <div className="cta-btn-box">
          <span className="txt-new-title">NEW DECADE STYLE</span>
          <h1 className="txt-new-main">Shop over 250 New Brands</h1>
          <h3 className="txt-new-desc">
            Carefully selected pieces from independent designers across Africa.
            Never seen style
          </h3>
          <Button className="btn-new-in" onClick={() => shopNewIn()}>
            Shop New Styles
          </Button>
        </div>
        {!isTabletOrMobile && (
          <div className="cards-container">
            {brands.map((brand) => (
              <div
                className="brand-card"
                key={brand.imageUrl}
                onClick={() => navigate(`/designers/${brand?.slug}`)}
              >
                <img
                  className="brand-image"
                  loading="lazy"
                  alt="brand"
                  src={brand.imageUrl}
                />
                <h2 className="brand-title">{brand.title}</h2>
              </div>
            ))}
          </div>
        )}
        {isTabletOrMobile && (
          <div>
            <Carousel {...carouselSettings} ref={carouselRef}>
              {brands.map((brand) => (
                <div
                  className="brand-card"
                  key={brand.imageUrl}
                  onClick={() => navigate(`/designers/${brand?.slug}`)}
                >
                  <img
                    className="brand-image"
                    alt="brand"
                    src={brand.imageUrl}
                    loading="lazy"
                  />
                  <h2 className="brand-title">{brand.title}</h2>
                </div>
              ))}
            </Carousel>

            <div className="process-icon-div-mobile">
              <div
                className="process-icon-container"
                onClick={() => {
                  if (activeSlide > 0) carouselRef.current.prev();
                }}
              >
                <LeftOutlined className="process-icon" />
              </div>
              <div
                className="process-icon-container"
                onClick={() => {
                  if (activeSlide !== 3) carouselRef.current.next();
                }}
              >
                <RightOutlined className="process-icon" />
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <Button className="mobile-btn-new-in" onClick={() => shopNewIn()}>
                Shop New In
              </Button>
            </div>
          </div>
        )}
      </div>
      <style jsx="true">{`
        .brands-container {
          background: #ffffff;
          margin-bottom: 40px;
        }

        // .brand-text {
        //   text-align: center;
        //   font: normal 500 40px DomaineSansText-Regular;
        //   color: #000000;
        //   padding-top: 16px;
        //   margin-bottom: 0px;
        // }
        // .brand-desc {
        //   text-align: center;
        //   font: normal 300 16px DomaineSansText-Light;
        //   color: #000000;
        //   margin-bottom: 0px;
        //   margin-top: 4px;
        // }
        // .txt-shop-now {
        //   cursor: pointer;
        //   font: normal 300 15px DomaineSansText-Light;
        //   color: #555555;
        //   text-decoration: underline;
        //   text-align: center;
        //   margin-top: 7px;
        // }
        .txt-shop-now:hover {
          text-decoration: none;
        }
        .main-brands-box {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          padding: 0 100px;
          gap: 8px;
        }
        .cta-btn-box {
          flex: 1;
          margin-top: 10px;
        }
        .txt-new-title {
          font: normal 300 14px DomaineSansText-Light;
          line-height: 23px;
          color: #555555;
        }
        .txt-new-main {
          font: normal normal 28px DomaineSansText-Regular;
          margin-bottom: 16px;
        }
        .txt-new-desc {
          font: normal normal 15px DomaineSansText-Light;
          margin-bottom: 20px;
          line-height: 1.5;
        }
        .btn-new-in {
          width: 254px;
          height: 42px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 15px DomaineSansText-Light;
        }
        .cards-container {
          align-items: center;
          display: flex;
          flex: 4;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        .brand-card {
          width: 23%;
          cursor: pointer;
        }
        .brand-image {
          object-fit: cover;
          object-position: top;
          width: 100%;
          // height: 345px;
        }
        .brand-title {
          font: normal normal 14px/23px DomaineSansText-Regular;
          letter-spacing: 0.28px;
          color: #000000;
          margin-top: 8px;
          text-align: center;
        }
        @media (max-width: 640px) {
          .brands-container {
            padding: 0 16px;
          }
          // .brand-text {
          //   font-size: 16px;
          //   text-align: left;
          //   font-weight: 400;
          // }
          // .brand-desc {
          //   font-size: 14px;
          //   text-align: left;
          //   font-family: "DomaineSansText-Light";
          // }
          // .txt-shop-now {
          //   margin-top: 12px;
          //   font-size: 12px;
          //   text-align: left;
          // }
          .main-brands-box {
            display: block;
            padding: 0;
            margin-top: 24px;
          }
          .txt-new-title {
            font-size: 14px;
            font-family: "DomaineSansText-Black";
          }
          .txt-new-main {
            font-size: 16px;
            // margin-top: 8px;
            margin-bottom: 4px;
          }
          .txt-new-desc {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
          }
          .btn-new-in {
            display: none;
          }
          .process-icon-container {
            padding: 8px;
            border-radius: 50%;
            border: 1px solid #800000;
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .process-icon-div-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            margin: 16px 0;
            display: none;
          }
          .process-icon {
            color: #800000;
          }
          .slick-slide div {
            width: 100%;
          }
          .brand-image {
            height: 248px;
            width: 100%;
            margin: auto;
            padding-right: 8px;
          }

          .mobile-btn-new-in {
            width: 208px;
            height: 40px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 14px DomaineSansText-Light;
          }
          .brand-title {
            font-family: "DomaineSansText-Regular";
            font-size: 14px;
          }
          .ant-carousel .slick-dots-bottom {
            bottom: 0;
            margin-right: 0;
            margin-left: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default Brands;
