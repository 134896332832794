import axios from "axios";

const getLatLng = async (values) => {
  const axiosInstance = axios.create();
  try {
    const { address, state, country } = values;
    const api = `https://maps.googleapis.com/maps/api/geocode/json?address=${address},${state},${country}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const result = await axiosInstance.get(api);
    return `${result.data.results[0].geometry.location.lat},${result.data.results[0].geometry.location.lng}`;
  } catch (e) {
    console.log(e.message);
  }
};

export default getLatLng;
