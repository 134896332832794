import { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { userActionCreators } from "../../redux/user";
import { Checkbox, Dropdown, Menu } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  ExclamationCircleFilled,
  PlusCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import BuyerSideNav from "./BuyerSideNav";
import getLatLng from "../../utils/getLatLng";
import { AddressModal } from "../../components/modals";
import Prompts from "../../components/modals/Prompts";
import triggerNotification from "../../hooks/triggerNotification";

const Address = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const dispatch = useDispatch();
  const checkedRef = useRef(null);
  const [checkIds, setCheckIds] = useState([]);

  const [currentAddress, setCurrentAddress] = useState();
  const [modalType, setModalType] = useState("new");
  const [isModalVisible, setModalVisible] = useState(false);

  const { contact } = useSelector((state) => state.user);

  const {
    deleteAddress,
    fetchAddress,
    addAddress,
    setPrimaryAddress,
    updateAddress,
  } = bindActionCreators(userActionCreators, dispatch);

  const editAddress = (address) => {
    setCurrentAddress(address);
    setModalType("edit");
    setModalVisible(true);
  };

  const onUpdateAddress = async (values) => {
    try {
      const resp = await getLatLng(values);
      const [latitude, longitude] = resp.split(",");

      values.latitude = latitude;
      values.longitude = longitude;
      values.dial = "+234";
      const res = await updateAddress(currentAddress.id, values);
      if (res.status === "ok") {
        setModalVisible(false);
        triggerNotification({
          type: "success",
          message: res.message,
        });
      }
      if (res.status === "not ok")
        triggerNotification({
          type: "error",
          message: res.message,
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitAddress = async (values) => {
    const resp = await getLatLng(values);
    const [latitude, longitude] = resp.split(",");

    values.latitude = latitude;
    values.longitude = longitude;
    values.dial = "+234";

    const res = await addAddress(values);
    if (res.status === "ok") {
      setModalVisible(false);
      triggerNotification({
        type: "success",
        message: res.message,
      });
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const setMainAddress = async (e, id) => {
    if (e?.target?.type === "checkbox" && e.target.checked) {
      setCheckIds((prev) => [...prev, id]);
      const res = await setPrimaryAddress(id);
      if (res.status === "ok") {
        setCheckIds((prev) => prev.filter((el) => el !== id));
      }
    } else {
      setPrimaryAddress(id);
    }
  };

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="account-address-page-container">
        <div className="account-address-page-header">
          {/* <img
              src="/images/back-arrow.png"
              alt=""
              className="back-icon"
              onClick={() => navigate(-1)}
            /> */}
          <div className="account-address-page-title">My Addresses</div>
          <div className="account-address-page-subtitle">
            Add or edit your addresses here. Address changes can also be made at
            checkout
          </div>
          <div className="account-address-page-header-divider"></div>
        </div>

        <div className="account-address-content">
          <div
            className="topright-add"
            onClick={() => {
              setModalType("new");
              setModalVisible(true);
            }}
          >
            <PlusCircleOutlined />
            <span>Add New Address</span>
          </div>
          {!isTabletOrMobile && <BuyerSideNav currentPageKey="3" />}
          {/* <BuyerSideNav currentPageKey="3" /> */}

          <div className="account-address-content-main">
            <div className="address-container">
              <div className="addresses">
                {contact.map((address, idx) => (
                  <div className="address-div" key={idx}>
                    <div className="address-main">
                      <div className="address-status-container">
                        <div className="address-status">
                          {address.status === 1
                            ? "Main Address"
                            : "Additional Address"}
                        </div>
                        {!isTabletOrMobile && (
                          <div
                            style={{ color: "#800000", cursor: "pointer" }}
                            onClick={() => editAddress(address)}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                      <h3 className="txt-user-name">
                        {address?.firstname} {address?.lastname}
                      </h3>
                      {!isTabletOrMobile && (
                        <div className="address-text-web">
                          <div className="address">{address?.address}</div>
                          <div>{address?.city},</div>
                          {address?.state && <div>{address.state},</div>}
                          <div>{address?.postalCode}</div>
                          <h4 className="address">{address?.country}</h4>
                          <h4 style={{ paddingTop: "4px" }}>
                            {address?.dial}
                            {address?.phone?.replace(/^0+/, "")}
                          </h4>
                        </div>
                      )}
                      {isTabletOrMobile && (
                        <div className="address-text-mobile">
                          {address?.address}, {address?.area}, {address?.state},{" "}
                          {address?.postalCode}, {address?.country}
                          <h4 style={{ paddingTop: "4px" }}>
                            {address?.dial}
                            {address?.phone?.replace(/^0+/, "")}
                          </h4>
                        </div>
                      )}
                    </div>

                    <div>
                      <div className="address-btn">
                        {/* <div
                          style={{ color: "#800000", cursor: "pointer" }}
                          onClick={() => editAddress(address)}
                        >
                          Edit
                        </div> */}
                        <Prompts
                          title="Confirm that you want to delete this address!"
                          children={
                            <svg
                              width="16"
                              height="20"
                              viewBox="0 0 18 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="address-btn-delete-icon"
                            >
                              <path
                                d="M15.75 5.48177C13.2525 5.17927 10.74 5.02344 8.235 5.02344C6.75 5.02344 5.265 5.1151 3.78 5.29844L2.25 5.48177"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6.375 4.55453L6.54 3.3537C6.66 2.48286 6.75 1.83203 8.0175 1.83203H9.9825C11.25 1.83203 11.3475 2.51953 11.46 3.36286L11.625 4.55453"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.1378 8.37891L13.6503 17.6097C13.5678 19.0489 13.5003 20.1672 11.4078 20.1672H6.59279C4.50029 20.1672 4.43279 19.0489 4.35029 17.6097L3.86279 8.37891"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.74756 15.125H10.2451"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.12451 11.457H10.8745"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          onConfirm={() => deleteAddress(address.id)}
                        />
                      </div>
                      <div className="address-status-change">
                        {!isTabletOrMobile && address.status !== 1 && (
                          <Checkbox
                            ref={checkedRef}
                            checked={checkIds.includes(address.id)}
                            // style={{ borderRadius: "50%" }}
                            onChange={(e) => setMainAddress(e, address.id)}
                          >
                            Mark as main address
                          </Checkbox>
                        )}
                      </div>
                    </div>

                    {isTabletOrMobile && (
                      <Dropdown
                        overlay={
                          <Menu className="mobile-address-menu">
                            <Menu.Item
                              key="1"
                              onClick={() => editAddress(address)}
                            >
                              Edit
                            </Menu.Item>
                            <hr />
                            {address?.status !== 1 && (
                              <>
                                <Menu.Item
                                  key="2"
                                  onClick={(e) => setMainAddress(e, address.id)}
                                >
                                  Set as Main
                                </Menu.Item>
                                <hr />
                              </>
                            )}

                            <Menu.Item key="3">
                              <Prompts
                                title="Confirm that you want to delete this address!"
                                children={<span>Delete</span>}
                                onConfirm={() => deleteAddress(address.id)}
                                placement="bottom"
                              />
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <MoreOutlined
                          style={{ fontSize: "26px", marginTop: "8px" }}
                        />
                      </Dropdown>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {contact?.length < 1 && (
              <div className="no-address-div">
                <ExclamationCircleFilled style={{ fontSize: 50 }} />
                <div className="no-address-text">
                  You have not added any addresses to your Address Book. Click{" "}
                  <span
                    style={{ cursor: "pointer", color: "#800000" }}
                    onClick={() => {
                      setModalType("new");
                      setModalVisible(true);
                    }}
                  >
                    here{" "}
                  </span>{" "}
                  to add an address
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddressModal
        address={currentAddress}
        visible={isModalVisible}
        setVisible={setModalVisible}
        onSubmit={modalType === "new" ? onSubmitAddress : onUpdateAddress}
        type={modalType}
      />

      <style>
        {`
          .account-address-page-container{
            background:#f9f9f9;
            padding-bottom: 40px;
          }
          .account-address-content{
            position:relative;
            min-height: 75vh;
            font-family: DomaineSansText-Light;
            background:#f9f9f9;
            margin-top: 40px;
          }
          .account-address-content-main{
            margin-left:200px;
            padding:0 40px;
          }
          .account-address-page-header{
            text-align:center;
            font-family: 'DomaineSansText-Light';
            padding-top: 24px;
            background:white;
            padding-bottom: 4px;
          }
          .account-address-page-title{
            font-size:24px
          }
          .account-address-page-subtitle{
            font-size:16px
          }
          .account-address-page-header-divider{
            height: 2px;
            width: 90%;
            border-bottom: 1px solid #d5d5d5;
            margin: 16px auto 24px auto;
          }

          .ant-form-vertical .ant-form-item-label > label {
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            margin-top: 8px;
          }


          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
          .ant-picker {
            height: 48px !important;
            width: 100%;
            border: 1px solid #707070;
          }
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #e5e5e5;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item{
            line-height:3.5
          }
          .ant-form-item-with-help .ant-form-item-explain{
            margin-top:4px
          }
          .address-modal-cancel-button{
            font-size: 28px;
            color: black;
            margin-top: 16px;
            margin-right: 16px;
          }
          .btn-add-address{
            height: 200px;
            width: 200px;
            font-size: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            text-transform: capitalize;
          }
          .no-address-div{
            background: white;
            padding: 20px;
            font-size: 16px;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
          }

          .address-div{
            background: white;
            width: fit-content;
            padding: 24px;
            font-size: 14px;
            width:320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .address-main{
            
          }
          .txt-user-name{
            font-family: 'DomaineSansText-Light';
            font-weight:bold;
            font-size: 16px;
            margin-top:10px;
          }
          .address-btn{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;
          }
          .addresses{
            display: flex;
            gap:24px;
            flex-wrap:wrap;
          }
          .address-status-container{
            display:flex;
            justify-content: space-between;
            align-items: center;
          }
          .address-status{
            font-family: 'DomaineSansText-Light';
            color: #92959E;
          }
          .address-status-change{
            height: 24px;
            margin-top: 16px;
            padding-top: 10px;
            border-top: 1px solid #d5d5d5;
          }
          .address-status-change .ant-checkbox + span{
            font-size:14px;
            color: #92959E;
          }
          .address-btn-delete-icon{
            stroke:#292D32
          }
          
          .address-btn-delete-icon:hover{
            stroke:#800000;
            cursor:pointer
          }

          .topright-add{
            position: absolute;
            right: 24px;
            top: -24px;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor:pointer
          }
          .topright-add:hover{
            color:#800000
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
            }

            .delete-popconfirm{
              font-family: 'DomaineSansText-Light';
              text-align:center;
              width:auto
            }
            .delete-popconfirm .ant-popover-inner{
              background:black;
            }
            .delete-popconfirm .ant-popover-inner-content{
              background:black;
              padding: 24px 32px;
            }
            .delete-popconfirm .ant-popover-message{
              margin-bottom: 24px;
              padding: 10px 30px 20px;
              border-bottom: 1px solid #d5d5d5;
            }
            .delete-popconfirm .ant-popover-message-title{
              color:white;
            }
            .delete-popconfirm .ant-popover-message-icon{
              display:none
            }
            .ant-popover-arrow{
              filter:invert(1);
              overflow:normal;
            }
            .delete-popconfirm .ant-popover-buttons{
              text-align:center
            }
            .delete-popconfirm .ant-popover-buttons button{
              width:120px;
              height:36px
            }
            .delete-popconfirm .ant-popover-buttons button:first-child{
              background:black;
              color:white;
              border:1px solid white
            }
            .delete-popconfirm .ant-popover-buttons button:nth-child(2){
              background:white;
              color:black;
              margin-left:24px
            }

          @media(max-width:640px){
            .account-address-content-main{
              margin-left:0;
              padding:0 16px;
            }
            .account-address-page-title {
              font-size: 18px;
            }
            .account-address-page-subtitle{
              font-size:12px;
              padding: 0 16px;
            }
            .account-address-content-section-title{
              font-size: 16px
            }
            .account-address-content{
              margin-top: 0;
              background: white;
            }
            .topright-add{
              position: static;
              padding: 0 16px;
            }
            .address-container{
              padding: 0 !important;
              margin-bottom: 0 !important;
            }
            .addresses{
              display:block;
              margin-top:16px
            }
            .address-div{
              padding: 0;
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 1px solid #d5d5d5;
              width: 100%;
              height:auto !important;      
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row

            }
            .txt-user-name{
              font-size: 16px;
              margin-bottom:0
            }
            .address-main{
              height: auto !important;
            }
            .address-btn{
              display:none
            }
            .address-text-mobile{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 14px;
              width: 86vw;
            }

            .mobile-address-menu{
              font-family: 'DomaineSansText-Light';
            }

            .account-address-modal .ant-select-single .ant-select-selector .ant-select-selection-item{
              font-size:14px !important
            }
            


          }


        `}
      </style>
    </div>
  );
};

export default Address;
