import LoadingIcon from "../components/LoadingIcon";

const customTheme = {
  token: {
    colorPrimary: "#000",
    colorTextSelection: "#ccc",
    colorSelectItemBackground: "#ccc",
    colorOutline: "#000",
    borderColor: "#fff",
    shadowFocus: "0 0 10px rgba(0, 0, 0, 0.2)",
    outlineWidth: "0px",
    colorBorder: "#000",
    colorRadioHover: "#000",
    colorRadioActive: "#000",
    colorRadioChecked: "#000",
    colorSelectItemSelected: "#ccc",
    colorSelectItemActive: "#ccc",
  },
  components: {
    Select: {
      notFoundContent: LoadingIcon,
    },
  },
};

export default customTheme;
