import { Button } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons";

const Linktree=()=>{
    return(
        <div>
            <div className='linktree-container'>
                <div className='linktree-header'>
                    <div className='linktree-image-container>'>
                        <div className='linktree-image'></div>
                        <div className='linktree-circle-logo'>
                            <img src='./images/accent-black-logo.png' alt='losode-icon-logo'/>
                        </div>
                        <div className='linktree-text-logo'>
                            <img src='./images/logo.png' alt='losode-text-logo'/>
                        </div>
                    </div>
                    
                </div>
                <div className='linktree-content'>
                    <Button className='linktree-link' type='primary' href='https://www.losode.com/' target="_blank" rel="noopener noreferrer" icon={<ArrowRightOutlined />}>Visit the website</Button>
                    <Button className='linktree-link' type='primary' href='https://www.twitter.com/thisislosode' target="_blank" rel="noopener noreferrer" icon={<ArrowRightOutlined />}>Follow us on twitter</Button>
                    <Button className='linktree-link' type='primary' href='https://www.instagram.com/thisislosode/' target="_blank" rel="noopener noreferrer" icon={<ArrowRightOutlined />}>Follow us on instagram</Button>
                    <Button className='linktree-link' type='primary' href='https://linkedin.com/company/losode' target="_blank" rel="noopener noreferrer" icon={<ArrowRightOutlined />}>See what we are up to on linkedIn</Button>
                    <Button className='linktree-link life-at-losode' type='primary'  href='https://www.instagram.com/lifeatlosode/' target="_blank" rel="noopener noreferrer"icon={<ArrowRightOutlined />}>Follow @Lifeatlosode to see what our people are up to</Button>
                </div>

            </div>

            <style jsx="true">{`
                .linktree-container{
                    margin: 90px 100px;
                }
                .linktree-image-container{
                    position:relative;
                }
                .linktree-image{
                    height:356px;
                    width:100%;
                    background-image:url('./images/linktree-banner.png');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius:32px;
                }
                .linktree-circle-logo{
                    border: 1px solid black;
                    border-radius: 50%;
                    padding: 30px;
                    height: 238px;
                    width: 238px;
                    text-align:center;
                    background:white;
                    position:relative;
                    margin: auto;
                    top: -114px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                }
                .linktree-text-logo{
                    margin: auto;
                    top: -114px;
                    position: relative;
                    text-align: center;
                }
                .linktree-text-logo img{
                    width: 196px;
                    height: 94px;
                }
                .linktree-content{
                    position:relative;
                    top:-114px;
                    margin-top:30px
                }
                .linktree-link{
                    font-size: 18px;
                    height: 80px;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: center;
                    width: 100%;
                    align-items: center;
                    margin: 24px 0;
                    gap: 20px;
                    font-family: DomaineSansText-Regular;
                  }
                  .ant-btn > .anticon + span, .ant-btn > span + .anticon{
                      margin-left:0px
                  }

                  @media(max-width:940px){
                    .linktree-container{
                        margin: 0px;
                    }
                    .linktree-image-container{
                        position:relative;
                    }
                    .linktree-image{
                        height:179px;
                        border-radius:0px;
                    }
                    .linktree-circle-logo{
                        border: 1px solid black;
                        border-radius: 50%;
                        padding: 30px;
                        height: 120px;
                        width: 120px;
                        top: -58px
                    }
                    .linktree-text-logo{
                        top:-58px
                    }
                    .linktree-content{
                        top:-58px
                    }
                    .linktree-circle-logo img{
                        width:62px;
                        height:96px;
                    }
                    .linktree-text-logo img{
                        width: 98px;
                        height: 47px;
                    }
                    .linktree-content{
                        padding: 0 20px;
                    }
                    .linktree-link{
                        font-size: 14px;
                        height: 47px;
                        margin: 16px 0;
                        gap: 20px;
                      }
                      .life-at-losode{
                          font-size:11px
                      }
    
                  }
            
            `}</style>
        </div>
    )
}

export default Linktree