import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Banner = () => {

  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="banner-container">
      <img src="./images/home-main-image-women.png" alt="banner" className="bannercontainer-image" />
      <div className="banner-content-container">
        <div className="txt-style-main" style={{color:'fff'}}>Express Yourself - Own your style </div>
        <div className="subtext">From dynamic prints to bold colors, choose your favorite designs from this season's collection</div>
        <Button
            className="btn-shop-banner"
            style={{marginTop:"20px"}}
            onClick={() => shopNewIn()}
          >
            Shop Now
        </Button>
      </div>
      <style jsx="true">{`
        .banner-container {
          position:relative;
          background-position: top;
        }
        .bannercontainer-image{
          width: 100%;
          height: 100%;
        }
        .banner-container .btn-shop-banner {
          width: 426px;
          height: 48px;
          background: #fff;
          border-radius: 2px;
          border: none;
          color: #000;
          font: normal normal 17px DomaineSansText-Light;
        }
        .banner-content-container{
          position:absolute;
          left: 8%;
          bottom: 22%;
          color: #fff;
        }
        .banner-content-container .subtext{
          font-size: 20px;
          font-family: DomaineSansText-Light;
          max-width: 530px;
        }

        @media(max-width:640px){
          .banner-container{
            height: 248px;
          }
          .banner-content-container{
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .banner-container .btn-shop-banner {
            width: 100%;
            height: 40px;
            font-size: 14px;
          }
          .banner-content-container .subtext{
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default Banner;
