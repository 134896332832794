// import { useEffect } from "react";
import { Tabs } from "antd";
import { useMediaQuery } from "react-responsive";

const { TabPane } = Tabs;

const SellerAgreement = () => {
  // useEffect(() => {
  //   document.title = "About us | losode.com";
  // }, []);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <div className="about-container">
        <div className="title-div-about">
          <div className="text-container">
            <h1 className="lg-txt">Designer Agreements and Privacy Policy</h1>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="tabs"
          tabBarGutter={!isTabletOrMobile ? 50 : 15}
        >
          <TabPane
            className="tab"
            tab="Designer Agreements and Privacy Policy"
            key="1"
          >
            <div className="about-content">
              <p style={{ fontWeight: "bold" }}>LOSODE DESIGNER AGREEMENT </p>
              <p>
                THIS LOSODE DESIGNERS AGREEMENT (THE "AGREEMENT") CONTAINS THE
                TERMS AND CONDITIONS THAT GOVERN DESIGNER ACCESS TO AND USE OF THE
                LOSODE MARKETPLACE AND ITS SERVICES. IT IS AN AGREEMENT BETWEEN
                LOSODE AND ITS DESIGNERS OR THE BUSINESS THE DESIGNER REPRESENTS AND
                LOSODE.
              </p>
              <p>
                B REGISTERING FOR OR USING ITS PLATFORM, DESIGNERS (ON BEHALF OF
                THE DESIGNER SELF OR THE BUSINESS THE DESIGNER REPRESENTS) AGREE TO
                BE BOUND BY THE TERMS OF THIS AGREEMENT.
              </p>
              <p>
                As used in this Agreement, "LOSODE," "us," and “its” means
                “LOSODE” and any of its Affiliates, and "designer" means the
                applicant (if registering for or using its platform as an
                individual), or the business employing the applicant (if
                registering for or using its platform as a business) and any of
                its Affiliates.
              </p>
              <p style={{ fontWeight: "bold" }}>1. Registration</p>
              <p>
                To become a designer on its platform, designer must complete the
                registration process on its designer's portal. Use of its platform
                is limited to parties that can lawfully enter into and form
                contracts under Nigerian Laws. As part of the application,
                designer must provide us with the designer (or designer's business')
                legal name, address, phone number and e-mail address, as well as
                any other information Losode may request. Any personal data the
                designer provides to us will be handled in accordance with
                LOSODE’s privacy policy.
              </p>
              <p style={{ fontWeight: "bold" }}>2. Services Rendered</p>
              <p>
                The Designer agrees to compensate Losode for rendering the
                services listed below:{" "}
              </p>

              <ul>
                <li>
                  An online storefront tailored to the Designer with payment
                  gateways and escrow services at www.losode.com{" "}
                </li>
                <li>
                  Access to the Losode Designers’ Portal, dashboard and other
                  account management systems;{" "}
                </li>
                <li>
                  Access to our Designer Acquisition and Management team as well
                  as other account management services;{" "}
                </li>
                <li>Access to our strategic logistics partners;</li>
                <li>Provision of analytics;</li>
                <li>The Designer Services Helpline Number</li>
                <li>
                  E-marketing, and other marketing services as available and
                  appropriate{" "}
                </li>
              </ul>

              <p>
                Losode may at its sole discretion add or remove services from
                the above list from time to time and some of the new services
                may attract separate charges, in which case the Designer will be
                informed before provision of the said services.{" "}
              </p>
              <p>
                Losode reserves the right to alter or vary the available
                services, the nature of the services and any charges at its sole
                discretion.{" "}
              </p>
              <p>
                The Designer agrees to use reasonable diligence in providing
                Losode with genuine and quality products, up-to-date product
                quantity information and accurate product information.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>3. Service Fee and Payments </p>
              <p>
                For all of the Services provided by Losode under this Agreement,
                the Designer shall compensate Losode, with commissions or other
                payments as required, in accordance with the terms Losode’s
                ‘Commission Structure’, which will be communicated to all
                designers; or agreed in advance between both parties, or detailed
                elsewhere on the Losode website.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>4. Lein on Payments </p>
              <p>
                Where Losode determines that the designer's actions or performance
                may result in returns, chargebacks, claims, disputes, violations
                of Losode terms or policies, or other risks to Losode or third
                parties, then Losode shall have sole discretion to withhold any
                payments to the designer for as long as Losode determines such
                risks to Losode or third parties persist.
              </p>
              <p>
                For any amounts that the designer owes Losode, it is hereby agreed
                that Losode may{" "}
              </p>

              <p style={{ marginLeft: "20px" }}>
                (a) charge designers’ a Account or any other payment instrument
                the designer provides to Losode or balance held by Losode;{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (b)  invoice the designer for amounts due to Losode, in which case
                the designer shall pay the invoiced amounts upon receipt;{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (c)  reverse any credits to Designer's Bank Account; or{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (d) collect payment or reimbursement from the designer by any
                other lawful means.{" "}
              </p>

              <p>
                Where it is determined that the Designer's account has been used to
                engage in deceptive, fraudulent, or illegal activity, or to
                repeatedly violates Losode’s Terms and Policies, then Losode may
                in its sole discretion permanently withhold any payments to the
                designer. 
              </p>

              <p>
                In addition, Losode may require the designer to pay other amounts
                to secure the performance of the designer's obligations under this
                Agreement or to mitigate the risk of returns, chargebacks,
                claims, disputes, violations of its terms or policies, or other
                risks to Losode or third parties. These amounts may be
                refundable or non-refundable in the manner Losode determines,
                and failure to comply with terms of this Agreement, including
                any applicable Program Policies, may result in their forfeiture.
              </p>

              <p style={{ fontWeight: "bold" }}>5. Terms and Termination</p>

              <p>
                The term of this Agreement will commence on the date the designer
                completes registration for the use of Losode’s services and
                continue until terminated by Losode or the designer as provided
                below.{" "}
              </p>
              <p>
                Losode may at any time terminate designer's use of its Services
                immediately on notice via email, the Contact Us form, or similar
                means.
              </p>

              <p>
                Losode may terminate designer's use of its services or terminate
                this Agreement for convenience with 14 days’ advance notice.
                Losode may suspend or terminate designer's use of its Service
                immediately if Losode determines that{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (a) designer has materially breached this Agreement and failed to
                remedy within 7 days of a Breach notice unless designer's breach
                exposes Losode to liability toward a third party, in which case
                Losode are entitled to reduce, or waive, the aforementioned
                remedy period at its reasonable discretion;
              </p>
              <p style={{ marginLeft: "20px" }}>
                (b) designer's account has been, or its controls identify that it
                may be used for deceptive or fraudulent, or illegal activity; or{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (c) designer's use of the Service has harmed, or its controls
                identify that it might harm, other designers, customers, or
                Losode’s legitimate interests. Losode will promptly notify
                designer of any such termination or suspension via email or
                similar means including the designer's portal, indicating the
                reason and any options to appeal, except where Losode have
                reason to believe that providing this information will hinder
                the investigation or prevention of deceptive, fraudulent, or
                illegal activity, or will enable designer to circumvent its
                safeguards.{" "}
              </p>
              <p>
                On termination of this Agreement, all related rights and
                obligations under this Agreement immediately terminate, except
                that (d) designer will remain responsible for performing all of
                his obligations in connection with transactions entered into
                before termination and for any liabilities that accrued before
                or as a result of termination.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>6. License </p>
              <p>
                Designer hereby grants Losode a royalty-free, non-exclusive,
                worldwide right and license for the duration of designer's
                original and derivative intellectual property rights to use any
                and all of Designer's Materials for the Services or other Losode
                product or service, and to sublicense the foregoing rights to
                its Affiliates and operators of Losode Associated Properties;
                provided, however, that Losode will not alter any of Designer's
                Trademarks from the form provided by designer (except to re-size
                trademarks to the extent necessary for presentation, so long as
                the relative proportions of such trademarks remain the same) and
                will comply with designer's removal requests as to specific uses
                of Designer's Materials (provided designer are unable to do so using
                standard functionality made available to designer via the Losode
                Sites, Social Media platforms or Service); provided further,
                howsoever, that nothing in this Agreement will prevent or impair
                Losode’s right to use Designer's Materials without designer's
                consent to the extent that such use is allowable without a
                license from designer or designer's Affiliates under Nigerian Law.
              </p>
              <p style={{ fontWeight: "bold" }}>7. Representation </p>
              <p>
                Each party represents and warrants that: (a) if it is a
                business, it is duly organized, validly existing and in good
                standing under the Laws of the Federal Republic of Nigeria; (b)
                it has all requisite right, power, and authority to enter into
                this Agreement, perform its obligations, and grant the rights,
                licenses, and authorizations in this Agreement; (c) any
                information provided or made available by one party to the other
                party or its Affiliates is at all times accurate and complete.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>8. Indemnification </p>
              <p>8.1 Designer Indemnification obligations </p>
              <p>
                Designer agrees to defend, indemnify, and hold harmless Losode,
                and its officers, directors, employees, and agents, against any
                third-party claim, loss, damage, settlement, cost, expense, or
                other liability (including, without limitation, attorneys’ fees)
                arising from or related to (a) designer's non-compliance with
                applicable Laws; (b) Designer's Products, including the offer,
                sale, fulfilment, refund, cancellation, return, or adjustments
                thereof, Designer's Materials, any actual or alleged infringement
                of any Intellectual Property Rights by any of the foregoing, and
                any personal injury, death (to the extent the injury or death is
                not caused by Losode), or property damage related thereto; (c)
                Designer's Taxes and duties or the collection, payment, or failure
                to collect or pay Designer's Taxes or duties, or the failure to
                meet tax registration obligations or duties; or (d) actual or
                alleged breach of any representations designer has made.
              </p>
              <p>8.2 LOSODE’s Indemnification obligations </p>
              <p>
                Losode will defend, indemnify, and hold harmless designer and
                designer's officers, directors, employees, and agents against any
                third-party Claim arising from or related to: (a) Losode’s
                non-compliance with applicable Laws; or (b) allegations that the
                operation of a Losode store infringes or misappropriates that
                third party’s intellectual property rights.
              </p>
              <p>8.3 Process </p>
              <p>
                If any indemnified Claim might adversely affect us, Losode may,
                to the extent permitted by applicable Law, voluntarily intervene
                in the proceedings at its expense. No party may consent to the
                entry of any judgment or enter into any settlement of an
                indemnified Claim without the prior written consent of the other
                party, which may not be unreasonably withheld; except that a
                party may settle any claim that is exclusively directed at and
                exclusively affects that party.
              </p>
              <p style={{ fontWeight: "bold" }}>
                9. Disclaimer and General Release
              </p>
              <p>
                a. THE LOSODE SITES AND THE SERVICES, INCLUDING ALL CONTENT,
                SOFTWARE, FUNCTIONS, MATERIALS, AND INFORMATION MADE AVAILABLE
                ON OR PROVIDED IN CONNECTION WITH THE SERVICES, ARE PROVIDED
                "AS-IS." AS A USER OF THE SERVICES, DESIGNERS USE THE LOSODE SITES
                AND SERVICES AT DESIGNER’S OWN RISK. LOSODE AND ITS AFFILIATES
                DISCLAIM:{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                i. ANY REPRESENTATIONS OR WARRANTIES REGARDING THIS AGREEMENT,
                THE SERVICES OR THE TRANSACTIONS CONTEMPLATED BY THIS AGREEMENT,
                INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE, OR NON-INFRINGEMENT;
              </p>
              <p style={{ marginLeft: "20px" }}>
                ii. IMPLIED WARRANTIES ARISING OUT OF COURSE OF DEALING, COURSE
                OF PERFORMANCE, OR USAGE OF TRADE; AND{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                iii. ANY OBLIGATION, LIABILITY, RIGHT, CLAIM, OR REMEDY IN TORT,
                WHETHER OR NOT ARISING FROM ITS NEGLIGENCE. LOSODE DO NOT
                WARRANT THAT THE FUNCTIONS CONTAINED IN THE LOSODE SITES AND THE
                SERVICES WILL MEET DESIGNER’S REQUIREMENTS OR BE AVAILABLE,
                TIMELY, SECURE, UNINTERRUPTED, OR ERROR FREE, AND LOSODE WILL
                NOT BE LIABLE FOR ANY SERVICE INTERRUPTIONS, INCLUDING BUT NOT
                LIMITED TO SYSTEM FAILURES OR OTHER INTERRUPTIONS THAT MAY
                AFFECT THE RECEIPT, PROCESSING, ACCEPTANCE, COMPLETION, OR
                SETTLEMENT OF ANY TRANSACTIONS.
              </p>
              <p>
                b. BECAUSE LOSODE IS NOT INVOLVED IN TRANSACTIONS BETWEEN
                CUSTOMERS AND DESIGNERS OR OTHER PARTICIPANT EALINGS, IF A DISPUTE
                ARISES BETWEEN ONE OR MORE PARTICIPANTS, EACH PARTICIPANT
                RELEASES LOSODE (AND ITS AGENTS AND EMPLOYEES) FROM CLAIMS,
                DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND
                AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED,
                DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY
                CONNECTED WITH SUCH DISPUTES.
              </p>
              <p style={{ fontWeight: "bold" }}>10. Limitation of Liability </p>
              <p>
                LOSODE WILL NOT BE LIABLE (WHETHER IN CONTRACT, WARRANTY, TORT
                (INCLUDING NEGLIGENCE, PRODUCT LIABILITY, OR OTHER THEORY), OR
                OTHERWISE) TO DESIGNER OR ANY OTHER PERSON FOR COST OF COVER,
                RECOVERY, OR RECOUPMENT OF ANY INVESTMENT MADE BY DESIGNER OR
                DESIGNER’S AFFILIATES IN CONNECTION WITH THIS AGREEMENT, OR FOR
                ANY LOSS OF PROFIT, REVENUE, BUSINESS, OR DATA OR PUNITIVE OR
                CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO THIS
                AGREEMENT, EVEN IF LOSODE HAS BEEN ADVISED OF THE POSSIBILITY OF
                THOSE COSTS OR DAMAGES. FURTHER, ITS AGGREGATE LIABILITY ARISING
                OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE TRANSACTIONS
                CONTEMPLATED WILL NOT EXCEED AT ANY TIME THE TOTAL AMOUNTS
                DURING THE PRIOR SIX-MONTH PERIOD PAID BY DESIGNER TO LOSODE IN
                CONNECTION WITH THE PARTICULAR SERVICE GIVING RISE TO THE CLAIM.
              </p>
              <p style={{ fontWeight: "bold" }}>11. Tax Matters</p>
              <p>
                As between the parties, the designer shall be responsible for the
                collection, reporting, and payment of any and all of Designer's
                Taxes, except to the extent that (i) Losode automatically
                calculates, collects, or remits taxes on designer's behalf
                according to applicable law; or (ii) Losode expressly agrees to
                receive taxes or other transaction-based charges on designer's
                behalf in connection with tax calculation services made
                available by Losode and used by designer. All fees and payments
                payable by designer to Losode under this Agreement or the
                applicable Service Terms are exclusive of any applicable taxes,
                deductions or withholding, and designer will be responsible for
                paying Losode any of Designer's Taxes imposed on such fees and any
                deduction or withholding required on any payment.
              </p>
              <p style={{ fontWeight: "bold" }}>
                12. Confidentiality and Personal Data{" "}
              </p>
              <p>
                During the course of designer's use of the Losode Services, designer
                may receive Confidential Information. Designer agree that for the
                term of this Agreement and 10 years after termination:
              </p>
              <p style={{ marginLeft: "20px" }}>
                (a) all Confidential Information will remain Losode's exclusive
                property;{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (b)  designer will use Confidential Information only as is
                reasonably necessary for designer's participation in the Services;{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (c)  designer will not otherwise disclose Confidential Information
                to any other Person except as required to comply with the Law;{" "}
              </p>
              <p style={{ marginLeft: "20px" }}>
                (d) designer will take all reasonable measures to protect the
                Confidential Information against any use or disclosure that is
                not expressly permitted in this Agreement; and
              </p>
              <p style={{ marginLeft: "20px" }}>
                (e) designer will retain Confidential Information only for so long
                as its use is necessary for participation in the Services or to
                fulfil designer's statutory obligations (e.g. tax) and in all
                cases will delete such information upon termination or as soon
                as no longer required for the fulfilment of statutory
                obligations.
              </p>
              <p>
                The foregoing sentence does not restrict designer's right to share
                Confidential Information with a governmental entity that has
                jurisdiction over designer, provided that designer limit the
                disclosure to the minimum necessary and explicitly indicate the
                confidential nature of the shared information to the
                governmental entity. Designer may not issue any press release or
                make any public statement related to Losode Services, or use
                Losode’s name, trademarks, or logo, in any way (including in
                promotional material) without Losode’s advanced written
                permission or misrepresent or embellish the relationship between
                the Parties in any way
              </p>
              <p>
                Generally, designer may not use customer personal data in any way
                inconsistent with applicable Law. Designer must keep customer
                personal data confidential at all times (the above 10 years’
                term limit does not apply to customer personal data).{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>13. Force Majeure</p>
              <p>
                Losode will not be liable for any delay or failure to perfor m
                any of its obligations under this Agreement by reasons, events
                or other matters beyond its reasonable control.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>14. Relationship of Parties</p>
              <p>
                Nothing in this Agreement shall create any partnership, joint
                venture, agency, franchise, sales representative, or employment
                relationship between the Parties. Designer will have no authority
                to make or accept any offers or representations on Losode’s
                behalf. This Agreement will not create an exclusive relationship
                between designer and Losode. Nothing expressed or mentioned in or
                implied from this Agreement is intended or shall be construed to
                give to any person other than the parties to this Agreement any
                legal or equitable right, remedy, or claim under or in respect
                to this Agreement. This Agreement and all of the
                representations, warranties, covenants, conditions, and
                provisions in this Agreement are intended to be and are for the
                sole and exclusive benefit of Losode, designer, and customers. As
                between designer and Losode, designer will be solely responsible for
                all obligations associated with the use of any third-party
                service or feature that designer permit Losode to use on designer's
                behalf, including compliance with any applicable terms of use.
                Designer will not make any statement, whether on designer's site or
                otherwise, that would contradict anything in this section.
              </p>
              <p style={{ fontWeight: "bold" }}>15. Modifications </p>
              <p>
                LOSODE may change or modify this Agreement at any time with
                immediate effect (a) for legal, regulatory, fraud and abuse
                prevention, or security reasons; (b) to change existing features
                or add additional features to the Services (where this does not
                materially adversely affect designer's use of the Services); or
                (c) to restrict products or activities that Losode deem unsafe,
                inappropriate, or offensive. Losode will notify designer about any
                change or modification.{" "}
              </p>
              <p>
                Designer's continued use of Losode Services after the effective
                date of any change to this Agreement in accordance with this
                Clause 15 will constitute designer's acceptance of that change. If
                any change is unacceptable to designer, designer agree not to use
                the Services and to end the Agreement as provided in Clause 3.{" "}
              </p>
              <p style={{ fontWeight: "bold" }}>Definitions </p>
              <p>
                As used in this Agreement, the following terms have the
                following meanings:{" "}
              </p>
              <p>
                <strong>"Affiliate"</strong> means, with respect to any entity,
                any other entity that directly or indirectly controls, is
                controlled by, or is under common control with that entity.{" "}
              </p>
              <p>
                <strong>"Losode Site"</strong> means www.losode.com{" "}
              </p>
              <p>
                <strong>"Confidential Information"</strong> means information
                relating to us, to the Services, or Losode customers that is not
                known to the general public including, but not limited to, any
                information identifying or unique to specific customers;
                reports, insights, and other information about the Services;
                data derived from the Services except for data (other than
                customer personal data) arising from the sale of designer's
                products comprising of products sold, prices, sales, volumes and
                time of the transaction; and technical or operational
                specifications relating to the Services. For the purposes of
                this Agreement, customer personal data constitutes Confidential
                Information at all times.{" "}
              </p>
              <p>
                <strong>"Content"</strong> means copyrightable works under
                applicable Law and content protected by database rights under
                applicable Law.{" "}
              </p>
              <p>
                <strong>"Governing Courts"</strong> means the Federal High Court
                of Nigeria or the High Court of Lagos State.{" "}
              </p>
              <p>
                <strong>"Governing Laws"</strong> means the laws of the Federal
                Republic of Nigeria{" "}
              </p>
              <p>
                <strong>"Intellectual Property Right"</strong> means any patent,
                copyright, Trademark, domain name, moral right, trade secret
                right, or any other intellectual property right arising under
                any Laws and all ancillary and related rights, including all
                rights of registration and renewal and causes of action for
                violation, misappropriation or infringement of any of the
                foregoing.{" "}
              </p>
              <p>
                <strong>"Law"</strong> means any law, ordinance, rule,
                regulation, order, license, permit, judgment, decision, or other
                requirement, now or in the future in effect in Nigeria.{" "}
              </p>
              <p>
                <strong>"Order Information"</strong> means, with respect to any
                of Designer's Products ordered through the Losode Site, the order
                information and shipping information that Losode provide or make
                available to designer.{" "}
              </p>
              <p>
                <strong>"Person"</strong> means any individual, corporation,
                partnership, limited liability company, governmental authority,
                association, joint venture, division, or other cognizable
                entity, whether or not having distinct legal existence.{" "}
              </p>
              <p>
                <strong>"Sales Proceeds"</strong> means the gross proceeds from
                any of Designer's Transactions, including (a) all shipping and
                handling, gift wrap and other charges; (b) taxes and customs
                duties; and (c) in the case of invoiced orders, any amounts that
                customers fail to pay to us or its Affiliates on or before the
                applicable invoice due date.{" "}
              </p>
              <p>
                <strong>"Designers Portal"</strong> means the online portal and
                tools made available by Losode to designer, for designer's use in
                managing designer's orders, inventory, and presence on Losode Site
                or any other online point of presence.{" "}
              </p>
              <p>
                <strong>"Technology"</strong> means any: (a) ideas, procedures,
                processes, systems, methods of operation, concepts, principles,
                and discoveries protected or protectable under the Laws of
                Nigeria; (b) interfaces, protocols, glossaries, libraries,
                structured XML formats, specifications, grammars, data formats,
                or other similar materials; and (c) software, hardware, code,
                technology, or other functional item.{" "}
              </p>
              <p>
                <strong>"Trademark"</strong> means any trademark, service mark,
                trade dress (including any proprietary "look and feel"), trade
                name, other proprietary logo or insignia, or any other source or
                business identifier, protected or protectable under any Laws.{" "}
              </p>
              <p>
                <strong>"Designer's Materials"</strong> means Designer's Trademarks,
                Content, Designer's Product information, data, materials, and
                other items or information provided or made available by to
                Losode or its Affiliates.{" "}
              </p>
              <p>
                <strong>"Designer's Personnel"</strong> means any third party
                warranting, administering or otherwise involved in the offer,
                sale, performance, or fulfilment of Designer's Products, including
                any of designer's employees, representatives, agents, contractors,
                or subcontractors.{" "}
              </p>
              <p>
                <strong>"Designer's Product"</strong> means any product or service
                the that designer: (a) have offered through the Losode.{" "}
              </p>
              <p>
                <strong>"Designer's Sales Channels"</strong> means all sales
                channels and other means through which designer or any of designer's
                Affiliates offers products or services, other than physical
                stores.{" "}
              </p>
              <p>
                <strong>"Designer's Taxes"</strong> means any and all sales, goods
                and services, use, excise, premium, import, export, value added,
                consumption, and other taxes, regulatory fees, levies
                (specifically including environmental levies), or charges and
                duties assessed, incurred, or required to be collected or paid
                for any reason (a) in connection with any advertisement, offer
                or sale of products or services by designer on or through or in
                connection with the Services; (b) in connection with any
                products or services provided for which Designer's Products are,
                directly or indirectly, involved as a form of payment or
                exchange; or (c) otherwise in connection with any action,
                inaction, or omission of designer or any Persons providing
                products or services, or designer or their respective employees,
                agents, contractors, or representatives, for which Designer's
                Products are, directly or indirectly, involved as a form of
                payment or exchange.{" "}
              </p>
              <p>
                <strong>"Designer's Trademarks"</strong> means Trademarks of
                designer's that designer provides to Losode: (a) in non-text form
                for branding purposes; and (b) separate from (and not embedded
                or otherwise incorporated in) any product specific information
                or materials.
              </p>
              <p>
                <strong>"Designer's Transaction" </strong>means any sale of
                Designer's Product(s) by Losode.{" "}
              </p>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <style jsx="true">{`
        .about-container {
          background: #f4f4f4;
        }
        .title-div-about {
          background-image: url("/images/static-header-image-4.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 250px;
          position: relative;
        }

        .text-container {
          padding: 70px 60px;
          z-index: 1;
          display: flex;
          height: 100%;
          align-items: center;
        }
        .sm-txt {
          color: #fff;
          font: normal normal 24px/28px DomaineSansText-Light;
        }
        .lg-txt {
          color: #fff;
          font-family: "DomaineSansText-Regular";
          font-size: 48px;
          height: fit-content;
          margin-bottom: 0;
        }
        .tabs {
          background: #f2f2f261;
          padding: 16px 60px;
        }
        .tab {
        }
        .ant-tabs-tab-btn {
          font: normal normal 18px/146.16% DomaineSansText-Light;
          color: #677189;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #000;
          font-weight: 500;
        }

        .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
        .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
          background: #000;
          width: 50px;
        }
        .about-content {
          font-size: 16px;
          line-height: 30px;
          font-family: "DomaineSansText-Light";
          padding-bottom: 40px;
        }
        .about-content li {
          padding-top: 12px;
        }
        .txt-description {
        }
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-designers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-designers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }
        .panel {
          font: normal normal 24px/31px DomaineSansText-Light;
        }
        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          top: 15px;
          font-size: 16px;
          right: 0px;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        @media (max-width: 640px) {
          .about-content {
            padding: 0 20px;
            margin: 10px;
            background: #fff;
            height: auto;
            margin-bottom: 70px;
            font-family: DomaineSansText-Regular !important;
          }
          .about-content p,
          .about-content li,
          .about-content {
            font-size: 13px !important;
            font-family: DomaineSansText-Regular;
          }
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
            font-family: DomaineSansText-Regular;
          }
          .lg-txt {
            font-size: 24px;
            line-height: 26px;
            font-family: DomaineSansText-Regular;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin-left: 0;
          }
          .tabs {
            padding-left: 0;
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
            justify-content: flex-start;
          }
          .txt-description {
            font-size: 13px !important;
            font-family: DomaineSansText-Regular !important;
          }
          .tabs {
            padding: 0px;
          }
          .about-content {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default SellerAgreement;
