import { useState} from "react";
import { Document, Page } from "react-pdf";

const PdfViewer = ({ cloudinaryUrl }) => {
  const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadFail(error) {
    console.error("Error loading PDF document:", error);
  }

  return (
    <div>
      <Document
        file={cloudinaryUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadFail}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            pageNumber={index + 1}
            key={`page_${index + 1}`}
            scale={1}
            width={window.innerWidth * 0.8} // Adjust width as needed
          />
        ))}
      </Document>
      {numPages > 1 && (
        <p>
          {/* Page {pageNumber} of {numPages} */}
        </p>
      )}
    </div>
  );
};

export default PdfViewer;
