import api from "../../api";

import {
  ADD_TO_CART,
  CHANGE_ITEMS_CURRENCY,
  APPLY_COUPON,
  APPLY_COUPON_FAILED,
  CHECK_OUT,
  CHECK_OUT_FAILED,
  DELETE_PRODUCT_IN_CART,
  PAYMENT,
  PAYMENT_FAILED,
  ORDER_RECEIPT,
  UPDATE_PRODUCT_ALREADY_IN_CART,
  ORDER_RECEIPT_FAILED,
  SET_TRANSACTION_RESULT,
  DELETE_TRANSACTION_RESULT,
  RESET,
  DELETE_STORE_ITEMS,
  ON_CHECK_OUT_REQUEST,
  GET_USER_CART,
  UPDATE_ITEM,
} from "./type";

export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

export const fetchDiscount = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await api.checkout.fetchCouponDiscount(value);
      if (data.status === "ok") {
        dispatch({
          type: APPLY_COUPON,
          payload: data.data,
        });
      }

      return data;
    } catch (err) {
      dispatch({
        type: APPLY_COUPON_FAILED,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const changeItemsCurrency = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.checkout.changeCartCurrency(values);
      if (data.status === "ok") {
        dispatch({
          type: CHANGE_ITEMS_CURRENCY,
          payload: data.data,
        });
      }

      return data;
    } catch (err) {
      dispatch({
        type: CHECK_OUT_FAILED,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const checkout = (values) => {
  return async (dispatch) => {
    dispatch({
      type: ON_CHECK_OUT_REQUEST,
    });
    try {
      const { data } = await api.checkout.checkout(values);
      if (data.status === "ok") {
        dispatch({
          type: CHECK_OUT,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: CHECK_OUT_FAILED,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const payment = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await api.checkout.payment(values);
      if (data.status === "ok") {
        dispatch({
          type: PAYMENT,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: PAYMENT_FAILED,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};
export const getOrderReceipt = (ref) => {
  return async (dispatch) => {
    try {
      const { data } = await api.checkout.orderReceipt(ref);
      if (data.status === "ok") {
        dispatch({
          type: ORDER_RECEIPT,
          payload: data.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({
        type: ORDER_RECEIPT_FAILED,
        payload: err,
        error: true,
      });
      return err.response.data;
    }
  };
};

export const updateProductAlreadyInCart = (product) => ({
  type: UPDATE_PRODUCT_ALREADY_IN_CART,
  payload: product,
});

export const updateCartItem = (product) => ({
  type: UPDATE_ITEM,
  payload: product,
});

export const deleteProductInCart = (item) => ({
  type: DELETE_PRODUCT_IN_CART,
  payload: item,
});

export const deleteStoreProductInCart = (name, price) => ({
  type: DELETE_STORE_ITEMS,
  payload: { name, price },
});

export const setTranxRes = (payload) => ({
  type: SET_TRANSACTION_RESULT,
  payload,
});
export const remTranxRes = () => ({
  type: DELETE_TRANSACTION_RESULT,
});

export const reset = () => ({
  type: RESET,
});

// cart persist endpoints

export const fetchUserCart = () => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.getCart();
      if (data.status === "ok") {
        dispatch({
          type: GET_USER_CART,
          payload: data.data,
        });
      }
    } catch (err) {
      return err.response.data;
    }
  };
};
export const persistCartItem = (id, payload) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.addToCart(id, payload);

      return data;
    } catch (err) {
      return err.response.data;
    }
  };
};

export const deleteCartItem = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await api.user.removeFromCart(id);
      return data;
    } catch (err) {
      return err.response.data;
    }
  };
};
