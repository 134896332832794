import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const Notfound = () => {
  const navigate = useNavigate();

  return (
    <div className="wrapper-404">
      <div className="content">
        <img
          src="/images/icon-exclaim.png"
          alt="not found"
          className="img-404"
        />
        <h3 className="txt-404">Page not found</h3>
        <p className="txt-404-desc">
          We are sorry, but the page you are <br />
          looking for does not exist
        </p>
        <Button
          className="btn-home"
          type="primary"
          onClick={() => navigate("/")}
        >
          Continue to Losode
        </Button>
      </div>

      <style jsx="true">{`
        .footer {
          display: none;
        }
        .content {
          color: #000;
          display: flex;
          font-family: DomaineSansText-Regular;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -25%);
          width: 100%;
        }
        .img-404 {
          height: 150px;
          width: 150px;
        }
        .txt-404 {
          font-size: 60px;
          font-weight: 800;
          line-height: 90.51px;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
        }
        .txt-404-desc {
          font-size: 25px;
          font-weight: 400;
          line-height: 41.38px;
          text-align: center;
          margin: 0;
        }
        .btn-home {
          height: 63px;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          padding: 0 50px;
        }
      `}</style>
    </div>
  );
};

export default Notfound;
